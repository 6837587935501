<div class="container-fluid" style="width: 1350px; margin-top: 50px;">
    <div class="row align-items-center rounded mt-5" style="border: solid 1px grey; height: 50px;">
        <h4 class="mt-1">&nbsp;&nbsp; Reimpresión de guías</h4>
    </div><br>
    <div class="row" style="margin-top: 25px;">
        <div class="col-2">
            <div class="form-check">
                <input class="form-check-input" [(ngModel)]="tipoBusqueda" type="radio" name="tipo" id="tipo" value="1">
                <label class="form-check-label">
                  Código de Barras
                </label>
            </div>
        </div>
        <div class="col-3">
            <div class="form-group">
                <input type="text" class="form-control" [(ngModel)]="nroCB" id="exampleInputEmail1" aria-describedby="emailHelp">
            </div>
        </div>
    </div>
    <div class="row" style="margin-top: 25px;">
        <div class="col-2">
            <div class="form-check">
                <input class="form-check-input" [(ngModel)]="tipoBusqueda" type="radio" name="tipo" id="tipo" value="2">
                <label class="form-check-label">
                  Tipo guía:
                </label>
            </div>
        </div>
        <div class="col-3">
            <div class="form-group">
                <select class="form-control" [(ngModel)]="tipoGuia" id="tipoG" [disabled]="tipoBusqueda=='1'">
                  <option value="0">Elija tipo guía</option>
                  <option value="A">A</option>
                  <option value="B">B</option>
                  <option value="R">R</option>
                  <option value="E">E</option>
                  <option value="X">X</option>
                </select>
              </div>
        </div>
        <div class="col-3">
            <div class="form-group">
                <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Sucursal:</span>
                    </div>
                    <input type="text" aria-label="First name" [(ngModel)]="sucursal" class="form-control" [disabled]="tipoBusqueda=='1'">
                </div>
            </div>
        </div>
        <div class="col-3">
            <div class="form-group">
                <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Nro guía:</span>
                    </div>
                    <input type="text" aria-label="First name" [(ngModel)]="nroGuia" class="form-control" [disabled]="tipoBusqueda=='1'">
                </div>
            </div>
        </div>
        <div class="col-3">
            <div class="form-group">
                <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Cantidad de copias:</span>
                    </div>
                    <input type="text" aria-label="First name" [(ngModel)]="cantCopias" class="form-control">
                </div>
            </div>
        </div>
    </div>
    <div class="row justify-content-end" style="margin-top: 25px;">
        <div class="col-2">
            <button type="button" class="btn btn-primary" (click)="buscar()">Imprimir</button>&nbsp;
            <button type="button" class="btn btn-primary" (click)="limpiar()">Limpiar</button>
        </div>
    </div>
</div>
