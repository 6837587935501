import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { CategoriaGet, CategoriaPost, CategoriaPV } from 'src/app/models/CategoriaPV';
import { Usuario } from 'src/app/models/Usuario';
import { CategoriaPVService } from 'src/app/services/categoria-pv.service';
declare var $:any;
@Component({
  selector: 'app-abm-categorias',
  templateUrl: './abm-categorias.component.html',
  styleUrls: ['./abm-categorias.component.scss']
})
export class AbmCategoriasComponent implements OnInit {
  @ViewChild('myGrid', { static: false }) myGrid: jqxGridComponent;
  formulario:FormGroup
  categoria:CategoriaGet={};
  categorias:CategoriaPV[]=[];
  categoriasBusqueda:CategoriaPV[]=[];
  usuario:Usuario;
  cargando:boolean=true;
  
  source: any =
    {
      datatype: 'json',
        datafields: [
          { name: 'CategoriaPVID'},
          { name: 'CategoriaPVDescrip'},
          { name: 'FechaAlta'},
          { name:'Baja'}
        ],
      localdata:this.categorias
    };
	getWidth() : any {
		if (document.body.offsetWidth < 500) {
			return '90%';
		}
		
		return 500;
	}
  dataAdapter: any;
    cellsrenderer = (row: number, columnfield: string, value: string | number, defaulthtml: string, columnproperties: any, rowdata: any): string => {
        if (value < 20) {
            return '<span style="margin: 4px; float: ' + columnproperties.cellsalign + '; color: #ff0000;">' + value + '</span>';
        }
        else {
            return '<span style="margin: 4px; float: ' + columnproperties.cellsalign + '; color: #008000;">' + value + '</span>';
        }
    };
    columns: any[] =
    [
      { text: 'Categoría ID', datafield: 'CategoriaPVID', width: 200, hidden:true},
      { text: 'Categoría Nombre', datafield: 'CategoriaPVDescrip', width: 300},
      { text: 'Fecha Alta', datafield: 'FechaAlta', width: 300, hidden:true},
      { text: 'Baja', datafield: 'Baja', width: 300, hidden:true},
      { text: 'Editar', datafield: 'Editar', columntype: 'button', width: 100,
        cellsrenderer: (): string => {
          return 'Editar';
        },
        buttonclick: (row:number)=>{
          let dataRecord=this.myGrid.getrowdata(row);
          this.formulario.controls['id'].setValue(dataRecord.CategoriaPVID);
          this.formulario.controls['nombre'].setValue(dataRecord.CategoriaPVDescrip);
          this.formulario.controls['fecha'].setValue(dataRecord.FechaAlta);
          this.formulario.controls['baja'].setValue(dataRecord.Baja);
          $("#modalCategoria").modal('show');
        }
      },
      { text: 'Eliminar', datafield: 'Eliminar', columntype: 'button', width: 100,
        cellsrenderer: (): string => {
          return 'Eliminar';
        },
        buttonclick: (row:number)=>{
          var opcion=confirm("¿Está seguro que desea eliminar la categoría?");
          if(opcion){
            let dataRecord=this.myGrid.getrowdata(row);
            this.delete(dataRecord);
          }
        }
      }
    ];
  constructor(private categoriaService:CategoriaPVService, private fb:FormBuilder, private router:Router) { }


  ngOnInit(): void {
    this.usuario=JSON.parse(sessionStorage.getItem('user'));
    if(this.usuario!=null && this.usuario!=undefined){
      for(var i=0; i<this.usuario.OpcionesMenu.length; i++){
        if(this.usuario.OpcionesMenu[i].Url=='categorias'){
          break;
        }else if(i==this.usuario.OpcionesMenu.length-1){

          //window.location.href='home';
          this.router.navigate(['/home'])
        }
      }
      if(this.usuario.OpcionesMenu.length==0){
        this.router.navigate(['/home'])
      }
    }else{
      //window.location.href='home';
      this.router.navigate(['/home'])
    }
    this.categoriaService.getAllCategorias().subscribe((res)=>{
      this.categoria=res;
      this.categorias=this.categoria.Categorias;
      this.source.localdata=this.categorias;
      this.mostrarGrid();
    })
    //this.categorias=this.categoriaService.getAllCategorias();
    this.source.localdata=this.categorias;
    this.mostrarGrid();
    this.formulario=this.fb.group({
      id:[''],
      nombre:[''],
      fecha:[''],
      baja:['']
    })
  }

  mostrarGrid(){
    this.dataAdapter = new jqx.dataAdapter(this.source);
    this.cargando=false;
  }

  buscarCategoria(nameBusqueda:string){
    console.log(nameBusqueda)
    this.source.localdata=this.categorias.filter(categoria=>{
      return categoria.CategoriaPVDescrip.toLowerCase().includes(nameBusqueda.toLowerCase());
    })
    this.mostrarGrid();
  }

  abrirModal(){
    this.formulario.controls['id'].setValue('');
    this.formulario.controls['nombre'].setValue('');
    $("#modalCategoria").modal('show');
  }
  
  save(){
    if(this.formulario.controls['id'].value==='' || this.formulario.controls['id'].value===0){
      var categoriaNueva:CategoriaPost={};
      categoriaNueva.Categoria={};
      categoriaNueva.Categoria.CategoriaPVID=0;
      categoriaNueva.Categoria.CategoriaPVDescrip=this.formulario.controls['nombre'].value;
      categoriaNueva.Categoria.FechaAlta=new Date();
      categoriaNueva.Categoria.Baja=false;
      this.categoriaService.guardarCategoria(categoriaNueva).subscribe((res)=>{
        if(res.Codigo===200){
          alert(res.Mensaje);
          this.categorias.push(res.Categoria);
          this.source.localdata=this.categorias;
          this.mostrarGrid();
        }else{
          alert(res.Mensaje);
        }
      })
      $("#modalCategoria").modal('hide');
    }else{
      var categoriaNueva:CategoriaPost={};
      categoriaNueva.Categoria={};
      categoriaNueva.Categoria.CategoriaPVID=this.formulario.controls['id'].value;
      categoriaNueva.Categoria.CategoriaPVDescrip=this.formulario.controls['nombre'].value;
      categoriaNueva.Categoria.FechaAlta=this.formulario.controls['fecha'].value;
      categoriaNueva.Categoria.Baja=this.formulario.controls['baja'].value;
      this.categoriaService.guardarCategoria(categoriaNueva).subscribe((res)=>{
        if(res.Codigo===200){
          alert(res.Mensaje);
          for(var i:number=0; i<this.categorias.length; i++){
            if(this.categorias[i].CategoriaPVID===res.Categoria.CategoriaPVID){
              this.categorias[i]=res.Categoria;
            }
          }
          this.source.localdata=this.categorias;
          this.mostrarGrid();
        }else{
          alert(res.Mensaje);
        }
        
      })
      $("#modalCategoria").modal('hide');
    }
    
  }

  delete(row:any){
    var categoriaNueva:CategoriaPost={};
    categoriaNueva.Categoria={};
    categoriaNueva.Categoria.CategoriaPVID=row.CategoriaPVID;
    categoriaNueva.Categoria.CategoriaPVDescrip=row.CategoriaPVDescrip;
    categoriaNueva.Categoria.FechaAlta=row.FechaAlta;
    categoriaNueva.Categoria.Baja=true;
    
    this.categoriaService.guardarCategoria(categoriaNueva).subscribe((res)=>{
      if(res.Codigo===200){
        for(var i:number=0; i<this.categorias.length; i++){
          if(this.categorias[i].CategoriaPVID===categoriaNueva.Categoria.CategoriaPVID){
            this.categorias.splice(i,1);
          }
        }
        this.source.localdata=this.categorias;
        this.mostrarGrid();
      }else{
        alert(res.Mensaje);
      }
    })
  }
}
