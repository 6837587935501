import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AnularFacturaPost, BusquedaFacturaGet, BusquedaFacturaPost, BusquedaGuiaGet, BusquedaGuiaPost, ConfirmarGuiaPost, ConformacionMasivaGet, ConformacionMasivaPost, DatosFacturaGet, DatosFacturaPost, getObservaciones, ObservacionesPost, RecepcionMasivaGet, RecepcionMasivaPost } from '../models/Orden-Guia';
import { UrlService } from './url.service';

@Injectable({
  providedIn: 'root'
})
export class RecepcionService {


  urlBusqueda:string=this.urlService.urlApi+"/api/Recepcion/BuscarGuia";
  urlConfirmar:string=this.urlService.urlApi+"/api/Recepcion/GuardarEstadoRecepcion";
  urlRecepcionMasiva:string=this.urlService.urlApi+"/api/Recepcion/RecepcionMasiva";
  urlDatosFactura:string=this.urlService.urlApi+"/api/Recepcion/GetDatosFacturaRemito";
  urlConformacionMasiva:string=this.urlService.urlApi+"/api/Recepcion/ConformacionMasiva";
  urlBuscarFactura:string=this.urlService.urlApi+"/api/Recepcion/BuscarFactura";
  urlAnularFactura:string=this.urlService.urlApi+"/api/Recepcion/AnularFactura";

  constructor(private http:HttpClient, private urlService:UrlService) { }

  busquedaGuiaRecepcion(datos:BusquedaGuiaPost):Observable<BusquedaGuiaGet>{
    return this.http.post<BusquedaGuiaGet>(this.urlBusqueda, datos);
  }

  confirmarGuiaRecepcion(datos:ConfirmarGuiaPost):Observable<BusquedaGuiaGet>{
    return this.http.post<BusquedaGuiaGet>(this.urlConfirmar, datos);
  }

  recepcionMasiva(datos:RecepcionMasivaPost):Observable<RecepcionMasivaGet>{
    return this.http.post<RecepcionMasivaGet>(this.urlRecepcionMasiva, datos);
  }

  getDatosFactura(datos:DatosFacturaPost):Observable<DatosFacturaGet>{
    return this.http.post<DatosFacturaGet>(this.urlDatosFactura, datos);
  }

  conformacionMasiva(datos:ConformacionMasivaPost):Observable<ConformacionMasivaGet>{
    return this.http.post<ConformacionMasivaGet>(this.urlConformacionMasiva, datos);
  }

  buscarFactura(datos:BusquedaFacturaPost):Observable<BusquedaFacturaGet>{
    return this.http.post<BusquedaFacturaGet>(this.urlBuscarFactura, datos);
  }

  anularFactura(datos:AnularFacturaPost):Observable<BusquedaFacturaGet>{
    return this.http.post<BusquedaFacturaGet>(this.urlAnularFactura, datos);
  }
}
