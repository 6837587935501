<div class="container-fluid" style="width: 1350px; margin-top: 50px;">
    <div class="row align-items-center rounded mt-5" style="border: solid 1px grey; height: 50px;">
        <h4 class="mt-1">&nbsp;&nbsp; Seguimiento</h4>
    </div><br>
    <div class="row" style="margin-top: 25px;">
        <div class="col-4">
            <div class="form-group">
                <label class="form-check-label">
                    Número Orden
                  </label>
                <input type="text" class="form-control" [(ngModel)]="planillaRetiro" id="planRet" name="planRet" aria-describedby="emailHelp">
            </div>
        </div>
        <div class="col-4">
            <div class="form-group">
                <label class="form-check-label">
                    Número Guía
                  </label>
                <input type="text" class="form-control" [(ngModel)]="cbGuia" id="codBG" name="codBG" aria-describedby="emailHelp">
            </div>
        </div>
        <div class="col-4">
            <div class="form-group">
                <label class="form-check-label">
                    Número Seguimiento Único
                  </label>
                <input type="text" class="form-control" [(ngModel)]="nroSeguimiento" id="nroSeg" name="nroSeg" aria-describedby="emailHelp">
            </div>
        </div>
    </div>
    
    <div class="row justify-content-end" style="margin-top: 25px;">
        <div class="col-4">
            <button type="button" class="btn btn-primary" (click)="consultar()" >Consultar</button>&nbsp;
            <button type="button" class="btn btn-primary" (click)="limpiar()">Limpiar</button>
        </div>
    </div><br>
    <div class="row" *ngIf="estados.length>0">
        <table id="guiasSeguimiento" class="table table-bordered"  style="white-space: nowrap; margin-left: -100px;"><!-- white-space: nowrap para ajustar columnas a contenido -->
            <thead>
              <tr>
                <th scope="col">Número Orden</th>
                <th scope="col">Número Solitud</th>
                <th scope="col">Estado</th>
                <th scope="col">Fecha Estado</th>
                <th scope="col">Número Seguimiento</th>
                <th scope="col">Destino</th>
                <th scope="col">Nombre</th>
                <th scope="col">Razón Social</th>
                <th scope="col">Destinatario</th>
                <th scope="col">Agencia Origen</th>
                <th scope="col">Modalidad</th>
                <th scope="col">Observacion</th>
                <th scope="col">Usuario</th>
                
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let e of estados">
                <th>{{e.NumeroOrden}}</th>
                <td>{{e.NumeroSolicitud}}</td>
                <td>{{e.Estado}}</td>
                <td>{{e.FechaEstado}}</td>
                <td>{{e.NroSeguimiento}}</td>
                <td>{{e.Destino}}</td>
                <td>{{e.Nombre}}</td>
                <td>{{e.RazonSocial}}</td>
                <td>{{e.Destinatario}}</td>
                <td>{{e.AgenciaOrigen}}</td>
                <td>{{e.Modalidad}}</td>
                <td>{{e.Observacion}}</td>
                <td>{{e.Usuario}}</td>
              </tr>
            </tbody>
          </table>
    </div>
</div>

