
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { CategoriaPV } from 'src/app/models/CategoriaPV';
import { DomicilioLocal } from 'src/app/models/Domicilio';
import { CAI, DiaGuiaRendicionPV, DiasRendicionPV, Empresa, PuntosVentaGetId, PuntoVentaGet, PuntoVentaGetId, PuntoVentaGetPost} from 'src/app/models/PuntoVenta';
import { Responsable } from 'src/app/models/Responsable';
import { Usuario } from 'src/app/models/Usuario';
import { CategoriaPVService } from 'src/app/services/categoria-pv.service';
import { DomicilioService } from 'src/app/services/domicilio.service';
import { ResponsableService } from 'src/app/services/responsable.service';
declare var $:any;

@Component({
  selector: 'app-abm-puntos-venta',
  templateUrl: './abm-puntos-venta.component.html',
  styleUrls: ['./abm-puntos-venta.component.scss']
})
export class AbmPuntosVentaComponent implements OnInit {

  @ViewChild('puntosVentaGrid', { static: false }) puntosVentaGrid: jqxGridComponent;
  @ViewChild('guiasGrid', { static: false }) guiasGrid: jqxGridComponent;
  @ViewChild('myWindow', { static: false }) myWindow: jqxWindowComponent;
  usuario:Usuario;
  cargando:boolean=true;

  puntosVenta:PuntoVentaGet[]=[];
  formPV:PuntoVentaGetPost={};
  puntosVentaBusqueda:PuntoVentaGet[]=[];
  puntoVentaGetId:PuntosVentaGetId={};
  empresasFacturacion:Empresa[]=[
    {
      EmpresaFacturacionPuntoVentaID:0,
      PuntoVentaID:0,
      EmpresaFacturacionID:1,
      TipoValidacionEmisionID:1,
      SucursalDGI:'',
      FechaInicioACT:new Date(),
      NumeroEstablecimiento:'',
    },
    {
      EmpresaFacturacionPuntoVentaID:0,
      PuntoVentaID:0,
      EmpresaFacturacionID:1,
      TipoValidacionEmisionID:2,
      SucursalDGI:'',
      FechaInicioACT:new Date(),
      NumeroEstablecimiento:'',
    },
    {
      EmpresaFacturacionPuntoVentaID:0,
      PuntoVentaID:0,
      EmpresaFacturacionID:2,
      TipoValidacionEmisionID:1,
      SucursalDGI:'',
      FechaInicioACT:new Date(),
      NumeroEstablecimiento:'',
    },
    {
      EmpresaFacturacionPuntoVentaID:0,
      PuntoVentaID:0,
      EmpresaFacturacionID:2,
      TipoValidacionEmisionID:2,
      SucursalDGI:'',
      FechaInicioACT:new Date(),
      NumeroEstablecimiento:'',
    }
  ];
  //domicilio
  domicilio:DomicilioLocal={};
  provinciaDom:string='';
  localidadDom:string='';
  cpDom:string='';
  calleDom:string='';
  nroDom:string='';
  telefono1:string='';
  telefono2:string='';
  observacionesDom:string='';
  domicilioAgregado:boolean=false;
  
  //datos búsqueda
  rs:string='';
  sucDGI:string='';
  baja:string=null;
  nomb:string='';
  cat:string='';
  /* dniBusqueda:string='';
  apeNomBusqueda:string='';
  responsablesBusqueda:Responsable[]=[]; */

  //muestra o no la grilla de guías cai
  mostrarGuias:boolean=false;
  agregarGuias:boolean=false;
  mostrarBoton:boolean=true;

  //datos formulario
  idPV:number=0;
  nombre:string='';
  credito:number;
  bloqueaValor:boolean=false;
  diasRendicion:DiasRendicionPV[]=[];
  diasGuiasRendicion:DiaGuiaRendicionPV[]=[];
  razonSocial:string='';
  nroSucursal:string='';
  disponible:boolean=true;
  dniResponsable:string='';
  apeNomResponsable:string='';
  responsable:Responsable={};
  categoriaPV:string='0'; //id categoría
  categoriaInput:string=''; 
  diasRendicionInput:string=null; //validar que hay días seleccionados
  diasGuiasRendicionInput:string=null; //validar que hay días seleccionados
  guias:CAI[]=[]; //guias para grilla
  guiaNueva:CAI={}; //guía que agrego al array
  tipoGuia:string=null;
  nroCAI:string='';
  fechaVtoCAI:Date;
  empresa:string=null;
  empresasPV:Empresa[]=[]
  idGuia:number=0;
  //datos para seleccionar
  categoriasSelec:CategoriaPV[]=[];
  responsables:Responsable[]=[];
  
  empresasFacturacionAux:Empresa[]=[
    {
      EmpresaFacturacionPuntoVentaID:0,
      PuntoVentaID:0,
      EmpresaFacturacionID:1,
      TipoValidacionEmisionID:1,
      SucursalDGI:'',
      FechaInicioACT:new Date(),
      NumeroEstablecimiento:'',
    },
    {
      EmpresaFacturacionPuntoVentaID:0,
      PuntoVentaID:0,
      EmpresaFacturacionID:1,
      TipoValidacionEmisionID:2,
      SucursalDGI:'',
      FechaInicioACT:new Date(),
      NumeroEstablecimiento:'',
    },
    {
      EmpresaFacturacionPuntoVentaID:0,
      PuntoVentaID:0,
      EmpresaFacturacionID:2,
      TipoValidacionEmisionID:1,
      SucursalDGI:'',
      FechaInicioACT:new Date(),
      NumeroEstablecimiento:'',
    },
    {
      EmpresaFacturacionPuntoVentaID:0,
      PuntoVentaID:0,
      EmpresaFacturacionID:2,
      TipoValidacionEmisionID:2,
      SucursalDGI:'',
      FechaInicioACT:new Date(),
      NumeroEstablecimiento:'',
    }
  ];

  //jqxgrid-pv
  source: any =
    {
      datatype: 'json',
        datafields: [
          { name: 'PuntoVentaID'},
          { name: 'SucursalDGI'},
          { name: 'RazonSocial'},
          { name: 'PuntoVentaDescrip'},
          { name: 'CategoriaNombre'},
          { name: 'Baja'}
        ]
    };
	getWidth() : any {
		if (document.body.offsetWidth < 900) {
			return '90%';
		}
		
		return 900;
	}
  dataAdapter: any;
    cellsrenderer = (row: number, columnfield: string, value: string | number, defaulthtml: string, columnproperties: any, rowdata: any): string => {
        if (value < 20) {
            return '<span style="margin: 4px; float: ' + columnproperties.cellsalign + '; color: #ff0000;">' + value + '</span>';
        }
        else {
            return '<span style="margin: 4px; float: ' + columnproperties.cellsalign + '; color: #008000;">' + value + '</span>';
        }
    };
    columns: any[] =
    [
      { text: 'Punto Venta ID', datafield: 'PuntoVentaID', width: 100, hidden:true},
      { text: 'Sucursal DGI', datafield: 'SucursalDGI', width: 100},
      { text: 'Razón Social', datafield: 'RazonSocial', width: 200},
      { text: 'Nombre', datafield: 'PuntoVentaDescrip', width: 200},
      { text: 'Categoría', datafield: 'CategoriaNombre', width: 100},
      { text: 'Estado', datafield: 'Baja', width: 100,
        cellsrenderer:(index:number, datafield:boolean, value:any, defaultvalue:any, columnproperties: any, column:any, rowdata:any):string=>{
          if(value===false){
            value="Habilitada"
            return '<span style="margin: 4px; float: ' + columnproperties.cellsalign + ';">'+ value +'</span>';
          }else if(value===true){
            value="Deshabilitada"
            return '<span style="margin: 4px; float: ' + columnproperties.cellsalign + ';"> '+ value +' </span>';
          }
        }
      },
      { text: 'Editar', datafield: 'Editar', columntype: 'button', width: 100,
        cellsrenderer: (): string => {
          return 'Editar';
        },
        buttonclick: (row:number)=>{
          let dataRecord=this.puntosVentaGrid.getrowdata(row);
          this.abrirModal(dataRecord.PuntoVentaID);
        }
      },
      { text: 'Deshabilitar', datafield: 'Deshabilitar', columntype: 'button', width: 100,
        cellsrenderer: (): string => {
          return 'Deshabilitar';
        },
        buttonclick: (row:number)=>{
          var opcion=confirm("¿Está seguro que quiere deshabilitar el punto venta?");
          if(opcion){
            let dataRecord=this.puntosVentaGrid.getrowdata(row);
            this.delete(dataRecord.PuntoVentaID);
          }
        }
      }
    ];

  //jqxgrid-guias
  source2: any =
  {
    datatype: 'json',
      datafields: [
        { name: 'PuntoVentaCAIID'},
        { name: 'PuntoVentaID'},
        { name: 'TipoGuia'},
        { name: 'NumeroCAI'},
        { name: 'FechaVencimientoCAI'},
        { name: 'EmpresaFacturacionID'}
      ]
  };
	getWidth2() : any {
		if (document.body.offsetWidth < 800) {
			return '90%';
		}
		
		return 800;
	}
  dataAdapter2: any;
    cellsrenderer2 = (row: number, columnfield: string, value: string | number, defaulthtml: string, columnproperties: any, rowdata: any): string => {
        if (value < 20) {
            return '<span style="margin: 4px; float: ' + columnproperties.cellsalign + '; color: #ff0000;">' + value + '</span>';
        }
        else {
            return '<span style="margin: 4px; float: ' + columnproperties.cellsalign + '; color: #008000;">' + value + '</span>';
        }
    };
    columns2: any[] =
    [
      { text: 'PuntoVentaCAIID', datafield: 'PuntoVentaCAIID', width: 100, hidden:true},
      { text: 'PuntoVentaID', datafield: 'PuntoVentaID', width: 100, hidden:true},
      { text: 'Tipo Guia', datafield: 'TipoGuia', width: 100},
      { text: 'Número CAI', datafield: 'NumeroCAI', width: 150},
      { text: 'Fecha vto CAI', datafield: 'FechaVencimientoCAI', width: 100},
      { text: 'Empresa', datafield: 'EmpresaFacturacionID', width: 250,
        cellsrenderer:(index:number, datafield:boolean, value:any, defaultvalue:any, columnproperties: any, column:any, rowdata:any):string=>{
          if(value===1){
            value="Autotransportes Andesmar S.A."
            return '<span style="margin: 4px; float: ' + columnproperties.cellsalign + ';">'+ value +'</span>';
          }else if(value===2){
            value="Andesmar Cargas S. A."
            return '<span style="margin: 4px; float: ' + columnproperties.cellsalign + ';"> '+ value +' </span>';
          }
        }
      },
      { text: 'Editar', datafield: 'Editar', columntype: 'button', width: 100,
        cellsrenderer: (): string => {
          return 'Editar';
        },
        buttonclick: (row:number)=>{
          let dataRecord=this.guiasGrid.getrowdata(row);
          this.tipoGuia=dataRecord.TipoGuia;
          this.nroCAI=dataRecord.NumeroCAI;
          //const fecha=new Date(formatDate(dataRecord.fechaVtoCAI, "dd-MM-yyyy", 'es-ARG', 'GMT-0300'));
          this.fechaVtoCAI=dataRecord.FechaVencimientoCAI;
          this.empresa=dataRecord.EmpresaFacturacionID;
          this.idGuia=dataRecord.PuntoVentaCAIID;
          this.mostrarBoton=false;
          this.agregarGuias=true;
          if(dataRecord.PuntoVentaCAIID===0){
            for(var i:number=0; i<this.guias.length; i++){
              if(this.guias[i].TipoGuia===dataRecord.TipoGuia &&
                this.guias[i].NumeroCAI===dataRecord.NumeroCAI &&
                this.guias[i].FechaVencimientoCAI===dataRecord.FechaVencimientoCAI &&
                this.guias[i].EmpresaFacturacionID===dataRecord.EmpresaFacturacionID){
                  this.guias.splice(i,1);
                  break;
              }
            }
          }
        }
      },
      { text: 'Eliminar', datafield: 'Eliminar', columntype: 'button', width: 100,
        cellsrenderer: (): string => {
          return 'Eliminar';
        },
        buttonclick: (row:number)=>{
          let dataRecord=this.guiasGrid.getrowdata(row);
          this.deleteGuia(dataRecord);
          
        }
      }
    ];
  
  constructor(private pvService:CategoriaPVService, private router:Router, private responsableService:ResponsableService, private domicilioService:DomicilioService) { }

  ngOnInit(): void {
    this.usuario=JSON.parse(sessionStorage.getItem('user'));
    if(this.usuario!=null && this.usuario!=undefined){
      for(var i=0; i<this.usuario.OpcionesMenu.length; i++){
        if(this.usuario.OpcionesMenu[i].Url=='punto-venta'){
          break;
        }else if(i==this.usuario.OpcionesMenu.length-1){
          //window.location.href='home';
          this.router.navigate(['/home'])
        }
      }
      if(this.usuario.OpcionesMenu.length==0){
        this.router.navigate(['/home'])
      }
    }else{
      //window.location.href='home';
      this.router.navigate(['/home'])
    }
    this.pvService.getAllPV().subscribe((res)=>{
      this.puntosVenta=res.Puntos;
      this.source.localdata=this.puntosVenta;
      this.mostrarGrid();
    })
    this.responsableService.seleccionarResponsableObservable.subscribe((respSelec)=>{
      this.responsable=respSelec;
      this.dniResponsable=this.responsable.NroDocumento;
      this.apeNomResponsable=this.responsable.Apellido+' '+this.responsable.Nombre;
    })
    this.responsableService.getAllResponsables().subscribe((res)=>{
      this.responsables=res.Responsables;
    })
    this.pvService.getAllCategorias().subscribe((res)=>{
      this.categoriasSelec=res.Categorias;
    })

    this.domicilioService.agregarDomicilioObservable.subscribe((domicilio)=>{
      if(domicilio===null){
        this.domicilioAgregado=false;
      }else{
        this.domicilioAgregado=true;
        this.domicilio=domicilio;
        this.calleDom=this.domicilio.Calle;
        this.nroDom=this.domicilio.CalleNro;
        this.provinciaDom=this.domicilio.ProvinciaDescrip;
        this.localidadDom=this.domicilio.LocalidadDescrip;
        this.cpDom=this.domicilio.CodigoPostalNro;
        this.telefono1=this.domicilio.Telefono;
        this.telefono2=this.domicilio.Telefono2;
        this.observacionesDom=this.domicilio.Observacion;
      }
    });

  }

  mostrarGrid(){
    this.dataAdapter = new jqx.dataAdapter(this.source);
    this.cargando=false;
  }

  validarNro(nroSucursal:string){
    if(nroSucursal!==null){
      if(this.idPV===0){
        for(let pv of this.puntosVenta){
          if(nroSucursal.toString()===pv.SucursalDGI){
            console.log('igual')
            this.disponible=false;
            break;
          }else{
            this.disponible=true;
          }
        }
      }
    }
  }

  agregarCheckDiasRendicion(event){
    if(this.idPV===0){
      if(event.target.checked){
        var diaRendicion:DiasRendicionPV={};
        diaRendicion.DiaID=parseInt(event.target.value);
        diaRendicion.PuntoVentaID=this.idPV;
        this.diasRendicion.push(diaRendicion);
        this.diasRendicionInput=this.diasRendicion.length.toString();
      }else{
        for(var i:number=0; i<this.diasRendicion.length; i++){
          if(this.diasRendicion[i].DiaID===parseInt(event.target.value)){
            this.diasRendicion.splice(i,1);
          }
          if(this.diasRendicion.length===0){
            this.diasRendicionInput=null;
          }else{
            this.diasRendicionInput=this.diasRendicion.length.toString();
          }
        }
      }
      console.log(this.diasRendicion)
    }else{
      if(event.target.checked){
        var diaRendicion:DiasRendicionPV={};
        diaRendicion.DiaID=parseInt(event.target.value);
        diaRendicion.PuntoVentaID=this.idPV;
        this.diasRendicion.push(diaRendicion);
        this.diasRendicionInput=this.diasRendicion.length.toString();
      }else{
        for(var i:number=0; i<this.diasRendicion.length; i++){
          if(this.diasRendicion[i].DiaID===parseInt(event.target.value)){
            this.diasRendicion.splice(i,1);
          }
          if(this.diasRendicion.length===0){
            this.diasRendicionInput=null;
          }else{
            this.diasRendicionInput=this.diasRendicion.length.toString();
          }
        }
      }
      console.log(this.diasRendicion)
    }
  }

  agregarCheckDiasGuiasRendicion(event){
    if(this.idPV===0){
      if(event.target.checked){
        var diaGuiaRendicion:DiaGuiaRendicionPV={}
        diaGuiaRendicion.DiaID=parseInt(event.target.value);
        diaGuiaRendicion.PuntoVentaID=this.idPV;
        this.diasGuiasRendicion.push(diaGuiaRendicion);
        this.diasGuiasRendicionInput=this.diasGuiasRendicion.length.toString();
      }else{
        for(var i:number=0; i<this.diasGuiasRendicion.length; i++){
          if(this.diasGuiasRendicion[i].DiaID===parseInt(event.target.value)){
            this.diasGuiasRendicion.splice(i,1);
          }
          if(this.diasGuiasRendicion.length===0){
            this.diasGuiasRendicionInput=null;
          }else{
            this.diasGuiasRendicionInput=this.diasGuiasRendicion.length.toString();
          }
        }
      }
      console.log(this.diasGuiasRendicion);
    }else{
      if(event.target.checked){
        var diaGuiaRendicion:DiaGuiaRendicionPV={}
        diaGuiaRendicion.DiaID=parseInt(event.target.value);
        diaGuiaRendicion.PuntoVentaID=this.idPV;
        this.diasGuiasRendicion.push(diaGuiaRendicion);
        this.diasGuiasRendicionInput=this.diasGuiasRendicion.length.toString();
      }else{
        for(var i:number=0; i<this.diasGuiasRendicion.length; i++){
          if(this.diasGuiasRendicion[i].DiaID===parseInt(event.target.value)){
            this.diasGuiasRendicion.splice(i,1);
          }
          if(this.diasGuiasRendicion.length===0){
            this.diasGuiasRendicionInput=null;
          }else{
            this.diasGuiasRendicionInput=this.diasGuiasRendicion.length.toString();
          }
        }
      }
      console.log(this.diasGuiasRendicion);
    }
  }

  abrirModal(idPV:number){
    if(idPV===0){
      alert("Es necesario dar de alta una agencia para un punto de venta nuevo. Comunicarse con administración");
      this.idPV=0;
      this.nombre='';
      this.nroSucursal='';
      this.razonSocial='';
      this.dniResponsable='';
      this.apeNomResponsable='';
      this.bloqueaValor=false;
      this.categoriaPV=null;
      this.credito=null;
      this.diasGuiasRendicion=[];
      this.diasRendicion=[];
      this.diasGuiasRendicionInput='';
      this.diasRendicionInput='';
      this.mostrarGuias=false;
      this.mostrarBoton=true;
      this.agregarGuias=false;
      this.empresasFacturacion=null;
      this.empresasFacturacion=[];
      this.empresasFacturacion=this.empresasFacturacionAux;
      this.baja='null';
      this.domicilioAgregado=false;
      this.domicilio=null;
      $("#fechaVto").val(null);

      $("#ckDR1").prop('checked', false);
      $("#ckDR2").prop('checked', false);
      $("#ckDR3").prop('checked', false);
      $("#ckDR4").prop('checked', false);
      $("#ckDR5").prop('checked', false);
      $("#ckDR6").prop('checked', false);
      $("#ckDR7").prop('checked', false);
      
      $("#ckGDR1").prop('checked', false);
      $("#ckGDR2").prop('checked', false);
      $("#ckGDR3").prop('checked', false);
      $("#ckGDR4").prop('checked', false);
      $("#ckGDR5").prop('checked', false);
      $("#ckGDR6").prop('checked', false);
      $("#ckGDR7").prop('checked', false);

      //$("#modalPuntoVenta").modal('show');
      
      
    }else{
      this.mostrarBoton=true;
      this.agregarGuias=false;
      var pvId:PuntoVentaGetId={}
      pvId.PuntoVentaID=idPV;
      this.pvService.getPVById(pvId).subscribe((res)=>{
        this.puntoVentaGetId=res;
        this.idPV=this.puntoVentaGetId.Punto.PuntoVentaID;
        this.nombre=this.puntoVentaGetId.Punto.PuntoVentaDescrip;
        this.nroSucursal=this.puntoVentaGetId.Punto.SucursalDGI;
        this.razonSocial=this.puntoVentaGetId.Punto.RazonSocial;
        this.categoriaPV=this.puntoVentaGetId.Punto.CategoriaPVID.toString();
        for(let responsable of this.responsables){
          if(responsable.ResponsableID===this.puntoVentaGetId.Punto.ResponsableID){
            this.dniResponsable=responsable.NroDocumento;
            this.responsable=responsable;
          }
        }
        this.apeNomResponsable=this.puntoVentaGetId.Punto.ResponsableNombre;
        
        if(this.puntoVentaGetId.Punto.BloqueaValor===0){
          this.bloqueaValor=false;
        }else{
          this.bloqueaValor=true;
        }
        this.credito=this.puntoVentaGetId.Punto.Credito;
        if(this.puntoVentaGetId.Punto.Baja){
          this.baja='true';
        }else{
          this.baja='false';
        }
        if(this.puntoVentaGetId.CAIs.length!==0){
          this.guias=this.puntoVentaGetId.CAIs;
          this.source2.localdata=this.guias;
          this.dataAdapter2 = new jqx.dataAdapter(this.source2);
          this.mostrarGuias=true;
        }
        
        if(this.puntoVentaGetId.DiasRendicion.length!==0){
          for(var i:number=0; i<this.puntoVentaGetId.DiasRendicion.length; i++){
            if(this.puntoVentaGetId.DiasRendicion[i].DiaID.toString()===$("#ckDR1").val()){
              $("#ckDR1").prop('checked', true);
              this.diasRendicion.push(this.puntoVentaGetId.DiasRendicion[i]);
              this.diasRendicionInput=this.diasRendicion.length.toString();
            }else if(this.puntoVentaGetId.DiasRendicion[i].DiaID.toString()===$("#ckDR2").val()){
              $("#ckDR2").prop('checked', true);
              this.diasRendicion.push(this.puntoVentaGetId.DiasRendicion[i]);
              this.diasRendicionInput=this.diasRendicion.length.toString();
            }else if(this.puntoVentaGetId.DiasRendicion[i].DiaID.toString()===$("#ckDR3").val()){
              $("#ckDR3").prop('checked', true);
              this.diasRendicion.push(this.puntoVentaGetId.DiasRendicion[i]);
              this.diasRendicionInput=this.diasRendicion.length.toString();
            }else if(this.puntoVentaGetId.DiasRendicion[i].DiaID.toString()===$("#ckDR4").val()){
              $("#ckDR4").prop('checked', true);
              this.diasRendicion.push(this.puntoVentaGetId.DiasRendicion[i]);
              this.diasRendicionInput=this.diasRendicion.length.toString();
            }else if(this.puntoVentaGetId.DiasRendicion[i].DiaID.toString()===$("#ckDR5").val()){
              $("#ckDR5").prop('checked', true);
              this.diasRendicion.push(this.puntoVentaGetId.DiasRendicion[i]);
              this.diasRendicionInput=this.diasRendicion.length.toString();
            }else if(this.puntoVentaGetId.DiasRendicion[i].DiaID.toString()===$("#ckDR6").val()){
              $("#ckDR6").prop('checked', true);
              this.diasRendicion.push(this.puntoVentaGetId.DiasRendicion[i]);
              this.diasRendicionInput=this.diasRendicion.length.toString();
            }else if(this.puntoVentaGetId.DiasRendicion[i].DiaID.toString()===$("#ckDR7").val()){
              $("#ckDR7").prop('checked', true);
              this.diasRendicion.push(this.puntoVentaGetId.DiasRendicion[i]);
              this.diasRendicionInput=this.diasRendicion.length.toString();
            }
          }
        }

        if(this.puntoVentaGetId.DiasGuiaRendicion.length!==0){
          for(var i:number=0; i<this.puntoVentaGetId.DiasGuiaRendicion.length; i++){
            if(this.puntoVentaGetId.DiasGuiaRendicion[i].DiaID.toString()===$("#ckGDR1").val()){
              $("#ckGDR1").prop('checked', true);
              this.diasGuiasRendicion.push(this.puntoVentaGetId.DiasGuiaRendicion[i]);
              this.diasGuiasRendicionInput=this.diasGuiasRendicion.length.toString();
            }else if(this.puntoVentaGetId.DiasGuiaRendicion[i].DiaID.toString()===$("#ckGDR2").val()){
              $("#ckGDR2").prop('checked', true);
              this.diasGuiasRendicion.push(this.puntoVentaGetId.DiasGuiaRendicion[i]);
              this.diasGuiasRendicionInput=this.diasGuiasRendicion.length.toString();
            }else if(this.puntoVentaGetId.DiasGuiaRendicion[i].DiaID.toString()===$("#ckGDR3").val()){
              $("#ckGDR3").prop('checked', true);
              this.diasGuiasRendicion.push(this.puntoVentaGetId.DiasGuiaRendicion[i]);
              this.diasGuiasRendicionInput=this.diasGuiasRendicion.length.toString();
            }else if(this.puntoVentaGetId.DiasGuiaRendicion[i].DiaID.toString()===$("#ckGDR4").val()){
              $("#ckGDR4").prop('checked', true);
              this.diasGuiasRendicion.push(this.puntoVentaGetId.DiasGuiaRendicion[i]);
              this.diasGuiasRendicionInput=this.diasGuiasRendicion.length.toString();
            }else if(this.puntoVentaGetId.DiasGuiaRendicion[i].DiaID.toString()===$("#ckGDR5").val()){
              $("#ckGDR5").prop('checked', true);
              this.diasGuiasRendicion.push(this.puntoVentaGetId.DiasGuiaRendicion[i]);
              this.diasGuiasRendicionInput=this.diasGuiasRendicion.length.toString();
            }else if(this.puntoVentaGetId.DiasGuiaRendicion[i].DiaID.toString()===$("#ckGDR6").val()){
              $("#ckGDR6").prop('checked', true);
              this.diasGuiasRendicion.push(this.puntoVentaGetId.DiasGuiaRendicion[i]);
              this.diasGuiasRendicionInput=this.diasGuiasRendicion.length.toString();
            }else if(this.puntoVentaGetId.DiasGuiaRendicion[i].DiaID.toString()===$("#ckGDR7").val()){
              $("#ckGDR7").prop('checked', true);
              this.diasGuiasRendicion.push(this.puntoVentaGetId.DiasGuiaRendicion[i]);
              this.diasGuiasRendicionInput=this.diasGuiasRendicion.length.toString();
            }
          }
        }
        var empresasAux:Empresa[]=this.empresasFacturacion;
        for(var i:number=0; i<this.empresasFacturacion.length; i++){
          for(var j:number=0; j<this.puntoVentaGetId.Empresas.length; j++){
            if(this.empresasFacturacion[i].EmpresaFacturacionID===this.puntoVentaGetId.Empresas[j].EmpresaFacturacionID &&
                empresasAux[i].TipoValidacionEmisionID===this.puntoVentaGetId.Empresas[j].TipoValidacionEmisionID){
                empresasAux[i].EmpresaFacturacionID=this.puntoVentaGetId.Empresas[j].EmpresaFacturacionID;
                empresasAux[i].EmpresaFacturacionPuntoVentaID=this.puntoVentaGetId.Empresas[j].EmpresaFacturacionPuntoVentaID;
                empresasAux[i].FechaInicioACT=this.puntoVentaGetId.Empresas[j].FechaInicioACT;
                empresasAux[i].NumeroEstablecimiento=this.puntoVentaGetId.Empresas[j].NumeroEstablecimiento;
                empresasAux[i].PuntoVentaID=this.puntoVentaGetId.Empresas[j].PuntoVentaID;
                empresasAux[i].SucursalDGI=this.puntoVentaGetId.Empresas[j].SucursalDGI;
                empresasAux[i].TipoValidacionEmisionID=this.puntoVentaGetId.Empresas[j].TipoValidacionEmisionID;
                this.empresasPV.push(this.puntoVentaGetId.Empresas[j]);
                break;
            }
          }
        }
        this.empresasFacturacion=empresasAux;
        $("#categoriaSelect").prop('disabled', true);
        $("#nroSucursalDGI").prop('disabled', true);
        
        $("#modalPuntoVenta").modal('show');
      });

      
    }
  }

  cerrarModal(){
    this.idPV=0;
    this.nombre='';
    this.nroSucursal='';
    this.razonSocial='';
    this.dniResponsable='';
    this.apeNomResponsable='';
    this.bloqueaValor=false;
    this.categoriaPV=null;
    this.credito=null;
    this.diasGuiasRendicion=[];
    this.diasRendicion=[];
    this.diasGuiasRendicionInput='';
    this.diasRendicionInput='';
    this.mostrarGuias=false;
    this.mostrarBoton=true;
    this.agregarGuias=false;
    this.guias=[];
    this.empresasFacturacion=null;
    this.empresasFacturacion=this.empresasFacturacionAux;
    this.empresasPV=null;
    this.empresasPV=[];
    this.baja='false';
    this.domicilioAgregado=false;
    this.domicilio=null;
    $("#ckDR1").prop('checked', false);
    $("#ckDR2").prop('checked', false);
    $("#ckDR3").prop('checked', false);
    $("#ckDR4").prop('checked', false);
    $("#ckDR5").prop('checked', false);
    $("#ckDR6").prop('checked', false);
    $("#ckDR7").prop('checked', false);
    
    $("#ckGDR1").prop('checked', false);
    $("#ckGDR2").prop('checked', false);
    $("#ckGDR3").prop('checked', false);
    $("#ckGDR4").prop('checked', false);
    $("#ckGDR5").prop('checked', false);
    $("#ckGDR6").prop('checked', false);
    $("#ckGDR7").prop('checked', false);

    $("#categoriaSelect").prop('disabled', false);
    $("#nroSucursalDGI").prop('disabled', false);

    $("#modalPuntoVenta").modal('hide');
  }

  abrirModalDom(accion:string){
    if(accion==='agregar'){
      this.domicilioService.agregarDomicilio(this.domicilio);
      $("#modalDomicilio").modal('show');
    }else if(accion==='editar'){
      this.domicilioService.agregarDomicilio(this.domicilio);
      $("#modalDomicilio").modal('show');
    }
    
  }

  /* buscarPV(event, campo:string){
    console.log(event)
    if(campo==='sucursal'){
      if((this.rs==='' || this.rs===null) && (this.baja==='null' || this.baja===null)){
        this.source.localdata=this.puntosVenta.filter(pv=>{
          return pv.SucursalDGI.includes(event);
        })
        this.mostrarGrid();
      }else if((this.rs!=='' || this.rs!==null) && (this.baja==='null' || this.baja===null)){
        this.puntosVentaBusqueda=this.puntosVenta.filter(pv=>{
          return pv.RazonSocial.toLowerCase().includes(this.rs.toLowerCase());
        })
        this.source.localdata=this.puntosVentaBusqueda.filter(pv=>{
          return pv.SucursalDGI.includes(event);
        })
        this.mostrarGrid();
      }else if((this.rs==='' || this.rs===null) && (this.baja!=='null' || this.baja!==null)){
        this.puntosVentaBusqueda=this.puntosVenta.filter(pv=>{
          return pv.Baja.toString().includes(this.baja);
        })
        this.source.localdata=this.puntosVentaBusqueda.filter(pv=>{
          return pv.SucursalDGI.includes(event);
        })
        this.mostrarGrid();
      }else if((this.rs!=='' || this.rs!==null) && (this.baja!=='null' || this.baja!==null)){
        this.puntosVentaBusqueda=this.puntosVenta.filter(pv=>{
          return pv.RazonSocial.toLowerCase().includes(this.razonSocial.toLowerCase());
        })
        this.puntosVentaBusqueda=this.puntosVentaBusqueda.filter(pv=>{
          return pv.Baja.toString().includes(this.baja);
        })
        this.source.localdata=this.puntosVentaBusqueda.filter(pv=>{
          return pv.SucursalDGI.includes(event);
        })
        this.mostrarGrid();
      }
    }else if(campo==='razonS'){
      if((this.sucDGI==='' || this.sucDGI===null) && (this.baja==='null' || this.baja===null)){
        this.source.localdata=this.puntosVenta.filter(pv=>{
          return pv.RazonSocial.toLowerCase().includes(event.toLowerCase());
        })
        this.mostrarGrid();
      }else if((this.sucDGI!=='' || this.sucDGI!==null) && (this.baja==='null' || this.baja===null)){
        this.puntosVentaBusqueda=this.puntosVenta.filter(pv=>{
          return pv.SucursalDGI.includes(this.sucDGI);
        })
        this.source.localdata=this.puntosVentaBusqueda.filter(pv=>{
          return pv.RazonSocial.toLowerCase().includes(event.toLowerCase());
        })
        this.mostrarGrid();
      }else if((this.sucDGI==='' || this.sucDGI===null) && (this.baja!=='null' || this.baja!==null)){
        this.puntosVentaBusqueda=this.puntosVenta.filter(pv=>{
          return pv.Baja.toString().includes(this.baja);
        })
        this.source.localdata=this.puntosVentaBusqueda.filter(pv=>{
          return pv.RazonSocial.toLowerCase().includes(event.toLowerCase());
        })
        this.mostrarGrid();
      }else if((this.sucDGI!=='' || this.sucDGI!==null) && (this.baja!=='null' || this.baja!==null)){
        this.puntosVentaBusqueda=this.puntosVenta.filter(pv=>{
          return pv.SucursalDGI.includes(this.sucDGI);
        })
        this.puntosVentaBusqueda=this.puntosVentaBusqueda.filter(pv=>{
          return pv.Baja.toString().includes(this.baja);
        })
        this.source.localdata=this.puntosVentaBusqueda.filter(pv=>{
          return pv.RazonSocial.toLowerCase().includes(event.toLowerCase());
        })
        this.mostrarGrid();
      }
    }else if(campo==='baja'){
      if(event==="null" || event===null){
        if((this.sucDGI==='' || this.sucDGI===null) && (this.rs==='' || this.rs===null)){
          this.source.localdata=this.puntosVenta;
          this.mostrarGrid();
        }else if((this.sucDGI!=='' || this.sucDGI!==null) && (this.rs==='' || this.rs===null)){
          this.source.localdata=this.puntosVenta.filter(pv=>{
            return pv.SucursalDGI.includes(this.sucDGI);
          })
          this.mostrarGrid();
        }else if((this.sucDGI==='' || this.sucDGI===null) && (this.rs!=='' || this.rs!==null)){
          this.source.localdata=this.puntosVenta.filter(pv=>{
            return pv.RazonSocial.toLowerCase().includes(this.rs.toLowerCase());
          })
          this.mostrarGrid();
        }else if((this.sucDGI!=='' || this.sucDGI!==null) && (this.rs!=='' || this.rs!==null)){
          this.puntosVentaBusqueda=this.puntosVenta.filter(pv=>{
            return pv.SucursalDGI.includes(this.sucDGI);
          })
          this.source.localdata=this.puntosVentaBusqueda.filter(pv=>{
            return pv.RazonSocial.toLowerCase().includes(this.rs.toLowerCase());
          })
          this.mostrarGrid();
        }
      }else{
        if((this.sucDGI==='' || this.sucDGI===null) && (this.rs==='' || this.rs===null)){
          this.source.localdata=this.puntosVenta.filter(pv=>{
            return pv.Baja.toString().includes(event);
          })
          this.mostrarGrid();
        }else if((this.sucDGI!=='' || this.sucDGI!==null) && (this.rs==='' || this.rs===null)){
          this.puntosVentaBusqueda=this.puntosVenta.filter(pv=>{
            return pv.SucursalDGI.includes(this.sucDGI);
          })
          this.source.localdata=this.puntosVentaBusqueda.filter(pv=>{
            return pv.Baja.toString().includes(event);
          })
          this.mostrarGrid();
        }else if((this.sucDGI==='' || this.sucDGI===null) && (this.rs!=='' || this.rs!==null)){
          this.puntosVentaBusqueda=this.puntosVenta.filter(pv=>{
            return pv.RazonSocial.toLowerCase().includes(this.rs.toLowerCase());
          })
          this.source.localdata=this.puntosVentaBusqueda.filter(pv=>{
            return pv.Baja.toString().includes(event);
          })
          this.mostrarGrid();
        }else if((this.sucDGI!=='' || this.sucDGI!==null) && (this.rs!=='' || this.rs!==null)){
          this.puntosVentaBusqueda=this.puntosVenta.filter(pv=>{
            return pv.SucursalDGI.includes(this.sucDGI);
          })
          this.puntosVentaBusqueda=this.puntosVentaBusqueda.filter(pv=>{
            return pv.RazonSocial.toLowerCase().includes(this.rs.toLowerCase());
          })
          this.source.localdata=this.puntosVentaBusqueda.filter(pv=>{
            return pv.Baja.toString().includes(event);
          })
          this.mostrarGrid();
        }
      }
    }
    
  } */

  /* NUEVO MÉTODO DE BÚSQUEDA */
  buscarPV(event, campo:string){
    if(campo=='sucursal'){
      if((this.rs==='' || this.rs===null) && (this.baja==='null' || this.baja===null) && (this.nomb==='' || this.nomb===null) && (this.cat==='' || this.cat===null)){
        this.puntosVentaBusqueda=this.puntosVenta.filter(pv=>{
          if(pv.SucursalDGI!=null){
            return pv.SucursalDGI.includes(event);
          }
        })
      }/* else if((this.rs!=='' || this.rs!==null) && (this.baja!=='null' || this.baja!==null) && (this.nomb!=='' || this.nomb!==null) && (this.cat!=='' || this.cat!==null)){
        this.puntosVentaBusqueda=this.puntosVenta.filter(pv=>{
          return pv.RazonSocial.toLowerCase().includes(this.razonSocial.toLowerCase());
        })
        this.puntosVentaBusqueda=this.puntosVentaBusqueda.filter(pv=>{
          return pv.PuntoVentaDescrip.toLowerCase().includes(this.nomb.toLowerCase());
        })
        this.puntosVentaBusqueda=this.puntosVentaBusqueda.filter(pv=>{
          return pv.CategoriaNombre.toLowerCase().includes(this.cat.toLowerCase());
        })
        this.puntosVentaBusqueda=this.puntosVentaBusqueda.filter(pv=>{
          return pv.Baja.toString().includes(this.baja);
        })
        this.source.localdata=this.puntosVentaBusqueda.filter(pv=>{
          return pv.SucursalDGI.includes(event);
        })
        this.mostrarGrid();
      } */else{
        var i=0;
        if(this.rs!='' && this.rs!=null){
          i++;
          this.puntosVentaBusqueda=this.puntosVenta.filter(pv=>{
            return pv.RazonSocial.toLowerCase().includes(this.rs.toLowerCase());
          })
        }
        if(this.nomb!=''&& this.nomb!=null){
          if(i==0){
            i++;
            this.puntosVentaBusqueda=this.puntosVenta.filter(pv=>{
              return pv.PuntoVentaDescrip.toLowerCase().includes(this.nomb.toLowerCase());
            })
          }else{
            this.puntosVentaBusqueda=this.puntosVentaBusqueda.filter(pv=>{
              return pv.PuntoVentaDescrip.toLowerCase().includes(this.nomb.toLowerCase());
            })
          }
        }
        if(this.cat!='' && this.cat!=null){
          if(i==0){
            i++;
            this.puntosVentaBusqueda=this.puntosVenta.filter(pv=>{
              return pv.CategoriaNombre.toLowerCase().includes(this.cat.toLowerCase());
            })
          }else{
            this.puntosVentaBusqueda=this.puntosVentaBusqueda.filter(pv=>{
              return pv.CategoriaNombre.toLowerCase().includes(this.cat.toLowerCase());
            })
          }
        }
        if(this.baja!='null' && this.baja!=null){
          if(i==0){
            i++;
            this.puntosVentaBusqueda=this.puntosVenta.filter(pv=>{
              return pv.Baja.toString().includes(this.baja);
            })
          }else{
            this.puntosVentaBusqueda=this.puntosVentaBusqueda.filter(pv=>{
              return pv.Baja.toString().includes(this.baja);
            })
          }
        }
        this.puntosVentaBusqueda=this.puntosVentaBusqueda.filter(pv=>{
          if(pv.SucursalDGI!=null){
            return pv.SucursalDGI.includes(event);
          }
        })
      }
    }else if(campo=='razonS'){
      if((this.sucDGI==='' || this.sucDGI===null) && (this.baja==='null' || this.baja===null) && (this.nomb==='' || this.nomb===null) && (this.cat==='' || this.cat===null)){
        this.puntosVentaBusqueda=this.puntosVenta.filter(pv=>{
          return pv.RazonSocial.toLowerCase().includes(event.toLowerCase());
        })
      }/* else if((this.rs!=='' || this.rs!==null) && (this.baja!=='null' || this.baja!==null) && (this.nomb!=='' || this.nomb!==null) && (this.cat!=='' || this.cat!==null)){
        this.puntosVentaBusqueda=this.puntosVenta.filter(pv=>{
          return pv.RazonSocial.toLowerCase().includes(this.razonSocial.toLowerCase());
        })
        this.puntosVentaBusqueda=this.puntosVentaBusqueda.filter(pv=>{
          return pv.PuntoVentaDescrip.toLowerCase().includes(this.nomb.toLowerCase());
        })
        this.puntosVentaBusqueda=this.puntosVentaBusqueda.filter(pv=>{
          return pv.CategoriaNombre.toLowerCase().includes(this.cat.toLowerCase());
        })
        this.puntosVentaBusqueda=this.puntosVentaBusqueda.filter(pv=>{
          return pv.Baja.toString().includes(this.baja);
        })
        this.source.localdata=this.puntosVentaBusqueda.filter(pv=>{
          return pv.SucursalDGI.includes(event);
        })
        this.mostrarGrid();
      } */else{
        var i=0;
        if(this.sucDGI!='' && this.sucDGI!=null){
          i++;
          this.puntosVentaBusqueda=this.puntosVenta.filter(pv=>{
            if(pv.SucursalDGI!=null){
              return pv.SucursalDGI.includes(this.sucDGI);
            }
          })
        }
        if(this.nomb!='' && this.nomb!=null){
          if(i==0){
            i++;
            this.puntosVentaBusqueda=this.puntosVenta.filter(pv=>{
              return pv.PuntoVentaDescrip.toLowerCase().includes(this.nomb.toLowerCase());
            })
          }else{
            this.puntosVentaBusqueda=this.puntosVentaBusqueda.filter(pv=>{
              return pv.PuntoVentaDescrip.toLowerCase().includes(this.nomb.toLowerCase());
            })
          }
        }
        if(this.cat!='' && this.cat!=null){
          if(i==0){
            i++;
            this.puntosVentaBusqueda=this.puntosVenta.filter(pv=>{
              return pv.CategoriaNombre.toLowerCase().includes(this.cat.toLowerCase());
            })
          }else{
            this.puntosVentaBusqueda=this.puntosVentaBusqueda.filter(pv=>{
              return pv.CategoriaNombre.toLowerCase().includes(this.cat.toLowerCase());
            })
          }
        }
        if(this.baja!='null' && this.baja!=null){
          if(i==0){
            i++;
            this.puntosVentaBusqueda=this.puntosVenta.filter(pv=>{
              return pv.Baja.toString().includes(this.baja);
            })
          }else{
            this.puntosVentaBusqueda=this.puntosVentaBusqueda.filter(pv=>{
              return pv.Baja.toString().includes(this.baja);
            })
          }
        }
        this.puntosVentaBusqueda=this.puntosVentaBusqueda.filter(pv=>{
          return pv.RazonSocial.toLowerCase().includes(event.toLowerCase());
        })
      }
    }if(campo=='nombre'){
      if((this.sucDGI==='' || this.sucDGI===null) && (this.baja==='null' || this.baja===null) && (this.rs==='' || this.rs===null) && (this.cat==='' || this.cat===null)){
        this.puntosVentaBusqueda=this.puntosVenta.filter(pv=>{
          return pv.PuntoVentaDescrip.toLowerCase().includes(event.toLowerCase());
        })
      }/* else if((this.rs!=='' || this.rs!==null) && (this.baja!=='null' || this.baja!==null) && (this.nomb!=='' || this.nomb!==null) && (this.cat!=='' || this.cat!==null)){
        this.puntosVentaBusqueda=this.puntosVenta.filter(pv=>{
          return pv.RazonSocial.toLowerCase().includes(this.razonSocial.toLowerCase());
        })
        this.puntosVentaBusqueda=this.puntosVentaBusqueda.filter(pv=>{
          return pv.PuntoVentaDescrip.toLowerCase().includes(this.nomb.toLowerCase());
        })
        this.puntosVentaBusqueda=this.puntosVentaBusqueda.filter(pv=>{
          return pv.CategoriaNombre.toLowerCase().includes(this.cat.toLowerCase());
        })
        this.puntosVentaBusqueda=this.puntosVentaBusqueda.filter(pv=>{
          return pv.Baja.toString().includes(this.baja);
        })
        this.source.localdata=this.puntosVentaBusqueda.filter(pv=>{
          return pv.SucursalDGI.includes(event);
        })
        this.mostrarGrid();
      } */else{
        var i=0;
        if(this.sucDGI!='' && this.sucDGI!=null){
          i++;
          this.puntosVentaBusqueda=this.puntosVenta.filter(pv=>{
            if(pv.SucursalDGI!=null){
              return pv.SucursalDGI.includes(this.sucDGI);
            }
          })
        }
        if(this.rs!='' && this.rs!=null){
          if(i==0){
            i++;
            this.puntosVentaBusqueda=this.puntosVenta.filter(pv=>{
              return pv.RazonSocial.toLowerCase().includes(this.rs.toLowerCase());
            })
          }else{
            this.puntosVentaBusqueda=this.puntosVentaBusqueda.filter(pv=>{
              return pv.RazonSocial.toLowerCase().includes(this.rs.toLowerCase());
            })
          }
        }
        if(this.cat!='' && this.cat!=null){
          if(i==0){
            i++;
            this.puntosVentaBusqueda=this.puntosVenta.filter(pv=>{
              return pv.CategoriaNombre.toLowerCase().includes(this.cat.toLowerCase());
            })
          }else{
            this.puntosVentaBusqueda=this.puntosVentaBusqueda.filter(pv=>{
              return pv.CategoriaNombre.toLowerCase().includes(this.cat.toLowerCase());
            })
          }
        }
        if(this.baja!='null' && this.baja!=null){
          if(i==0){
            i++;
            this.puntosVentaBusqueda=this.puntosVenta.filter(pv=>{
              return pv.Baja.toString().includes(this.baja);
            })
          }else{
            this.puntosVentaBusqueda=this.puntosVentaBusqueda.filter(pv=>{
              return pv.Baja.toString().includes(this.baja);
            })
          }
        }
        this.puntosVentaBusqueda=this.puntosVentaBusqueda.filter(pv=>{
          return pv.PuntoVentaDescrip.toLowerCase().includes(event.toLowerCase());
        })
      }
    }if(campo=='categoria'){
      if((this.rs==='' || this.rs===null) && (this.baja==='null' || this.baja===null) && (this.nomb==='' || this.nomb===null) && (this.sucDGI==='' || this.sucDGI===null)){
        this.puntosVentaBusqueda=this.puntosVenta.filter(pv=>{
          return pv.CategoriaNombre.toLowerCase().includes(event.toLowerCase());
        })
      }/* else if((this.rs!=='' || this.rs!==null) && (this.baja!=='null' || this.baja!==null) && (this.nomb!=='' || this.nomb!==null) && (this.cat!=='' || this.cat!==null)){
        this.puntosVentaBusqueda=this.puntosVenta.filter(pv=>{
          return pv.RazonSocial.toLowerCase().includes(this.razonSocial.toLowerCase());
        })
        this.puntosVentaBusqueda=this.puntosVentaBusqueda.filter(pv=>{
          return pv.PuntoVentaDescrip.toLowerCase().includes(this.nomb.toLowerCase());
        })
        this.puntosVentaBusqueda=this.puntosVentaBusqueda.filter(pv=>{
          return pv.CategoriaNombre.toLowerCase().includes(this.cat.toLowerCase());
        })
        this.puntosVentaBusqueda=this.puntosVentaBusqueda.filter(pv=>{
          return pv.Baja.toString().includes(this.baja);
        })
        this.source.localdata=this.puntosVentaBusqueda.filter(pv=>{
          return pv.SucursalDGI.includes(event);
        })
        this.mostrarGrid();
      } */else{
        var i=0;
        if(this.sucDGI!='' && this.sucDGI!=null){
          i++;
          this.puntosVentaBusqueda=this.puntosVenta.filter(pv=>{
            if(pv.SucursalDGI!=null){
              return pv.SucursalDGI.includes(this.sucDGI);
            }
          })
        }
        if(this.rs!='' && this.rs!=null){
          if(i==0){
            i++;
            this.puntosVentaBusqueda=this.puntosVenta.filter(pv=>{
              return pv.RazonSocial.toLowerCase().includes(this.rs.toLowerCase());
            })
          }else{
            this.puntosVentaBusqueda=this.puntosVentaBusqueda.filter(pv=>{
              return pv.RazonSocial.toLowerCase().includes(this.rs.toLowerCase());
            })
          }
        }
        if(this.nomb!='' && this.nomb!=null){
          if(i==0){
            i++;
            this.puntosVentaBusqueda=this.puntosVenta.filter(pv=>{
              return pv.PuntoVentaDescrip.toLowerCase().includes(this.nomb.toLowerCase());
            })
          }else{
            this.puntosVentaBusqueda=this.puntosVentaBusqueda.filter(pv=>{
              return pv.PuntoVentaDescrip.toLowerCase().includes(this.nomb.toLowerCase());
            })
          }
        }
        
        if(this.baja!='null' && this.baja!=null){
          if(i==0){
            i++;
            this.puntosVentaBusqueda=this.puntosVenta.filter(pv=>{
              return pv.Baja.toString().includes(this.baja);
            })
          }else{
            this.puntosVentaBusqueda=this.puntosVentaBusqueda.filter(pv=>{
              return pv.Baja.toString().includes(this.baja);
            })
          }
        }
        this.puntosVentaBusqueda=this.puntosVentaBusqueda.filter(pv=>{
          return pv.CategoriaNombre.toLowerCase().includes(event.toLowerCase());
        })
      }
    }if(campo=='baja'){
      if((this.rs==='' || this.rs===null) && (this.cat==='' || this.cat===null) && (this.nomb==='' || this.nomb===null) && (this.sucDGI==='' || this.sucDGI===null)){
        this.puntosVentaBusqueda=this.puntosVenta.filter(pv=>{
          return pv.Baja.toString().includes(event);
        })
      }/* else if((this.rs!=='' || this.rs!==null) && (this.baja!=='null' || this.baja!==null) && (this.nomb!=='' || this.nomb!==null) && (this.cat!=='' || this.cat!==null)){
        this.puntosVentaBusqueda=this.puntosVenta.filter(pv=>{
          return pv.RazonSocial.toLowerCase().includes(this.razonSocial.toLowerCase());
        })
        this.puntosVentaBusqueda=this.puntosVentaBusqueda.filter(pv=>{
          return pv.PuntoVentaDescrip.toLowerCase().includes(this.nomb.toLowerCase());
        })
        this.puntosVentaBusqueda=this.puntosVentaBusqueda.filter(pv=>{
          return pv.CategoriaNombre.toLowerCase().includes(this.cat.toLowerCase());
        })
        this.puntosVentaBusqueda=this.puntosVentaBusqueda.filter(pv=>{
          return pv.Baja.toString().includes(this.baja);
        })
        this.source.localdata=this.puntosVentaBusqueda.filter(pv=>{
          return pv.SucursalDGI.includes(event);
        })
        this.mostrarGrid();
      } */else{
        var i=0;
        if(this.sucDGI!='' && this.sucDGI!=null){
          i++;
          this.puntosVentaBusqueda=this.puntosVenta.filter(pv=>{
            if(pv.SucursalDGI!=null){
              return pv.SucursalDGI.includes(this.sucDGI);
            }
          })
        }
        if(this.rs!='' && this.rs!=null){
          if(i==0){
            i++;
            this.puntosVentaBusqueda=this.puntosVenta.filter(pv=>{
              return pv.RazonSocial.toLowerCase().includes(this.rs.toLowerCase());
            })
          }else{
            this.puntosVentaBusqueda=this.puntosVentaBusqueda.filter(pv=>{
              return pv.RazonSocial.toLowerCase().includes(this.rs.toLowerCase());
            })
          }
        }
        if(this.nomb!='' && this.nomb!=null){
          if(i==0){
            i++;
            this.puntosVentaBusqueda=this.puntosVenta.filter(pv=>{
              return pv.PuntoVentaDescrip.toLowerCase().includes(this.nomb.toLowerCase());
            })
          }else{
            this.puntosVentaBusqueda=this.puntosVentaBusqueda.filter(pv=>{
              return pv.PuntoVentaDescrip.toLowerCase().includes(this.nomb.toLowerCase());
            })
          }
        }
        
        if(this.cat!='' && this.cat!=null){
          if(i==0){
            i++;
            this.puntosVentaBusqueda=this.puntosVenta.filter(pv=>{
              return pv.CategoriaNombre.toLowerCase().includes(this.cat.toLowerCase());
            })
          }else{
            this.puntosVentaBusqueda=this.puntosVentaBusqueda.filter(pv=>{
              return pv.CategoriaNombre.toLowerCase().includes(this.cat.toLowerCase());
            })
          }
        }
        this.puntosVentaBusqueda=this.puntosVentaBusqueda.filter(pv=>{
          return pv.Baja.toString().includes(event);
        })
      }
    }
    this.source.localdata=this.puntosVentaBusqueda;
    this.mostrarGrid();
  }

  seleccionar(event){
    if(event.target.value==='null'){
      this.categoriaInput='';
      this.categoriaPV=null;
    }else{
      this.categoriaInput=event.target.value;
    }
    console.log(this.categoriaInput);
    console.log(this.categoriaPV);
  }

  seleccionarEmpresa(empresa:Empresa, event){
    console.log(event)
    
    if(this.idPV===0){
      if(event.target.checked){
        if(empresa.SucursalDGI!==''){
          this.empresasPV.push(empresa);
        }else{
          alert("Debe completar el puntero para poder seleccionar");
          
          $("#"+event.target.id).prop('checked', false);
        }
      }else{
        for(var i:number=0; i<this.empresasPV.length; i++){
          if(this.empresasPV[i].EmpresaFacturacionID===empresa.EmpresaFacturacionID && 
            this.empresasPV[i].FechaInicioACT===empresa.FechaInicioACT &&
            this.empresasPV[i].NumeroEstablecimiento===empresa.NumeroEstablecimiento &&
            this.empresasPV[i].SucursalDGI===empresa.SucursalDGI &&
            this.empresasPV[i].TipoValidacionEmisionID===empresa.TipoValidacionEmisionID){
              this.empresasPV.splice(i,1);
              break;
            }
        }
      }
    }else{
      if(event.target.checked){
        if(empresa.SucursalDGI!==''){
          this.empresasPV.push(empresa);
        }else{
          alert("Debe completar el puntero para poder seleccionar");
          
          $("#"+event.target.id).prop('checked', false);
        }
      }else{
        for(var i:number=0; i<this.empresasPV.length; i++){
          if(this.empresasPV[i].EmpresaFacturacionID===empresa.EmpresaFacturacionID && 
            this.empresasPV[i].FechaInicioACT===empresa.FechaInicioACT &&
            this.empresasPV[i].NumeroEstablecimiento===empresa.NumeroEstablecimiento &&
            this.empresasPV[i].SucursalDGI===empresa.SucursalDGI &&
            this.empresasPV[i].TipoValidacionEmisionID===empresa.TipoValidacionEmisionID){
              this.empresasPV.splice(i,1);
              break;
            }
        }
      }
    }
    console.log(this.empresasPV);
  }

  save(){
    var correcto:boolean=false;
    if(this.nroSucursal!==null){
      if(this.idPV===0){
        for(let pv of this.puntosVenta){
          if(this.nroSucursal.toString()===pv.SucursalDGI){
            alert("El número de sucursal pertenece a otro Punto de Venta");
            correcto=false;
            break;
          }else{
            correcto=true;
          }
        }
      }else{
        correcto=true;
      }
    }
    if(correcto){
      if(this.idPV===0){
        this.formPV.PuntoVenta={};
        this.formPV.PuntoVenta.PuntoVentaID=this.idPV;
        this.formPV.PuntoVenta.Baja=false;
        this.formPV.PuntoVenta.FechaAlta=new Date();
        this.formPV.PuntoVenta.PuntoVentaDescrip=this.nombre;
        this.formPV.PuntoVenta.Credito=this.credito;
        if(this.bloqueaValor){
          this.formPV.PuntoVenta.BloqueaValor=1;
        }else{
          this.formPV.PuntoVenta.BloqueaValor=0;
        }
        if(this.baja==='false'){
          this.formPV.PuntoVenta.Baja=false;
        }else{
          this.formPV.PuntoVenta.Baja=true;
        }
        this.formPV.PuntoVenta.RazonSocial=this.razonSocial;
        this.formPV.PuntoVenta.SucursalDGI=this.nroSucursal.toString();
        this.formPV.PuntoVenta.CategoriaPVID=parseInt(this.categoriaPV);
        for(let categoria of this.categoriasSelec){
          if(categoria.CategoriaPVID===parseInt(this.categoriaPV)){
            this.formPV.PuntoVenta.CategoriaNombre=categoria.CategoriaPVDescrip;
            break;
          }
        }
        this.formPV.PuntoVenta.ResponsableID=this.responsable.ResponsableID;
        this.formPV.PuntoVenta.ResponsableNombre=this.responsable.Apellido + ' '+ this.responsable.Nombre;
        
        this.formPV.PuntoVenta.DomicilioID=0;
        this.formPV.Domicilio=this.domicilio;

        this.formPV.DiasRendicion=this.diasRendicion;
        this.formPV.DiasGuiaRendicion=this.diasGuiasRendicion;
  
        this.formPV.Empresas=this.empresasPV;
  
        this.formPV.CAIs=this.guias;
        console.log(this.formPV);
        this.pvService.postPV(this.formPV).subscribe((res)=>{
          if(res.Codigo===200){
            alert(res.Mensaje);
            this.puntosVenta.push(res.Punto);
            this.source.localdata=this.puntosVenta;
            this.mostrarGrid();
          }else{
            alert(res.Mensaje);
          }
        })
        this.cerrarModal();
      }else{
        this.formPV.PuntoVenta={};
        this.formPV.PuntoVenta.PuntoVentaID=this.puntoVentaGetId.Punto.PuntoVentaID;
        this.formPV.PuntoVenta.Baja=this.puntoVentaGetId.Punto.Baja;
        this.formPV.PuntoVenta.FechaAlta=this.puntoVentaGetId.Punto.FechaAlta;
        this.formPV.PuntoVenta.PuntoVentaDescrip=this.nombre;
        this.formPV.PuntoVenta.Credito=this.credito;
        if(this.bloqueaValor){
          this.formPV.PuntoVenta.BloqueaValor=1;
        }else{
          this.formPV.PuntoVenta.BloqueaValor=0;
        }
        if(this.baja==='false'){
          this.formPV.PuntoVenta.Baja=false;
        }else{
          this.formPV.PuntoVenta.Baja=true;
        }
        this.formPV.DiasRendicion=this.diasRendicion;
        this.formPV.DiasGuiaRendicion=this.diasGuiasRendicion;
        this.formPV.PuntoVenta.RazonSocial=this.razonSocial;
        this.formPV.PuntoVenta.SucursalDGI=this.nroSucursal;
        this.formPV.PuntoVenta.CategoriaPVID=parseInt(this.categoriaPV);
        for(let categoria of this.categoriasSelec){
          if(categoria.CategoriaPVID===parseInt(this.categoriaPV)){
            this.formPV.PuntoVenta.CategoriaNombre=categoria.CategoriaPVDescrip;
            break;
          }
        }
        this.formPV.PuntoVenta.ResponsableID=this.responsable.ResponsableID;
        this.formPV.PuntoVenta.ResponsableNombre=this.responsable.Apellido + ' '+ this.responsable.Nombre;
        
        this.formPV.PuntoVenta.DomicilioID=this.domicilio.DomicilioLocalID;
        this.formPV.Domicilio=this.domicilio;

        this.formPV.DiasRendicion=this.diasRendicion;
        this.formPV.DiasGuiaRendicion=this.diasGuiasRendicion;
  
        this.formPV.Empresas=this.empresasPV;
  
        this.formPV.CAIs=this.guias;
        console.log(this.formPV);
        this.pvService.postPV(this.formPV).subscribe((res)=>{
          if(res.Codigo===200){
            alert(res.Mensaje);
            for(let pv of this.puntosVenta){
              if(pv.PuntoVentaID===res.Punto.PuntoVentaID){
                pv.PuntoVentaID=res.Punto.PuntoVentaID;
                pv.Baja=res.Punto.Baja;
                pv.BloqueaValor=res.Punto.BloqueaValor;
                pv.CategoriaNombre=res.Punto.CategoriaNombre;
                pv.CategoriaPVID=res.Punto.CategoriaPVID;
                pv.Credito=res.Punto.Credito;
                pv.FechaAlta=res.Punto.FechaAlta;
                pv.PuntoVentaDescrip=res.Punto.PuntoVentaDescrip;
                pv.RazonSocial=res.Punto.RazonSocial;
                pv.ResponsableID=res.Punto.ResponsableID;
                pv.ResponsableNombre=res.Punto.ResponsableNombre;
                pv.SucursalDGI=res.Punto.SucursalDGI;
                this.source.localdata=this.puntosVenta;
                this.mostrarGrid();
                break;
              }
            }
          }else{
            alert(res.Mensaje);
          }
          /* this.puntosVenta.push(res.Punto);
          this.source.localdata=this.puntosVenta;
          this.mostrarGrid(); */
        })
        this.cerrarModal()
      }
    }
  }

  delete(id:number){
    var pvId:PuntoVentaGetId={}
    pvId.PuntoVentaID=id;
    this.pvService.getPVById(pvId).subscribe((res)=>{
      console.log(res);
      var puntoVenta:PuntoVentaGetPost={};
      /* puntoVenta.PuntoVenta={}; */
      puntoVenta.PuntoVenta=res.Punto;
      puntoVenta.PuntoVenta.Baja=true;
      puntoVenta.CAIs=res.CAIs;
      puntoVenta.Empresas=res.Empresas;
      puntoVenta.DiasRendicion=res.DiasRendicion;
      puntoVenta.DiasGuiaRendicion=res.DiasGuiaRendicion;

      this.pvService.postPV(puntoVenta).subscribe((res)=>{
        if(res.Codigo===200){
          alert(res.Mensaje)
          for(let pv of this.puntosVenta){
            if(pv.PuntoVentaID===res.Punto.PuntoVentaID){
              pv.PuntoVentaID=res.Punto.PuntoVentaID;
              pv.Baja=res.Punto.Baja;
              pv.BloqueaValor=res.Punto.BloqueaValor;
              pv.CategoriaNombre=res.Punto.CategoriaNombre;
              pv.CategoriaPVID=res.Punto.CategoriaPVID;
              pv.Credito=res.Punto.Credito;
              pv.FechaAlta=res.Punto.FechaAlta;
              pv.PuntoVentaDescrip=res.Punto.PuntoVentaDescrip;
              pv.RazonSocial=res.Punto.RazonSocial;
              pv.ResponsableID=res.Punto.ResponsableID;
              pv.ResponsableNombre=res.Punto.ResponsableNombre;
              pv.SucursalDGI=res.Punto.SucursalDGI;
              this.source.localdata=this.puntosVenta;
              this.mostrarGrid();
              break;
            }
          }
        }else{
          alert(res.Mensaje)
        }
      })
    })
  }

  /* buscarResponsable(event, campo:string){
    if(campo==='apeNom'){
      if(this.dniBusqueda==='' || this.dniBusqueda==='null' || this.dniBusqueda===null){
        this.responsablesBusqueda=this.responsables.filter(resp=>{
          if(resp.Apellido.toLowerCase().includes(event.toLowerCase())){
            return resp;
          }
          if(resp.Nombre.toLowerCase().includes(event.toLowerCase())){
            return resp;
          }
        })
      }else{
        this.responsablesBusqueda=this.responsables.filter(resp=>{
          return resp.NroDocumento.includes(this.dniBusqueda);
        })
        this.responsablesBusqueda=this.responsablesBusqueda.filter(resp=>{
          if(resp.Apellido.toLowerCase().includes(event.toLowerCase())){
            return resp;
          }
          if(resp.Nombre.toLowerCase().includes(event.toLowerCase())){
            return resp;
          }
        })
      }
    }else if(campo==='dni'){
      if(event==="" || event==='null' || event===null){
        if(this.apeNomBusqueda===''){
          this.responsablesBusqueda=this.responsables;
        }else{
          this.responsablesBusqueda=this.responsables.filter(resp=>{
            if(resp.Apellido.toLowerCase().includes(this.apeNomBusqueda.toLowerCase())){
              return resp;
            }
            if(resp.Nombre.toLowerCase().includes(this.apeNomBusqueda.toLowerCase())){
              return resp;
            }
          })
        }
      }else{
        if(this.apeNomBusqueda===''){
          this.responsablesBusqueda=this.responsables.filter(resp=>{
            return resp.NroDocumento.includes(event);
          })
        }else{
          this.responsablesBusqueda=this.responsables.filter(resp=>{
            if(resp.Apellido.toLowerCase().includes(this.apeNomBusqueda.toLowerCase())){
              return resp;
            }
            if(resp.Nombre.toLowerCase().includes(this.apeNomBusqueda.toLowerCase())){
              return resp;
            }
          })
          this.responsablesBusqueda=this.responsablesBusqueda.filter(resp=>{
            return resp.NroDocumento.includes(event);
          })
        }
      }
    }
    console.log(this.responsables);
    console.log(this.responsablesBusqueda);
  }

  seleccionarResponsable(responsable:Responsable){
    this.dniResponsable=responsable.NroDocumento;
    this.apeNomResponsable=responsable.Apellido+' '+responsable.Nombre;
    this.responsable=responsable;
    $("#modalResponsables").modal('hide');
    $("#modalPuntoVenta").modal('hide');
    $("#modalPuntoVenta").modal('show');
  }
  
  cerrarResponsables(){
    $("#modalResponsables").modal('hide');
    $("#modalPuntoVenta").modal('hide');
    $("#modalPuntoVenta").modal('show');
  } */

  saveGuia(){
    if(this.idPV===0){
        this.guiaNueva.TipoGuia=this.tipoGuia;
        this.guiaNueva.NumeroCAI=this.nroCAI;
        this.guiaNueva.FechaVencimientoCAI=this.fechaVtoCAI;
        this.guiaNueva.EmpresaFacturacionID=+this.empresa;
        this.guiaNueva.PuntoVentaID=this.idPV;
        this.guiaNueva.PuntoVentaCAIID=0;
        this.guiaNueva.Baja=false;
        this.guias.push(this.guiaNueva);
        this.source2.localdata=this.guias;
        this.dataAdapter2 = new jqx.dataAdapter(this.source2);
        this.mostrarGuias=true;
        this.tipoGuia='';
        this.nroCAI='';
        this.fechaVtoCAI=null;
        this.empresa=null;
        this.idGuia=0;
        this.guiaNueva={};
        this.mostrarBoton=true;
        this.agregarGuias=false;
        console.log(this.guias)
    }else{
      if(this.guias.length===0){
        this.guiaNueva.TipoGuia=this.tipoGuia;
        this.guiaNueva.NumeroCAI=this.nroCAI;
        this.guiaNueva.FechaVencimientoCAI=this.fechaVtoCAI;
        this.guiaNueva.EmpresaFacturacionID=parseInt(this.empresa);
        this.guiaNueva.PuntoVentaID=this.idPV;
        this.guiaNueva.PuntoVentaCAIID=0;
        this.guias.push(this.guiaNueva);
        this.source2.localdata=this.guias;
        this.dataAdapter2 = new jqx.dataAdapter(this.source2);
        this.mostrarGuias=true;
        this.tipoGuia='';
        this.nroCAI='';
        this.fechaVtoCAI=null;
        this.empresa=null;
        this.idGuia=0;
        this.mostrarBoton=true;
        this.agregarGuias=false;
      }else{
        var igual:number=0;
        for(let guia of this.guias){
          if(guia.PuntoVentaCAIID===this.idGuia){
              guia.EmpresaFacturacionID=parseInt(this.empresa);
              guia.NumeroCAI=this.nroCAI;
              guia.FechaVencimientoCAI=this.fechaVtoCAI;
              guia.TipoGuia=this.tipoGuia;
              igual++;
          }
        }
        if(igual===0){
          this.guiaNueva.TipoGuia=this.tipoGuia;
          this.guiaNueva.NumeroCAI=this.nroCAI;
          this.guiaNueva.FechaVencimientoCAI=this.fechaVtoCAI;
          this.guiaNueva.EmpresaFacturacionID=parseInt(this.empresa);
          this.guiaNueva.PuntoVentaID=this.idPV;
          this.guiaNueva.PuntoVentaCAIID=0;
          this.guias.push(this.guiaNueva);
          this.source2.localdata=this.guias;
          this.dataAdapter2 = new jqx.dataAdapter(this.source2);
        }else{
          this.source2.localdata=this.guias;
          this.dataAdapter2 = new jqx.dataAdapter(this.source2);
        }
        this.tipoGuia='';
        this.nroCAI='';
        this.fechaVtoCAI=null;
        this.empresa=null;
        this.idGuia=0;
        this.mostrarBoton=true;
        this.agregarGuias=false;
      }
      
    }
    
  }

  deleteGuia(row:any){
    
    for(var i:number=0; i<this.guias.length; i++){
      if(this.guias[i].NumeroCAI===row.NumeroCAI && this.guias[i].TipoGuia===row.TipoGuia){
        this.guias.splice(i,1);
      }
    }
    this.source2.localdata=this.guias;
    this.dataAdapter2 = new jqx.dataAdapter(this.source2);
    if(this.guias.length===0){
      this.mostrarGuias=false;
    }
  }

  abrirCalendar(){
    this.myWindow.position({ x: 868, y: 468 });
    this.myWindow.open();
  }

  seleccionarFecha(event:any){
    let date=event.args.date;
    this.fechaVtoCAI=date;
    this.myWindow.close();
  }

  agregar(){
    this.mostrarBoton=false;
    this.agregarGuias=true;
  }
}
