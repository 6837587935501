<div class="container">
  <h1 class="mt-1 text-center rounded mt-5">Call Center Reclamos</h1>
  <br />
  <div class="row justify-content-center">
    <div class="col-10 ">
      <!--Form with header-->


      <div class="card border-grey rounded-1">
        <div class="card-header p-0">
          <div class="bg-info text-white text-center py-2">
            <h3>
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-send"
                viewBox="0 0 16 16">
                <path
                  d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" />
              </svg>
              Contactanos
            </h3>
            <h4 class="col-12  m-0">
              Agencia Conectada: {{ usuario.AgenciaRazonSocial }}
            </h4>
          </div>
        </div>

          <div class="card-body p-3">
            <!--Body-->

          <div class="form-group">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                    class="bi bi-clipboard-data" viewBox="0 0 16 16">
                    <path
                      d="M4 11a1 1 0 1 1 2 0v1a1 1 0 1 1-2 0v-1zm6-4a1 1 0 1 1 2 0v5a1 1 0 1 1-2 0V7zM7 9a1 1 0 0 1 2 0v3a1 1 0 1 1-2 0V9z" />
                    <path
                      d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                    <path
                      d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                  </svg>
                </div>
                <input type="text" id="nroGuia" aria-label="First name" (click)="deshabilitarBoton()"
                  [(ngModel)]="nroGuia" class="form-control col-12" placeholder="Escriba su Numero de Guia" required />
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                    class="bi bi-file-binary" viewBox="0 0 16 16">
                    <path
                      d="M5.526 13.09c.976 0 1.524-.79 1.524-2.205 0-1.412-.548-2.203-1.524-2.203-.978 0-1.526.79-1.526 2.203 0 1.415.548 2.206 1.526 2.206zm-.832-2.205c0-1.05.29-1.612.832-1.612.358 0 .607.247.733.721L4.7 11.137a6.749 6.749 0 0 1-.006-.252zm.832 1.614c-.36 0-.606-.246-.732-.718l1.556-1.145c.003.079.005.164.005.249 0 1.052-.29 1.614-.829 1.614zm5.329.501v-.595H9.73V8.772h-.69l-1.19.786v.688L8.986 9.5h.05v2.906h-1.18V13h3z" />
                    <path
                      d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" />
                  </svg>
                </div>
                <input type="text" id="nroOrden" aria-label="First name" (click)="deshabilitarBoton()"
                  [(ngModel)]="nroOrden" class="form-control col-12 placeholder-lg"
                  placeholder="Escriba su Numero de Orden" required />
              </div>
            </div>
          </div>

          <div class="text-center">
            <button type="button" [disabled]="disableConsultarButton"
              class="btn btn-info btn-block rounded-0 col-12 py-2" [disabled]="loading" (click)="consultar()">
              Consultar
              <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </button>
          </div>
        </div>
         <div class="container col-12" *ngIf="datosagrilla.length > 0">

        <table id="datosReclamo" class="table table-striped  table-bordered">
          <!-- white-space: nowrap para ajustar columnas a contenido -->
          <thead>
            <tr>

              <th scope="col">Fecha</th>
              <th scope="col">Estado</th>
              <th scope="col">Agencia</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let g of datosagrilla">

              <td>{{ g.Fecha }}</td>
              <td>{{ g.Estado }}</td>
              <td>{{ g.Agencia }}</td>
            </tr>
          </tbody>
        </table>

        </div>
        <br>
        <div *ngIf="datosagrilla.length>0" class="col-12">
        <table id="datoshistorico" class="col-12" >
          <!-- white-space: nowrap para ajustar columnas a contenido -->
          <thead>
            <tr>
              <th scope="col">DETALLE YA TOMADO</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let g of datosagrillaHistorico">
              <td>{{g.Observacion }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <br>
      <div class="col-12">
        <select id="slect" class="custom-select" (change)="SeleccionAccion($event)">
          <option *ngFor="let AccCall of accionesCall" [value]="AccCall.AccionesCallID">
            {{ AccCall.AccionesCallDescrip }}
          </option>
        </select>
      </div>
      <br />
      <div id="adicionDatos">
        <div class="form-group" class="col-12">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">Telefono:</span>
            </div>
            <input type="text" aria-label="First name" [(ngModel)]="telefono" class="form-control" />
          </div>
          <br />
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">Direccion:</span>
            </div>
            <input type="text" aria-label="First name" [(ngModel)]="direccion" class="form-control col-12" />
          </div>
          <br />
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">Detalle:</span>
            </div>
            <textarea type="text" aria-label="First name" [(ngModel)]="detalle" class="form-control col-12"></textarea>
          </div>
        </div>
      </div>

      <div id="nuevaFecha">
        <div class="form-group" class="col-12">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">Telefono:</span>
            </div>
            <input type="text" aria-label="First name" [(ngModel)]="telefononuevaFecha" class="form-control" />
          </div>
          <br />
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">Direccion:</span>
            </div>
            <input type="text" aria-label="First name" [(ngModel)]="direccionNuevaFecha" class="form-control" />
          </div>
          <br />
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">Fecha Nueva:</span>
            </div>
            <input type="date" aria-label="First name" [(ngModel)]="FechaNueva" class="form-control" />
          </div>
        </div>
      </div>
      <br>
      
      <div id="botonreclamocarga">
        <div class="col-12 text-center">
          <button type="button" class=" btn btn-info btn-block rounded-0 col-12 mb-4"  [disabled]="loading" (click)="CargarReclamo()">
            Cargar
            <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          </button>
        </div>
      </div>
     
      <app-modal-observaciones></app-modal-observaciones>

      </div>  
    </div>
  </div>
</div>
