import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BuscarGuiaGet, BuscarGuiaPost, ConfirmarHRIPost, EditarHRIGet, EliminarGet, GuardarHRIGet, GuardarHRIPost, GuiasPendientesGet, HojaRutaInternoGet, HojaRutaInternoPost } from '../models/HojaRuta';
import { UrlService } from './url.service';


@Injectable({
  providedIn: 'root'
})
export class HojaRutaService {

  urlConsultaHojaRuta:string=this.urlService.urlApi+"/api/HojaRuta/Consultar";
   urlConsultarGuiasPendientes:string=this.urlService.urlApi+"/api/HojaRuta/GuiasHRI";
  urlGuardarHRI:string=this.urlService.urlApi+"/api/HojaRuta/GuardarHRI";
  urlBuscarGuia:string=this.urlService.urlApi+"/api/HojaRuta/Buscarguia";
  urlConfirmarHRI:string=this.urlService.urlApi+"/api/HojaRuta/ConfirmarHRI";
  urlEditarHRI:string=this.urlService.urlApi+"/api/HojaRuta/EditarHRI";
  urlEliminarHRI:string=this.urlService.urlApi+"/api/HojaRuta/EliminarHRI";


  constructor(private http:HttpClient, private urlService:UrlService) { }

  consultarHRI(datos:HojaRutaInternoPost):Observable<HojaRutaInternoGet>{
    return this.http.post<HojaRutaInternoGet>(this.urlConsultaHojaRuta, datos);
  }

  consultarGuiasPendientes(datos:HojaRutaInternoPost):Observable<GuiasPendientesGet>{
    return this.http.post<GuiasPendientesGet>(this.urlConsultarGuiasPendientes, datos);
  }

  guardarHRI(datos:GuardarHRIPost):Observable<GuardarHRIGet>{
    return this.http.post<GuardarHRIGet>(this.urlGuardarHRI, datos);
  }

  buscarGuia(datos:BuscarGuiaPost):Observable<BuscarGuiaGet>{
    return this.http.post<BuscarGuiaGet>(this.urlBuscarGuia, datos);
  }

  confirmarHRI(datos:ConfirmarHRIPost):Observable<GuardarHRIGet>{
    return this.http.post<GuardarHRIGet>(this.urlConfirmarHRI, datos);
  }

  editarHRI(datos:HojaRutaInternoPost):Observable<EditarHRIGet>{
    return this.http.post<HojaRutaInternoGet>(this.urlEditarHRI, datos);
  }

  eliminarHRI(datos:HojaRutaInternoPost):Observable<EliminarGet>{
    return this.http.post(this.urlEliminarHRI, datos);
  }
}
