import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { GuardarOpcionGet, GuardarOpcionPost, logIn, LogInGet, OpcionGet, Usuario, UsuarioGet, UsuarioPost, UsuariosGet } from '../models/Usuario';
import { UrlService } from './url.service';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  usuario:string="admin"
  clave:string="admin"
  user:Usuario={};


  urlLogIn:string=this.urlService.urlApi+"/api/LogIn/LogIn";
  urlConsultaUsuarios:string=this.urlService.urlApi+"/api/Usuario/Consultar";
  urlGuardarUsuario:string=this.urlService.urlApi+"/api/Usuario/Guardar";
  urlEliminarUsuario:string=this.urlService.urlApi+"/api/Usuario/Eliminar";
  urlGetOpciones=this.urlService.urlApi+"/api/Utiles/ObtenerOpciones";
  urlGuardarOpciones=this.urlService.urlApi+"/api/Usuario/GuardarOpcionUsuario";


  private agregarUsuarioSubject=new Subject<Usuario>();
  agregarUsuarioObservable=this.agregarUsuarioSubject.asObservable();
  constructor(private http:HttpClient, private urlService:UrlService) { }

  ingreso(usuario:string, pass:string):boolean{
    if(usuario===this.usuario && pass==this.clave){
   
      return true;
    }else{
      return false;
    }
  }

  agregarUsuario(usuario:Usuario){
    this.user=usuario;
    this.agregarUsuarioSubject.next(usuario);
    sessionStorage.setItem('user', JSON.stringify(usuario));
  }

  salir(){
    this.user=null;
    this.agregarUsuarioSubject.next(this.user);
    sessionStorage.setItem('user', null);
  }

  getLogIn(datos:logIn):Observable<LogInGet>{
    return this.http.post<LogInGet>(this.urlLogIn, datos);
  }
  /* 
    validar usuario y pass ingresado
    usar el usuario en navbar para ver cuales opciones se muestran, usar el servicio como en domicilio para comunicar componentes
   */
  getUsuarios():Observable<UsuariosGet>{
    return this.http.post<UsuariosGet>(this.urlConsultaUsuarios, "");
  }

  guardarUsuario(datos:UsuarioPost):Observable<UsuarioGet>{
    return this.http.post<UsuarioGet>(this.urlGuardarUsuario, datos);
  }

  eliminarUsuario(datos:UsuarioPost):Observable<UsuarioGet>{
    return this.http.post<UsuarioGet>(this.urlEliminarUsuario, datos);
  }

  getOpciones():Observable<OpcionGet>{
    return this.http.post<OpcionGet>(this.urlGetOpciones, "");
  }

  getGuardarOpcionUsuario(datos:GuardarOpcionPost):Observable<GuardarOpcionGet>{
    return this.http.post<GuardarOpcionGet>(this.urlGuardarOpciones, datos);
  }

}
