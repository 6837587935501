import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Local } from 'src/app/models/Local';
import { AnularFacturaPost, BusquedaFacturaPost, FacturaAnulacion, Observacion } from 'src/app/models/Orden-Guia';
import { Usuario } from 'src/app/models/Usuario';
import { ObservacionService } from 'src/app/services/observacion.service';
import { RecepcionService } from 'src/app/services/recepcion.service';

@Component({
  selector: 'app-anular-facturas',
  templateUrl: './anular-facturas.component.html',
  styleUrls: ['./anular-facturas.component.scss']
})
export class AnularFacturasComponent implements OnInit {

  tipoBusqueda:string="1";
  nroCB:string;
  tipoGuia:string="0";
  sucursal:string;
  nroGuia:string;
  razonSocialAgencia:string;
  importe:number;
  clasificacionFactura:string;
  observacionInput:string;
  observacion:Observacion={
    Codigo:null,
    ObsDescrip:''
  }
  usuario:Usuario;
  local:Local={
  }
  agenciaConectadaID:number;
  fecha:Date=new Date();

  factura:FacturaAnulacion={};

  constructor(private router:Router, private recepcionService:RecepcionService, private obsService:ObservacionService) { }

  ngOnInit(): void {

    this.usuario=JSON.parse(sessionStorage.getItem('user'));
    if(this.usuario!=null && this.usuario!=undefined){
      for(var i=0; i<this.usuario.OpcionesMenu.length; i++){
        if(this.usuario.OpcionesMenu[i].Url=='anular-facturas'){
          this.local.LocalID=this.usuario.LocalID;
          this.agenciaConectadaID=this.usuario.agenciaID;
          
          break;
        }else if(i==this.usuario.OpcionesMenu.length-1){
          //window.location.href='home';
          this.router.navigate(['/home'])
        }
      }
      if(this.usuario.OpcionesMenu.length==0){
        this.router.navigate(['/home'])
      }
    }else{
      //window.location.href='home';
      this.router.navigate(['/home'])
    }

    this.obsService.agregarObsObservable.subscribe(obs=>{
      if(obs!=null){
        this.observacion=obs;
      }
    })
  }

  buscar(){
    var correcto:boolean=false;
    var cb:string;
    if(this.tipoBusqueda=="1"){
      if(this.nroCB.length>=10){
        if(this.nroCB.substring(0,1)=="3"){
          cb="A"+this.nroCB.substring(1);
        }else if(this.nroCB.substring(0,1)=="4"){
          cb="B"+this.nroCB.substring(1);
        }else if(this.nroCB.substring(0,1)=="5"){
          cb="E"+this.nroCB.substring(1);
        }else if(this.nroCB.substring(0,1)=="9"){
          cb="R"+this.nroCB.substring(1);
        }else if(this.nroCB.substring(0,1)=="6"){
          cb="X"+this.nroCB.substring(1);
        }
        correcto=true;
      }else{
        alert("El código de barra debe tener 10 dígitos o más");
        correcto=false;
      }
    }else{
      correcto=true;
    }

    if(correcto){
      var datos:BusquedaFacturaPost={
        busquedaXCodigo:this.tipoBusqueda=="1"?true:false,
        codigoBarra:cb,
        tipoGuia:this.tipoGuia,
        nroSucursal:this.sucursal,
        nroGuia:this.nroGuia,
        unidadNegocioID:this.usuario.unidadNegocioID,
        agenciaConectadaID:this.agenciaConectadaID
      }
      this.recepcionService.buscarFactura(datos).subscribe(res=>{
        if(res.Codigo==400){
          alert(res.Mensaje);
          this.limpiar();
        }else{
          this.factura=res.factura;
          this.razonSocialAgencia=this.factura.AgenciaRazonSocial;
          this.importe=this.factura.Importe;
          this.clasificacionFactura=this.factura.ClasificacionFactura;
        }
      })
    }
  }

  limpiar(){
    this.nroCB=null;
    this.tipoBusqueda="1";
    this.tipoGuia="0";
    this.sucursal=null;
    this.nroGuia=null;
    this.importe=null;
    this.clasificacionFactura=null;
    this.razonSocialAgencia=null;
    this.observacionInput=null;
    this.observacion={
      Codigo:null,
      ObsDescrip:''
    }
  }

  confirmar(){
    this.factura.ObservacionID=this.observacion.ObservacionID!=undefined?this.observacion.ObservacionID:null;
    var datos:AnularFacturaPost={
      factura:this.factura,
      agenciaConectadaID:this.agenciaConectadaID,
      usuarioID:this.usuario.usuarioID
    }
    this.recepcionService.anularFactura(datos).subscribe(res=>{
      if(res.Codigo==400){
        alert(res.Mensaje);
        this.limpiar();
      }else if(res.Codigo==401){
        alert(res.Mensaje);
      }
      else{
        alert(res.Mensaje);
        this.limpiar();
      }
    })
  }


}
