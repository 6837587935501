import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalPostId } from 'src/app/models/Local';
import { GuiaSeguimiento, GuiaSeguimientoEstados, GuiaSeguimientoFiltroPost, GuiaSeguimientoPost } from 'src/app/models/Orden-Guia';
import { Usuario } from 'src/app/models/Usuario';
import { EmisionService } from 'src/app/services/emision.service';
import * as XLSX from 'xlsx';
declare var $:any;
@Component({
  selector: 'app-seguimiento-guias',
  templateUrl: './seguimiento-guias.component.html',
  styleUrls: ['./seguimiento-guias.component.scss']
})
export class SeguimientoGuiasComponent implements OnInit {

  guias:GuiaSeguimiento[]=[];
  cb:string="";
  cbGuia:string="";
  planillaRetiro:string="";
  remito:string="";
  tipoGuia:string="";
  nroSuc:string="";
  nroGuia:number;
  nroSeguimiento:string="";
  tiposGuiaSelec:string[]=[];
  guiasFiltradas:GuiaSeguimientoEstados[]=[];
  guiasFiltradasModal:GuiaSeguimientoEstados[]=[];
  mostrarBoton:boolean=false;
  estadoHis:boolean=false;
  cargando:boolean=false;

  usuario:Usuario;
  localId: LocalPostId = {
    LocalID: 0
  }
  agenciaID: number = 0;
  constructor(private emisionService:EmisionService, private router:Router) { }

  ngOnInit(): void {
    this.usuario=JSON.parse(sessionStorage.getItem('user'));
    if(this.usuario!=null && this.usuario!=undefined){
      for(var i=0; i<this.usuario.OpcionesMenu.length; i++){
        if(this.usuario.OpcionesMenu[i].Url=='seguimiento-guias'){
          this.localId.LocalID=this.usuario.LocalID;
          this.agenciaID=this.usuario.agenciaID;
          break;
        }else if(i==this.usuario.OpcionesMenu.length-1){
          //window.location.href='home';
          this.router.navigate(['/home'])
        }
      }
      if(this.usuario.OpcionesMenu.length==0){
        this.router.navigate(['/home'])
      }
    }else{
      //window.location.href='home';
      this.router.navigate(['/home'])
    }
    this.tiposGuiaSelec.push("A");
    this.tiposGuiaSelec.push("B");
    this.tiposGuiaSelec.push("R");
  }

  limpiar(){
    this.cb=''
    this.cbGuia=''
    this.remito=''
    this.planillaRetiro=''
    this.nroGuia=null
    this.nroSuc=''
    this.nroSeguimiento=''
    $("#todas").prop('checked', true);
    $("#A").prop('checked', false);
    $("#B").prop('checked', false);
    $("#R").prop('checked', false);
  }

  seleccionar(evento, idTG:number, tipo:string){
    if(idTG==0){
      if(evento.target.checked){
        $("#todas").prop('checked', true);
        if(this.tiposGuiaSelec.length<3){
          this.tiposGuiaSelec=[];
          
        }
      }else{
        this.tiposGuiaSelec=[];
      }
    }else{
      $("#todas").prop('checked', false);
      this.tiposGuiaSelec=[];
      if(evento.target.checked){
        if(this.tiposGuiaSelec.length>0){
          for(var i=1;i<=3; i++){
            for(var j=0; j<this.tiposGuiaSelec.length;j++){
              if(tipo==this.tiposGuiaSelec[j]){
                break;
              }else if(j==this.tiposGuiaSelec.length-1){
                this.tiposGuiaSelec.push(tipo);
                break;
              }
            }
          }
        }else{
          this.tiposGuiaSelec.push(tipo);
        }
      }else{
        if(this.tiposGuiaSelec.length>0){
          for(var j=0; j<this.tiposGuiaSelec.length;j++){
            if(tipo==this.tiposGuiaSelec[j]){
              this.tiposGuiaSelec.splice(j,1);
              break;
            }
          }
        }
      }
    }
  }

  validarDatos():boolean{
    var correcto:boolean=true;
    if(this.cb=='' && this.remito=='' && this.planillaRetiro=='' && (this.nroGuia==null || this.nroGuia==undefined) && this.nroSuc=='' && this.nroSeguimiento=='' && this.cbGuia=='') correcto=false;
    if(this.tipoGuia=='') correcto=false;
    return correcto;
  }

  consultar(){
    if(!this.cargando){
      this.tipoGuia="";
      for(var j=0; j<this.tiposGuiaSelec.length;j++){
        this.tipoGuia+=this.tiposGuiaSelec[j]+",";
      }
      
      var correcto:boolean=this.validarDatos();
      if(!correcto) alert("Complete alguno de los campos o seleccione todas o algún tipo de guía");
      else{
        this.cargando=true;
        var tGuia:string='';
        var nroSuc:string='';
        var nroGuia:number=0;
        if(this.cbGuia!='' && this.cbGuia!=undefined && this.cbGuia!=null){
          if(this.cbGuia.substring(0,1)=="3"){
            tGuia="A";
          }else if(this.cbGuia.substring(0,1)=="4"){
            tGuia="B";
          }else if(this.cbGuia.substring(0,1)=="9"){
            tGuia="R";
          }
          this.tipoGuia=tGuia+',';
          nroSuc=this.cbGuia.substring(1,5);
          nroGuia=parseInt(this.cbGuia.substring(5));
        }

        var datos:GuiaSeguimientoPost={
          codigoBarras:this.cb,
          remitoCliente:this.remito,
          ordenPlanillaRetiro:this.planillaRetiro,
          nroGuia:this.nroGuia==null?nroGuia:this.nroGuia,
          nroSucursal:this.nroSuc==''?nroSuc:this.nroSuc,
          tipoGuia:this.tipoGuia,
          nroSeguimientoUnico:this.nroSeguimiento
        }
        this.emisionService.getSeguimientoGuias(datos).subscribe(res=>{
          if(res.Codigo==400) alert(res.Mensaje);
          else{
            this.guias=res.guias;
            for(let g of this.guias){
              g.NroGuiaCompleto=this.rellenar(g.NroGuia.toString(), '0', 8);
            }
            this.mostrarBoton=false;
          }
          this.cargando=false;
        })
      }
    }else{
      alert("Hay una petición en progreso, por favor espere");
    }

  }

  exportarExcel(){
    var name='GuiasSeguimientoConsul.xlsx';
    let element=document.getElementById("guiasSeguimiento");
    const worksheet: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    const book: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(book, worksheet, 'Sheet1');

    XLSX.writeFile(book, name);
  }

  rellenar(cadena:string, valor:string, longitud:number):string{
    var cadenaNueva:string="";
    if(cadena.length<longitud){
      cadenaNueva=valor+cadena;
    }else if(cadena.length==longitud){
      return cadena;
    }
    if(cadenaNueva.length<longitud){
      return this.rellenar(cadenaNueva, valor, longitud);
    }else{
      return cadenaNueva;
    }
  }

  filtrar(opcion:string){
    if(opcion=="estados"){
      var datos:GuiaSeguimientoFiltroPost={
        tipoFiltro:"estadoHis"
      }
      this.cargando=true;
    }else{
      var datos:GuiaSeguimientoFiltroPost={
        tipoFiltro:"estadoOperativo"
      }
      this.cargando=true;
    }
    this.emisionService.getSeguimientoGuiaFiltro(datos).subscribe(res=>{
      if(res.Codigo==200){
        this.guiasFiltradas=res.guiasSeguimiento;
        this.mostrarBoton=true;
        if(opcion=="estados"){
          this.estadoHis=true;
        }else{
          this.estadoHis=false;
        }
        this.cargando=false;
      }
    })
  }

  abrirModal(guiaId:number){
    this.guiasFiltradasModal=this.guiasFiltradas.filter(g=>{
      if(g.GuiaID==guiaId){
        return g;
      }
    })

    $("#modalEstadosGuia").modal('show');
  }
}
