import { Component, OnInit } from '@angular/core';
import { Localidad, Provincia, ProvinciaId } from 'src/app/models/Domicilio';
import { DomicilioLocal } from 'src/app/models/Domicilio';
import { DomicilioService } from 'src/app/services/domicilio.service';
declare var $:any;

@Component({
  selector: 'app-modal-domicilio',
  templateUrl: './modal-domicilio.component.html',
  styleUrls: ['./modal-domicilio.component.scss']
})
export class ModalDomicilioComponent implements OnInit {

  //datos form
  correcto:boolean=true;
  provincia:string='';
  localidad:string='';
  cp:string='';
  calle:string='';
  nro:string='';
  telefono:string='';
  telefono2:string='';
  observaciones:string='';
  domicilio:DomicilioLocal={};
  provincias:Provincia[]=[];
  localidades:Localidad[]=[];
  provinciaSeleccionada:ProvinciaId={};
  localidadesByProv:Localidad[]=[];
  localidadesSelect:Localidad[]=[];
  callesLocalidades:Localidad[]=[];
  constructor(private domicilioService:DomicilioService) { }

  ngOnInit(): void {
    this.domicilioService.agregarDomicilioObservable.subscribe((domicilio)=>{
      if(domicilio!=null){
        //domicilio
        this.domicilio=domicilio;
        this.provincia=this.domicilio.ProvinciaDescrip;
        this.localidad=this.domicilio.LocalidadDescrip;
        this.cp=this.domicilio.CodigoPostalNro;
        this.calle=this.domicilio.Calle;
        this.nro=this.domicilio.CalleNro;
        this.telefono=this.domicilio.Telefono;
        this.telefono2=this.domicilio.Telefono2;
        this.observaciones=this.domicilio.Observacion;
        //localidades
        for(let provincia of this.provincias){
          if(provincia.ProvinciaDescrip===this.provincia){
            this.provinciaSeleccionada.ProvinciaId=provincia.ProvinciaID;
            this.domicilio.ProvinciaID=provincia.ProvinciaID;
            this.domicilio.ProvinciaDescrip=provincia.ProvinciaDescrip;
            break;
          }
        }
        
        this.domicilioService.getAllLocalidadesByIdProv(this.provinciaSeleccionada).subscribe((localidadesApi)=>{
          this.localidadesByProv=localidadesApi.Localidades;
          console.log(this.localidadesByProv)
          this.localidades=this.localidadesByProv.filter(loc=>{
            return loc.LocalidadDescrip.toLowerCase().includes("capital federal".toLowerCase());
          })
          for(let localidad of this.localidadesByProv){
            if(localidad.LocalidadDescrip.toLowerCase()!=='capital federal'){
              this.localidadesSelect.push(localidad);
            }
          }
          var localidad:Localidad={
            localidadid:0,
            AlturaDesde:null, 
            AlturaHasta:null, 
            CalleDescrip:null,
            CodigoPostal:null,
            CodigoPostalID:0,
            LocalidadDescrip:"CAPITAL FEDERAL",
            ProvinciaID:0
          }
          this.localidadesSelect.push(localidad);
          console.log(this.localidadesSelect);
        })
        //calles
        setTimeout(() => {
          if(this.localidad.toLowerCase()==='capital federal'){
            for(let localidad of this.localidadesByProv){
              if(localidad.LocalidadDescrip===this.localidad){
                this.callesLocalidades.push(localidad);
              }
            }
            
          }else{
            for(let localidad of this.localidadesByProv){
              if(localidad.LocalidadDescrip===this.localidad){
                this.cp=localidad.CodigoPostal;
                this.domicilio.LocalidadID=localidad.localidadid;
                this.domicilio.LocalidadDescrip=localidad.LocalidadDescrip;
                this.domicilio.CodigoPostalNro=localidad.CodigoPostal;
                this.domicilio.CodigoPostalID=localidad.CodigoPostalID;
                break;
              }
            }
          }
        }, 2500);
        
      }
    });
    this.domicilioService.getAllProvincias().subscribe((provinciasApi)=>{
      this.provincias=provinciasApi.Provincias;
    });
  }

  mostrarDatos(){
    
  }

  guardarDomicilio(){
    if(this.localidad.toLowerCase()!=='capital federal'){
      this.domicilio.DomicilioLocalID=0;
      this.domicilio.Calle=this.calle;
      this.domicilio.CalleNro=this.nro;
      this.domicilio.Telefono=this.telefono;
      this.domicilio.Telefono2=this.telefono2;
      this.domicilio.Observacion=this.observaciones;
      this.domicilioService.agregarDomicilio(this.domicilio);
    }else{
      this.domicilio.DomicilioLocalID=0;
      this.domicilio.CalleNro=this.nro;
      this.domicilio.Telefono=this.telefono;
      this.domicilio.Telefono2=this.telefono2;
      this.domicilio.Observacion=this.observaciones;
      this.domicilioService.agregarDomicilio(this.domicilio);
    }
    console.log(this.domicilio);
    $("#modalDomicilio").modal('hide');
    $("#modalLocal").modal('hide');
    $("#modalLocal").modal('show');
    $("#modalPuntoVenta").modal('hide');
    $("#modalPuntoVenta").modal('show');
    this.provincia='';
    this.localidad='';
    this.calle='';
    this.nro='';
    this.telefono='';
    this.telefono2='';
    this.observaciones='';
    this.cp='';
  }

  cerrarModal(){
    if(this.domicilio===null){
      this.domicilioService.agregarDomicilio(null);
    }
    $("#modalDomicilio").modal('hide');
    $("#modalLocal").modal('hide');
    $("#modalLocal").modal('show');
    $("#modalPuntoVenta").modal('hide');
    $("#modalPuntoVenta").modal('show');
    this.provincia='';
    this.localidad='';
    this.calle='';
    this.nro='';
    this.telefono='';
    this.telefono2='';
    this.observaciones='';
    this.cp='';
  }

  provinciaIngresada(event){
    console.log(event)
    for(let provincia of this.provincias){
      if(provincia.ProvinciaDescrip===event.target.value){
        this.provinciaSeleccionada.ProvinciaId=provincia.ProvinciaID;
        this.domicilio.ProvinciaID=provincia.ProvinciaID;
        this.domicilio.ProvinciaDescrip=provincia.ProvinciaDescrip;
        break;
      }
    }
    
    this.domicilioService.getAllLocalidadesByIdProv(this.provinciaSeleccionada).subscribe((localidadesApi)=>{
      this.localidadesByProv=localidadesApi.Localidades;
      console.log(this.localidadesByProv)
      this.localidades=this.localidadesByProv.filter(loc=>{
        return loc.LocalidadDescrip.toLowerCase().includes("capital federal".toLowerCase());
      })
      for(let localidad of this.localidadesByProv){
        if(localidad.LocalidadDescrip.toLowerCase()!=='capital federal'){
          this.localidadesSelect.push(localidad);
        }
      }
      var localidad:Localidad={
        localidadid:0,
        AlturaDesde:null, 
        AlturaHasta:null, 
        CalleDescrip:null,
        CodigoPostal:null,
        CodigoPostalID:0,
        LocalidadDescrip:"CAPITAL FEDERAL",
        ProvinciaID:0
      }
      this.localidadesSelect.push(localidad);
      console.log(this.localidadesSelect);
    })
  }

  localidadIngresada(event){
    if(event.target.value.toLowerCase()==='capital federal'){
      for(let localidad of this.localidadesByProv){
        if(localidad.LocalidadDescrip===event.target.value){
          this.callesLocalidades.push(localidad);
        }
      }
    }else{
      for(let localidad of this.localidadesByProv){
        if(localidad.LocalidadDescrip===event.target.value){
          this.cp=localidad.CodigoPostal;
          this.domicilio.LocalidadID=localidad.localidadid;
          this.domicilio.LocalidadDescrip=localidad.LocalidadDescrip;
          this.domicilio.CodigoPostalNro=localidad.CodigoPostal;
          this.domicilio.CodigoPostalID=localidad.CodigoPostalID;
          break;
        }
      }
    }
    console.log(this.callesLocalidades);
  }

  calleIngresada(event){
    if(this.localidad.toLowerCase()==='capital federal'){
      var cadena=event.target.value.split(" (", 2)
      var cadenaNros=cadena[1].split("-");
      cadenaNros[0]=cadenaNros[0].replace(" ", "");
      cadenaNros[1]=cadenaNros[1].replace(" ", "");
      cadenaNros[1]=cadenaNros[1].replace(")", "");
      console.log(cadena[0]);
      console.log(cadenaNros);
      for(let localidad of this.callesLocalidades){
        if(localidad.CalleDescrip===cadena[0] && localidad.AlturaDesde===cadenaNros[0] && localidad.AlturaHasta===cadenaNros[1]){
          this.cp=localidad.CodigoPostal;
          this.domicilio.LocalidadID=localidad.localidadid;
          this.domicilio.LocalidadDescrip=localidad.LocalidadDescrip;
          this.domicilio.CalleNro=localidad.CodigoPostal;
          this.domicilio.CodigoPostalID=localidad.CodigoPostalID;
          this.domicilio.CodigoPostalNro=localidad.CodigoPostal;
          this.domicilio.Calle=cadena[0];
          break;
        }
      }
      if(this.nro!=''){
        this.validarCalleNro(parseInt(this.nro));
      }
    }
  }

  validarCalleNro(nro:number){
    var localidad1:Localidad;
    for(let localidad of this.localidadesByProv){
      if(localidad.CalleDescrip===this.domicilio.Calle && localidad.CodigoPostal===this.cp){
        localidad1=localidad;
        if(nro<parseInt(localidad.AlturaDesde) || nro>parseInt(localidad.AlturaHasta)){
          this.correcto=false;
        }else{
          this.correcto=true;
        }
        break;
      }
    }
  }
}
