import { Component, Input, OnInit } from '@angular/core';
import { AutorizadosPost, Cliente, ClienteEventual, ClienteEventualPost, ClientePostBusqueda, NominaAutorizados, TipoDocumento } from 'src/app/models/Cliente';
import { DomicilioId, DomicilioLocal, Localidad, LocalidadPostBusqueda } from 'src/app/models/Domicilio';
import { Local, LocalCFacturable, LocalCO, LocalPostId, LocalServicio, LocalUVenta } from 'src/app/models/Local';
import { CodigoBarra, CodigoBarraGetConfirmacion, CodigoBarraGetValidacion, CodigoBarraPostConfirmacion, CodigoBarraPostValidacion, ConceptoEmision, ConceptoEmisionFacturablePost, ConceptoEmisionPost, ConceptosFacturacionOR, CondicionIva, CondicionPago, ConsultaCreditoAgenciaGet, ConsultaCreditoAgenciaPost, ConsultaRecargoPost, DatosImpresion, EmitirGuia,  EmitirGuiaPost, GuardadoPdfPost, Guia, GuiaConceptoFacturacion, GuiaEmitida, OpcionEnvio, OpcionesEnvioPost, OrdenRetiro, Producto, ProductoPost, ProductoPostAgencia } from 'src/app/models/Orden-Guia';
import { Usuario } from 'src/app/models/Usuario';
import { EmisionService } from 'src/app/services/emision.service';
import { LocalService } from 'src/app/services/local.service';
declare var $: any;
@Component({
  selector: 'app-orden-retiro',
  templateUrl: './orden-retiro.component.html',
  styleUrls: ['./orden-retiro.component.scss', './NuevoEstilosOrdenDeRetiro.css']
})
export class OrdenRetiroComponent implements OnInit {

  @Input() ordenRetiro:OrdenRetiro;

  @Input() localUV:LocalUVenta[];
  @Input() localCO:LocalCO;
  @Input() localservicio:LocalServicio[];
  @Input() localCf:LocalCFacturable[];
  @Input() domicilioLocal:DomicilioLocal;
  fecha:string='12/08/2020';
  //variable de paginación
  p: number = 1;
  //local que hace la emision-utilizado para pruebas luego dependerá del login
  localId: LocalPostId = {
    LocalID: 360
  }
  agenciaID: number = 345;
  local: Local = {};
  localUv: LocalUVenta[] = [];
  localCo: LocalCO = {};
  localServicio: LocalServicio[] = [];
  localCF: LocalCFacturable[] = [];
  localDomicilio: DomicilioLocal = {}
  consultaCreditoAgencia: ConsultaCreditoAgenciaGet = {}

  //variables para que vayan apareciendo los pasos y activar/desactivar campos
  paso2: boolean = true;
  paso3: boolean = true;
  despachanteOrigen: boolean = true;
  despachanteDestino: boolean = true;
  opcionesEnvio: boolean = true;
  tablaServicios: boolean = true;
  nuevoServicio: boolean = true;
  botonServicios: boolean = true;
  mostrarCalleOrigen: boolean = true;
  mostrarCalleDestino: boolean = true;
  botonesEmitir: boolean = true;
  botonEmitir:boolean=true;
  mostrarEmbalaje: boolean = true;
  mostrarCodigo: boolean = true;
  mostrarOpcionEnvio: boolean = true;
  mostrarOpcionEnvioCodigo: boolean = true;
  codClienteO: boolean = true;
  codClienteD: boolean = true;
  esCorpo: boolean = true;
  cargando: boolean = true;
  cargandoProductos: boolean = true;
  cargandoServicio: boolean = true;
  validandoCodigo:boolean=true;
  confirmandoCodigo:boolean=true;
  mostrarContado: boolean = true;
  mostrarCtaCte: boolean = true;
  tieneBonificacion: boolean = true;
  opcionSeleccionada?: boolean = true;
  precintoObligatorio: boolean = true;
  hsDiasObligatorio: boolean = true;
  cbObligatorio: boolean = true;
  pocoCredito: boolean = false;
  mostrarAlert: boolean = true;
  precintoValid: boolean = true;
  hsDiasValid: boolean = true;
  cbValid: boolean = true;
  clienteOrigen: boolean = true;
  clienteDestino:boolean=true;
  carrito:boolean=true;
  pagoTarjeta:boolean=true;
  tieneRecargo:boolean=true;
  cargandoEmision:boolean=true;
  //variables a recibir desde la api
  localesDestino: Local[] = [];
  serviciosAdicionales: GuiaConceptoFacturacion[] = [];
  nuevoServicioAdicional: GuiaConceptoFacturacion = {
    ConceptoFacturacionID: 0,
    Importe: null,
    NroPrecinto: "",
    CodigoBarras: "",
    Cantidad: null
  }
  conceptoDescrip
  conceptosFacturables: ConceptoEmision[] = [];
  conceptosSelect: ConceptoEmision[] = [];
  conceptosTabla: ConceptosFacturacionOR[] = [];

  localidadesDestino: Localidad[] = [];
  localidadesDestinoFiltro: Localidad[] = [];
  localidadesDestinoCapitalFederal: Localidad[] = [];
  localidadesOrigen: Localidad[] = [];
  localidadesOrigenFiltro: Localidad[] = [];
  localidadesOrigenCapitalFederal: Localidad[] = []
  callesOrigen: Localidad[] = [];
  callesDestino: Localidad[] = [];
  localidadOrigenCompleta: Localidad = {}
  localidadDestinoCompleta: Localidad = {}
  clientes: Cliente[] = [];

  tiposDoc: TipoDocumento[] = [];
  condicionesIva: CondicionIva[] = [];
  condicionesPago: CondicionPago[] = [];

  productosApi: Producto[] = [];
  productos: Producto[] = [];
  producto: Producto = {};
  productosSeleccionados: Producto[] = [];
  codigos: CodigoBarra[] = [];
  codigoApi: CodigoBarraGetValidacion = {};
  codigosConfirmadosApi: CodigoBarraGetConfirmacion = {};
  modalidadesSeleccionadas: number[] = [];
  opcionesEnvioApi: OpcionEnvio[] = [];
  opcionesEnvioFiltro: OpcionEnvio[] = [];
  opcionEnvioSeleccionada: OpcionEnvio = {};
  opcionesEnvioCodigo: OpcionEnvio[] = [];

  autorizadosCorpo: NominaAutorizados[] = [];
  autorizado:NominaAutorizados={};
  guias:Guia[]=[];
  guiaAEnviar:EmitirGuia[]=[];
  guiasEmitidas:GuiaEmitida[]=[];
  datosImpresion:DatosImpresion[]=[];
  usuario:Usuario;
  
  //formulario paso 1
  tipoCliente: string = '';
  codCliente: number;
  nroOrdenRetiro: string = '';
  leyendaPago:string='';
  tipoPago: string;
  localidadOrigen: string = '';
  localidadOrigenID: number;
  cpOrigen: string = '';
  cpOrigenID: number;
  calleOrigen: string = '';
  localidadDestino: string = '';
  localidadDestinoID: number;
  cpDestino: string = '';
  cpDestinoID: number;
  calleDestino: string = '';
  cantBultos: number;
  peso: number;
  pesoM3:number;
  valorDeclarado: number;
  UVId: string = "";
  alto: number;
  ancho: number;
  largo: number;
  embalaje: number;
  codigoPromocional: string = '';
  porcentajeBonificacion: number;
  ordenPlanillaRetiro: string = '';
  remitoCliente: string = '';
  precintoSeguridad: string = '';
  observaciones: string = '';
  modalidadTT: string = '';
  modalidadPP: string = '';
  modalidadTP: string = '';
  modalidadPT: string = '';
  pagoDestino: boolean = false;
  importeTotal: number;
  cb: string;
  valorDeclaradoCodigo:number;
  crCodigo:number;
  mensaje:string;
  //formulario paso 2
  clienteRemitente: Cliente = {};
  clienteEventualRemitente: ClienteEventual = {};
  domicilioClienteRemitente: DomicilioLocal = {};
  codClienteRemitente: number;
  nombreRemitente: string = '';
  apellidoRemitente: string = '';
  tipoDocRemitente: string = '';
  dniRemitente: string = '';
  codAreaRemitente: number;
  telefonoRemitente: string = '';
  mailRemitente: string = '';
  tipoCondicionIVARemitente: string = '';
  nroCalleRemitente: string = '';
  pisoRemitente: string = '';
  descRemitente: string = '';
  tipoDocDespachanteOrigen: string = '';
  dniDespachanteOrigen: string = '';
  nombreDespachanteOrigen: string = '';
  apellidoDespachanteOrigen: string = '';

  //formulario paso 3
  clienteDestinatario: Cliente = {}
  clienteEventualDestinatario: ClienteEventual = {};
  domicilioClienteDestinatario: DomicilioLocal = {}
  tipoClienteDestinatario: string = 'eventual';
  codClienteDestinatario: number;
  nombreDestinatario: string = '';
  apellidoDestinatario: string = '';
  tipoDocDestinatario: string = '';
  dniDestinatario: string = '';
  codAreaDestinatario: number;
  telefonoDestinatario: string = '';
  mailDestinatario: string = '';
  tipoCondicionIVADestinatario: string = '';
  nroCalleDestinatario: string = '';
  pisoDestinatario: string = '';
  descDestinatario:string='';
  tipoDocDespachanteDestino: string = '';
  dniDespachanteDestino: string = '';
  nombreDespachanteDestino: string = '';
  apellidoDespachanteDestino: string = '';

  //último paso
  nroLote:string=null;
  nroCupon:string=null;


  constructor(private emisionService: EmisionService, private localService:LocalService) { }

  ngOnInit(): void {

    /* this.usuario=JSON.parse(sessionStorage.getItem('user'));

    if(this.usuario!=null && this.usuario!=undefined){
      for(var i=0; i<this.usuario.OpcionesMenu.length; i++){
        if(this.usuario.OpcionesMenu[i].URL=='emision'){
          this.localId.LocalID=this.usuario.LocalID;
          this.agenciaID=this.usuario.agenciaID;
          break;
        }else if(i==this.usuario.OpcionesMenu.length-1){
          window.location.href='home';
        }
      }
    }else{
      window.location.href='home';

    } */

    this.emisionService.getTipoDocumento().subscribe((res) => {
      this.tiposDoc = res.TiposDocumentos;
    })
    this.emisionService.getCondicionIva().subscribe((res) => {
      this.condicionesIva = res.CondicionIva;
    })

    /* this.localService.getOneLocal(this.localId).subscribe((res) => {
      this.local = res.Locales[0];
      var localDom: DomicilioId = {
        Id: this.local.DomicilioLocalID,
        LocalID:this.local.LocalID
      }
      this.localService.getLocalDomicilio(localDom).subscribe((res) => {
        this.localDomicilio = res.Domicilio;
        if (this.localDomicilio.LocalidadDescrip.toLowerCase() === 'capital federal') {
          this.mostrarCalleOrigen = true;
          this.localidadOrigen = this.localDomicilio.LocalidadDescrip + ", " + this.localDomicilio.ProvinciaDescrip;
          this.cpOrigen = this.localDomicilio.CodigoPostalNro;
          this.localidadOrigenID = this.localDomicilio.LocalidadID;
          this.cpOrigenID = this.localDomicilio.CodigoPostalID;
        } else {
          this.mostrarCalleOrigen = false;
          this.localidadOrigen = this.localDomicilio.LocalidadDescrip + ", " + this.localDomicilio.ProvinciaDescrip;
          this.cpOrigen = this.localDomicilio.CodigoPostalNro;
          this.localidadOrigenID = this.localDomicilio.LocalidadID;
          this.cpOrigenID = this.localDomicilio.CodigoPostalID;
        }
        this.localidadOrigenCompleta.localidadid=this.localDomicilio.LocalidadID;
        this.localidadOrigenCompleta.LocalidadDescrip=this.localDomicilio.LocalidadDescrip;
        this.localidadOrigenCompleta.ProvinciaID=this.localDomicilio.ProvinciaID;
        this.localidadOrigenCompleta.ProvinciaDescrip=this.localDomicilio.ProvinciaDescrip;
        this.localidadOrigenCompleta.CodigoPostalID=this.localDomicilio.CodigoPostalID;
        this.localidadOrigenCompleta.CodigoPostal=this.localDomicilio.CodigoPostalNro;
      })
    })
    this.localService.getLocalCO(this.localId).subscribe((res) => {
      this.localCo = res.Local;
    })
    this.localService.getLocalServicio(this.localId).subscribe((res) => {
      this.localServicio = res.LocalesServicio
    })
    this.localService.getLocalUv(this.localId).subscribe((res) => {
      this.localUv = res.LocalesUV

    }) */
  }

  mostrarOR(){
    if(this.ordenRetiro!=null && this.ordenRetiro!=undefined){
      this.opcionesEnvioApi=[];
      this.opcionesEnvioFiltro=[];
      var opcion:OpcionEnvio={
        ProductoDescrip:this.ordenRetiro.ProductoDescrip,
        ModalidadEntregaDescrip:this.ordenRetiro.ModalidadEntregaDescrip,
        ImporteNormal:this.ordenRetiro.ImporteTotal,
        Importe:this.ordenRetiro.ImporteTotal,
        ValorDeclarado:this.ordenRetiro.ValorDeclarado,
        UnidadVentaID:this.ordenRetiro.UnidadVentaID,
        ModalidadEntregaID:this.ordenRetiro.ModalidadEntregaID
      }
      switch(this.ordenRetiro.ModalidadEntregaID){
        case 1:
          $("#tt").prop('checked', true);
          break;
        case 2:
          $("#pp").prop('checked', true);
          break;
        case 3:
          $("#tp").prop('checked', true);
          break;
        case 4:
          $("#pt").prop('checked', true);
          break;
      }

      this.opcionesEnvioFiltro.push(opcion);
      this.tipoCliente=this.ordenRetiro.TipoClienteRemitente;
      this.tipoPago=this.ordenRetiro.CondicionPagoID.toString();
      this.codCliente=this.ordenRetiro.CodigoRemitente==0?null:this.ordenRetiro.CodigoRemitente;
      this.localidadOrigen=this.ordenRetiro.LocalidadRemitente+", "+this.ordenRetiro.ProvinciaRemitente;
      this.localidadDestino=this.ordenRetiro.LocalidadDestinatario+", "+this.ordenRetiro.ProvinciaDestinatario;
      this.cpOrigen=this.ordenRetiro.CodigoPostalRemitente.toString();
      this.cpDestino=this.ordenRetiro.CodigoPostalDestinatario.toString();
      this.cantBultos=this.ordenRetiro.Bultos;
      this.peso=this.ordenRetiro.Peso>this.ordenRetiro.PesoAforado?this.ordenRetiro.Peso:this.ordenRetiro.PesoAforado;
      this.valorDeclarado=this.ordenRetiro.ValorDeclarado;
      this.alto=this.ordenRetiro.Alto;
      this.ancho=this.ordenRetiro.Ancho;
      this.largo=this.ordenRetiro.Largo;
      this.nroOrdenRetiro=this.ordenRetiro.NumeroOrden;
      if(this.ordenRetiro.Pago=="" && this.ordenRetiro.CondicionPagoID==1){
        this.leyendaPago="FALTA QUE PAGUE EL REMITENTE";
      }else if(this.ordenRetiro.Pago!=""){
        if(this.ordenRetiro.Pago.toUpperCase()=="APPROVED"){
          this.leyendaPago="YA ESTÁ PAGADO";
        }else if(this.ordenRetiro.Pago.toUpperCase()=="PENDING"){
          this.leyendaPago="Esparando confirmación de pago de MERCADO PAGO";
        }else{
          this.leyendaPago="La solicitud no se encuentra en condiciones de ser emitida. Verificar Pago en MERCADO PAGO";
        }
      }
      this.remitoCliente=this.ordenRetiro.RemitoClienteNro;
      this.ordenPlanillaRetiro=this.ordenRetiro.NumeroSolicitud;
      this.conceptosTabla=this.ordenRetiro.ServiciosAdicionales;
      for(var i=0;i<this.conceptosTabla.length; i++){
        if(this.conceptosTabla[i].ConceptoFacturacionID==0 &&
          this.conceptosTabla[i].ImporteConcepto==0){
            this.conceptosTabla.splice(i, 1);
          }
      }
      this.nombreRemitente=this.ordenRetiro.NombreRemitente;
      this.apellidoRemitente=this.ordenRetiro.ApellidoRemitente;
      this.tipoDocRemitente=this.ordenRetiro.TipoDocumentoIDRemitente.toString();
      this.dniRemitente=this.ordenRetiro.TipoDocumentoIDRemitente==1?this.ordenRetiro.NroDocumentoRemitente:this.ordenRetiro.NroCUITRemitente;
      this.telefonoRemitente=this.ordenRetiro.TelefonoRemitente;
      this.tipoCondicionIVARemitente=this.ordenRetiro.TipoDocumentoIDRemitente.toString();
      this.calleOrigen=this.ordenRetiro.CalleRemitente;
      this.nroCalleRemitente=parseInt(this.ordenRetiro.CalleNroRemitente)>0?this.ordenRetiro.CalleNroRemitente:'';
      this.pisoRemitente=this.ordenRetiro.PisoDeptoRemitente;
      
      this.tipoClienteDestinatario=this.ordenRetiro.TipoClienteDestinatario;
      this.nombreDestinatario=this.ordenRetiro.NombreDestinatario;
      this.apellidoDestinatario=this.ordenRetiro.ApellidoDestinatario;
      this.tipoDocDestinatario=this.ordenRetiro.TipoDocumentoIDDestinatario.toString();
      this.dniDestinatario=this.ordenRetiro.TipoDocumentoIDDestinatario==1?this.ordenRetiro.NroDocumentoDestinatario:this.ordenRetiro.NroCUITDestinatario;
      this.calleDestino=this.ordenRetiro.CalleDestinatario;
      this.nroCalleDestinatario=parseInt(this.ordenRetiro.CalleNroDestinatario)>0?this.ordenRetiro.CalleNroDestinatario:'';
      this.pisoDestinatario=this.ordenRetiro.PisoDeptoDestinatario;
      this.telefonoDestinatario=this.ordenRetiro.TelefonoDestinatario;
      this.tipoCondicionIVADestinatario=this.ordenRetiro.TipoDocumentoIDDestinatario.toString();
      
      
    }
  }

  agregarCarrito(){
    var or:OrdenRetiro=this.ordenRetiro;
    or.Bultos=this.cantBultos;
    or.Alto=this.alto;
    or.Ancho=this.ancho;
    or.RemitoClienteNro=this.remitoCliente;
    or.PesoM3=this.pesoM3;
    or.ValorDeclarado=this.valorDeclarado;
    this.emisionService.agregarOR(or);
    var datosEnvio:OpcionesEnvioPost={
      UnidadVentaID:or.UnidadVentaID.toString()+",",
      ModalidadEntregaID:or.ModalidadEntregaID.toString()+",",
      Peso:or.Peso,
      PesoM3:or.PesoM3,
      Alto:or.Alto,
      Ancho:or.Ancho,
      Largo:or.Largo,
      ValorDeclarado:or.ValorDeclarado,
      ImporteCR:0,
      CantBultos:or.Bultos,
      LocalidadOrigenID:this.localDomicilio.LocalidadID,
      LocalidadDestinoID:or.LocalidadID,
      LocalidadRemitenteID:or.LocalidadRemitenteID,
      LocalidadDestinatarioID:or.LocalidadDestinatarioID,
      CodigoPostalOrigenID:this.localDomicilio.CodigoPostalID,
      CodigoPostalOrigenNro:this.localDomicilio.CodigoPostalNro,
      CodigoPostalRemitenteID:or.CodigoPostalRemitenteID,
      CodigoPostalRemitenteNro:or.CodigoPostalRemitente,
      CodigoPostalDestinoID:or.CodigoPostalID,
      CodigoPostalDestinoNro:or.CodigoPostal,
      CodigoPostalDestinatarioID:or.CodigoPostalDestinatarioID,
      CodigoPostalDestinatarioNro:or.CodigoPostalDestinatario,
      CondicionPagoID:or.CondicionPagoID==6?or.CondicionPagoIDDestinatario:or.CondicionPagoIDRemitente,//debería ser la del remitente o destinatario si es fpd
      CondicionIvaID:or.CondicionIvaIDRemitente,
      FPD:or.CondicionPagoID==6?true:false,
      LocalOrigenID:this.agenciaID,
      UnidadNegocioID:1,
      PromocionServicioID:0,
      PorcentajeBonificacion:0,
      esOR:true
    }
    this.emisionService.getOpcionesEnvio(datosEnvio).subscribe(res=>{

      if(res.Mensaje!=null){
        alert(res.Mensaje);
        $("#modalOR").modal('hide');
      }else{
        if(res.OpcionesEnvio.length==1){
          this.emisionService.agregarOpcionEnvio(res.OpcionesEnvio[0]);
          $("#modalOR").modal('hide');
        }
      }
    })
  }

}
