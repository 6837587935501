<!-- Pantalla consulta -->
<div class="container-fluid mt-5" style="width: 94%;">
    <div class="row">
      <div class="col">
        <label>Código Local</label>
        <input type="number" class="form-control" id="codLoc" [(ngModel)]="codigo" name="codLoc" (input)="buscarLocal($event.target.value, 'codigo')" placeholder="Buscar ...">
      </div> 
      <div class="col">
        <label>Nombre Local</label>
        <input type="text" class="form-control" id="nomLoc" [(ngModel)]="nombreL" name="nomLoc" (input)="buscarLocal($event.target.value, 'nombreL')" placeholder="Buscar ...">
      </div>
      <div class="col">
        <label>Habilitada/Deshabilitada</label>
        <select class="custom-select" id="bj" [(ngModel)]="baja" name="bj" (change)="buscarLocal($event.target.value, 'baja')">
          <option selected value="null" disabled>Seleccione</option>
          <option value="false">Habilitada</option>
          <option value="true">Deshabilitada</option>
        </select>
      </div>
      <div class="col">
        <label>Nombre Punto Venta</label>
        <input type="text" class="form-control" id="nomPV" [(ngModel)]="nombrePV" name="nomPV" (input)="buscarLocal($event.target.value, 'nombrePV')" placeholder="Buscar ...">
      </div>
      <div class="col">
        <label>Localidad</label>
        <input type="text" class="form-control" id="loc" [(ngModel)]="localidad" name="loc" (input)="buscarLocal($event.target.value, 'localidad')" placeholder="Buscar ...">
      </div>
    </div>
      <br>
      <button class="btn btn-info" (click)="abrirModalLocal(0)">Agregar Local</button>
      <jqxGrid #localesGrid class="grid mt-2"
          [width]="getWidth()" [source]="dataAdapter" [columns]="columns"
          [pageable]="true" [autoheight]="true" *ngIf="!cargando">
      </jqxGrid><br>
      <div *ngIf="cargando">
        <h4>Cargando...</h4>
      </div>
  </div>
  <!-- Modal agregar/modificar local -->
  <div>
    <div class="modal" tabindex="-1" id="modalLocal" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Local</h5>
            </div>
            <div class="modal-body">
                <form #formLocal="ngForm">
                  <div class="form-group" hidden>
                    <label>LocalID</label>
                    <input class="form-control" type="text" [(ngModel)]="idLocal" name="idl" id="idL">
                  </div>
                  <div class="container">
                    <div class="row">
                      <div class="col">
                        <div class="form-group">
                          <label>Código Local</label>
                          <input class="form-control" type="number" required [(ngModel)]="codigoLocal" name="cod" id="cod" #cod="ngModel" (blur)="validarCodigoLocal(codigoLocal)" (focus)="disponible=true">
                          <div [hidden]="cod.valid" class="alert alert-danger mt-1" style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido</div>
                          <div [hidden]="disponible" class="alert alert-danger mt-1" style="width: 200px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;" >
                            Código Local no disponible
                          </div>
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <label>Nombre</label>
                          <input class="form-control" type="text" required [(ngModel)]="nombreLocal" name="nombre" id="nombre" #nombre="ngModel">
                          <div [hidden]="nombre.valid" class="alert alert-danger mt-1" style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido</div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div class="form-group">
                          <label>Punto Venta</label>
                          <div class="input-group">
                            <input class="form-control" style="width: 100px;" type="number" required [(ngModel)]="nroSucPv" name="sucPv" id="sucPv" #sucPv="ngModel">
                            <input class="form-control" style="width: 200px;" type="text" required [(ngModel)]="nombrePv" name="nombPV" id="nombPV" #nombPV="ngModel">&nbsp;
                            <button class="btn btn-info" data-toggle="modal" data-target="#modalPV">Seleccionar</button>
                          </div>
                          <div class="input-group">
                            <div [hidden]="sucPv.valid" class="alert alert-danger mt-1" style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">
                              *Campo requerido
                            </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <div [hidden]="nombPV.valid" class="alert alert-danger mt-1" style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">
                              *Campo requerido
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-4">
                        <div class="form-group">
                          <label>Estado</label>
                          <select class="custom-select" required [(ngModel)]="estadoLocal" name="estado" #estado="ngModel">
                            <option selected value="false">Habilitada</option>
                            <option value="true">Deshabilitada</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <hr class="my-4">
                    <div class="row">
                      <div class="col">
                        <div class="form-group">
                          <label>Responsable</label>
                          <div class="input-group">
                            <input type="number" class="form-control" style="width: 100px;" [(ngModel)]="dniResponsable" name="dniR" required #dniR="ngModel"><!-- dni -->
                            
                            <input type="text" class="form-control" style="width: 200px;" [(ngModel)]="apeNomResponsable" name="apeNomR" required #apeNomR="ngModel">&nbsp;<!-- apellido y nombre -->
                            
                            <button type="button" class="btn btn-info" data-toggle="modal" data-target="#modalResponsables" >Seleccionar</button>
                            
                          </div>
                          <div class="input-group">
                            <div [hidden]="dniR.valid" class="alert alert-danger mt-1" style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">
                              *Campo requerido
                            </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <div [hidden]="apeNomR.valid" class="alert alert-danger mt-1" style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">
                              *Campo requerido
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr class="my-4">
                    <div class="row">
                      <div class="col">
                        <div class="form-group">
                          <label>Domicilio</label><br>
                          <button class="btn btn-info" *ngIf="!domicilioAgregado" (click)="abrirModalDom('agregar')">Agregar Domicilio</button>
                        </div>
                      </div>
                    </div>
                    <div class="row" *ngIf="domicilioAgregado">
                      <div class="col">
                        <div class="form-group">
                          <label>Provincia</label>
                          <input class="form-control" type="text" required [(ngModel)]="provinciaDom" name="prov" id="prov" #prov="ngModel" disabled>
                          <div [hidden]="prov!==null" class="alert alert-danger mt-1" style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido</div>
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <label>Localidad</label>
                          <input class="form-control" type="text" required [(ngModel)]="localidadDom" name="localid" id="localid" #localid="ngModel" disabled>
                          <div [hidden]="localid!==null" class="alert alert-danger mt-1" style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido</div>
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <label>Código Postal</label>
                          <input class="form-control" type="number" required [(ngModel)]="cpDom" name="cpD" id="cpD" #cpD="ngModel" disabled>
                          <div [hidden]="cpD!==null" class="alert alert-danger mt-1" style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido</div>
                        </div>
                      </div>
                    </div>
                    <div class="row" *ngIf="domicilioAgregado">
                      <div class="col">
                        <div class="form-group">
                          <label>Calle</label>
                          <input class="form-control" type="text" required [(ngModel)]="calleDom" name="calle" id="calle" #calle="ngModel" disabled>
                          <div [hidden]="calle!==null" class="alert alert-danger mt-1" style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido</div>
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <label>Nro</label>
                          <input class="form-control" type="number" required [(ngModel)]="nroDom" name="nro" id="nro" #nro="ngModel" disabled>
                          <div [hidden]="nro!==null" class="alert alert-danger mt-1" style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido</div>
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <label>Teléfono</label>
                          <input class="form-control" type="number" [(ngModel)]="telefono1" name="tel1" id="tel1" disabled>
                        </div>
                      </div>
                    </div>
                    <div class="row" *ngIf="domicilioAgregado">
                      <div class="col-4">
                        <div class="form-group">
                          <label>Fax</label>
                          <input class="form-control" type="number" [(ngModel)]="telefono2" name="tel2" id="tel2" disabled>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label>Observaciones</label>
                          <textarea class="form-control" type="text" [(ngModel)]="observacionesDom" name="obsdom" id="obsdom" disabled></textarea>
                        </div>
                      </div>
                      <div class="col-2 align-self-end" style="text-align: right;">
                        <div class="form-group">
                          <button class="btn btn-info" (click)="abrirModalDom('editar')">Editar Domicilio</button>
                        </div>
                      </div>
                    </div>
                    <hr class="my-4"><br>
                    <div class="row">
                      <div class="col">
                        <div class="form-group">
                          <input type="checkbox" [(ngModel)]="activa" name="act" id="act"> &nbsp; Activa
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <input type="checkbox" [(ngModel)]="cabecera" name="cab" id="cab"> &nbsp; Cabecera
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <input type="checkbox" [(ngModel)]="etiquetas" name="etiq" id="etiq"> &nbsp; Etiquetas
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <input type="checkbox" [(ngModel)]="entregaDom" name="entDom" id="entDom"> &nbsp; Entrega a Domicilio
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <input type="checkbox" [(ngModel)]="retiroDom" name="retDom" id="retDom"> &nbsp; Retira en Domicilio
                        </div>
                      </div>
                    </div><br>
                    <div class="row">
                      <div class="col-3 align-self-center">
                        <div class="form-group">
                          <input type="checkbox" [(ngModel)]="pRedespacho" name="pred" id="pred"> &nbsp; Punto de Redespacho
                        </div>
                      </div>
                      <div class="col-9">
                        <div class="form-group" *ngIf="pRedespacho">
                          <label>Punto de Redespacho</label>
                          <div class="input-group">
                            <input class="form-control" style="width: 100px;" type="number" [(ngModel)]="nroLocal" name="nloc" id="nloc" #nloc="ngModel">
                            <input class="form-control" style="width: 200px;" type="text" [(ngModel)]="nomLocal" name="nomL" id="nomL" #nomL="ngModel">&nbsp;
                            <button class="btn btn-info" data-toggle="modal" data-target="#modalLR">Seleccionar</button>
                          </div>
                          <div class="input-group">
                            <div [hidden]="nroLocal!=null" class="alert alert-danger mt-1" style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido</div>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <div [hidden]="nomLocal!=''" class="alert alert-danger mt-1" style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido</div>
                          </div>
                        </div>
                      </div>
                    </div><br>
                    <div class="row">
                      <div class="col-2">
                        <div class="form-group">
                          <input type="checkbox" [(ngModel)]="retiroWeb" name="retWeb" id="retWeb"> &nbsp; Retiro WEB
                        </div>
                      </div>
                      <div class="col-2">
                        <div class="form-group">
                          <input type="checkbox" [(ngModel)]="visualizaWeb" name="visWeb" id="visWeb"> &nbsp; Visualiza WEB
                        </div>
                      </div>
                      <div class="col-3">
                        <div class="form-group">
                          <input type="checkbox" [(ngModel)]="esCD" name="cd" id="cd"> &nbsp; Es Centro de Distribución 
                        </div>
                      </div>
                      <div class="col-2">
                        <div class="form-group">
                          <input type="checkbox" [(ngModel)]="recibeCargas" name="recCargas" id="recCargas"> &nbsp; Recibe Cargas
                        </div>
                      </div>
                      <div class="col-3">
                        <div class="form-group">
                          <input type="checkbox" [(ngModel)]="tarjetaCredito" name="tc" id="tc"> &nbsp; Acepta Tarjeta de Crédito
                        </div>
                      </div>
                    </div><br>
                    <div class="row">
                      <div class="col-3">
                        <div class="form-group">
                          <input type="checkbox" [(ngModel)]="colectora" name="colec" id="colec"> &nbsp; Trabaja con Colectora
                        </div>
                      </div>
                      <div class="col-3">
                        <div class="form-group">
                          <input type="checkbox" [(ngModel)]="recibePlaArrastre" name="plaArrastre" id="plaArrastre"> &nbsp; Recibe Planilla Arrastre
                        </div>
                      </div>
                      <div class="col-3">
                        <div class="form-group">
                          <label>Punto Recepción</label>
                          <input type="number" class="form-control" style="width: 100px;" [(ngModel)]="pRecepcion" name="pr" id="pr">
                        </div>
                      </div>
                    </div>
                    <hr class="my-4">
                    <div class="row">
                      <div class="col-4">
                        <div class="form-group">
                          <label>Servicios</label>
                          <table class="table">
                            <thead class="thead-light">
                              <tr>
                                <th hidden>ID</th>
                                <th scope="col">Servicio</th>
                                <th scope="col">Emisión</th>
                                <th scope="col">Recepción</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let servicio of serviciosApi, let i=index">
                                <td hidden>{{servicio.ServicioID}}</td>
                                <td>{{servicio.ServicioDescrip}}</td>
                                <td><input type="checkbox" [id]="'emision'+i" name="emision" (change)="seleccionarServicio($event, 'emision', servicio)"></td>
                                <td><input type="checkbox" [id]="'recepcion'+i" name="recepcion" (change)="seleccionarServicio($event, 'recepcion', servicio)"></td>
                              </tr>
                            </tbody>
                          </table>
                          <div [hidden]="serviciosSeleccionados.length>0" class="alert alert-danger mt-1" style="width: 220px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Al menos un servicio requerido</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-success" [disabled]="!formLocal.form.valid" (click)="save()">Guardar</button>
              <button type="button" class="btn btn-danger" (click)="cerrarModalLocal()">Cerrar</button>
            </div>
          </div>
        </div>
      </div>
</div>
<!-- Modal pv-->
<div>
  <div class="modal" tabindex="1" id="modalPV" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Puntos Venta</h5>
        </div>
        <div class="modal-body">
          <div class="container">
            <div class="row">
              <div class="col">
                <label>Sucursal DGI</label>
                <input type="number" class="form-control" [(ngModel)]="sucDgi" id="suc" name="suc" (input)="buscarPV($event.target.value, 'nroSuc')" placeholder="Buscar ...">
              </div>
              <div class="col">
                <label>Nombre Punto Venta</label>
                <input type="text" class="form-control" [(ngModel)]="nomPv" id="nomb" name="nomb" (input)="buscarPV($event.target.value, 'nomSuc')" placeholder="Buscar ...">
              </div>
              <div class="col">
                <label>Categoría</label>
                <input type="text" class="form-control" [(ngModel)]="categoria" id="categ" name="categ" (input)="buscarPV($event.target.value, 'cat')" placeholder="Buscar ...">
              </div>
            </div>
          </div><br>
          <table class="table">
            <thead class="thead-dark">
              <tr>
                <th scope="col" hidden>ID</th>
                <th scope="col" style="width: 30px; text-align: center;">SucursalDGI</th>
                <th scope="col" style="text-align: center;">Nombre Punto Venta</th>
                <th scope="col" style="text-align: center;">Responsable</th>
                <th scope="col" style="text-align: center;">Categoría</th>
                <th scope="col" style="text-align: center;"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let pv of puntosVentaBusqueda | paginate: {itemsPerPage:5, currentPage:p};">
                <td hidden>{{pv.PuntoVentaID}}</td>
                <td style="text-align: center;"><!-- &nbsp;&nbsp; -->{{pv.SucursalDGI}}</td>
                <td style="text-align: center;">{{pv.PuntoVentaDescrip}}</td>
                <td style="text-align: center;">{{pv.ResponsableNombre}}</td>
                <td style="text-align: center;">{{pv.CategoriaNombre}}</td>
                <td>
                  <button class="btn btn-info" (click)="seleccionarPV(pv)">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                      <path fill-rule="evenodd" d="M1.5 13A1.5 1.5 0 0 0 3 14.5h10a1.5 1.5 0 0 0 1.5-1.5V8a.5.5 0 0 0-1 0v5a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V3a.5.5 0 0 1 .5-.5h8a.5.5 0 0 0 0-1H3A1.5 1.5 0 0 0 1.5 3v10z"/>
                    </svg>
                  </button>
              </td>
              </tr>
            </tbody>
            <pagination-controls (pageChange)="p=$event"></pagination-controls>
          </table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" (click)="cerrarPV()">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal domicilio -->
<app-modal-domicilio></app-modal-domicilio>
<!-- Modal para unidades de venta -->
<div>
  <div class="modal" tabindex="1" id="modalUV" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Unidades Venta-Agencia: {{nombreLocalUv}}</h5>
        </div>
        <div class="modal-body">
          <div class="container-fluid" style="width: 700px;">
            <div class="row">
              <div class="col-4">
                <div class="form-group">
                  <label for="nomLR">Unidad de Venta</label>
                  <input type="text" class="form-control" [(ngModel)]="nombreUV" id="nomUv" name="nomUv" (input)="buscarUV($event.target.value)" placeholder="Buscar ...">
                </div>
              </div>
            </div>
            <table class="table">
              <thead class="thead-dark">
                <tr>
                  <th scope="col" hidden>ID</th>
                  <th scope="col-1" style="text-align: center;"><input type="checkbox" id="selectAll" (change)="seleccionarTodasUV($event)"></th>
                  <th scope="col-6" style="text-align: center;">Unidades de Venta</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let unidad of unidadesVentaBusqueda">
                  <td hidden></td>
                  <td style="text-align: center;"><input type="checkbox" [id]="unidad.UnidadVentaID" (change)="seleccionarUV($event, unidad)"></td>
                  <td>{{unidad.UnidadVentaDescrip}}</td>
                </tr>
              </tbody>
            </table>
            <!-- <div class="form-group">
              <select class="custom-select" [(ngModel)]="unidadesVenta" multiple size="10">
                <option *ngFor="let unidad of unidadesVentaApi" [id]="unidad.UnidadVentaID" [value]="unidad">{{unidad.UnidadVentaDescrip}}</option>
              </select>
            </div> -->
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-success" (click)="guardarUV()">Guardar</button>
          <button type="button" class="btn btn-danger" (click)="cerrarUV()">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal responsables -->
<app-modal-responsables></app-modal-responsables>
<!-- Modal puntos redespacho -->
<div>
  <div class="modal" tabindex="1" id="modalLR" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Locales</h5>
        </div>
        <div class="modal-body">
          <div class="container">
            <div class="row">
              <div class="col">
                <label>Código</label>
                <input type="number" class="form-control" [(ngModel)]="codigoLR" id="codLR" name="codLR" (input)="buscarPV($event.target.value, 'nroSuc')" placeholder="Buscar ...">
              </div>
              <div class="col">
                <label>Nombre Local</label>
                <input type="text" class="form-control" [(ngModel)]="nomLR" id="nomLR" name="nomLR" (input)="buscarPV($event.target.value, 'nomSuc')" placeholder="Buscar ...">
              </div>
              <div class="col">
                <label>Localidad</label>
                <input type="text" class="form-control" [(ngModel)]="localidadLR" id="localiLR" name="localiLR" (input)="buscarPV($event.target.value, 'cat')" placeholder="Buscar ...">
              </div>
            </div>
          </div><br>
          <table class="table">
            <thead class="thead-dark">
              <tr>
                <th scope="col" hidden>ID</th>
                <th scope="col">Código</th>
                <th scope="col">Nombre</th>
                <th scope="col">Responsable</th>
                <th scope="col">Localidad</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let local of localesRedespachoBusqueda | paginate: {itemsPerPage:5, currentPage:p};">
                <td hidden>{{local.LocalID}}</td>
                <td>&nbsp;&nbsp;{{local.CodigoLocal}}</td>
                <td>{{local.LocalDescrip}}</td>
                <td>{{local.ResponsableNombre}}</td>
                <td>{{local.LocalidadDescrip}}</td>
                <td>
                  <button class="btn btn-info" (click)="seleccionarLR(local)">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                      <path fill-rule="evenodd" d="M1.5 13A1.5 1.5 0 0 0 3 14.5h10a1.5 1.5 0 0 0 1.5-1.5V8a.5.5 0 0 0-1 0v5a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V3a.5.5 0 0 1 .5-.5h8a.5.5 0 0 0 0-1H3A1.5 1.5 0 0 0 1.5 3v10z"/>
                    </svg>
                  </button>
              </td>
              </tr>
            </tbody>
            <pagination-controls (pageChange)="p=$event"></pagination-controls>
          </table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" (click)="cerrarLR()">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal conceptos facturación -->
<div>
  <div class="modal" tabindex="1" id="modalCF" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title"><b>Local: </b>{{nombreLocal}}</h5>
        </div>
        <div class="modal-body">
          <div class="container">
            <div class="row">
              <div class="col-4">
                <label>Concepto Facturable</label>
                <input type="text" class="form-control" [(ngModel)]="concepto" id="conc" name="conc" (input)="buscarCF($event.target.value)" placeholder="Buscar ...">
              </div>
            </div>
          </div><br>
          <table class="table">
            <thead class="thead-dark">
              <tr>
                <th scope="col" hidden>ID</th>
                <th scope="col-1" style="text-align: center;"><input type="checkbox" id="cfselectAll" (change)="seleccionarTodosCF($event)"></th>
                <th scope="col-5" style="text-align: center;">Concepto Facturable</th>
                <th scope="col-3" style="text-align: center;">Carga en Emisión Guía</th>
                <th scope="col-3" style="text-align: center;">Cargar en Modalidad Entrega</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let cf of conceptosFacturablesBusqueda">
                <td hidden>cf.ConceptoFacturacionId</td>
                <td><input type="checkbox" [id]="'cf'+cf.ConceptoFacturacionId" (change)="seleccionarCF($event, cf)"></td>
                <td>{{cf.ConceptoFacturacionDescrip}}</td>
                <td style="text-align: center;"><input type="checkbox" [id]="'guia'+cf.ConceptoFacturacionId" (change)="seleccionarCFOpciones($event, cf, 'guia')"></td>
                <td style="text-align: center;"><input type="checkbox" [id]="'entrega'+cf.ConceptoFacturacionId" (change)="seleccionarCFOpciones($event, cf, 'entrega')"></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-success" (click)="guardarLocalCF()">Guardar</button>
          <button type="button" class="btn btn-danger" (click)="cerrarCF()">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <div>
  <div class="modal" tabindex="1" id="modalResponsables" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Responsables</h5>
        </div>
        <div class="modal-body">
          <div class="container">
            <div class="row">
              <div class="col">
                <label>DNI</label>
                <input type="number" class="form-control" id="dniBusq" [(ngModel)]="dniBusqueda" name="dniBusq" (input)="buscarResponsable($event.target.value, 'dni')" placeholder="Buscar ...">
              </div>
              <div class="col">
                <label>Nombre y Apellido</label>
                <input type="text" class="form-control" id="apeNomBusq" [(ngModel)]="apeNomBusqueda" name="apeNomBusq" (input)="buscarResponsable($event.target.value, 'apeNom')" placeholder="Buscar ...">
              </div>
            </div>
          </div><br>
          <table class="table">
            <thead class="thead-dark">
              <tr>
                <th scope="col" hidden>ID</th>
                <th scope="col">DNI/CUIT</th>
                <th scope="col">Apellido</th>
                <th scope="col">Nombre</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let responsable of responsablesBusqueda | paginate: {itemsPerPage:10, currentPage:p};">
                <td hidden>{{responsable.ResponsableID}}</td>
                <td>{{responsable.NroDocumento}}</td>
                <td>{{responsable.Apellido}}</td>
                <td>{{responsable.Nombre}}</td>
                <td>
                  <button class="btn btn-info" (click)="seleccionarResponsable(responsable)">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-person-check-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm9.854-2.854a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                    </svg>
                  </button>
              </td>
              </tr>
            </tbody>
            <pagination-controls (pageChange)="p=$event"></pagination-controls>
          </table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" (click)="cerrarResponsables()">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
</div> -->