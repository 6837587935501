<!-- Página con tabla y filtros -->
<div class="container-fluid mt-5" style="width: 1130px;">
  <div class="row">
    <div class="col">
      <label>Sucursal DGI</label>
      <input type="number" class="form-control" id="sucursalDGI" [(ngModel)]="sucDGI" name="sucursalDGI" (input)="buscarPV($event.target.value, 'sucursal')" placeholder="Buscar ...">
    </div>
    <div class="col">
      <label>Razón Social</label>
      <input type="text" class="form-control" id="razonSocial" [(ngModel)]="rs" name="razonSocial" (input)="buscarPV($event.target.value, 'razonS')" placeholder="Buscar ...">
    </div>
    <div class="col">
      <label>Nombre</label>
      <input type="text" class="form-control" id="nombre" [(ngModel)]="nomb" name="nombre" (input)="buscarPV($event.target.value, 'nombre')" placeholder="Buscar ...">
    </div>
    <div class="col">
      <label>Categoría</label>
      <input type="text" class="form-control" id="categoria" [(ngModel)]="cat" name="categoria" (input)="buscarPV($event.target.value, 'categoria')" placeholder="Buscar ...">
    </div>
    <div class="col">
      <label>Habilitada/Deshabilitada</label>
      <select class="custom-select" id="bj" [(ngModel)]="baja" name="bj" (change)="buscarPV($event.target.value, 'baja')">
        <option selected value="null" disabled>Seleccione</option>
        <option value="false">Habilitada</option>
        <option value="true">Deshabilitada</option>
    </select>
    </div>
  </div>
    <br>
    <button class="btn btn-info" (click)="abrirModal(0)">Agregar Punto Venta</button>
    <jqxGrid #puntosVentaGrid class="grid mt-2"
        [width]="getWidth()" [source]="dataAdapter" [columns]="columns"
        [pageable]="true" [autoheight]="true" *ngIf="!cargando">
    </jqxGrid><br>
    <div *ngIf="cargando">
      <h4>Cargando...</h4>
    </div>
</div>

<!-- Modal con formulario -->
<div>
    <div class="modal" tabindex="-1" id="modalPuntoVenta" data-backdrop="static" data-keyboard="false"> <!--  -->
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Punto de Venta</h5>
            </div>
            <div class="modal-body">
                <form #formPV="ngForm">
                    <div class="form-group" hidden>
                      <label>Punto venta ID</label>
                      <input type="number" class="form-control" [(ngModel)]="idPV" disabled name="id">
                    </div>
                    <div class="container">
                      <div class="row">
                        <div class="col">
                          <div class="form-group">
                            <label>N° Sucursal</label>
                            <input type="number" class="form-control" id="nroSucursalDGI" [(ngModel)]="nroSucursal" name="sucursalDGI" required #sucursalDGI="ngModel" (blur)="validarNro(nroSucursal)" (focus)="disponible=true">
                            <div [hidden]="sucursalDGI.valid" class="alert alert-danger mt-1" style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">
                              *Campo requerido
                            </div>
                            <div [hidden]="disponible" class="alert alert-danger mt-1" style="width: 200px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;" >
                              Nro de Sucursal no disponible
                            </div>
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label>Razón Social</label>
                            <input type="text" class="form-control" [(ngModel)]="razonSocial" name="razonSoc" required #razonSoc="ngModel">
                            <div [hidden]="razonSoc.valid" class="alert alert-danger mt-1" style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">
                              *Campo requerido
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <div class="form-group">
                            <label>Nombre</label>
                            <input type="text" class="form-control" [(ngModel)]="nombre" name="nom" required #nom="ngModel">
                            <div [hidden]="nom.valid" class="alert alert-danger mt-1" style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">
                              *Campo requerido
                            </div>
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label>Categoría PV</label>
                            <select class="custom-select" id="categoriaSelect" required [(ngModel)]="categoriaPV" name="categoria" #categoria="ngModel" (change)="seleccionar($event)">
                                <option selected value="null">Seleccione una catergoría</option>
                                <option *ngFor="let categoria of categoriasSelec" [value]="categoria.CategoriaPVID">{{categoria.CategoriaPVDescrip}}</option>
                            </select>
                            <div [hidden]="categoria.valid" class="alert alert-danger mt-1" style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">
                              *Campo requerido
                            </div>
                            <input type="text" class="form-control" hidden [(ngModel)]="categoriaInput" name="categInput" #categInput="ngModel"><!-- si es update se setea el nombre de la categoria y se oculta el select -->
                            
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <div class="form-group">
                            <label>Estado</label>
                            <select class="custom-select" id="bj" [(ngModel)]="baja" name="bj">
                              <option selected value="false">Habilitada</option>
                              <option value="true">Deshabilitada</option>
                            </select>
                          </div>
                        </div>
                        <div class="col"></div>
                      </div>
                      <hr class="my-4">
                      <div class="row">
                        <div class="col">
                          <div class="form-group">
                            <label>Responsable</label>
                            <div class="input-group">
                              <input type="number" class="form-control" style="width: 100px;" [(ngModel)]="dniResponsable" name="dniR" required #dniR="ngModel"><!-- dni -->
                              
                              <input type="text" class="form-control" style="width: 200px;" [(ngModel)]="apeNomResponsable" name="apeNomR" required #apeNomR="ngModel">&nbsp;<!-- apellido y nombre -->
                              
                              <button type="button" class="btn btn-info" data-toggle="modal" data-target="#modalResponsables" >Seleccionar</button>
                              
                            </div>
                            <div class="input-group">
                              <div [hidden]="dniR.valid" class="alert alert-danger mt-1" style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">
                                *Campo requerido
                              </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <div [hidden]="apeNomR.valid" class="alert alert-danger mt-1" style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">
                                *Campo requerido
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr class="my-4">
                    <div class="row">
                      <div class="col">
                        <div class="form-group">
                          <label>Domicilio</label><br>
                          <button class="btn btn-info" *ngIf="!domicilioAgregado" (click)="abrirModalDom('agregar')">Agregar Domicilio</button>
                        </div>
                      </div>
                    </div>
                    <div class="row" *ngIf="domicilioAgregado">
                      <div class="col">
                        <div class="form-group">
                          <label>Provincia</label>
                          <input class="form-control" type="text" required [(ngModel)]="provinciaDom" name="prov" id="prov" #prov="ngModel" disabled>
                          <div [hidden]="prov!==null" class="alert alert-danger mt-1" style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido</div>
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <label>Localidad</label>
                          <input class="form-control" type="text" required [(ngModel)]="localidadDom" name="localid" id="localid" #localid="ngModel" disabled>
                          <div [hidden]="localid!==null" class="alert alert-danger mt-1" style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido</div>
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <label>Código Postal</label>
                          <input class="form-control" type="number" required [(ngModel)]="cpDom" name="cpD" id="cpD" #cpD="ngModel" disabled>
                          <div [hidden]="cpD!==null" class="alert alert-danger mt-1" style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido</div>
                        </div>
                      </div>
                    </div>
                    <div class="row" *ngIf="domicilioAgregado">
                      <div class="col">
                        <div class="form-group">
                          <label>Calle</label>
                          <input class="form-control" type="text" required [(ngModel)]="calleDom" name="calle" id="calle" #calle="ngModel" disabled>
                          <div [hidden]="calle!==null" class="alert alert-danger mt-1" style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido</div>
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <label>Nro</label>
                          <input class="form-control" type="number" required [(ngModel)]="nroDom" name="nro" id="nro" #nro="ngModel" disabled>
                          <div [hidden]="nro!==null" class="alert alert-danger mt-1" style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido</div>
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <label>Teléfono</label>
                          <input class="form-control" type="number" [(ngModel)]="telefono1" name="tel1" id="tel1" disabled>
                        </div>
                      </div>
                    </div>
                    <div class="row" *ngIf="domicilioAgregado">
                      <div class="col-4">
                        <div class="form-group">
                          <label>Fax</label>
                          <input class="form-control" type="number" [(ngModel)]="telefono2" name="tel2" id="tel2" disabled>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label>Observaciones</label>
                          <textarea class="form-control" type="text" [(ngModel)]="observacionesDom" name="obsdom" id="obsdom" disabled></textarea>
                        </div>
                      </div>
                      <div class="col-2 align-self-end" style="text-align: right;">
                        <div class="form-group">
                          <button class="btn btn-info" (click)="abrirModalDom('editar')">Editar Domicilio</button>
                        </div>
                      </div>
                    </div>
                      <hr class="my-4">
                      <div class="row align-items-center">
                        <div class="col">
                          <div class="form-group">
                            <label>Límite de Crédito</label>
                            <input type="number" class="form-control" [(ngModel)]="credito" name="cred" required #cred="ngModel">
                            <div [hidden]="cred.valid" class="alert alert-danger mt-1" style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">
                              *Campo requerido
                            </div>
                          </div>
                        </div>
                        <div class="col ">
                          <div class="form-group">
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" [(ngModel)]="bloqueaValor" name="bloqueaVal" [value]="bloqueaValor" id="chkBloqueaValor">
                              <label class="form-check-label">
                                ¿Bloquea la cuenta cuando alcance el límite de crédito?
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <div class="form-group">
                            <label>Días Rendición</label><br>
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="ckDR1" value="1" (change)="agregarCheckDiasRendicion($event)">
                                <label class="form-check-label" for="inlineCheckbox2">Lunes</label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="ckDR2" value="2" (change)="agregarCheckDiasRendicion($event)">
                                <label class="form-check-label" for="inlineCheckbox2">Martes</label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="ckDR3" value="3" (change)="agregarCheckDiasRendicion($event)">
                                <label class="form-check-label" for="inlineCheckbox3">Miércoles</label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="ckDR4" value="4" (change)="agregarCheckDiasRendicion($event)">
                                <label class="form-check-label" for="inlineCheckbox3">Jueves</label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="ckDR5" value="5" (change)="agregarCheckDiasRendicion($event)">
                                <label class="form-check-label" for="inlineCheckbox3">Viernes</label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="ckDR6" value="6" (change)="agregarCheckDiasRendicion($event)">
                                <label class="form-check-label" for="inlineCheckbox3">Sábado</label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="ckDR7" value="7" (change)="agregarCheckDiasRendicion($event)">
                                <label class="form-check-label" for="inlineCheckbox3">Domingo</label>
                              </div>
                              <input type="text" class="form-control" hidden required [(ngModel)]="diasRendicionInput" id="diasR" required name="diasR" #diasR="ngModel">
                              <div [hidden]="diasR.valid" class="alert alert-danger mt-1" style="width: 200px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">
                                *Al menos un día requerido
                              </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <div class="form-group">
                            <label>Días Guías de Rendición</label><br>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="ckGDR1" value="1" (change)="agregarCheckDiasGuiasRendicion($event)">
                                <label class="form-check-label" for="inlineCheckbox1">Lunes</label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="ckGDR2" value="2" (change)="agregarCheckDiasGuiasRendicion($event)">
                                <label class="form-check-label" for="inlineCheckbox2">Martes</label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="ckGDR3" value="3" (change)="agregarCheckDiasGuiasRendicion($event)">
                                <label class="form-check-label" for="inlineCheckbox3">Miércoles</label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="ckGDR4" value="4" (change)="agregarCheckDiasGuiasRendicion($event)">
                                <label class="form-check-label" for="inlineCheckbox3">Jueves</label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="ckGDR5" value="5" (change)="agregarCheckDiasGuiasRendicion($event)">
                                <label class="form-check-label" for="inlineCheckbox3">Viernes</label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="ckGDR6" value="6" (change)="agregarCheckDiasGuiasRendicion($event)">
                                <label class="form-check-label" for="inlineCheckbox3">Sábado</label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="ckGDR7" value="7" (change)="agregarCheckDiasGuiasRendicion($event)">
                                <label class="form-check-label" for="inlineCheckbox3">Domingo</label>
                              </div>
                              <input type="text" class="form-control" hidden required [(ngModel)]="diasGuiasRendicionInput" required name="diasGR" #diasGR="ngModel">
                              <div [hidden]="diasGR.valid" class="alert alert-danger mt-1" style="width: 200px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">
                                *Al menos un día requerido
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                      </form>
                      <hr class="my-4">
                      <div class="container">
                      <div class="row">
                        <div class="col">
                          <div class="form-group">
                            <label>Datos Empresa Facturación</label>
                            <table class="table table-bordered">
                              <thead class="thead-light">
                                <tr>
                                  <th scope="col" >Empresa</th>
                                  <th scope="col" >Puntero</th>
                                  <th scope="col" >Fecha Inicio Actividades</th>
                                  <th scope="col" >Nro Establecimiento</th>
                                  <th scope="col" >Emisión Electrónica</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let empresa of empresasFacturacion, let i=index">
                                  <td *ngIf="empresa.EmpresaFacturacionID===1">
                                    <input type="checkbox" [id]="'auto'+i" (change)="seleccionarEmpresa(empresa, $event)" [checked]="empresa.EmpresaFacturacionPuntoVentaID!==0"> &nbsp; Autotransportes Andesmar S.A.
                                  </td>
                                  <td *ngIf="empresa.EmpresaFacturacionID===2">
                                    <input type="checkbox" [id]="'cargas'+i" (change)="seleccionarEmpresa(empresa, $event)" [checked]="empresa.EmpresaFacturacionPuntoVentaID!==0"> &nbsp; Andesmar Cargas S.A.
                                  </td>
                                  <td>
                                    <input class="form-control" type="number" [(ngModel)]="empresa.SucursalDGI" name="nroSuc" style="width: 100px;">
                                  </td>
                                  <td>
                                    <input type="date" class="form-control" [ngModel]="empresa.FechaInicioACT | date:'yyyy-MM-dd'" (ngModelChange)="empresa.FechaInicioACT=$event" name="fechaAct" >
                                  </td>
                                  <td>
                                    <input class="form-control" type="text" [(ngModel)]="empresa.NumeroEstablecimiento" name="nroEstablecimiento">
                                  </td>
                                  <td style="text-align: center;">
                                    <input class="form-check-input" type="checkbox" [checked]="empresa?.TipoValidacionEmisionID===2" disabled>
                                  </td> 
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <hr class="my-4">
                      <div class="row">
                        <div class="col">
                          <div class="form-group">
                            <label>Datos autoimpresor</label>
                          </div>
                        </div>
                      </div>
                      <div class="row" *ngIf="mostrarBoton">
                        <div class="col">
                          <button class="btn btn-info" (click)="agregar()">Agregar CAI</button>
                        </div>
                      </div>
                      <!-- Mostrar guías cargadas -->
                      <form #formGuias="ngForm">
                        <div class="row" *ngIf="agregarGuias">
                          <div class="col">
                            <div class="form-group">
                              <label>Tipo Guía</label>
                              <select class="custom-select" required [(ngModel)]="tipoGuia" name="tipoguia" #tipoguia="ngModel">
                                <option selected value="null" disabled>Seleccione un tipo de guía</option>
                                <option value="A">A</option>
                                <option value="B">B</option>
                                <option value="E">E</option>
                                <option value="R">R</option>
                                <option value="X">X</option>
                              </select>
                              <div [hidden]="tipoguia.valid" class="alert alert-danger mt-1" style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">
                                *Campo requerido
                              </div>
                            </div>
                          </div>
                          <div class="col">
                            <div class="form-group">
                              <label>Número de CAI</label>
                              <input type="text" class="form-control" required [(ngModel)]="nroCAI" name="nroCai" #nroCai="ngModel">
                              <div [hidden]="nroCai.valid" class="alert alert-danger mt-1" style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">
                                *Campo requerido
                              </div>
                            </div>
                          </div>
                          <div class="col">
                            <div class="form-group">
                              <label>Fecha vencimiento CAI</label>
                              <input type="date" class="form-control" required name="fechavto" [ngModel]="fechaVtoCAI | date:'yyyy-MM-dd'" (ngModelChange)="fechaVtoCAI=$event" #fechavto="ngModel">
                              <div [hidden]="fechavto.valid" class="alert alert-danger mt-1" style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">
                                *Campo requerido
                              </div>
                            </div>
                          </div>
                          <div class="col">
                            <div class="form-group">
                              <label>Empresa</label>
                              <select class="custom-select" required [(ngModel)]="empresa" name="empresaF" #empresaF="ngModel">
                                <option value=null disabled>Seleccione una empresa</option>
                                <option value="1">Autotransportes Andesmar S.A.</option>
                                <option value="2">Andesmar Cargas S.A.</option>
                              </select>
                              <div [hidden]="empresaF.valid" class="alert alert-danger mt-1" style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">
                                *Campo requerido
                              </div>
                            </div>
                          </div>
                          <div class="col" style="text-align: center;">
                            <div class="form-group">
                              <label></label><br>
                              <button class="btn btn-success" [disabled]="!formGuias.form.valid" (click)="saveGuia()">Guardar CAI Guía</button>
                            </div>
                          </div>
                        </div>
                      </form>
                      <div class="row justify-content-md-center" *ngIf="mostrarGuias">
                        <div class="col-10" >
                          <jqxGrid #guiasGrid class="grid mt-2"
                            [width]="getWidth2()" [source]="dataAdapter2" [columns]="columns2"
                            [pageable]="true" [autoheight]="true">
                        </jqxGrid>
                        </div>
                      </div>
                    </div>
                
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-success" [disabled]="!formPV.form.valid" (click)="save()">Guardar</button>
              <button type="button" class="btn btn-danger" (click)="cerrarModal()">Cerrar</button>
            </div>
          </div>
        </div>
      </div>
</div>
<!-- Modal responsables -->
<app-modal-responsables></app-modal-responsables>

<!-- Modal domicilio -->
<app-modal-domicilio></app-modal-domicilio>
<!-- <div>
  <div class="modal" tabindex="1" id="modalResponsables" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Responsables</h5>
        </div>
        <div class="modal-body">
          <div class="container">
            <div class="row">
              <div class="col">
                <label>DNI</label>
                <input type="number" class="form-control" id="dniBusq" [(ngModel)]="dniBusqueda" name="dniBusq" (input)="buscarResponsable($event.target.value, 'dni')" placeholder="Buscar ...">
              </div>
              <div class="col">
                <label>Nombre y Apellido</label>
                <input type="text" class="form-control" id="apeNomBusq" [(ngModel)]="apeNomBusqueda" name="apeNomBusq" (input)="buscarResponsable($event.target.value, 'apeNom')" placeholder="Buscar ...">
              </div>
            </div>
          </div><br>
          <table class="table">
            <thead class="thead-dark">
              <tr>
                <th scope="col" hidden>ID</th>
                <th scope="col">DNI/CUIT</th>
                <th scope="col">Apellido</th>
                <th scope="col">Nombre</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let responsable of responsablesBusqueda | paginate: {itemsPerPage:10, currentPage:p};">
                <td hidden>{{responsable.ResponsableID}}</td>
                <td>{{responsable.NroDocumento}}</td>
                <td>{{responsable.Apellido}}</td>
                <td>{{responsable.Nombre}}</td>
                <td>
                  <button class="btn btn-info" (click)="seleccionarResponsable(responsable)">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-person-check-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm9.854-2.854a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                    </svg>
                  </button>
              </td>
              </tr>
            </tbody>
            <pagination-controls (pageChange)="p=$event"></pagination-controls>
          </table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" (click)="cerrarResponsables()">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
</div> -->

