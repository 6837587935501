import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Local } from 'src/app/models/Local';
import { Observacion, RecepcionMasiva, RecepcionMasivaPost } from 'src/app/models/Orden-Guia';
import { Usuario } from 'src/app/models/Usuario';
import { LocalService } from 'src/app/services/local.service';
import { ObservacionService } from 'src/app/services/observacion.service';
import { RecepcionService } from 'src/app/services/recepcion.service';
declare var $:any;
@Component({
  selector: 'app-recepcion-masiva',
  templateUrl: './recepcion-masiva.component.html',
  styleUrls: ['./recepcion-masiva.component.scss']
})
export class RecepcionMasivaComponent implements OnInit {

  usuario:Usuario={};
  local:Local={};
  agenciaConectadaID:number;

  nroGuia:string;
  guias:RecepcionMasiva[]=[];
  observacion:Observacion={};
  codObs:number;
  descripObs:string;
  total:number=30;
  restantes:number=30;
  iObs:number=0;
  iObsIncomp:number=0;
  iLoc:number;
  guiasNoRecib:string="";
  guiasIncomp:RecepcionMasiva[]=[];
  guiasNoRecibidas:boolean=false;
  guiasIncompletas:boolean=false;

  localDestino:Local;
  constructor(private obsService:ObservacionService, private router:Router, private recepcionMasiva:RecepcionService, private localService:LocalService) { }

  ngOnInit(): void {
    this.usuario=JSON.parse(sessionStorage.getItem('user'));
    if(this.usuario!=null && this.usuario!=undefined){
      for(var i=0; i<this.usuario.OpcionesMenu.length; i++){
        if(this.usuario.OpcionesMenu[i].Url=='recepcion-masiva'){
          this.local.LocalID=this.usuario.LocalID;
          this.agenciaConectadaID=this.usuario.agenciaID;
          
          break;
        }else if(i==this.usuario.OpcionesMenu.length-1){
          //window.location.href='home';
          this.router.navigate(['/home'])
        }
      }
      if(this.usuario.OpcionesMenu.length==0){
        this.router.navigate(['/home'])
      }
    }else{
      //window.location.href='home';
      this.router.navigate(['/home'])
    }

    this.obsService.agregarObsObservable.subscribe(obs=>{
      if(obs!=null){
        this.observacion=obs;
        if(this.iObsIncomp!=0){
          $("#codI"+this.iObsIncomp).val(this.observacion.Codigo);
          $("#descripI"+this.iObsIncomp).val(this.observacion.ObsDescrip);
          
          for(let g of this.guiasIncomp){
            if(g.guiaID==this.iObsIncomp){
              g.observacionID=this.observacion.ObservacionID;
            }
          }
          this.iObs=0;
        }else{
          $("#cod"+this.iObs).val(this.observacion.Codigo);
          $("#descrip"+this.iObs).val(this.observacion.ObsDescrip);
          
          for(let g of this.guias){
            if(g.guiaID==this.iObs){
              g.observacionID=this.observacion.ObservacionID;
            }
          }
          this.iObs=0;
        }
        
      }
    })

    this.localService.agregarLocalObservable.subscribe(res=>{
      if(res!=null){
        this.localDestino=res;
        $("#id"+this.iLoc).val(this.localDestino.CodigoLocal);
        $("#rs"+this.iLoc).val(this.localDestino.LocalDescrip);
        for(let g of this.guiasIncomp){
          if(g.guiaID==this.iLoc){
            g.agenciaDestinoID=this.localDestino.LocalID;
          }
        }
      }
    })
  }

  agregar(){
    if(this.nroGuia.length>5){
      if(this.restantes==0){
        alert("Ya ingresó el total de guías permitidas");
      }else{
        var guia:RecepcionMasiva={
          guiaID:this.guias.length+1,
          nroGuia:this.nroGuia,
          incompleta:false,
          recibida:false
        }
        this.guias.push(guia);
        this.nroGuia='';
        this.restantes=this.restantes-1;
      }
    }else{
      alert("Longitud de número de guía incorrecta");
    }
  }

  seleccionarObs(i:number, tipo:string){
    if(tipo=="incompleta"){
      this.iObsIncomp=i;
    }else{
      this.iObs=i;
    }
    $("#modalObservaciones").modal("show");
  }

  seleccionarLocal(i:number){
    this.iLoc=i;
    $("#modalAgencia").modal("show");
  }

  confirmar(){
    if(this.guiasIncompletas){
      this.guias=this.guiasIncomp;
    }
    var post:RecepcionMasivaPost={
      guiasRecepcionMasiva:this.guias,
      agenciaConectada:this.agenciaConectadaID,
      unidadNegocioID:this.usuario.unidadNegocioID,
      usuarioID:this.usuario.usuarioID
    }
    if(this.guias.length>0){
      this.recepcionMasiva.recepcionMasiva(post).subscribe(res=>{
        if(res.Codigo==200){
          if(res.guiasNoRecibidas.length>0){
            for(let g of res.guiasNoRecibidas){
              this.guiasNoRecib=this.guiasNoRecib+g+",";
            }
            this.guias=[];
            this.guiasNoRecibidas=true;
          }else{
            this.guiasNoRecib="";
            this.guiasNoRecibidas=false;
          }
          if(res.guiasDatosFaltante.length>0){
            this.guiasIncomp=res.guiasDatosFaltante;
            this.guiasIncompletas=true;
          }else{
            this.guiasIncomp=[];
            this.guiasIncompletas=false;
          }
          if(res.guiasNoRecibidas==null && res.guiasDatosFaltante==null){
            this.guias=[];
          }
        }else if(res.Codigo==400){
          alert(res.Mensaje);
        }
        this.total=30;
        this.restantes=30;
      })
    }
  }

  cancelar(){
    this.guiasNoRecib="";
    this.guiasIncomp=[];
    this.guiasNoRecibidas=false;
    this.guiasIncompletas=false;
    this.observacion=null;
    this.localDestino=null;
    this.total=30;
    this.restantes=30;
    this.guias=[]
  }

}
