<div class="container">
    <table class="table">
        <thead class="thead-dark">
          <tr>
            <th scope="col">Metodo</th>
            <th scope="col">Fecha</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let e of errores">
            <td>{{e.Metodo}}</td>
            <td>{{e.FechaError}}</td>
            <td>
                <button class="btn btn-primary" (click)="mostrarError(e)">VER DETALLE</button>
            </td>
          </tr>
          
        </tbody>
      </table>
</div>


  <div><!-- modal detalle error -->
    <div class="modal " tabindex="-1" id="modalDetalle">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Detalle Error</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="container">
                  <div class="row">
                    <div class="col-3">Fecha: {{error.FechaError}}</div>
                    <div class="col-3">Método: {{error.Metodo}}</div>
                </div>
                <div class="row">
                    <div class="col" style="word-break: break-all">
                        <p>Mensaje: {{error.Mensaje}}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col" style="word-break: break-all">
                        <p>Traza: {{error.Traza}}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col" style="word-break: break-all">
                      Request: {{error.Request}}
                    </div>
                </div>
                <div class="row" *ngIf="error.InnerException!=null">
                    <div class="col" style="word-break: break-all">
                        <p>InnerException: {{error.InnerException}}</p>
                    </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
  </div>