import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CategoriaGet, CategoriaPost } from '../models/CategoriaPV';
import { HttpClient } from '@angular/common/http';
/* import { PuntoVenta } from '../models/PuntoVenta'; */
import { PuntosVentaGet, PuntosVentaGetId, PuntoVentaGetId, PuntoVentaGetPost } from '../models/PuntoVenta';
import { UrlService } from './url.service';
@Injectable({
  providedIn: 'root'
})
export class CategoriaPVService {

  //Request URL Api
  urlCategoriasGet:string=this.urlService.urlApi+"/api/CategoriaPuntoDeVenta/ObtenerCategorias";
  urlCategoriasPost:string=this.urlService.urlApi+"/api/CategoriaPuntoDeVenta/Guardar";
  urlPuntosVentaGet:string=this.urlService.urlApi+'/api/PuntoDeVenta/ObtenerPuntosVenta';
  urlPuntosVentaGetId:string=this.urlService.urlApi+'/api/PuntoDeVenta/ObtenerPuntoVentaById';
  urlPuntoVentaPost:string=this.urlService.urlApi+'/api/PuntoDeVenta/Guardar';
  urlResponsablesGet:string=this.urlService.urlApi+'/api/Utiles/ObtenerResponsables';
  
  /* urlCategoriasGet:string="http://localhost:7724/api/CategoriaPuntoDeVenta/ObtenerCategorias";
  urlCategoriasPost:string="http://localhost:7724/api/CategoriaPuntoDeVenta/Guardar";
  urlPuntosVentaGet:string='http://localhost:7724/api/PuntoDeVenta/ObtenerPuntosVenta';
  urlPuntosVentaGetId:string='http://localhost:7724/api/PuntoDeVenta/ObtenerPuntoVentaById';
  urlPuntoVentaPost:string='http://localhost:7724/api/PuntoDeVenta/Guardar';
  urlResponsablesGet:string='http://localhost:7724/api/Utiles/ObtenerResponsables'; */

  constructor(private http:HttpClient, private urlService:UrlService) { }

  //categorías
  getAllCategorias():Observable<CategoriaGet>{
    return this.http.post<CategoriaGet>(this.urlCategoriasGet,'');
  }

  guardarCategoria(categoria:CategoriaPost):Observable<CategoriaPost>{
    return this.http.post<CategoriaPost>(this.urlCategoriasPost, categoria);
  }

  //punto venta
  getAllPV():Observable<PuntosVentaGet>{
    return this.http.post<PuntosVentaGet>(this.urlPuntosVentaGet, '');
  }

  getPVById(id:PuntoVentaGetId):Observable<PuntosVentaGetId>{
    return this.http.post<PuntosVentaGetId>(this.urlPuntosVentaGetId, id);
  }

  postPV(puntoVenta:PuntoVentaGetPost):Observable<PuntosVentaGetId>{
    return this.http.post<PuntosVentaGetId>(this.urlPuntoVentaPost, puntoVenta);
  }
  
  //responsables
  /* getAllResponsables():Observable<ResponsableGet>{
    return this.http.post<ResponsableGet>(this.urlResponsablesGet, '');
  } */
  
  
}
