<div class="container-fluid" style="width: 1350px; margin-top: 50px;">
    <div class="row align-items-center rounded mt-5" style="border: solid 1px grey; height: 50px;">
        <h4 class="mt-1">&nbsp;&nbsp; Anular Facturas</h4>
    </div><br>
    <div class="row">
        <div class="col-4">Agencia Conectada: {{usuario.AgenciaRazonSocial}}</div>
    </div>
    <div class="row" style="margin-top: 25px;">
        <div class="col-2">
            <div class="form-check">
                <input class="form-check-input" [(ngModel)]="tipoBusqueda" type="radio" name="tipo" id="tipo" value="1">
                <label class="form-check-label">
                  Código de Barras
                </label>
            </div>
        </div>
        <div class="col-3">
            <div class="form-group">
                <input type="text" onclick="deshabilitarBoton()" class="form-control" [(ngModel)]="nroCB" id="exampleInputEmail1" aria-describedby="emailHelp">
            </div>
        </div>
    </div>
    <div class="row" style="margin-top: 25px;">
        <div class="col-2">
            <div class="form-check">
                <input class="form-check-input" [(ngModel)]="tipoBusqueda" type="radio" name="tipo" id="tipo" value="2">
                <label class="form-check-label">
                  Tipo guía:
                </label>
            </div>
        </div>
        <div class="col-3">
            <div class="form-group">
                <select class="form-control" [(ngModel)]="tipoGuia" id="tipoG" [disabled]="tipoBusqueda=='1'">
                  <option value="0">Elija tipo guía</option>
                  <option value="A">A</option>
                  <option value="B">B</option>
                  <option value="R">R</option>
                  <option value="E">E</option>
                  <option value="X">X</option>
                </select>
              </div>
        </div>
        <div class="col-3">
            <div class="form-group">
                <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Sucursal:</span>
                    </div>
                    <input type="text" aria-label="First name" [(ngModel)]="sucursal" class="form-control" [disabled]="tipoBusqueda=='1'">
                </div>
            </div>
        </div>
        <div class="col-3">
            <div class="form-group">
                <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Nro guía:</span>
                    </div>
                    <input type="text" aria-label="First name" [(ngModel)]="nroGuia" class="form-control" [disabled]="tipoBusqueda=='1'">
                </div>
            </div>
        </div>
    </div>
    <div class="row justify-content-end" style="margin-top: 25px;">
        <div class="col-2">
            <button type="button" class="btn btn-primary" (click)="buscar()">Buscar</button>&nbsp;
            <button type="button" class="btn btn-primary" (click)="limpiar()">Limpiar</button>
        </div>
    </div>
    <div class="row" style="margin-top: 25px;">
        <div class="col-5">
            <div class="form-group">
                <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Agencia:</span>
                    </div>
                    <input type="text" aria-label="Last name" [(ngModel)]="razonSocialAgencia" class="form-control" >
                </div>
            </div>
        </div>
        <div class="col-4">
            <div class="form-group">
                <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Importe:</span>
                    </div>
                    <input type="text" aria-label="First name" [(ngModel)]="importe" class="form-control" >
                </div>
            </div>
        </div>
    </div>
    <div class="row" style="margin-top: 25px;">
        <div class="col-4">
            <div class="form-group">
                <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Fecha:</span>
                    </div>
                    <input type="date" aria-label="First name" [ngModel]="fecha  | date:'yyyy-MM-dd'" (ngModelChange)="fecha=$event" class="form-control">
                  </div>
            </div>
        </div>
        <div class="col-4">
            <div class="form-group">
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <label class="input-group-text" >Clasificación Factura:</label>
                    </div>
                    <input type="text" aria-label="First name" [(ngModel)]="clasificacionFactura" class="form-control">
                  </div>
            </div>
        </div>
    </div>
    <div class="row" style="margin-top: 25px;">
        <div class="col-5">
            <div class="form-group">
                <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Observaciones:</span>
                    </div>
                    <input type="text" aria-label="First name" class="form-control" [value]="observacion.Codigo">
                    <input type="text" aria-label="Last name" class="form-control" [value]="observacion.ObsDescrip">
                    <button type="button" class="btn btn-primary" data-target="#modalObservaciones" data-toggle="modal">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                            <path fill-rule="evenodd" d="M1.5 13A1.5 1.5 0 0 0 3 14.5h10a1.5 1.5 0 0 0 1.5-1.5V8a.5.5 0 0 0-1 0v5a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V3a.5.5 0 0 1 .5-.5h8a.5.5 0 0 0 0-1H3A1.5 1.5 0 0 0 1.5 3v10z"/>
                          </svg>
                    </button>
                  </div>
            </div>
        </div>
    </div>
    <div class="row" style="margin-top: 25px;">
        <div class="col">
            <div class="form-group">
                <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Observaciones:</span>
                    </div>
                    <textarea class="form-control" [(ngModel)]="observacionInput" name="" id="" cols="30" rows="5"></textarea>
                    
                  </div>
            </div>
        </div>
    </div>
</div>
<div class="row justify-content-end" style="margin-top: 25px;">
    <div class="col-3">
        <button type="button" class="btn btn-primary" style="margin-left: 10px;" (click)="confirmar()">Confirmar</button>
    </div>
</div><br><br>
<app-modal-observaciones></app-modal-observaciones>
