<div class="container-fluid" style="width: 1350px; margin-top: 50px;">
    <div class="row align-items-center rounded mt-5" style="border: solid 1px grey; height: 50px;">
        <h4 class="mt-1">&nbsp;&nbsp; Seguimiento</h4>
    </div><br>
    <div class="row" style="margin-top: 25px;">
        <div class="col-4">
            <div class="form-group">
                <label class="form-check-label">
                    Código de Barras Guía
                  </label>
                <input type="text" class="form-control" [(ngModel)]="cbGuia" id="codBG" name="codBG" aria-describedby="emailHelp">
            </div>
        </div>
        <div class="col-4">
            <div class="form-group">
                <label class="form-check-label">
                    Orden/Planilla Retiro
                  </label>
                <input type="text" class="form-control" [(ngModel)]="planillaRetiro" id="planRet" name="planRet" aria-describedby="emailHelp">
            </div>
        </div>
        <div class="col-4">
            <div class="form-group">
                <label class="form-check-label">
                    Código de Barras Prevendido
                  </label>
                <input type="text" class="form-control" [(ngModel)]="cb" id="codB" name="codB" aria-describedby="emailHelp">
            </div>
        </div>
    </div>
    <div class="row" style="margin-top: 25px;">
        <div class="col-4">
            <div class="form-group">
                <label class="form-check-label">
                    Remito Cliente
                  </label>
                <input type="text" class="form-control" [(ngModel)]="remito" id="remit" name="remit" aria-describedby="emailHelp">
            </div>
        </div>
        <div class="col-2">
            <div class="form-check">
                <label class="form-check-label">
                    Tipo guía
                  </label>
            </div>
        </div>
        <div class="col-1">
            <div class="form-group">
                <input class="form-check-input" type="checkbox" name="tipo" id="A" value="1" (change)="seleccionar($event, 1, 'A')"> &nbsp; A
            </div>
        </div>
        <div class="col-1">
            <div class="form-group">
                <input class="form-check-input" type="checkbox" name="tipo" id="B" value="2" (change)="seleccionar($event, 2, 'B')"> &nbsp; B
            </div>
        </div>
        <div class="col-1">
            <div class="form-group">
                <input class="form-check-input" type="checkbox" name="tipo" id="R" value="3" (change)="seleccionar($event, 3, 'R')"> &nbsp; R
            </div>
        </div>
        <div class="col-1">
            <div class="form-group">
                <input class="form-check-input" type="checkbox" name="tipo" id="todas" value="0" checked (change)="seleccionar($event, 0, '')"> &nbsp; TODAS
            </div>
        </div>
    </div>
    <div class="row" style="margin-top: 25px;">
        <div class="col-4">
            <div class="form-group">
                <label class="form-check-label">
                    Nro Guía
                  </label>
                <div class="row">
                    <div class="col-3">
                        <input type="text" class="form-control" [(ngModel)]="nroSuc" id="nroS" name="nroS" aria-describedby="emailHelp" style="width: 100px;">
                    </div>
                    <div class="col-1">-</div>
                    <div class="col-2"><input type="text" class="form-control" [(ngModel)]="nroGuia" id="nroG" name="nroG" aria-describedby="emailHelp" style="width: 100px;"></div>
                </div>
            </div>
        </div>
        <div class="col-4">
            <div class="form-group">
                <label class="form-check-label">
                    Número Seguimiento Único
                  </label>
                <input type="text" class="form-control" [(ngModel)]="nroSeguimiento" id="nroSeg" name="nroSeg" aria-describedby="emailHelp">
            </div>
        </div>
    </div>
    <div class="row justify-content-end" style="margin-top: 25px;">
        <div class="col-4">
            <button type="button" class="btn btn-primary" (click)="consultar()" >Consultar</button>&nbsp;
            <button type="button" class="btn btn-primary" (click)="exportarExcel()" *ngIf="guias.length>0">Exportar a Excel</button>&nbsp;
            <button type="button" class="btn btn-primary" (click)="limpiar()">Limpiar</button>
        </div>
    </div><br>
    <div class="=row">
        <button type="button" class="btn btn-link" (click)="mostrarBoton=false">Sin agrupar</button>
        <button type="button" class="btn btn-link" (click)="filtrar('estados')">Estados históricos por guía</button>
        <button type="button" class="btn btn-link" (click)="filtrar('')">Estados operativos</button>
    </div><br>
    <div [hidden]="!cargando" class="alert alert-light mt-1"
              style="width: 150px; height: 20px; font-size: 14px; padding: 0rem 1.30rem;">Cargando...</div>
    <div class="row" *ngIf="guias.length>0">
        <table id="guiasSeguimiento" class="table table-bordered"  style="white-space: nowrap; margin-left: -100px;"><!-- white-space: nowrap para ajustar columnas a contenido -->
            <thead>
              <tr>
                <th *ngIf="mostrarBoton"></th>
                <th scope="col">Número</th>
                <th scope="col">Tipo</th>
                <th scope="col">Fecha Emisión</th>
                <th scope="col">Fecha Despacho Acordada</th>
                <th scope="col">Ag. origen</th>
                <th scope="col">Ag. destino</th>
                <th scope="col">Unidad de venta</th>
                <th scope="col">Modalidad entrega</th>
                <th scope="col">Tipo bulto</th>
                <th scope="col">Precinto seguridad</th>
                <th scope="col">Valor declarado</th>
                <th scope="col">Bultos</th>
                <th scope="col">Importe CR</th>
                <th scope="col">Comisión CR</th>
                <th scope="col">Retiro</th>
                <th scope="col">Entrega</th>
                <th scope="col">Servicio Adicional</th>
                <th scope="col">Importe total</th>
                <th scope="col">Condición de pago</th>
                <th scope="col">Cliente</th>
                <th scope="col">Remito cliente</th>
                <th scope="col">Hoja de ruta</th>
                <th scope="col">Planilla retiro</th>
                <th scope="col">Planilla entrega</th>
                <th scope="col">Estado administrativo</th>
                <th scope="col">Fecha estado administrativo</th>
                <th scope="col">Estado movimiento</th>
                <th scope="col">Fecha estado movimiento</th>
                <th scope="col">Estado sentido viaje</th>
                <th scope="col">Fecha estado sentido viaje</th>
                <th scope="col">Estado ubicación</th>
                <th scope="col">Fecha estado ubicación</th>
                <th scope="col">Estado entrega</th>
                <th scope="col">Fecha estado entrega</th>
                <th scope="col">Estado devolución</th>
                <th scope="col">Fecha estado devolución</th>
                <th scope="col">Estado rendición admin.</th>
                <th scope="col">Fecha estado rendición admin.</th>
                <th scope="col">Fecha entrega</th>
                <th scope="col">Estadía destino</th>
                <th scope="col">Remitente</th>
                <th scope="col">Destinatario</th>
                <th scope="col">Observaciones</th>
                <th scope="col">Observaciones tipificadas</th>
                <th scope="col">Rendición emisión</th>
                <th scope="col">Rendición conformación</th>
                <th scope="col">Consolidado</th>
                <th scope="col">Factura terceros</th>
                <th scope="col">Destinatario real</th>
                <th scope="col">Nro Lote</th>
                <th scope="col">Nro Cupón</th>
                <th scope="col">Tel. Remitente</th>
                <th scope="col">Tel. Destinatario</th>
                <th scope="col">Peso total real(kg)</th>
                <th scope="col">Hoja de ruta reparto</th>
                <th scope="col">Nro remito cliente</th>
                <th scope="col">Nro factura cliente</th>
                <th scope="col">Hoja de ruta cliente</th>
                <th scope="col">Factura cancelada</th>
                <th scope="col">Nro PreCaso</th>
                <th scope="col">Email Remitente</th>
                <th scope="col">Email Destinatario</th>
                <th scope="col">Localidad Destinatario</th>
                <th scope="col">Movil</th>
                <th scope="col">Fajas de Seguridad</th>
                <th scope="col">Número seguimiento único</th>
                <th scope="col">Hoja ruta interno</th>
                <th scope="col">Nro de Caso</th>
                <th scope="col">Empresa de Facturación</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let g of guias">
                <td *ngIf="mostrarBoton"><button type="button" class="btn btn-light" (click)="abrirModal(g.GuiaID)">+</button></td>
                <th>{{g.NroSucursalGuia}}-{{g.NroGuiaCompleto}}</th>
                <td>{{g.TipoGuia}}</td>
                <td>{{g.FechaEmision | date:'dd/MM/yyyy'}}</td>
                <td>{{g.FechaDespacho | date:'dd/MM/yyyy'}}</td>
                <td>{{g.RazonSocialOrigen}}</td>
                <td>{{g.RazonSocialDestino}}</td>
                <td>{{g.UnidadVentaDescrip}}</td>
                <td>{{g.ModalidadEntregaDescrip}}</td>
                <td>{{g.tipoBulto}}</td>
                <td>{{g.PrecintoSeguridad}}</td>
                <td>{{g.ValorDeclaradoTotal | number}}</td>
                <td>{{g.CantidadBultosTotal}}</td>
                <td>{{g.ContrareembolsoImporteTotal}}</td>
                <td>{{g.ImporteComisionCR | number}}</td>
                <td>{{g.ImporteRetiro | number}}</td>
                <td>{{g.ImporteEntrega | number}}</td>
                <td>{{g.ImporteServicioAdicional | number }}</td>
                <td>{{g.ImporteTotalGuia | number}}</td>
                <td>{{g.CondicionPagoDescrip}}</td>
                <td>{{g.ClienteDescrip}}</td>
                <td>{{g.RemitoClienteNro}}</td>
                <td>{{g.HojaRuta}}</td>
                <td>{{g.OPlanillaRetiro}}</td>
                <td>{{g.OPlanillaEntrega}}</td>
                <td>{{g.EstadoAdmin}}</td>
                <td>{{g.EstadoAdminFecha | date:'dd/MM/yyyy'}}</td>
                <td>{{g.EstadoMovim}}</td>
                <td>{{g.EstadoMovimFecha | date:'dd/MM/yyyy'}}</td>
                <td>{{g.EstadoViaje}}</td>
                <td>{{g.EstadoViajeFecha | date:'dd/MM/yyyy'}}</td>
                <td>{{g.EstadoEstatico}}</td>
                <td>{{g.EstadoEstaticoFecha | date:'dd/MM/yyyy'}}</td>
                <td>{{g.EstadoEntrega}}</td>
                <td>{{g.EstadoEntregaFecha | date:'dd/MM/yyyy'}}</td>
                <td>{{g.EstadoDevoluc}}</td>
                <td>{{g.EstadoDevolucFecha | date:'dd/MM/yyyy'}}</td>
                <td>{{g.EstadoRendAdm}}</td>
                <td>{{g.EstadoRendAdmFecha | date:'dd/MM/yyyy'}}</td>
                <td>{{g.FechaEntrega | date:'dd/MM/yyyy'}}</td>
                <td>{{g.DiasEstadia}}</td>
                <td>{{g.Remitente}}</td>
                <td>{{g.Destinatario}}</td>
                <td>{{g.Observaciones}}</td>
                <td>{{g.ObservacionesTipificadas}}</td>
                <td>{{g.RendicionEmision}}</td>
                <td>{{g.RendicionConformacion}}</td>
                <td>{{g.ConsolidadoID}}</td>
                <td>{{g.FacturaTerceros}}</td>
                <td>{{g.DestinatarioReal}}</td>
                <td>{{g.NroLote}}</td>
                <td>{{g.NroCupon}}</td>
                <td>{{g.TelRemitente}}</td>
                <td>{{g.TelDestinatario}}</td>
                <td>{{g.PesoDetalleTotal}}</td>
                <td>{{g.HojaRuraR}}</td>
                <td>{{g.NroRemitoCliente}}</td>
                <td>{{g.NroFacturaCliente}}</td>
                <td>{{g.NroHojaRutaCliente}}</td>
                <td>{{g.facturaCancela}}</td>
                <td>{{g.NroPreCaso}}</td>
                <td>{{g.EmailRemitente}}</td>
                <td>{{g.EmailDestinatario}}</td>
                <td>{{g.Localidad}}</td>
                <td>{{g.Movil}}</td>
                <td>{{g.FajaSeguridad}}</td>
                <td>{{g.NumeroSeguimiento}}</td>
                <td>{{g.HojaRutaInternoID}}</td>
                <td>{{g.NroCaso}}</td>
                <td>{{g.EmpresaFactura}}</td>
              </tr>
            </tbody>
          </table>
    </div>
</div>



<div>
    <div class="modal" tabindex="1" id="modalEstadosGuia" data-keyboard="false">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Guia Nro: </h5>
          </div>
          <div class="modal-body">
            <div class="container">
              <table class="table" *ngIf="estadoHis">
                <thead>
                  <tr>
                    <th scope="col" hidden>ID</th>
                    <th scope="col">Estado</th>
                    <th scope="col">Tipo de Estado</th>
                    <th scope="col">Fecha</th>
                    <th scope="col">Agencia</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let guia of guiasFiltradasModal"><!-- *ngFor="let local of localesDestino | paginate: {itemsPerPage:5, currentPage:p};" -->
                    <td hidden></td>
                    <td>{{guia.EstadoGuiaDescrip}}</td>
                    <td>{{guia.TipoEstadoGuiaDescrip}}</td>
                    <td>{{guia.FechaHis | date:'dd/MM/yyyy hh:mm:ss'}}</td>
                    <td>{{guia.RazonSocial}}</td>
                    <td>{{guia.Observaciones}}</td>
                  </tr>
                </tbody>
              </table>
              <table class="table" *ngIf="!estadoHis">
                <thead>
                  <tr>
                    <th scope="col" hidden>ID</th>
                    <th scope="col">Fecha</th>
                    <th scope="col">Estado</th>
                    <th scope="col">Localidad</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let guia of guiasFiltradasModal"><!-- *ngFor="let local of localesDestino | paginate: {itemsPerPage:5, currentPage:p};" -->
                    <td hidden></td>
                    <td>{{guia.FechaHis | date:'dd/MM/yyyy hh:mm:ss'}}</td>
                    <td>{{guia.Estado}}</td>
                    <td>{{guia.LocalidadDescrip}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal">Cerrar</button>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
