import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AutorizadoCliente, AutorizadosGet, AutorizadosPost, ClienteCorporativo, ClienteEventualBusquedaGet, ClienteEventualBusquedaPost, ClienteEventualGet, ClienteEventualPost, ClienteGet, ClientePostBusqueda } from '../models/Cliente';
import { UrlService } from './url.service';

@Injectable({
  providedIn: 'root'
})
export class ClienteService {
  autorizado:AutorizadoCliente={};
  private agregarAutorizadoSubject=new Subject<AutorizadoCliente>();
  agregarAutorizadoObservable=this.agregarAutorizadoSubject.asObservable();

 
  urlClienteCorpo=this.urlService.urlApi+"/api/Cliente/ObtenerClienteByCodigo";
  urlClienteEventual=this.urlService.urlApi+"/api/Cliente/ObtenerClienteEventualByNroDocumento";
  /* urlClienteEventual="http://localhost:7724/api/Cliente/ObtenerClienteEventualByNroDocumento"; */
  /* urlClienteEventualBusq="http://localhost:7724/api/Cliente/ConsultarClientesEventuales"; */
  urlClienteEventualBusq=this.urlService.urlApi+"/api/Cliente/ConsultarClientesEventuales";
  urlNominaAutorizadosCorporativo=this.urlService.urlApi+"/api/Cliente/ObtenerNominaAutorizadosCorporativo"; 
  
  constructor(private http:HttpClient, private urlService:UrlService) { }

  agregarAutorizado(aut:AutorizadoCliente){
    this.autorizado=aut;
    this.agregarAutorizadoSubject.next(aut);
  }

  getCliente(cliente:ClientePostBusqueda):Observable<ClienteGet>{
    return this.http.post<ClienteGet>(this.urlClienteCorpo, cliente);
  }

  getClienteEventual(cliente:ClienteEventualPost):Observable<ClienteEventualGet>{
    return this.http.post<ClienteEventualGet>(this.urlClienteEventual, cliente);
  }

  getNominaAutorizadosCorporativo(clienteId:AutorizadosPost):Observable<AutorizadosGet>{
    return this.http.post<AutorizadosGet>(this.urlNominaAutorizadosCorporativo, clienteId);
  }

  getClienteEventualBusq(cliente:ClienteEventualBusquedaPost):Observable<ClienteEventualBusquedaGet>{
    return this.http.post<ClienteEventualBusquedaGet>(this.urlClienteEventualBusq, cliente);
  }

 
}
