import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ErrorGet } from '../models/Error';
import { UrlService } from './url.service';

@Injectable({
  providedIn: 'root'
})
export class ErroresService {

  /* url:string='http://localhost:7724/api/Utiles/ListaErrores'; */
  url:string=this.urlService.urlApi+'/api/Utiles/ListaErrores';
  constructor(private http:HttpClient, private urlService:UrlService) { }

  getErrores():Observable<ErrorGet>{
    return this.http.get<ErrorGet>(this.url);
  }
}
