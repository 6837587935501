import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UrlService {
 

  urlApi:string="https://apispk20testing.andesmar.com.ar";
 //urlApi:string="http://localhost:7724";
  constructor() { }
}
 