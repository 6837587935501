<div class="modal" tabindex="-1" id="modalDomicilio" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Domicilio</h5>
        </div>
        <div class="modal-body">
            <form #formDomicilio="ngForm">
                <div class="container">
                    <div class="row">
                        <div class="col">
                            <div class="form-group">
                                <label>Provincia</label>
                                <input list="provincias" value="" class="form-control" required [(ngModel)]="provincia" name="prov" #prov="ngModel" (change)="provinciaIngresada($event)">
                                <datalist id="provincias" *ngIf="provincia!=''" >
                                    <option *ngFor="let provincia of provincias" [id]="provincia.ProvinciaID" [value]="provincia.ProvinciaDescrip"></option>
                                </datalist>
                                <div [hidden]="prov.valid" class="alert alert-danger mt-1" style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido</div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label>Localidad</label>
                                <input list="localidades" value="" class="form-control" required [(ngModel)]="localidad" name="loc" #loc="ngModel" (change)="localidadIngresada($event)">
                                <datalist id="localidades" *ngIf="localidad!=''" >
                                    <option *ngFor="let localidad of localidadesSelect" [value]="localidad.LocalidadDescrip"></option>
                                </datalist>
                                <div [hidden]="loc.valid" class="alert alert-danger mt-1" style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido</div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label>Código Postal</label>
                                <input value="" class="form-control" required [(ngModel)]="cp" name="codPos" #codPos="ngModel">
                                <div [hidden]="codPos.valid" class="alert alert-danger mt-1" style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="form-group">
                                <label>Calle</label>
                                <input list="calles" value="" class="form-control" required [(ngModel)]="calle" name="nomCalle" #nomCalle="ngModel" (change)="calleIngresada($event)">
                                <datalist id="calles" *ngIf="callesLocalidades.length!=0">
                                    <option *ngFor="let calle of callesLocalidades" [id]="calle.localidadid">{{calle.CalleDescrip}} ({{calle.AlturaDesde}} - {{calle.AlturaHasta}})</option>
                                </datalist>
                                <div [hidden]="nomCalle.valid" class="alert alert-danger mt-1" style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido</div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label>Número</label>
                                <input type="text" class="form-control" required [(ngModel)]="nro" name="numero" #numero="ngModel" (input)=validarCalleNro($event.target.value)>
                                <div [hidden]="numero.valid" class="alert alert-danger mt-1" style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido</div>
                                <div [hidden]="correcto" class="alert alert-danger mt-1" style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">Valor Incorrecto</div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label>Teléfono</label>
                                <input type="number" class="form-control" [(ngModel)]="telefono" name="tel">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <div class="form-group">
                                <label>Fax</label>
                                <input type="number" class="form-control" [(ngModel)]="telefono2" name="tel2">
                            </div>
                        </div>
                        <div class="col-8">
                            <div class="form-group">
                                <label>Observaciones</label>
                                <textarea class="form-control" [(ngModel)]="observaciones" name="obs" cols="15" rows="5"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-success" [disabled]="!formDomicilio.form.valid" (click)="guardarDomicilio()">Guardar</button>
          <button type="button" class="btn btn-danger" (click)="cerrarModal()">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
