<div (focus)="buscarAutorizados()" class="modal" tabindex="1" id="modalAutorizados" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header headerModal">
                <h5 class="modal-title titulo">Autorizados Clientes:<span class="nombreCliente">&nbsp;{{clienteCorpo.RazonSocial}}</span></h5>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="row">
                        <div class="col">
                          <label>Nro. Documento</label>
                          <input type="number" class="form-control" id="dniBusq" [(ngModel)]="dniBusqueda" name="dniBusq" (input)="filtrarAutorizados($event.target.value, 'dni')" placeholder="Buscar ...">
                        </div>
                        <div class="col">
                          <label>Nombre y Apellido</label>
                          <input type="text" class="form-control" id="apeNomBusq" [(ngModel)]="apeNomBusqueda" name="apeNomBusq" (input)="filtrarAutorizados($event.target.value, 'apeNom')" placeholder="Buscar ...">
                        </div>
                      </div>
                    <table class="table">
                        <thead class="titulosModal">
                            <tr>
                                <th scope="col" hidden>ID</th>
                                <th scope="col">Nro Documento</th>
                                <th scope="col">Nombre</th>
                                <th scope="col">Apellido</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let aut of autorizadosFiltro | paginate: {itemsPerPage:5, currentPage:p};">
                                <td hidden>{{aut.NominaAutorizadoID}}</td>
                                <td>&nbsp;&nbsp;{{aut.NroDocumento}}</td>
                                <td>{{aut.Nombre}}</td>
                                <td>{{aut.Apellido}}</td>
                                <td>
                                    <button title="Seleccionar autorizado" class="btn btn-info botonSeleccionar" (click)="seleccionarAutorizado(aut)">
                                        <svg width="1em" height="1em" viewBox="0 0 16 16"
                                            class="bi bi-check2-square" fill="currentColor"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd"
                                                d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                            <path fill-rule="evenodd"
                                                d="M1.5 13A1.5 1.5 0 0 0 3 14.5h10a1.5 1.5 0 0 0 1.5-1.5V8a.5.5 0 0 0-1 0v5a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V3a.5.5 0 0 1 .5-.5h8a.5.5 0 0 0 0-1H3A1.5 1.5 0 0 0 1.5 3v10z" />
                                        </svg>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                        <pagination-controls (pageChange)="p=$event"></pagination-controls>
                    </table>
                </div>
                <div class="modal-footer divBotonCerrar">
                    <button type="button" class="btn btn-danger botonSeleccionar botonCerrar" data-dismiss="modal">Cerrar</button>
                </div>
            </div>
        </div>
    </div>
</div>

