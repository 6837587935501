import { Component, OnInit,NgModule } from '@angular/core';

@Component({
  selector: 'app-spinners',
  templateUrl: './app-spinners.component.html',
  styleUrls: ['./app-spinners.component.scss']
})
export class AppSpinnersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
