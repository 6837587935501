import { Component, Input, OnInit } from '@angular/core';
import { Observacion, ObservacionesPost } from 'src/app/models/Orden-Guia';
import { ObservacionService } from 'src/app/services/observacion.service';
declare var $:any;

@Component({
  selector: 'app-modal-observaciones',
  templateUrl: './modal-observaciones.component.html',
  styleUrls: ['./modal-observaciones.component.scss']
})
export class ModalObservacionesComponent implements OnInit {

  observaciones:Observacion[]=[]
  observacionesBusqueda:Observacion[]=[]
  @Input() tipoObsID:number;
  codigo:string=null;
  descripcion:string=null;
  p:number=1;
  constructor(private obsService:ObservacionService) { }

  ngOnInit(): void {

  }
  
  getObservaciones(){

    this.observaciones=[];
    this.observacionesBusqueda=[];
    var obs:ObservacionesPost={
      codigo:0,
      obsDescrip:"",
      tipoObservacionID:this.tipoObsID>0?this.tipoObsID:1,
      impactoID:1
    }
    this.obsService.getObservaciones(obs).subscribe(res=>{
      if(res.Codigo==200){
        this.observaciones=res.observaciones;
        this.observacionesBusqueda=this.observaciones;
      }
    })
  }

  buscarObservacion(event, campo:string){
    if(campo==='codigo'){
      if(event==='' || event===null || event==='null'){
        if((this.descripcion==='' || this.descripcion===null)){
          this.observacionesBusqueda=this.observaciones;
        }else if((this.descripcion!=='' || this.descripcion!==null)){
          this.observacionesBusqueda=this.observaciones.filter((l)=>{
            return l.ObsDescrip.toLowerCase().includes(this.descripcion.toLowerCase());
          });
        }
      }else{
        if((this.descripcion==='' || this.descripcion===null)){
          this.observacionesBusqueda=this.observaciones.filter((l)=>{
            return l.Codigo.toString().includes(this.codigo);
          })
        }else if((this.descripcion!=='' || this.descripcion!==null)){
          this.observacionesBusqueda=this.observaciones.filter((l)=>{
            return l.ObsDescrip.toLowerCase().includes(this.descripcion.toLowerCase());
          });
          this.observacionesBusqueda=this.observacionesBusqueda.filter((l)=>{
            return l.Codigo.toString().includes(this.codigo);
          });
        }
      }
    }else if(campo==='descripcion'){
      if((this.codigo===null)){
        this.observacionesBusqueda=this.observaciones.filter((l)=>{
          return l.ObsDescrip.toLowerCase().includes(event.toLowerCase());
        });
      }else if((this.codigo!==null  || this.codigo!=="")){
        this.observacionesBusqueda=this.observaciones.filter((l)=>{
          return l.Codigo.toString().includes(this.codigo);
        });
        this.observacionesBusqueda=this.observacionesBusqueda.filter((l)=>{
          return l.ObsDescrip.toLocaleLowerCase().includes(event.toLowerCase());
        });
      }
    }
  }

  seleccionarObs(obs:Observacion){
    this.obsService.agregarObservacion(obs);
    $("#modalObservaciones").modal('hide');
  }
}
