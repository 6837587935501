<div class="container-fluid" style="width: 1350px; margin-top: 50px;">
    <div class="row align-items-center rounded mt-5" style="border: solid 1px grey; height: 50px;">
        <h4 class="mt-1">&nbsp;&nbsp; Recepción Masiva</h4>
    </div><br>
    <div class="row justify-content-md-center">
        <div class="col-4">
            <div class="form-group">
                <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Número de Guía</span>
                    </div>
                    <input type="text" aria-label="First name" class="form-control" [(ngModel)]="nroGuia" (keyup.enter)="agregar()">
                </div>
            </div> 
        </div>
        <div class="col-1">
            <button class="btn btn-primary" (click)="agregar()">Aceptar</button>
        </div>
        <div class="col-1">
            <span style="font-size: 24px; font-weight: bold;">Restan:{{restantes}}</span>
        </div>
    </div>
    <div class="row justify-content-md-center" *ngIf="guias.length>0">
       <div class="col-6" style="display: flex; align-items: center; justify-content: center;">
            <table class="table table-borderless">
                <thead>
                <tr>
                    <th scope="col">Nro. de Guía</th>
                    <th scope="col">Observación</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let g of guias">
                    <td>{{g.nroGuia}}</td>
                    <td>
                        <div class="form-group">
                            <div class="input-group">
                                <input type="text" aria-label="First name" class="form-control" [id]="'cod'+g.guiaID">
                                <input type="text" aria-label="Last name" class="form-control" [id]="'descrip'+g.guiaID">
                                <button type="button" class="btn btn-primary" (click)="seleccionarObs(g.guiaID,'')"> <!-- data-target="#modalObservaciones" data-toggle="modal" -->
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                                        <path fill-rule="evenodd" d="M1.5 13A1.5 1.5 0 0 0 3 14.5h10a1.5 1.5 0 0 0 1.5-1.5V8a.5.5 0 0 0-1 0v5a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V3a.5.5 0 0 1 .5-.5h8a.5.5 0 0 0 0-1H3A1.5 1.5 0 0 0 1.5 3v10z"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
       </div>
    </div>
    <div class="row" *ngIf="guiasNoRecibidas">
        <div class="col">
            <div class="alert alert-dark" style="text-align: center;">Las siguientes guías no están en condiciones de ser recibidas: {{guiasNoRecib}}</div>
        </div>
    </div>
    <div *ngIf="guiasIncompletas"><!-- *ngIf="guiasIncompletas" -->
        <div style="font-style: italic; font-size: 20px;">Debe completar los datos de las siguientes guías para recibirlas:</div>
        <div class="row justify-content-md-center">
            <div class="col-11" style="display: flex; align-items: center; justify-content: center;">
                <table class="table table-borderless">
                    <thead>
                    <tr>
                        <th scope="col">Nro. de Guía</th>
                        <th scope="col">Observación</th>
                        <th scope="col">Valor Declarado</th>
                        <th scope="col">Bultos</th>
                        <th scope="col">Destino</th>
                        <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let g of guiasIncomp"><!-- guiasIncomp -->
                        <td>{{g.nroGuia}}</td>
                        <td>
                            <div class="form-group">
                                <div class="input-group">
                                    <input type="text" aria-label="First name" class="form-control" [id]="'codI'+g.guiaID">
                                    <input type="text" aria-label="Last name" class="form-control" [id]="'descripI'+g.guiaID">
                                    <button type="button" class="btn btn-primary" (click)="seleccionarObs(g.guiaID, 'incompleta')">
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                                            <path fill-rule="evenodd" d="M1.5 13A1.5 1.5 0 0 0 3 14.5h10a1.5 1.5 0 0 0 1.5-1.5V8a.5.5 0 0 0-1 0v5a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V3a.5.5 0 0 1 .5-.5h8a.5.5 0 0 0 0-1H3A1.5 1.5 0 0 0 1.5 3v10z"/>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="form-group">
                                <input type="text" class="form-control" [(ngModel)]="g.valorDeclarado">
                            </div>
                        </td>
                        <td>
                            <div class="form-group">
                                <input type="text" class="form-control" [(ngModel)]="g.bultos">
                            </div>
                        </td>
                        <td>
                            <div class="form-group">
                                <div class="input-group">
                                    <input type="text" aria-label="First name" class="form-control" [id]="'id'+g.guiaID">
                                    <input type="text" aria-label="Last name" class="form-control" [id]="'rs'+g.guiaID">
                                    <button type="button" class="btn btn-primary" (click)="seleccionarLocal(g.guiaID)">
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                                            <path fill-rule="evenodd" d="M1.5 13A1.5 1.5 0 0 0 3 14.5h10a1.5 1.5 0 0 0 1.5-1.5V8a.5.5 0 0 0-1 0v5a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V3a.5.5 0 0 1 .5-.5h8a.5.5 0 0 0 0-1H3A1.5 1.5 0 0 0 1.5 3v10z"/>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="form-group">
                                <select class="custom-select" [(ngModel)]="g.tipoGuia">
                                    <option value="1">Factura</option>
                                    <option value="2">Rendicion</option>
                                    <option value="3">Agencia a agencia</option>
                                    <option value="4">Remito</option>
                                    <option value="5">Unidades de logística</option>
                                    <option value="6">Distribución local</option>
                                </select>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="row justify-content-end" style="margin-top: 25px;">
        <div class="col-3">
            <button type="button" class="btn btn-primary" style="margin-left: 50px;" *ngIf="guias.length>0 || guiasIncomp.length>0" (click)="confirmar()">Confirmar</button>
            <button type="button" class="btn btn-primary" style="margin-left: 20px;" *ngIf="guiasIncompletas" (click)="cancelar()">Cancelar</button>
        </div>
    </div><br><br>
</div>

<app-modal-observaciones></app-modal-observaciones>
<app-modal-agencia></app-modal-agencia>