<div class="container mt-5">
    <input type="text" class="form-control" (input)="buscarCategoria($event.target.value)" style="width: 700px;" placeholder="Buscar Nombre..."><br>
    <button class="btn btn-info" (click)="abrirModal()">Agregar Categoría</button>
    <jqxGrid #myGrid class="grid mt-2"
        [width]="getWidth()" [source]="dataAdapter" [columns]="columns"
        [pageable]="true" [autoheight]="true" *ngIf="!cargando">
    </jqxGrid><br>
    <div *ngIf="cargando">
      <h4>Cargando...</h4>
    </div>
</div>

<div>
    <div class="modal" tabindex="-1" id="modalCategoria">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Categoría PV</h5>
            </div>
            <div class="modal-body">
                <form [formGroup]="formulario">
                    <div class="form-group" hidden>
                      <label>Categoría ID</label>
                      <input type="number" class="form-control" formControlName="id" aria-describedby="emailHelp" disabled>
                    </div>
                    <div class="form-group">
                      <label>Categoría Nombre</label>
                      <input type="text" class="form-control" formControlName="nombre">
                    </div>
                </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-success" (click)="save()">Guardar</button>
              <button type="button" class="btn btn-danger" data-dismiss="modal">Cerrar</button>
            </div>
          </div>
        </div>
      </div>
</div>
