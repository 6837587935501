<div class="modal" tabindex="-1" id="impresion">
    <div class="modal-dialog modal-xl modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Impresión de Guías</h5>
        </div>
        <div class="modal-body">
          <div class="container">
              <div class="row">
                  <div class="col">

                      <!-- <img style="width: 370px;" src="assets/logoandesmarcargas.png" alt=""> -->

                      <!-- <img style="width: 370px;" src="assets/AndesmarCargas-andesmar-cargas-2.png" alt=""> -->
                      <p style="font-size: 14px; font-family: Arial, Helvetica, sans-serif; text-align: center;">de Autotransportes Andesmar S.A- R.N.P.S.P Nº 818</p>
                      <p style="text-align: center;">Responsable Inscripto</p>
                  </div>
                  <div class="col" style="text-align: center;">
                    <p style="color: rgb(199, 194, 194); font-size: 40px; font-family: Arial, Helvetica, sans-serif; margin-top: -10px;">R</p>
                    <p style="color: rgb(199, 194, 194); font-size: 12px; font-family: Arial, Helvetica, sans-serif; margin-top: -30px;">Código 91</p>
                    <p style="color: #21618C; font-size: 10px; font-weight: bold; margin-top: -15px;"><b>N° DE GUÍA:</b></p>
                    <p style="font-size: 18px; margin-top: -25px;"><b>N°: 5308-00626385-R</b></p>
                  </div>
                  <div class="col" style="text-align: right;">
                      <p style="font-size: 20px;">Fecha: 12/08/2020</p>
                      <p style="font-size: 24px; color: #21618C; margin-top: -10px;"><b>N° DE SEGUIMIENTO <br> 333612</b></p>
                  </div>
              </div>
              <div class="row">
                  <div class="col">
                      Oficina: CND BUENOS AIRES
                      Localidad: AVELLANEDA (BS AS)
                      Provincia: BUENOS AIRES
                  </div>
                  <div class="col">
                      <p style="margin-top: 10px;">
                        Dirección: GRAL. GORRITI 650 <br>
                        Tel. Sucursal: 1224300
                      </p>
                  </div>
                  <div class="col">
                     <p style="margin-top: 10px;">
                        Call center: 0810-122-4300
                        www.andesmarcargas.com
                     </p>
                  </div>
                  <div class="col">
                      C.U.I.T: 30601502778 <br>
                      Ingresos Brutos: 30601502778 <br>
                      N° Establ: <br>
                      Inicio de act.: 02/09/1983
                  </div>
              </div>
              <div class="row">
                  <div class="col-4">
                      <p style="color: #21618C; font-size: 24px; margin-top: 50px;"><b>N° DE CLIENTE: 5258</b></p>
                  </div>
                  <div class="col-4" style="text-align: center;">

                    <!-- <img src="assets/codigobarra.png" alt=""> -->
                  </div>
              </div><br>
              <div class="row">
                  <div class="col-6">
                      <p style="background-color: #21618C; height: 40px; color: white;"><span style="margin-left: 10px;vertical-align: middle; font-size: 24px;">REMITENTE</span></p>
                      <div class="row">
                          <div class="col-4" style="margin-left: 20px;">Remitente:</div>
                          <div class="col-4"><b>ENVIOPACK SA</b></div>
                      </div>
                      <div class="row">
                        <div class="col-4" style="margin-left: 20px;">Domicilio:</div>
                        <div class="col-5"><b>Av Gral Juan Domingo Perón 4212</b></div>
                    </div>
                    <div class="row">
                        <div class="col-4" style="margin-left: 20px;">Localidad:</div>
                        <div class="col-5"><b>GENERAL PACHECO</b></div>
                    </div>
                    <div class="row">
                        <div class="col-4" style="margin-left: 20px;">Teléfono:</div>
                        <div class="col-5"></div>
                    </div>
                    <div class="row">
                        <div class="col-4" style="margin-left: 20px;">CUIT:</div>
                        <div class="col-5"></div>
                    </div>
                    <div class="row">
                        <div class="col-4" style="margin-left: 20px;">Condición IVA</div>
                        <div class="col-5"><b>Consumidor Final</b></div>
                    </div>
                  </div>
                  <div class="col-6">
                    <p style="background-color: #21618C; height: 40px; color: white;"><span style="margin-left: 10px;vertical-align: middle; font-size: 24px;">DESTINO</span></p>
                    <div class="row">
                        <div class="col-4" style="margin-left: 20px;">Destinatario:</div>
                        <div class="col-5"><b>Noelia Tobares</b></div>
                    </div>
                    <div class="row">
                        <div class="col-4" style="margin-left: 20px;">Domicilio:</div>
                        <div class="col-5"><b>Vucetich(Piso, Depto) 3315</b></div>
                    </div>
                    <div class="row">
                        <div class="col-4" style="margin-left: 20px;">Localidad</div>
                        <div class="col-5"><b>MENDOZA (MZA)</b></div>
                    </div>
                    <div class="row">
                        <div class="col-4" style="margin-left: 20px;">Provincia:</div>
                        <div class="col-5"><b>MENDOZA</b></div>
                    </div>
                    <div class="row">
                        <div class="col-4" style="margin-left: 20px;">Teléfono:</div>
                        <div class="col-5"><b>+542615132701</b></div>
                    </div>
                    <div class="row">
                        <div class="col-4" style="margin-left: 20px;">Agencia Destino:</div>
                        <div class="col-5"><b>CND MENDOZA</b></div>
                    </div>
                  </div>
              </div><br>
              <div class="row">
                  <div class="col">
                    <p style="border: 1px solid#21618C; height: 40px; color: white;"><span style="margin-left: 10px;vertical-align: middle; font-size: 24px; color: #21618C;"><b>ENVÍO</b></span></p>
                    <div class="row">
                        <div class="col-9">
                            <div class="row" style="margin-top: -5px;">
                                <div class="col-4" style="margin-left: 20px; margin-top: 8px;">Modalidad de envío:</div>
                                <div class="col-6"><span style="font-size: 22px;"><b>Puerta-Puerta</b></span></div>
                            </div>
                            <div class="row" style="margin-top: -5px;">
                                <div class="col-4" style="margin-left: 20px; margin-top: 8px;">Producto:</div>
                                <div class="col-6"><span style="font-size: 22px;"><b>A Express</b></span></div>
                            </div>
                            <div class="row" style="margin-top: -5px;">
                                <div class="col-4" style="margin-left: 20px; margin-top: 8px;"><p style="background-color: #21618C; width: 210px; color: white;"><span style="margin-left: 15px;"><b>Condición de Pago</b></span></p></div>
                                <div class="col-6"><span style="font-size: 22px;"><b>Cuenta Corriente</b></span></div>
                            </div>
                            <div class="row" style="margin-top: -5px;">
                                <div class="col-4" style="margin-left: 20px;">Bultos:</div>
                                <div class="col-6" style="margin-top: -8px;"><span style="font-size: 22px;"><b>1</b></span></div>
                            </div>
                            <div class="row" style="margin-top: -5px;">
                                <div class="col-4" style="margin-left: 20px; margin-top: 8px;">Observaciones:</div>
                                <div class="col-5"><p style="font-size: 22px; line-height: 100%; margin-top: 8px;">
                                    <b>EXPRESS CORP ACUSE RECIBO-PTA-PTA - CTA.CTE. - Orden/Plan.Retiro: 65956-R
                                        Remito Nro: EP001835050N - - El Servicio se prestara para días hábiles.-Orden Retiro. Fecha de Entrega 30/07/2020 - Fecha
                                        Estimada entrega: 20/08/2020</b>
                                </p></div>
                            </div>
                            <div class="row" style="margin-top: -5px;">
                                <div class="col-4" style="margin-left: 20px; margin-top: 8px;">N° de etiqueta:</div>
                                <div class="col-6"><span style="font-size: 22px;"><b>2222</b></span></div>
                            </div>
                            <div class="row" style="margin-top: -5px;">
                                <div class="col-4" style="margin-left: 20px; margin-top: 8px;">Tiempo estimado entrega:</div>
                                <div class="col-6"><span style="font-size: 22px; margin-top: 8px;"><b>48</b></span></div>
                            </div>
                            <div class="row" style="margin-top: -5px;">
                                <div class="col-4" style="margin-left: 20px; margin-top: 8px;">Peso Total:</div>
                                <div class="col-6"><span style="font-size: 22px;"><b>0,10</b></span></div>
                            </div>
                            <div class="row" style="margin-top: -5px;">
                                <div class="col-4" style="margin-left: 20px; margin-top: 8px;">Valor declarado total:</div>
                                <div class="col-6"><span style="font-size: 22px;"><b>$2250,00</b></span></div>
                            </div>
                            <div class="row" style="margin-top: -5px;">
                                <div class="col-4" style="margin-left: 20px; margin-top: 8px;">Valor declarado por bulto:</div>
                                <div class="col-6"><span style="font-size: 22px;"><b>$2250,00</b></span></div>
                            </div>
                            <div class="row" style="margin-top: -5px;">
                                <div class="col-4" style="margin-left: 20px; margin-top: 8px;">N° de Remito</div>
                                <div class="col-6"><span style="font-size: 22px;"><b>-</b></span></div>
                            </div>
                            <div class="row" style="margin-top: -5px;">
                                <div class="col-4" style="margin-left: 20px; margin-top: 8px;">Fecha de despacho acordada con el cliente:</div>
                                <div class="col-6"><span style="font-size: 22px;"><b></b></span></div>
                            </div>
                        </div> 
                        <div class="col-3" style="margin-left: -120px;">
                            <div class="row">
                                <div class="col-3">Flete Nacional:</div>
                                <div class="col-3"><b>$122,22</b></div>
                            </div>
                            <div class="row">
                                <div class="col-3">Seguro:</div>
                                <div class="col-3"><b>$0,00</b></div>
                            </div>
                            <div class="row">
                                <div class="col-3">Retiro:</div>
                                <div class="col-3"><b>$30,55</b></div>
                            </div>
                            <div class="row">
                                <div class="col-3">Entrega:</div>
                                <div class="col-3"><b>$52,95</b></div>
                            </div>
                            <div class="row">
                                <div class="col-3">Comis. CCRR:</div>
                                <div class="col-3"><b>$0,00</b></div>
                            </div>
                            <div class="row">
                                <div class="col-3">Serv. Adicional:</div>
                                <div class="col-3"><b>$0,00</b></div>
                            </div>
                            <div class="row">
                                <div class="col-3">Neto:</div>
                                <div class="col-3"><b>$205,72</b></div>
                            </div>
                            <div class="row">
                                <div class="col-3">IVA:</div>
                                <div class="col-3"><b>$0,00</b></div>
                            </div>
                            <div class="row">
                                <div class="col-3">TOTAL:</div>
                                <div class="col-3"><b>$205,72</b></div>
                            </div>
                        </div>
                    </div>
                  </div>
              </div>
              <div class="row">
                <div class="col-3" ><p style="margin-top: 100px; margin-left: 15px;">Firma Remitente/Destinatario</p></div>
                <div class="col-2" style="text-align: center;">
                    <div class="row">
                        <div class="col"><p style="margin-top: 50px;">DNI</p></div>
                    </div>
                    <div class="row">
                        <div class="col"><p style="margin-top: 10px;">Aclaración</p></div>
                    </div>
                </div>
                <div class="col-7">
                    <div class="row">
                        <div class="col-9" style="text-align: right;">
                          <span style="font-size: 16px;">
                            <b>
                                Escaneá el código QR, ingresá tu número de seguimiento y seguí tu envío. También podés hacerlo en <span style="font-size: 20px;">www.andesmarcargas.com</span> <br>
                                <i>Los Términos y Condiciones se encuentran en nuestra página web</i>
                            </b>
                          </span>
                        </div>
                        <div class="col-3">

                            <!-- <img src="assets/qr.png" alt="" style="height: 135px; width: 135px;"> -->

                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col" style="margin-left: 5px;">
                    <p style="font-size: 9px; text-align: justify;">
                        El marco legal aplicable al servicio de encomiendas contratado, es el previsto en las leyes Nacionales N° 12.346, 20.216, disposiciones legales y reglamentarias concordantes, parte de las cuales se reproducen en la presente Guía, dejando constancia que la totalidad de los Términos y
                        Condiciones que rigen el servicio de encomienda contratado se encuentran publicitados físicamente en la agencia donde se efectúa el despacho y en la página web de la empresa www.andesmarcargas.com, los que el remitente voluntariamente declara haber leído y aceptado: 1.- CONTRATO:
                        La Guía emitida, constituye el Titulo Legal del Contrato de Transporte de Encomiendas, por cuyo contenido se decidirán todas las cuestiones derivadas o que se deriven del mismo; siendo además el único medio eficaz para probar su existencia. Las condiciones particulares, regirán la relación
                        contractual que vincula a "remitente y/o transportista", las mismas resultan aplicables a "agentes", "representantes" y/o "a cualquier otra persona física o jurídica" que el transportista contrate a los efectos de cumplimentar la entrega de las encomiendas. 2.- ENCOMIENDA: Se entiende por
                        "encomienda" todo objeto o bulto que no siendo equipaje, es transportado por el “transportista”, amparado en una guía, mediante el pago de una tarifa determinada.3.-CONTENIDO. DECLARACIÓN JURADA: El remitente manifiesta con carácter de declaración jurada, que la descripción del
                        contenido de la encomienda, que efectúa en la presente guía, es real, exacto y completo; no contiene elementos prohibidos por disposiciones legales vigentes, ni riesgosos para la seguridad del medio transportado, tripulación pasajeros y/o resto de carga; siendo el único responsable por el
                        contenido de la encomienda, asumiendo de las consecuencias civiles, penales, impositivas y/o administrativas que por ello se generen.4.-VALOR DECLARADO: Se entiende por Valor declarado, al valor por el cual Usted realiza el envío, marcando el límite máximo de responsabilidad, que
                        AUTOTRANSPORTES ANDESMAR S.A. asume como consecuencia del mismo.5.-ALCANCE DE RESPONSABILIDAD: La responsabilidad de AUTOTRANSPORTES ANDESMAR S.A. por pérdida, extravío y/o destrucción total o parcial de la encomienda despachada, se limita al Valor
                        Declarado voluntariamente por el remitente, en la presente Guía. La citada responsabilidad comienza, desde la recepción por AUTOTRANSPORTES ANDESMAR S.A. de los bultos contenidos en la Guía, y hasta el momento de su entrega en destino. 6.- CASO FORTUITO O FUERZA
                        MAYOR: AUTOTRANSPORTES ANDESMAR SA no será responsable de la pérdida, extravío, destrucción y/o entrega errónea de la encomienda despachada, cuando ello se funde en caso fortuito o fuerza mayor. 7.-Si alguna de las cláusulas o parte de las mismas fueran declaradas nulas o
                        ineficaces permanecerán subsistentes el resto de los Términos y Condiciones no afectados por la nulidad o ineficacia.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col" style="text-align: center;">
                    Nº <b>685155137</b> — <b>TRIPLICADO BULTO</b> — <b>C.A.I 46138177270764</b> – Fecha de vencimiento: <b>21/09/2020</b>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div id="iframeContainer" style="width: 700px;">
                        
                    </div>
                </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-success" (click)="imprimir()">Imprimir</button>
          <button type="button" class="btn btn-primary" (click)="descargar()">Descargar</button>
        </div>
      </div>
    </div>
  </div>