import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AutorizacionesGet, AutorizacionesPost, BusquedaGuiaPost, CodigoBarraGetConfirmacion, CodigoBarraGetValidacion, CodigoBarraPostConfirmacion, CodigoBarraPostValidacion, ConceptoEmisionFacturableGet, ConceptoEmisionFacturablePost, ConceptoEmisionGet, ConceptoEmisionPost, CondicionIvaGet, CondicionPagoGet, ConsultaCreditoAgenciaGet, ConsultaCreditoAgenciaPost, ConsultaRecargoGet, ConsultaRecargoPost, EmitirGuiaGet, EmitirGuiaPost, enviarEmailGet, EnvioCodigo, EnvioCodigoGet, GuardadoPdfPost, GuardadoPostGet, GuiaSeguimientoFiltroPost, GuiaSeguimientoGet, GuiaSeguimientoPost, GuiaSegumientoFiltroGet, ImpresionMasiva, ImpresionPicaPost, OpcionEnvio, OpcionesEnvioGet, OpcionesEnvioPost, OrdenRetiro, OrdenRetiroGet, OrdenRetiroPost, ProductoPost, ProductoPostAgencia, ProductosGet, ReimpresionGet, SeguimientoOrdenGet, SeguimientoOrdenPost, TipoDocumentoGet, validarOperacionGet } from '../models/Orden-Guia';
import { UrlService } from './url.service';

@Injectable({
  providedIn: 'root'
})
export class EmisionService {

  //variables para el manejo de la orden de retiro
  ordenRetiro:OrdenRetiro={}
  agregado:boolean;
  opcionEnvio:OpcionEnvio={}
  private agregarOrdenRetiroSubject=new Subject<OrdenRetiro>();
  agregarOrdenRetiroObservable=this.agregarOrdenRetiroSubject.asObservable();
  private mandarAgregadoSubject=new Subject<boolean>();
  mandarAgregadoObservable=this.mandarAgregadoSubject.asObservable();
  private agregarOpcionEnvioSubject=new Subject<OpcionEnvio>();
  agregarOpcionEnvioObservable=this.agregarOpcionEnvioSubject.asObservable();

  //Request URL Api


 

  urlObtenerTipoDocumento:string=this.urlService.urlApi+"/api/Utiles/ObtenerTipoDocumento";
  urlObtenerCondicionIva:string=this.urlService.urlApi+"/api/Utiles/ObtenerCondicionIva";
  urlObtenerCondicionPago:string=this.urlService.urlApi+"/api/Utiles/ObtenerCondicionPago";

  urlObtenerProductosByCliente:string=this.urlService.urlApi+"/api/Emision/ObtenerProductosByCliente";
  urlObtenerProductosByAgencia:string=this.urlService.urlApi+"/api/Emision/ObtenerProductosByAgencia";

  urlObtenerConceptosFacturacionEmision:string=this.urlService.urlApi+"/api/Emision/ObtenerConceptosFacturacionEmision";
  urlObtenerConceptosFacturacionIngreso:string=this.urlService.urlApi+"/api/Emision/ObtenerConceptosFacturacionIngreso";
  urlObtenerImporteServicioAdicional:string=this.urlService.urlApi+"/api/Emision/ObtenerImporteServicioAdicional";
  urlConsultaCreditoAgencia:string=this.urlService.urlApi+"/api/Emision/ObtenerCreditoAgencia"; 
  urlValidarCB:string=this.urlService.urlApi+"/api/Emision/ValidarCodigoBarra";
  urlConfirmarCB:string=this.urlService.urlApi+"/api/Emision/ConfirmarCodigosBarra";
  urlConsultarTarifaRecargo:string=this.urlService.urlApi+"/api/Emision/ConsultarRecargoPagoTarjeta";
 
  urlEmision:string=this.urlService.urlApi+"/api/Emision/EmitirGuia"; 

  urlObtenerOpcionesEnvio:string=this.urlService.urlApi+"/api/Emision/ObtenerOpcionesEnvio";

  urlGuardadoPdf:string=this.urlService.urlApi+"/api/Emision/GuardarPDF";


  urlBuscarOrdenRetiro:string=this.urlService.urlApi+"/api/Emision/BuscarOrdenRetiro";

  urlAutorizaciones:string=this.urlService.urlApi+"/api/Emision/AutorizacionOcasional";


  urlReimpresion:string=this.urlService.urlApi+"/api/Emision/Reimpresion";

 
  urlSeguimientoGuias:string=this.urlService.urlApi+"/api/SeguimientoGuias/Consultar";

  /* urlSeguimientoGuiasFiltro:string="http://localhost:7724/api/SeguimientoGuias/FiltrarConsulta"; */
  urlSeguimientoGuiasFiltro:string=this.urlService.urlApi+"/api/SeguimientoGuias/FiltrarConsulta";

  /* urlImpresionPica:string="http://localhost:7724/api/Emision/ImpresionPica"; */
  urlImpresionPica:string=this.urlService.urlApi+"/api/Emision/ImpresionPica";

  /* urlEmisionMasiva:string="http://localhost:7724/api/Emision/EmisionMasiva"; */
  urlEmisionMasiva:string=this.urlService.urlApi+"/api/Emision/EmisionMasiva";

 /*  urlValidarOperacion:string="http://localhost:7724/api/Emision/ValidarOperacion"; */
  urlValidarOperacion:string=this.urlService.urlApi+"/api/Emision/ValidarOperacion";

  /* urlEnviarEmail:string="http://localhost:7724/api/Emision/EnviarExcelByEmail"; */
  urlEnviarEmail:string=this.urlService.urlApi+"/api/Emision/EnviarExcelByEmail";

  /* urlFinalizarOperacion:string="http://localhost:7724/api/Emision/FinalizarOperacion"; */
  urlFinalizarOperacion:string=this.urlService.urlApi+"/api/Emision/FinalizarOperacion";

  /* urlImprimirGuiasMasivas:string="http://localhost:7724/api/Emision/ImprimirGuias"; */
  urlImprimirGuiasMasivas:string=this.urlService.urlApi+"/api/Emision/ImprimirGuias";

  /* urlSeguimientoOrdenes:string="http://localhost:7724/api/SeguimientoGuias/ConsultarOR"; */
  urlSeguimientoOrdenes:string=this.urlService.urlApi+"/api/SeguimientoGuias/ConsultarOR";

  constructor(private http:HttpClient, private urlService:UrlService) { }

  //métodos consulta api
  
  getTipoDocumento():Observable<TipoDocumentoGet>{
    return this.http.post<TipoDocumentoGet>(this.urlObtenerTipoDocumento, "");
  }

  getCondicionIva():Observable<CondicionIvaGet>{
    return this.http.post<CondicionIvaGet>(this.urlObtenerCondicionIva, "");
  }

  getCondicionPago():Observable<CondicionPagoGet>{
    return this.http.post<CondicionPagoGet>(this.urlObtenerCondicionPago, "");
  }

  getProductosByCliente(producto:ProductoPost):Observable<ProductosGet>{
    return this.http.post<ProductosGet>(this.urlObtenerProductosByCliente, producto);
  }

  getProductosByAgencia(producto:ProductoPostAgencia):Observable<ProductosGet>{
    return this.http.post<ProductosGet>(this.urlObtenerProductosByAgencia, producto);
  }

  getOpcionesEnvio(datos:OpcionesEnvioPost):Observable<OpcionesEnvioGet>{
    return this.http.post<OpcionesEnvioGet>(this.urlObtenerOpcionesEnvio, datos);
  }

  getConceptosFacturacionEmision(concepto:ConceptoEmisionPost):Observable<ConceptoEmisionGet>{
    return this.http.post<ConceptoEmisionGet>(this.urlObtenerConceptosFacturacionEmision, concepto);
  }

  getConceptosFacturacionEmisionIngreso(concepto:ConceptoEmisionPost):Observable<ConceptoEmisionGet>{
    return this.http.post<ConceptoEmisionGet>(this.urlObtenerConceptosFacturacionIngreso, concepto);
  }

  getConceptoFacturacionImporte(concepto:ConceptoEmisionFacturablePost):Observable<ConceptoEmisionFacturableGet>{
    return this.http.post<ConceptoEmisionFacturableGet>(this.urlObtenerImporteServicioAdicional, concepto);
  }

  getConsultaCreditoAgencia(datos:ConsultaCreditoAgenciaPost):Observable<ConsultaCreditoAgenciaGet>{
    return this.http.post<ConsultaCreditoAgenciaGet>(this.urlConsultaCreditoAgencia, datos);
  }

  getValidarCB(cb:CodigoBarraPostValidacion):Observable<CodigoBarraGetValidacion>{
    return this.http.post<CodigoBarraGetValidacion>(this.urlValidarCB, cb);
  }

  getConfirmarCB(cb:CodigoBarraPostConfirmacion):Observable<CodigoBarraGetConfirmacion>{
    return this.http.post<CodigoBarraGetConfirmacion>(this.urlConfirmarCB, cb);
  }

  getConsultarRecargo(consulta:ConsultaRecargoPost):Observable<ConsultaRecargoGet>{
    return this.http.post<ConsultaRecargoGet>(this.urlConsultarTarifaRecargo, consulta);
  }

  getEmitir(datos:EmitirGuiaPost):Observable<EmitirGuiaGet>{
    return this.http.post<EmitirGuiaGet>(this.urlEmision, datos);
  }

  getGuardarPdf(datos:GuardadoPdfPost):Observable<GuardadoPostGet>{
    return this.http.post<GuardadoPostGet>(this.urlGuardadoPdf, datos);
  }

  getOrdenRetiro(nroOrden:OrdenRetiroPost):Observable<OrdenRetiroGet>{
    return this.http.post<OrdenRetiroGet>(this.urlBuscarOrdenRetiro, nroOrden);
  }

  getAutorizacionOcasional(datos:AutorizacionesPost):Observable<AutorizacionesGet>{
    return this.http.post<AutorizacionesGet>(this.urlAutorizaciones, datos);
  }

  getReimpresion(datos:BusquedaGuiaPost):Observable<ReimpresionGet>{
    return this.http.post<ReimpresionGet>(this.urlReimpresion, datos);
  }

  getSeguimientoGuias(datos:GuiaSeguimientoPost):Observable<GuiaSeguimientoGet>{
    return this.http.post<GuiaSeguimientoGet>(this.urlSeguimientoGuias, datos);
  }

  getSeguimientoGuiaFiltro(datos:GuiaSeguimientoFiltroPost):Observable<GuiaSegumientoFiltroGet>{
    return this.http.post<GuiaSegumientoFiltroGet>(this.urlSeguimientoGuiasFiltro, datos);
  }
  
  getImprimirEtiquetas(datos:ImpresionPicaPost):Observable<GuardadoPostGet>{
    return this.http.post<GuardadoPostGet>(this.urlImpresionPica, datos);
  }

  getEmisionMasiva(datos:EnvioCodigo):Observable<EnvioCodigoGet>{
    return this.http.post<EnvioCodigoGet>(this.urlEmisionMasiva, datos);
  }

  getValidarOperacion(usuarioID:number, codigoCliente: string):Observable<validarOperacionGet>{
    return this.http.get<validarOperacionGet>(this.urlValidarOperacion+"?usuarioID="+usuarioID+"&codigoCliente="+codigoCliente);
  }

  getEnviarEmail(usuarioID:number, codigoCliente: string, razonSocial: string):Observable<enviarEmailGet>{
    return this.http.get<enviarEmailGet>(this.urlEnviarEmail+"?usuarioID="+usuarioID+"&codigoCliente="+codigoCliente+"&razonSocial="+razonSocial);
  }

  getFinalizarOperacion(usuarioID:number, codigoCliente: string):Observable<Object>{
    return this.http.get(this.urlFinalizarOperacion+"?usuarioID="+usuarioID+"&codigoCliente="+codigoCliente);
  }

  getImprimirGuiasMasivas(usuarioID:number, codigoCliente: string):Observable<ImpresionMasiva>{
    return this.http.get<ImpresionMasiva>(this.urlImprimirGuiasMasivas+"?usuarioID="+usuarioID+"&codigoCliente="+codigoCliente)
  }

  getSeguimientoOrdenes(datos:SeguimientoOrdenPost):Observable<SeguimientoOrdenGet>{
    return this.http.post<SeguimientoOrdenGet>(this.urlSeguimientoOrdenes, datos);
  }

  //manejo de OR y opción envío
  agregarOR(or:OrdenRetiro){
    this.ordenRetiro=or;
    this.agregarOrdenRetiroSubject.next(or);
  }

  mandarAgregado(agregado:boolean){
    this.agregado=agregado;
    this.mandarAgregadoSubject.next(agregado);
  }

  agregarOpcionEnvio(opcionE:OpcionEnvio){
    this.opcionEnvio=opcionE;
    this.agregarOpcionEnvioSubject.next(opcionE);
  }
}