<div class="container contenedor ">
  <div class="header">
    <div class="row align-items-center rounded mt-4 divTitulo">
      <h4 class="titulo mt-4">&nbsp;&nbsp; 1° Características de envío</h4>
    </div><br>
  </div>
  <!-- <div class="alert alert-danger mt-3" role="alert" *ngIf="pocoCredito">
        {{consultaCreditoAgencia.Mensaje}}
    </div> -->
  <form #formEmision="ngForm" class="form">

    <div class="row mt-3 align-items-center formSeccionUno">
      <div class="col-4 mt-3">
        <div class="form-check">
          <input class="form-check-input" type="radio" [(ngModel)]="tipoCliente" name="opcionesCliente" id="ckEventual"
            value="eventual" style="width: 30px; height: 30px; vertical-align: middle;">
          <label class="form-check-label rounded" for="exampleRadios1"
            style="margin-left: 15px;width: 130px; height: 40px; text-align: center; vertical-align: middle; color: white;">
            <p class="formSeccionUnoP" style="margin-top: 8px;">Eventual</p>
          </label>
        </div>
      </div>
      <div class="col-4 mt-3">
        <div class="form-check">
          <input class="form-control" [(ngModel)]="codCliente" id="codCorpo" name="cliCorpo" type="text"
            style="width: 80px; display: initial;" (keydown.tab)="cargarCorporativoRemitente($event)" maxlength="5"
            (keydown.enter)="cargarCorporativoRemitente($event);$event.preventDefault()">
          <button class="btn btn-success"
            style="background-color: #004993; border: 1px solid #004993; margin-top: -5px;" type="button"
            (click)="abrirModalClientes('remitente')">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search"
              viewBox="0 0 16 16">
              <path
                d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
            </svg>
          </button>
          <label class="form-check-label rounded"
            style="margin-left: 15px;width: 130px; height: 40px; text-align: center; vertical-align: middle; color: white;">
            <p class="formSeccionUnoP" style="margin-top: 8px;">Corporativo</p>
          </label>
        </div>
        <!-- <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">Código Barra</span>
                  </div>
                  <input type="text" class="form-control" [(ngModel)]="cb" name="codigoB" #codigoB="ngModel" maxlength="11">
                  <div class="input-group-append">
                    <button class="btn btn-success" style="background-color: #004993; border: 1px solid #004993;" type="button" id="button-addon2"
                      (click)="ingresarCB()">Agregar</button>
                  </div>
                </div> -->
      </div>
      <div class="col-4 mt-3">
        <div class="form-check">
          <input class="form-control" [(ngModel)]="nroOrdenRetiro" name="ordenR" id="ordenR" type="text"
            style="width: 80px; display: initial;" (blur)="cargarOrdenRetiro($event)">
          <label class="form-check-label rounded"
            style="margin-left: 10px;width: 210px; height: 40px; text-align: center; vertical-align: middle; color: white;">
            <p class="formSeccionUnoP" style="margin-top: 8px;">Cliente web/Orden retiro</p>
          </label>
        </div>
      </div>
    </div>

    <div class="row mt-3 mb-4 align-items-center formSeccionDos">
      <div class="col-4 mt-3" *ngIf="mostrarCtaCte">
        <div class="form-check">
          <input class="form-check-input" type="radio" [(ngModel)]="tipoPago" name="tipoPago" id="cta" value="2"
            style="width: 30px; height: 30px; vertical-align: middle;">
          <label class="form-check-label rounded" for="exampleRadios2"
            style="margin-left: 15px;width: 130px; height: 40px; text-align: center; vertical-align: middle; color: white;">
            <p class="formSeccionUnoP" style="margin-top: 8px; font-size: 16px;">Cta/Cte</p>
          </label>
        </div>
      </div>
      <div class="col-4 mt-3" *ngIf="mostrarContado">
        <div class="form-check">
          <input class="form-check-input" type="radio" [(ngModel)]="tipoPago" name="tipoPago" id="contado" value="1"
            style="width: 30px; height: 30px; vertical-align: middle;">
          <label class="form-check-label rounded" for="exampleRadios2"
            style="margin-left: 15px;width: 130px; height: 40px; text-align: center; vertical-align: middle; color: white;">
            <p class="formSeccionUnoP" style="margin-top: 8px; font-size: 16px;">Pago en origen (Contado)</p>
          </label>
        </div>
      </div>
      <div class="col-4 mt-3"
        [hidden]="clienteRemitente.ClienteID!=undefined && clienteRemitente.tienePagoDestino==false">
        <div class="form-check">
          <input class="form-check-input" type="radio" [(ngModel)]="tipoPago" name="tipoPago" id="pagoDestino" value="6"
            style="width: 30px; height: 30px; vertical-align: middle;">
          <!-- (change)="tipoPagoSeleccionado($event)" -->
          <label class="form-check-label rounded" for="exampleRadios2"
            style="margin-left: 15px;width: 130px; height: 40px; text-align: center; vertical-align: middle; color: white;">
            <p class="formSeccionUnoP" style="margin-top: 8px; font-size: 16px;">Pago en destino</p>
          </label>
        </div>
      </div>
      <div class="col-4 mt-3">
        <div class="form-check">
          <input class="form-control" [(ngModel)]="codAutorizacion" id="codAuto" name="codAuto" type="text"
            style="width: 150px; display: initial;" maxlength="20" (keydown.tab)="buscarAutorizacionByCod()">
          <button class="btn btn-success"
            style="background-color: #004993; border: 1px solid #004993; margin-top: -5px;" type="button"
            (click)="abrirModalAutorizaciones()" id="btnAuto">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search"
              viewBox="0 0 16 16">
              <path
                d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
            </svg>
          </button>
          <label class="form-check-label rounded"
            style="margin-left: 15px;width: 130px; height: 40px; text-align: center; vertical-align: middle; color: white;">
            <p class="formSeccionUnoP" style="margin-top: 8px;">Autorización</p>
          </label>
          <div *ngIf="cargandoAutorizacion" class="mt-2">
            <h6>Cargando...</h6>
          </div>
        </div>
      </div>
      <!-- <div class="col-4 mt-3 columnaBoton">
        <button class="btn btn-primary botonLimpiarPrimero" (click)="limpiarPaso1()">Limpiar</button>
      </div> -->
    </div>
    <div class="row mb-4 align-items-center justify-content-end formSeccionDos">
      <div class="col-4 mt-3 columnaBoton">
        <button class="btn btn-primary botonLimpiarPrimero" (click)="limpiarPaso1()">Limpiar</button>
      </div>
    </div>

    <div class="row mt-5 centrar" style="margin-left: 30px;" *ngIf="esCorpo">
      <label style="margin-left: 25px;">CLIENTE: &nbsp;</label>
      <span style="font-size: 30; font-weight: bold;">{{clienteRemitente.RazonSocial}}</span>
    </div>

    <div class="row mt-5 centrar">
      <div class="col-8">
        <div class="form-group">
          <!-- <label>Localidad Origen</label> -->
          <input placeholder="*Localidad de origen" class="form-control" required [(ngModel)]="localidadOrigen"
            name="locOrig" id="locOrig" list="localidadesOrigen" type="text" #locOrig="ngModel"
            (input)="localidadIngresada('origen', $event)" (change)="ocultarCotizacion()" autocomplete="off"
            maxlength="100">
          <datalist id="localidadesOrigen">
            <option *ngFor="let localidad of localidadesOrigen" [id]="localidad.localidadid" style="width: 200px;">
              <span *ngIf="localidad.localidadid!=1345; else otro">{{localidad.LocalidadDescrip}},
                {{localidad.ProvinciaDescrip}} ({{localidad.CodigoPostal}})</span>
              <ng-template #otro>{{localidad.LocalidadDescrip}}, {{localidad.ProvinciaDescrip}}</ng-template>
            </option>
          </datalist>
          <div [hidden]="locOrig.valid" class="alert alert-danger mt-1"
            style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <!-- <label>CP Origen</label> -->
          <input placeholder="CP" class="form-control" id="cpOrig" [(ngModel)]="cpOrigen" required name="cpOrig"
            #cpOrig="ngModel" type="number" maxlength="4">
          <div [hidden]="cpOrig.valid" class="alert alert-danger mt-1"
            style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido
          </div>
        </div>
      </div>

      <!-- <div class="col-4" *ngIf="mostrarCalleOrigen">
        <div class="form-group">
          <input placeholder="*Calle Origen" class="form-control" list="calles" required [(ngModel)]="calleOrigen"
            name="calleOrig" id="calleOrig" #calleOrig="ngModel" type="text"
            (input)="calleIngresada('origen', $event)" (change)="botonContinuar=true" autocomplete="off" maxlength="50">
          <datalist id="calles">
            <option *ngFor="let calle of callesOrigen" [id]=calle.localidadid>{{calle.CalleDescrip}},
              {{calle.AlturaDesde}}-{{calle.AlturaHasta}} ({{calle.CodigoPostal}})</option>
          </datalist>
          <div [hidden]="calleOrigen!=''" class="alert alert-danger mt-1"
            style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido
          </div>
        </div>
      </div> -->
    </div>
    <div class="row centrar">
      <div class="col-8">
        <div class="form-group">
          <!-- <label>Localidad Destino</label> -->
          <input placeholder="*Localidad de destino" class="form-control" list="localidadesDestino" required
            [(ngModel)]="localidadDestino" name="locDest" id="locDest" #locDest="ngModel" type="text"
            (input)="localidadIngresada('destino', $event)" (change)="ocultarCotizacion()" autocomplete="off"
            maxlength="100">
          <datalist id="localidadesDestino">
            <option *ngFor="let localidad of localidadesDestino" [id]="localidad.localidadid">
              <span *ngIf="localidad.localidadid!=1345; else otro">{{localidad.LocalidadDescrip}},
                {{localidad.ProvinciaDescrip}} ({{localidad.CodigoPostal}})</span>
              <ng-template #otro>{{localidad.LocalidadDescrip}}, {{localidad.ProvinciaDescrip}}</ng-template>
            </option>
          </datalist>
          <div [hidden]="locDest.valid" class="alert alert-danger mt-1"
            style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <!-- <label>CP</label> -->
          <input placeholder="CP" class="form-control" id="cpDest" required [(ngModel)]="cpDestino" name="cpDest"
            #cpDest="ngModel" type="number" maxlength="4">
          <div [hidden]="cpDest.valid" class="alert alert-danger mt-1"
            style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido
          </div>
        </div>
      </div>
      <!-- <div class="col-4" *ngIf="mostrarCalleDestino">
        <div class="form-group">
          <input placeholder="*Calle Destino" class="form-control" list="calles" required [(ngModel)]="calleDestino" name="calleDest" id="calleDest"
            #calleDest="ngModel" type="text" (input)="calleIngresada('destino', $event)" (change)="botonContinuar=true"
            autocomplete="off" maxlength="50">
          <datalist id="calles">
            <option *ngFor="let calle of callesDestino" [id]="calle.localidadid">{{calle.CalleDescrip}},
              {{calle.AlturaDesde}}-{{calle.AlturaHasta}} ({{calle.CodigoPostal}})</option>
          </datalist>
          <div [hidden]="calleDestino!=''" class="alert alert-danger"
            style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido
          </div>
        </div>
      </div> -->
    </div>
    <div class="row centrar">
      <div class="col-3">
        <div class="form-group">
          <!-- <label></label> -->
          <input placeholder="Cant. Bultos" class="form-control" required [(ngModel)]="cantBultos" id="cantBul"
            name="cantBul" #cantBul="ngModel" type="tel" maxlength="6"
            onkeydown="javascript: return event.keyCode === 8 || event.keyCode===9 ? true : !isNaN(Number(event.key))"
            (change)="ocultarCotizacion()"> <!-- 190 tecla . en teclado 110 en teclado numerico -->
          <div [hidden]="cantBul.valid" class="alert alert-danger mt-1"
            style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido
          </div>
          <div class="mt-3">
            <input type="checkbox" name="ckCBUnico" id="ckCBUnico" [(ngModel)]="imprimirUnCB" #ckCBUnico="ngModel"
              tabindex="-1">&nbsp;
            <label>Imprimir un solo CB</label>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <!-- <label>Peso</label> -->
          <input placeholder="Peso total" class="form-control" required [(ngModel)]="peso" name="pesoBul"
            #pesoBul="ngModel" type="tel" (blur)="buscarProductos()" onkeydown="javascript: return event.keyCode === 8 ||
            event.keyCode === 190 || event.keyCode === 110 || event.keyCode===9 ? true : !isNaN(Number(event.key))"
            maxlength="6" (change)="ocultarCotizacion()">
          <div [hidden]="peso>0 || pesoM3>0" class="alert alert-danger mt-1"
            style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Ingrese Peso o M3
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <!-- <label>M3</label> -->
          <input placeholder="M3" class="form-control" required [(ngModel)]="pesoM3" name="pesom3" #pesom3="ngModel"
            type="tel" onkeydown="javascript: return event.keyCode === 8 ||
            event.keyCode === 190 || event.keyCode === 110 || event.keyCode===9 ? true : !isNaN(Number(event.key))"
            maxlength="8" (change)="ocultarCotizacion()">
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <!-- <label>Valor Declarado</label> -->
          <input placeholder="Valor Declarado Total" class="form-control" required [(ngModel)]="valorDeclarado"
            name="valorDec" #valorDec="ngModel" id="valorDec" type="tel" onkeydown="javascript: return event.keyCode === 8 ||
            event.keyCode === 190 || event.keyCode === 110 || event.keyCode===9 ? true : !isNaN(Number(event.key))"
            maxlength="8" (change)="ocultarCotizacion()">
          <div [hidden]="valorDec.valid" class="alert alert-danger mt-1"
            style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido
          </div>
        </div>
      </div>
    </div>


    <!-- ???  DIMENSIONES DE PRODUCTO-->
    <div class="row centrar" *ngIf="peso>10">
      <div class="col-4">
        <div class="form-group">
          <!-- <label>Alto</label> -->
          <input placeholder="Alto" class="form-control" required [(ngModel)]="alto" name="altoBul" #altoBul="ngModel"
            type="tel" onkeydown="javascript: return event.keyCode === 8 ||
        event.keyCode === 190 || event.keyCode === 110 || event.keyCode===9 ? true : !isNaN(Number(event.key))"
            maxlength="6" (change)="ocultarCotizacion()">
          <!-- <div [hidden]="altoBul.valid" class="alert alert-danger mt-1"
        style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido
      </div> -->
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <!-- <label>Ancho</label> -->
          <input placeholder="Ancho" class="form-control" required [(ngModel)]="ancho" name="anchoBul"
            #anchoBul="ngModel" type="tel" onkeydown="javascript: return event.keyCode === 8 ||
        event.keyCode === 190 || event.keyCode === 110 || event.keyCode===9 ? true : !isNaN(Number(event.key))"
            maxlength="6" (change)="ocultarCotizacion()">
          <!-- <div [hidden]="anchoBul.valid" class="alert alert-danger mt-1"
        style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido
      </div> -->
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <!-- <label>Largo</label> -->
          <input placeholder="Largo" class="form-control" required [(ngModel)]="largo" name="largoBul"
            #largoBul="ngModel" type="tel" onkeydown="javascript: return event.keyCode === 8 ||
        event.keyCode === 190 || event.keyCode === 110 || event.keyCode===9 ? true : !isNaN(Number(event.key))"
            maxlength="6" (change)="ocultarCotizacion()">
          <!-- <div [hidden]="largoBul.valid" class="alert alert-danger mt-1"
        style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido
      </div> -->
        </div>
      </div>
    </div>

    <!-- ORDEN PLANILLA, REMITO, PRECINTO -->
    <div class="row centrar">
      <!-- style="background: #eaf4f7; width: 100%; padding: 0px 3%;" [hidden]="opcionesEnvioApi.length==0" -->
      <div class="col-4">
        <div class="form-group">
          <!-- <label>Orden/Planilla Retiro</label> -->
          <input placeholder="Orden/Planilla Retiro" class="form-control" [(ngModel)]="ordenPlanillaRetiro"
            name="ordenPlaRet" type="text" maxlength="20"> <!-- style="background: #eaf4f7" -->
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <!-- <label>Remito Cliente</label> -->
          <input placeholder="Remito Cliente" class="form-control" [(ngModel)]="remitoCliente" name="remCli"
            #remCli="ngModel" type="text" maxlength="30" id="remito">
          <!-- style="background: #eaf4f7" (focus)="alertRemito=false"-->
          <!-- <div [hidden]="remCli.valid && !alertRemito" class="alert alert-danger mt-1"
        style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido
      </div> -->
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <!-- <label>Precinto de Seguridad</label> -->
          <input placeholder="Precinto de Seguridad" class="form-control" [(ngModel)]="precintoSeguridad" name="preSeg"
            type="text" maxlength="30"> <!-- style="background: #eaf4f7" -->
        </div>
      </div>
    </div>

    <div *ngIf="cargandoProductos">
      <span class="spinner-border spinner-border-lg"  style="width: 3rem; height: 3rem;" role="status" aria-hidden="true"></span>
    </div>

    <!-- PRODUCTOS  -->
    <div class="row centrar" *ngIf="mostrarEmbalaje">
      <div class="col-12">
        <h5 class="tituloProductos">Productos:</h5>
        <div class="form-group listaProductos">
          <div class="row" *ngFor="let producto of productos" style="margin-left: 0px; margin-right: 0px;">
            <div class="col contenedorElementos">
              <div style="display: flex; flex-direction: column;">
                <img [src]="'assets/'+producto.ProductoImg" style="max-width: 97px; margin: auto; max-height: 69.48px;">
                <label class="form-check-label"
                  style="margin: auto; color: #495057">{{producto.ProductoDescrip}}</label>
              </div>
              <div style="height: 40px;">
                <input class="form-check-input" type="checkbox" name="prod" [value]="producto.UnidadVentaID"
                  style="width: 30px; height: 30px; margin-left: 0px; position: relative;" [id]="producto.UnidadVentaID"
                  (change)="seleccionarProductos($event, producto); ocultarCotizacion()">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- CODIGO PROMOCIONAL -->
    <div class="row mt-3 align-items-center formSeccionCodigoPromocional centrar"
      [hidden]="codAutorizacion!='' && codAutorizacion!=null">
      <div class="col-4 mt-3">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" (change)="ingresarCodigoPromocional($event)"
            style="width: 30px; height: 30px; vertical-align: middle;" id="codProm"
            maxlength="30"><!--  botonContinuar=true -->
          <label class="form-check-label rounded" for="exampleRadios1"
            style="margin-left: 15px;width: 260px; height: 40px; text-align: center; vertical-align: middle;">
            <p class="codigoPromocionalP">Código Promocional</p>
          </label>
        </div>
        <div class="form-group" *ngIf="mostrarCodigo">
          <!-- <label>Código Promocional</label> -->
          <input placeholder="Código Promocional" class="form-control" type="text" [(ngModel)]="codigoPromocional"
            name="codPromo" style="margin-bottom: 38px; border: 1px solid #004993 !important;">
          <!-- (change)="botonContinuar=true" -->
        </div>
      </div>
      <div class="col-8 mt-3">
        <div>
          <input class="form-control" placeholder="Bonificacion %" [(ngModel)]="porcentajeBonificacion"
            [disabled]="tipoCliente=='corporativo'" type="number" name="bonificacion"
            style="width: 100%; height: calc(1.5em + 0.75rem + 2px); border: 1px solid #004993 !important;" onkeydown="javascript: return event.keyCode === 8 ||
        event.keyCode === 190 || event.keyCode === 110 || event.keyCode===9 ? true : !isNaN(Number(event.key))"
            maxlength="5"><!-- (change)="botonContinuar=true" -->
          <div [hidden]="tipoCliente!='corporativo'" class="alert alert-light mt-1"
            style="width: 500px; height: 20px; font-size: 16px; padding: 0rem 1.30rem;">No se puede agregar bonificación
            a un cliente corporativo</div>
        </div>
      </div>
    </div>

    <!-- CODIGO PROMOCIONA ESTILO VIEJO -->

    <!-- <div class="col-4">
    <div class="form-group" style="margin-top: 20px; margin-left: 30px;">
      <input class="form-check-input" type="checkbox" (change)="ingresarCodigoPromocional($event)"> ¿Tenés
      código promocional?
    </div>
    <div class="form-group" *ngIf="mostrarCodigo">
      <label>Código Promocional</label>
      <input class="form-control" type="text" [(ngModel)]="codigoPromocional" name="codPromo">
    </div>
  </div>
  <div class="col-4">
    <div class="form-group" style="margin-top: 20px;">
      <label>Bonificacion (%)</label>
      <input class="form-control" type="text" [(ngModel)]="porcentajeBonificacion" name="porcentajeBon">
    </div>
  </div> -->



    <br>
    <br>

    <!-- MODALIDADES -->
    <div class="row  modalidad">
    <div class="row  modalidad">
      <div class="mt-1 text-center rounded mt-2 mb-2">
        <h3 class="mt-1 text-center rounded ">Modalidad</h3>
      </div>

      <div class="modalidades centrar">
        <div>
          <input type="checkbox" name="todas" id="todas" value="0" class="visible"
            (change)="modalidadSeleccionada($event, 0)">
          <label for="todas">Todas</label>
        </div>
        <div>
          <input type="checkbox" [(ngModel)]="modalidadTT" name="TT" id="TT" value="1" class="visible"
            (change)="modalidadSeleccionada($event, 1)">
          <label for="TT">Sucursal - Sucursal</label>
        </div>
        <div>
          <input type="checkbox" [(ngModel)]="modalidadPP" name="PP" id="PP" value="2" class="visible"
            (change)="modalidadSeleccionada($event, 2)">
          <label for="PP">Puerta-Puerta</label>
        </div>
        <div>
          <input type="checkbox" [(ngModel)]="modalidadTP" name="TP" id="TP" value="3" class="visible"
            (change)="modalidadSeleccionada($event, 3)">
          <label for="TP">Sucursal - Puerta</label>
        </div>
        <div>
          <input type="checkbox" [(ngModel)]="modalidadPT" name="PT" id="PT" value="4" class="visible"
            (change)="modalidadSeleccionada($event, 4)">
          <label for="PT">Puerta - Sucursal</label>
        </div>
      </div>

    </div>
    <!-- SERVICIOS ADICIONALES => BOTON -->
    <div class="botones">
      <div class="row justify-content-center">
        <div class="col-6">
          <button class="btn btn-success botonServiciosAdicionales" (click)="agregarServicios()" *ngIf="botonServicios">
            <span>Servicios Adicionales</span>
            
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group" style="margin: 0px;">

            <!-- <h5>Servicios Adicionales</h5> -->
      
            
            <table class="table table-bordered" id="serviciosAdicionales" *ngIf="tablaServicios">
              <thead>
                <tr>
                  <th style="width: 600px;">Concepto</th>
                  <th style="width: 200px; text-align: center;">Precinto</th>
                  <th style="width: 150px; text-align: center;">Hs/Días</th>
                  <th style="width: 200px; text-align: center;">Código Barra</th>
                  <th style="width: 150px; text-align: center;">Producto</th>
                  <th style="width: 150px; text-align: center;">Importe</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let fila of conceptosTabla">
                  <td>
                    {{fila.Descripcion}}
                  </td>
                  <td>
                    {{fila.Precinto}}
                  </td>
                  <td>
                    {{fila.HsDias}}
                  </td>
                  <td>
                    {{fila.CodigoBarras}}
                  </td>
                  <td>{{fila.Producto}}</td>
                  <td>${{fila.ImporteTotal}}</td>
                  <td>
                    <button class="btn btn-info" title="Editar" (click)="editarServicio(fila)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-pencil-square" viewBox="0 0 16 16">
                        <path
                          d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                        <path fill-rule="evenodd"
                          d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                      </svg>
                    </button>
                  </td>
                  <td>
                    <button class="btn btn-danger" title="Eliminar" (click)="eliminarServicio(fila)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-trash" viewBox="0 0 16 16">
                        <path
                          d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                        <path fill-rule="evenodd"
                          d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                      </svg>
                    </button>
                  </td>
                </tr>
                <tr *ngIf="nuevoServicio">
                  <td>
                    <select class="form-control" [(ngModel)]="nuevoServicioAdicional.ConceptoFacturacionID"
                      name="conceptoFac" (change)="conceptoSeleccionado($event)">
                      <option value=0 selected disabled>Elija un concepto de facturación</option>
                      <option *ngFor="let concepto of conceptosSelect" [value]="concepto.ConceptoFacturacionID">
                        {{concepto.Descripcion}}</option>
                    </select>
                  </td>
                  <td>
                    <input class="form-control" type="text" [(ngModel)]="nuevoServicioAdicional.NroPrecinto"
                      name="precinto" id="precinto" [disabled]="!precintoObligatorio"
                      (input)="validarCampo('precinto',$event)">
                    <!-- <div [hidden]="nuevoServicioAdicional.NroPrecinto!=null && nuevoServicioAdicional.NroPrecinto!='' && precintoObligatorio" class="alert alert-danger mt-1"
                  style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">
                  *Campo requerido</div> -->
                  </td>
                  <td>
                    <input class="form-control" type="text" [(ngModel)]="nuevoServicioAdicional.Cantidad" name="tiempo"
                      id="tiempo" [disabled]="!hsDiasObligatorio" (input)="validarCampo('hsDias',$event)">
                    <div [hidden]="hsDiasValid" class="alert alert-danger mt-1"
                      style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">
                      *Campo requerido</div>
                  </td>
                  <td>
                    <input class="form-control" type="text" [(ngModel)]="nuevoServicioAdicional.CodigoBarras"
                      name="codigoB" id="codigoB" (input)="validarCampo('cb',$event)" [disabled]="!cbObligatorio">
                    <div [hidden]="cbValid" class="alert alert-danger mt-1"
                      style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">
                      *Campo requerido</div>
                  </td>
                  <td>
                    <select class="form-control" [(ngModel)]="nuevoServicioAdicional.UnidadVentaID" name="conceptoFac">
                      <option value='0' selected disabled>Seleccione producto</option>
                      <option *ngFor="let producto of productosSeleccionados" [value]="producto.UnidadVentaID">
                        {{producto.ProductoDescrip}}</option>
                    </select>
                  </td>
                  <td>
                    <input class="form-control" type="text" [(ngModel)]="nuevoServicioAdicional.Importe" name="importe"
                      id="importe" disabled>
                  </td>
                  <td style="text-align: center;">
                    <button class="btn btn-info" title="Agregar" (click)="agregarFila()">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                        class="bi bi-check2" viewBox="0 0 16 16">
                        <path
                          d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                      </svg>
                    </button>
                  </td>
                  <td style="text-align: center;">
                    <button class="btn btn-info" title="Cerrar" (click)="cerrarFila()">
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-x"
                        viewBox="0 0 16 16">
                        <path
                          d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                      </svg>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    

    <!-- OPCIONES DE ENVIO -->
    <div id="cotizacion">
      <div class="opcionesEnvio" *ngIf="mostrarOpcionEnvio">

        <!-- opciones disponibles de envío -->
        <div class="row justify-content-between">
          <div class="col-4">
            <h5 style="margin-left: 30px; margin-top: 15px;">Opciones de Envíos</h5>
          </div>
          <div class="col-4">
            <button class="btn btn-light" style="padding: 10px; margin-top: 30px; margin-left: 250px;"
              (click)="imprimirCotizacion()">
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-printer"
                viewBox="0 0 16 16">
                <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                <path
                  d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
              </svg>
            </button>
          </div>
        </div>
        <table class="table table-borderless">
          <thead>
            <tr>
              <th scope="col" style="text-align: center;">Modalidad</th>
              <th scope="col" style="text-align: center;" *ngFor="let opcion of encabezadoTablaOpciones">
                {{opcion.descripcion}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="filasTablaOpcionesTT.length>0">
              <th scope="row" style="text-align: center; vertical-align: center;">TERMINAL-TERMINAL</th>
              <td *ngFor="let fila of filasTablaOpcionesTT" style="text-align: center;">
                <div class="row justify-content-center"
                  [hidden]="fila.precioTotal==0 && ((fila.opcion!=null && fila.opcion.calculoImporteGuia==null)|| fila.opcion==null)">
                  <div class="col"
                    *ngIf="fila.opcion!=null && fila.opcion.ImporteBonificacion!=null && fila.opcion.ImporteBonificacion!=0">
                    <span style="color:blue"><i>PRECIO NORMAL</i></span><br>
                    <!-- <span *ngIf="fila.opcion.PorcentajeBonificacion==0">${{fila.precioNormal}}</span> -->
                    <span style="text-decoration: line-through;">${{fila.precioNormal}}</span><br>
                    <span
                      *ngIf="fila.opcion!=null && fila.opcion.CodigoDescuento!=null; else otro">{{fila.opcion.PorcentajeBonificacion}}%
                      DE DESCUENTO<br>CODIGO:&nbsp;{{fila.opcion.CodigoDescuento |uppercase}}</span>
                    <ng-template #otro><span>BONIFICACIÓN AGENCIA</span></ng-template>
                  </div>
                  <div class="col">
                    <input type="radio" name="opcionesEnvio" [disabled]="fila.precioTotal==0"
                      [checked]="opcionesEnvioFiltro.length==1" [id]="fila.UnidadVentaID+','+fila.ModalidadEntregaID"
                      (change)="seleccionarEnvio(fila.opcion)">&nbsp;
                    <span style="color:blue"><i>PRECIO TOTAL</i></span><br>
                    <span>${{fila.precioTotal}}</span><br><span
                      [hidden]="fila.opcion!=null && fila.opcion.calculoImporteGuia!=null && fila.opcion.calculoImporteGuia.ImporteServicioAdicional==0">(servicio
                      adicional incluido)</span>
                  </div>
                </div>
                <div class="row justify-content-center"
                  *ngIf="fila.precioTotal==0 && ((fila.opcion!=null && fila.opcion.calculoImporteGuia==null)|| fila.opcion==null)">
                  <div class="alert alert-info">Las cotizaciones que no se muestran es porque no se puede <br> realizar
                    el envío a la localidad ingresada con esa modalidad</div>
                </div>
                <!-- <div *ngIf="fila.opcion.ImporteBonificacion==null || fila.opcion.ImporteBonificacion==0">
              <input type="radio" name="opcionesEnvio"
                  [disabled]="fila.precioTotal==0"
                  [id]="fila.UnidadVentaID+','+fila.ModalidadEntregaID"
                  (change)="seleccionarEnvio($event, fila.opcion)">&nbsp;
              <span style="color:blue"><i>PRECIO TOTAL</i></span><br>
              <span>${{fila.precioTotal}}</span>
            </div> -->
                <!-- <div *ngIf="fila.precioNormal!=undefined && fila.precioNormal>0">
                  <span style="color:blue"><i>PRECIO NORMAL</i></span><br>
                  <span *ngIf="fila.opcion.PorcentajeBonificacion==0">${{fila.precioNormal}}</span>
                  <div *ngIf="fila.opcion.ImporteBonificacion!=null && fila.opcion.ImporteBonificacion!=0">
                    <span style="text-decoration: line-through;" >${{fila.precioNormal}}</span><br>
                    <span>{{fila.opcion.PorcentajeBonificacion}}%</span>
                    <span *ngIf="fila.opcion.ImporteNormal>fila.opcion.Importe"> DE DESCUENTO</span>
                    <span *ngIf="fila.opcion.ImporteNormal<fila.opcion.Importe"> DE INCREMENTO</span>
                    <span> CODIGO:&nbsp;{{fila.opcion.CodigoDescuento |uppercase}}</span>
                  </div>
              </div>
              <div *ngIf="fila.precioTotal!=undefined && fila.precioTotal>0">
                  <input type="radio" name="opcionesEnvio"
                  [disabled]="fila.precioTotal==0"
                  [id]="fila.UnidadVentaID+','+fila.ModalidadEntregaID"
                  (change)="seleccionarEnvio($event, fila.opcion)">&nbsp;
                  <span style="color:blue"><i>PRECIO TOTAL</i></span><br>
                  <span>${{fila.precioTotal}}</span>
              </div> -->
              </td>
            </tr>
            <tr *ngIf="filasTablaOpcionesTP.length>0">
              <th scope="row" style="text-align: center; vertical-align: center;">TERMINAL-PUERTA</th>
              <td *ngFor="let fila of filasTablaOpcionesTP" style="text-align: center;">
                <div class="row justify-content-center"
                  [hidden]="fila.precioTotal==0 && ((fila.opcion!=null && fila.opcion.calculoImporteGuia==null)|| fila.opcion==null)">
                  <div class="col"
                    *ngIf="fila.opcion!=null && fila.opcion.ImporteBonificacion!=null && fila.opcion.ImporteBonificacion!=0">
                    <span style="color:blue"><i>PRECIO NORMAL</i></span><br>
                    <!-- <span *ngIf="fila.opcion.PorcentajeBonificacion==0">${{fila.precioNormal}}</span> -->
                    <span style="text-decoration: line-through;">${{fila.precioNormal}}</span><br>
                    <span
                      *ngIf="fila.opcion!=null && fila.opcion.CodigoDescuento!=null; else otro">{{fila.opcion.PorcentajeBonificacion}}%
                      DE DESCUENTO<br>CODIGO:&nbsp;{{fila.opcion.CodigoDescuento |uppercase}}</span>
                    <ng-template #otro><span>BONIFICACIÓN AGENCIA</span></ng-template>
                  </div>
                  <div class="col">
                    <input type="radio" name="opcionesEnvio" [disabled]="fila.precioTotal==0"
                      [checked]="opcionesEnvioFiltro.length==1" [id]="fila.UnidadVentaID+','+fila.ModalidadEntregaID"
                      (change)="seleccionarEnvio(fila.opcion)">&nbsp;
                    <span style="color:blue"><i>PRECIO TOTAL</i></span><br>
                    <span>${{fila.precioTotal}}</span><br><span
                      [hidden]="fila.opcion!=null && fila.opcion.calculoImporteGuia!=null && fila.opcion.calculoImporteGuia.ImporteServicioAdicional==0">(servicio
                      adicional incluido)</span>
                  </div>
                </div>
                <div class="row justify-content-center"
                  *ngIf="fila.precioTotal==0 && ((fila.opcion!=null && fila.opcion.calculoImporteGuia==null)|| fila.opcion==null)">
                  <div class="alert alert-info">Las cotizaciones que no se muestran es porque no se puede <br> realizar
                    el envío a la localidad ingresada con esa modalidad</div>
                </div>
                <!--  <div *ngIf="fila.opcion.ImporteBonificacion==null || fila.opcion.ImporteBonificacion==0">
              <input type="radio" name="opcionesEnvio"
                  [disabled]="fila.precioTotal==0"
                  [id]="fila.UnidadVentaID+','+fila.ModalidadEntregaID"
                  (change)="seleccionarEnvio($event, fila.opcion)">&nbsp;
              <span style="color:blue"><i>PRECIO TOTAL</i></span><br>
              <span>${{fila.precioTotal}}</span>
            </div> -->
                <!-- <div *ngIf="fila.precioNormal!=undefined">
                  <span style="color:blue"><i>PRECIO NORMAL</i></span><br>
                  <span *ngIf="fila.opcion.PorcentajeBonificacion==0">${{fila.precioNormal}}</span>
                  <div *ngIf="fila.opcion.ImporteBonificacion!=null && fila.opcion.ImporteBonificacion!=0">
                    <span style="text-decoration: line-through;" >${{fila.precioNormal}}</span><br>
                    <span>{{fila.opcion.PorcentajeBonificacion}}%</span>
                    <span *ngIf="fila.opcion.ImporteNormal>fila.opcion.Importe"> DE DESCUENTO</span>
                    <span *ngIf="fila.opcion.ImporteNormal<fila.opcion.Importe"> DE INCREMENTO</span>
                    <span> CODIGO:&nbsp;{{fila.opcion.CodigoDescuento |uppercase}}</span>
                  </div>
              </div>
              <div *ngIf="fila.precioTotal!=undefined">
                  <input type="radio" name="opcionesEnvio"
                  [disabled]="fila.precioTotal==0"
                  [id]="fila.UnidadVentaID+','+fila.ModalidadEntregaID"
                  (change)="seleccionarEnvio($event, fila.opcion)">&nbsp;
                  <span style="color:blue"><i>PRECIO TOTAL</i></span><br>
                  <span >${{fila.precioTotal}}</span><br>

              </div> -->
              </td>
            </tr>
            <tr *ngIf="filasTablaOpcionesPT.length>0">
              <th scope="row" style="text-align: center; vertical-align: center;">PUERTA-TERMINAL</th>
              <td *ngFor="let fila of filasTablaOpcionesPT" style="text-align: center;">
                <div class="row justify-content-center"
                  [hidden]="fila.precioTotal==0 && ((fila.opcion!=null && fila.opcion.calculoImporteGuia==null)|| fila.opcion==null)">
                  <div class="col"
                    *ngIf="fila.opcion!=null && fila.opcion.ImporteBonificacion!=null && fila.opcion.ImporteBonificacion!=0">
                    <span style="color:blue"><i>PRECIO NORMAL</i></span><br>
                    <!-- <span *ngIf="fila.opcion.PorcentajeBonificacion==0">${{fila.precioNormal}}</span> -->
                    <span style="text-decoration: line-through;">${{fila.precioNormal}}</span><br>
                    <span
                      *ngIf="fila.opcion!=null && fila.opcion.CodigoDescuento!=null; else otro">{{fila.opcion.PorcentajeBonificacion}}%
                      DE DESCUENTO<br>CODIGO:&nbsp;{{fila.opcion.CodigoDescuento |uppercase}}</span>
                    <ng-template #otro><span>BONIFICACIÓN AGENCIA</span></ng-template>
                  </div>
                  <div class="col">
                    <input type="radio" name="opcionesEnvio" [disabled]="fila.precioTotal==0"
                      [checked]="opcionesEnvioFiltro.length==1" [id]="fila.UnidadVentaID+','+fila.ModalidadEntregaID"
                      (change)="seleccionarEnvio(fila.opcion)">&nbsp;
                    <span style="color:blue"><i>PRECIO TOTAL</i></span><br>
                    <span>${{fila.precioTotal}}</span><br><span
                      [hidden]="fila.opcion!=null && fila.opcion.calculoImporteGuia!=null && fila.opcion.calculoImporteGuia.ImporteServicioAdicional==0">(servicio
                      adicional incluido)</span>
                  </div>
                </div>
                <div class="row justify-content-center"
                  *ngIf="fila.precioTotal==0 && ((fila.opcion!=null && fila.opcion.calculoImporteGuia==null)|| fila.opcion==null)">
                  <div class="alert alert-info">Las cotizaciones que no se muestran es porque no se puede <br> realizar
                    el envío a la localidad ingresada con esa modalidad</div>
                </div>
                <!-- <div *ngIf="fila.opcion.ImporteBonificacion==null || fila.opcion.ImporteBonificacion==0">
              <input type="radio" name="opcionesEnvio"
                  [disabled]="fila.precioTotal==0"
                  [id]="fila.UnidadVentaID+','+fila.ModalidadEntregaID"
                  (change)="seleccionarEnvio($event, fila.opcion)">&nbsp;
              <span style="color:blue"><i>PRECIO TOTAL</i></span><br>
              <span>${{fila.precioTotal}}</span>
            </div> -->
                <!-- <div *ngIf="fila.precioNormal!=undefined">
                  <span style="color:blue"><i>PRECIO NORMAL</i></span><br>
                  <span *ngIf="fila.opcion.PorcentajeBonificacion==0">${{fila.precioNormal}}</span>
                  <div *ngIf="fila.opcion.ImporteBonificacion!=null && fila.opcion.ImporteBonificacion!=0">
                    <span style="text-decoration: line-through;" >${{fila.precioNormal}}</span><br>
                    <span>{{fila.opcion.PorcentajeBonificacion}}%</span>
                    <span *ngIf="fila.opcion.ImporteNormal>fila.opcion.Importe"> DE DESCUENTO</span>
                    <span *ngIf="fila.opcion.ImporteNormal<fila.opcion.Importe"> DE INCREMENTO</span>
                    <span> CODIGO:&nbsp;{{fila.opcion.CodigoDescuento |uppercase}}</span>
                  </div>
              </div>
              <div *ngIf="fila.precioTotal!=undefined">
                  <input type="radio" name="opcionesEnvio"
                  [disabled]="fila.precioTotal==0"
                  [id]="fila.UnidadVentaID+','+fila.ModalidadEntregaID"
                  (change)="seleccionarEnvio($event, fila.opcion)">&nbsp;
                  <span style="color:blue"><i>PRECIO TOTAL</i></span><br>
                  <span >${{fila.precioTotal}}</span>
              </div> -->
              </td>
            </tr>
            <tr *ngIf="filasTablaOpcionesPP.length>0">
              <th scope="row" style="text-align: center; vertical-align: center;">PUERTA-PUERTA</th>
              <td *ngFor="let fila of filasTablaOpcionesPP" style="text-align: center;">
                <div class="row justify-content-center"
                  [hidden]="fila.precioTotal==0 && ((fila.opcion!=null && fila.opcion.calculoImporteGuia==null)|| fila.opcion==null)">
                  <div class="col"
                    *ngIf="fila.opcion!=null && fila.opcion.ImporteBonificacion!=null && fila.opcion.ImporteBonificacion!=0">
                    <span style="color:blue"><i>PRECIO NORMAL</i></span><br>
                    <!-- <span *ngIf="fila.opcion.PorcentajeBonificacion==0">${{fila.precioNormal}}</span> -->
                    <span style="text-decoration: line-through;">${{fila.precioNormal}}</span><br>
                    <span
                      *ngIf="fila.opcion!=null && fila.opcion.CodigoDescuento!=null; else otro">{{fila.opcion.PorcentajeBonificacion}}%
                      DE DESCUENTO<br>CODIGO:&nbsp;{{fila.opcion.CodigoDescuento |uppercase}}</span>
                    <ng-template #otro><span>BONIFICACIÓN AGENCIA</span></ng-template>
                  </div>
                  <div class="col">
                    <input type="radio" name="opcionesEnvio" [disabled]="fila.precioTotal==0"
                      [checked]="opcionesEnvioFiltro.length==1" [id]="fila.UnidadVentaID+','+fila.ModalidadEntregaID"
                      (change)="seleccionarEnvio(fila.opcion)">&nbsp;
                    <span style="color:blue"><i>PRECIO TOTAL</i></span><br>
                    <span>${{fila.precioTotal}}</span><br><span
                      [hidden]="fila.opcion!=null && fila.opcion.calculoImporteGuia!=null && fila.opcion.calculoImporteGuia.ImporteServicioAdicional==0">(servicio
                      adicional incluido)</span>
                  </div>
                </div>
                <div class="row justify-content-center"
                  *ngIf="fila.precioTotal==0 && ((fila.opcion!=null && fila.opcion.calculoImporteGuia==null)|| fila.opcion==null)">
                  <div class="alert alert-info">Las cotizaciones que no se muestran es porque no se puede <br> realizar
                    el envío a la localidad ingresada con esa modalidad</div>
                </div>
                <!-- <div *ngIf="fila.opcion.ImporteBonificacion==null || fila.opcion.ImporteBonificacion==0">
              <input type="radio" name="opcionesEnvio"
                  [disabled]="fila.precioTotal==0"
                  [id]="fila.UnidadVentaID+','+fila.ModalidadEntregaID"
                  (change)="seleccionarEnvio($event, fila.opcion)">&nbsp;
              <span style="color:blue"><i>PRECIO TOTAL</i></span><br>
              <span>${{fila.precioTotal}}</span>
            </div> -->
                <!-- <div *ngIf="fila.precioNormal!=undefined">
                  <span style="color:blue"><i>PRECIO NORMAL</i></span><br>
                  <span *ngIf="fila.opcion.PorcentajeBonificacion==0">${{fila.precioNormal}}</span>
                  <div *ngIf="fila.opcion.ImporteBonificacion!=null && fila.opcion.ImporteBonificacion!=0">
                    <span style="text-decoration: line-through;" >${{fila.precioNormal}}</span><br>
                    <span>{{fila.opcion.PorcentajeBonificacion}}%</span>
                    <span *ngIf="fila.opcion.ImporteNormal>fila.opcion.Importe"> DE DESCUENTO</span>
                    <span *ngIf="fila.opcion.ImporteNormal<fila.opcion.Importe"> DE INCREMENTO</span>
                    <span> CODIGO:&nbsp;{{fila.opcion.CodigoDescuento |uppercase}}</span>
                  </div>
              </div>
              <div *ngIf="fila.precioTotal!=undefined">
                  <input type="radio" name="opcionesEnvio"
                  [disabled]="fila.precioTotal==0"
                  [id]="fila.UnidadVentaID+','+fila.ModalidadEntregaID"
                  (change)="seleccionarEnvio($event, fila.opcion)">&nbsp;
                  <span style="color:blue"><i>PRECIO TOTAL</i></span><br>
                  <span >${{fila.precioTotal}}</span>
              </div> -->
              </td>
            </tr>
          </tbody>
        </table><br><br>
        <!-- <div class="row" style="margin-top: 10px; " *ngFor="let opcion of opcionesEnvioFiltro">
      <div class="col-3" style="text-align: center;">
        <label class="titulosModalidadElegida">Modalidad elegida</label>
        <br>
        <div class="divModalidades">
          <label class="contenidoModalidad">{{opcion.ProductoDescrip}}</label>
          <input class="inputElegirModalidad" type="radio" name="opcionesEnvio" [disabled]="opcion.Mensaje!=undefined && opcion.Mensaje!=''"
          [id]="opcion.UnidadVentaID+','+opcion.ModalidadEntregaID" (change)="seleccionarEnvio($event, opcion)">
        </div>
        <div class="alert alert-warning" *ngIf="opcion.Mensaje!=undefined && opcion.Mensaje!=''">
          {{opcion.Mensaje}}</div>
      </div>
      <div class="col-3" style="text-align: center;">
        <span class="titulosModalidad"><i>Modalidad de envío</i></span><br>
        <span class="contenidoModalidad">{{opcion.ModalidadEntregaDescrip | uppercase}}</span>
      </div>
      <div class="col-2" style="text-align: center;">
        <span class="titulosModalidad"><i>Precio normal</i></span><br>
        <span class="contenidoModalidad">${{opcion.ImporteNormal}}</span><br>
        <span *ngIf="tieneBonificacion">{{porcentajeBonificacion}}% DE DESCUENTO APLICADO AL FLETE</span>
      </div>
      <div class="col-2" style="text-align: center;">
        <span class="titulosModalidad"><i>Valor declarado</i></span><br>
        <span class="contenidoModalidad">${{opcion.ValorDeclarado}}</span>
      </div>
      <div class="col-2" style="text-align: center;">
        <span class="titulosModalidad"><i>Precio total</i></span><br>
        <span class="contenidoModalidad">${{opcion.Importe}}</span>
      </div>
    </div> -->
        <div class="alert alert-info" *ngIf="mostrarOpcionEnvio && opcionesEnvioFiltro.length>0">Tarifas sujetas a
          modificación</div>
        <div class="alert alert-info" *ngIf="localDestinoSeleccionado!=null">Local de destino de su
          encomienda:&nbsp;{{localDestinoSeleccionado.LocalDescrip}}</div>
      </div>

    </div>
    <!-- IMPRESIÓN DE COTIZACIÓN -->
    <!-- <div class="opcionesEnvio" *ngIf="imprimirCot" id="cotizacion">
  <div class="row justify-content-between">
    <div class="col-4">
      <h5 style="margin-left: 30px; margin-top: 15px;">Opciones de Envíos</h5>
    </div>
    <div class="col-4">
      <span style="margin-top: 15px; color: #004993; font-size: 20px;">{{localidadOrigenCompleta.LocalidadDescrip}},{{localidadOrigenCompleta.ProvinciaDescrip}} - {{localidadDestinoCompleta.LocalidadDescrip}},{{localidadDestinoCompleta.ProvinciaDescrip}}</span>
    </div>
  </div>
  <table class="table table-borderless">
    <thead>
      <tr>
        <th scope="col" style="text-align: center;">Modalidad</th>
        <th scope="col" style="text-align: center;" *ngFor="let opcion of encabezadoTablaOpciones">{{opcion.descripcion}}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="filasTablaOpcionesTT.length>0">
        <th scope="row" style="text-align: center; vertical-align: center;">TERMINAL-TERMINAL</th>
        <td *ngFor="let fila of filasTablaOpcionesTT" style="text-align: center;">
          <div class="row justify-content-center" >
            <div class="col" *ngIf="fila.opcion.ImporteBonificacion!=null && fila.opcion.ImporteBonificacion!=0">
              <span style="color:blue"><i>PRECIO NORMAL</i></span><br>
              <span style="text-decoration: line-through;" >${{fila.precioNormal}}</span><br>
              <span *ngIf="fila.opcion.CodigoDescuento!=null; else otro">{{fila.opcion.PorcentajeBonificacion}}% DE DESCUENTO<br>CODIGO:&nbsp;{{fila.opcion.CodigoDescuento |uppercase}}</span>
              <ng-template #otro><span>BONIFICACIÓN AGENCIA</span></ng-template>
            </div>
            <div class="col">
              <input type="radio"
                [disabled]="fila.precioTotal==0"
                [checked]="opcionesEnvioFiltro.length==1"
                (change)="seleccionarEnvio(fila.opcion)">&nbsp;
                <span style="color:blue"><i>PRECIO TOTAL</i></span><br>
                <span>${{fila.precioTotal}}</span>
            </div>
          </div>
        </td>
      </tr>
      <tr *ngIf="filasTablaOpcionesTP.length>0">
        <th scope="row" style="text-align: center; vertical-align: center;">TERMINAL-PUERTA</th>
        <td *ngFor="let fila of filasTablaOpcionesTP" style="text-align: center;">
          <div class="row justify-content-center" >
            <div class="col" *ngIf="fila.opcion.ImporteBonificacion!=null && fila.opcion.ImporteBonificacion!=0">
              <span style="color:blue"><i>PRECIO NORMAL</i></span><br>
              <span style="text-decoration: line-through;" >${{fila.precioNormal}}</span><br>
              <span *ngIf="fila.opcion.CodigoDescuento!=null; else otro">{{fila.opcion.PorcentajeBonificacion}}% DE DESCUENTO<br>CODIGO:&nbsp;{{fila.opcion.CodigoDescuento |uppercase}}</span>
              <ng-template #otro><span>BONIFICACIÓN AGENCIA</span></ng-template>
            </div>
            <div class="col">
              <input type="radio"
                [disabled]="fila.precioTotal==0"
                [checked]="opcionesEnvioFiltro.length==1"
                (change)="seleccionarEnvio(fila.opcion)">&nbsp;
                <span style="color:blue"><i>PRECIO TOTAL</i></span><br>
                <span>${{fila.precioTotal}}</span>
            </div>
          </div>
        </td>
      </tr>
      <tr *ngIf="filasTablaOpcionesPT.length>0">
        <th scope="row" style="text-align: center; vertical-align: center;">PUERTA-TERMINAL</th>
        <td *ngFor="let fila of filasTablaOpcionesPT" style="text-align: center;">
          <div class="row justify-content-center" >
            <div class="col" *ngIf="fila.opcion.ImporteBonificacion!=null && fila.opcion.ImporteBonificacion!=0">
              <span style="color:blue"><i>PRECIO NORMAL</i></span><br>
              <span style="text-decoration: line-through;" >${{fila.precioNormal}}</span><br>
              <span *ngIf="fila.opcion.CodigoDescuento!=null; else otro">{{fila.opcion.PorcentajeBonificacion}}% DE DESCUENTO<br>CODIGO:&nbsp;{{fila.opcion.CodigoDescuento |uppercase}}</span>
              <ng-template #otro><span>BONIFICACIÓN AGENCIA</span></ng-template>
            </div>
            <div class="col">
              <input type="radio"
                [disabled]="fila.precioTotal==0"
                [checked]="opcionesEnvioFiltro.length==1"
                (change)="seleccionarEnvio(fila.opcion)">&nbsp;
                <span style="color:blue"><i>PRECIO TOTAL</i></span><br>
                <span>${{fila.precioTotal}}</span>
            </div>
          </div>
        </td>
      </tr>
      <tr *ngIf="filasTablaOpcionesPP.length>0">
        <th scope="row" style="text-align: center; vertical-align: center;">PUERTA-PUERTA</th>
        <td *ngFor="let fila of filasTablaOpcionesPP" style="text-align: center;">
          <div class="row justify-content-center" >
            <div class="col" *ngIf="fila.opcion.ImporteBonificacion!=null && fila.opcion.ImporteBonificacion!=0">
              <span style="color:blue"><i>PRECIO NORMAL</i></span><br>
              <span style="text-decoration: line-through;" >${{fila.precioNormal}}</span><br>
              <span *ngIf="fila.opcion.CodigoDescuento!=null; else otro">{{fila.opcion.PorcentajeBonificacion}}% DE DESCUENTO<br>CODIGO:&nbsp;{{fila.opcion.CodigoDescuento |uppercase}}</span>
              <ng-template #otro><span>BONIFICACIÓN AGENCIA</span></ng-template>
            </div>
            <div class="col">
              <input type="radio"
                [disabled]="fila.precioTotal==0"
                [checked]="opcionesEnvioFiltro.length==1"
                (change)="seleccionarEnvio(fila.opcion)">&nbsp;
                <span style="color:blue"><i>PRECIO TOTAL</i></span><br>
                <span>${{fila.precioTotal}}</span>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table><br><br>
</div> -->



    <!-- BOTONES -->
    <div class="botones ">
      <!-- INGRESO CODIGOS DE PRODUCTOS -->
      <div class="row justify-content-center">
        <div class="col-6">
          <button class="btn btn-info botonCodigoProductos" (click)="abrirModalCodigos()"
            [disabled]="codAutorizacion!=null && codAutorizacion!=''">Ingreso de Códigos de
            Productos</button>
        </div>
      </div>

      <!-- BOTON CONTINUAR -->
      <div class="row justify-content-center">
        <div class="col-6">
          <button class="btn btn-info botonContinuar" (click)="mostrarOpcionesEnvio()"
            [disabled]="codAutorizacion!='' && codAutorizacion!=null">Cotizar
            <span  *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          </button>
        </div>
      </div>

      <div class="mensajeCargando" *ngIf="cargando">
        <span class="spinner-border spinner-border-lg" style="width: 3rem; height: 3rem;" role="status" aria-hidden="true"></span>
      </div>
      <div class="alert alert-danger mt-2" *ngIf="mostrarAlert">{{mensaje}}</div>

    </div>

    <br>

    <!-- TABLA CODIGO DE BARRA -->
    <div class="tablaCodigoBarra" *ngIf="mostrarOpcionEnvioCodigo">
      <!-- opciones disponibles de envío -->
      <h5 class="tituloTablaCodigo">Opciones Envío Códigos Barra</h5>
      <div class="row"
        style=" text-align: center; display: flex; margin-top: 10px; width: 100%; justify-content: center;"
        *ngFor="let opcion of opcionesEnvioCodigo">
        <div style="text-align: center; margin: auto;">
          <!-- <img src="https://www.imexcourier.com.ar/wp-content/uploads/2020/01/encomiendas-300x220.jpg" alt="" style="margin-left: 15px;"><br>-->
          <label style="margin-bottom: 0px;" class="titulosModalidad">Modalidad elegida</label><br>
          <label style="margin-bottom: 0px;" class="contenidoModalidad">{{opcion.ProductoDescrip}}</label>
          <input class="inputCodigoBarra" type="radio" name="opcionesEnvio"
            [disabled]="opcion.Mensaje!=undefined && opcion.Mensaje!=''"
            [id]="opcion.UnidadVentaID+','+opcion.ModalidadEntregaID" (change)="seleccionarEnvio(opcion)">
          <!-- style="width: 20px; height: 20px; margin-top: 10px;" -->
          <div class="alert alert-warning" *ngIf="opcion.Mensaje!=undefined && opcion.Mensaje!=''">
            {{opcion.Mensaje}}</div>
        </div>
        <div style="text-align: center; margin: auto;">
          <span class="titulosModalidad"><i>Modalidad de envío</i></span><br>
          <span class="contenidoModalidad">{{opcion.ModalidadEntregaDescrip | uppercase}}</span>
        </div>
        <div style="text-align: center; margin: auto;">
          <span class="titulosModalidad"><i>Precio normal</i></span><br>
          <span class="contenidoModalidad">${{opcion.ImporteNormal}}</span><br>
          <span *ngIf="tieneBonificacion">{{porcentajeBonificacion}}% DE DESCUENTO APLICADO AL FLETE</span>
        </div>
        <div style="text-align: center; margin: auto;">
          <span class="titulosModalidad"><i>Valor declarado</i></span><br>
          <span class="contenidoModalidad">${{opcion.ValorDeclarado}}</span>
        </div>
        <div style="text-align: center; margin: auto;">
          <span class="titulosModalidad"><i>Precio total</i></span><br>
          <span class="contenidoModalidad">${{opcion.Importe}}</span>
        </div>
      </div>
    </div>



    <!-- <div class="row centrar" style="background: #eaf4f7; width: 100%; padding: 10px 3%;" [hidden]="opcionesEnvioApi.length==0">
  <div class="col-12">
    <div class="form-group">
      <textarea placeholder="Observaciones" class="form-control" [(ngModel)]="observaciones" name="obs"
        style="background: #eaf4f7; width: 100%; height: 100px;"></textarea>
    </div>
  </div>
</div> -->


    <br>
    <br>
    <!-- COMENTADO, EN EL DISEÑO NUEVO NO VA, EN PRINCIPIO -->
    <!-- <div class="row" *ngIf="importeTotal!=null">
      <div class="col"><span style="font-size: 20px; font-weight: bold;">Importe total:</span>&nbsp;<span
          style="font-size: 20px;">${{importeTotal}}</span></div>
    </div> -->




    <!-- PASO 2 DATOS REMITENTE -->
    <div class="seccionRemitente " *ngIf="paso2">
      <!-- aparece al seleccionar opción de envío -->
      <div class="row align-items-center rounded mt-5">
        <h4 class="tituloRemitente">&nbsp;&nbsp; 2° - Características del remitente</h4>
        <!-- <h4 class="mt-1" *ngIf="pagoDestino===false">&nbsp;&nbsp; 2°PASO - Datos Remitente</h4>
            <h4 class="mt-1" *ngIf="pagoDestino===true">&nbsp;&nbsp; 2°PASO - Datos Destinatario</h4> -->
      </div>

      <br>
      <!-- <div class="row mb-5 align-items-center" *ngIf="pagoDestino===true">
            <div class="col-3 mt-3">
                <div class="form-check">
                    <input class="form-check-input" type="radio" [(ngModel)]="tipoClienteDestinatario" name="opcionesClienteDestinatario" id="exampleRadios1" value="eventual" style="width: 30px; height: 30px; vertical-align: middle;" (change)="mostrarCodCliente($event)">
                    <label class="form-check-label rounded" for="exampleRadios1" style="margin-left: 15px;width: 130px; height: 40px; border: solid 1px black; text-align: center; vertical-align: middle; color: white; background-color: black;">
                      <p style="margin-top: 8px; font-size: 14px;">EVENTUAL</p>
                    </label>
                  </div>
            </div>
            <div class="col-3 mt-3">
                <div class="form-check">
                    <input class="form-check-input" type="radio" [(ngModel)]="tipoClienteDestinatario" name="opcionesClienteDestinatario" id="exampleRadios2" value="corporativo" style="width: 30px; height: 30px; vertical-align: middle;" (change)="mostrarCodCliente($event)">
                    <label class="form-check-label rounded" for="exampleRadios2" style="margin-left: 15px;width: 130px; height: 40px; border: solid 1px black; text-align: center; vertical-align: middle; color: white; background-color: black;">
                        <p style="margin-top: 8px; font-size: 14px;">CORPORATIVO</p>
                    </label>
                  </div>
            </div>
        </div> -->
      <div class="row centrar">
        <div class="col-2">
          <div class="form-group" *ngIf="codClienteO">
            <label>Código Cliente</label>
            <input [(ngModel)]="codClienteRemitente" [disabled]="clienteOrigen" name="codRemitente" id="codRemitente"
              type="text" class="form-control">
          </div>
        </div>
      </div>

      <div class="row centrar">
        <div class="col-3">
          <div class="form-group">
            <select class="custom-select" [disabled]="clienteOrigen" required [(ngModel)]="tipoCondicionIVARemitente"
              name="tipoIvaRemitente" id="tipoIvaRemitente" (blur)="tipoCondicionIVAIngresado('remitente')">
              <option value="" selected disabled>Tipo Condición IVA</option>
              <option *ngFor="let condIva of condicionesIva" [value]="condIva.CondicionIvaID">
                {{condIva.CondicionIvaDescrip}}</option>
              <!-- <option value="1">Responsable Inscripto</option>
                        <option value="2">Monotributo</option>
                        <option value="4">Exento</option>
                        <option value="5">Consumidor Final</option> -->
            </select>
            <div [hidden]="tipoCondicionIVARemitente!=''" class="alert alert-danger mt-1"
              style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido
            </div>
          </div>
        </div>
      </div>

      <div class="row centrar">

        <div class="col-6">
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <select class="custom-select" [disabled]="clienteOrigen" required [(ngModel)]="tipoDocRemitente"
                  name="tipodocRemi" id="tipodocRemi" #tipodocRemi="ngModel" (change)="mostrarPaso3()">
                  <option value="" selected disabled>Tipo de documento</option>
                  <option *ngFor="let tipoDoc of tiposDoc" [value]="tipoDoc.TipoDocumentoID">
                    {{tipoDoc.TipoDocumentoDescrip}}</option>
                </select>
                <div [hidden]="tipoDocRemitente!=''" class="alert alert-danger mt-1"
                  style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo
                  requerido</div>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <!-- <label>Nro Documento</label> -->
                <input placeholder="N° de documento" type="tel" class="form-control" [disabled]="clienteOrigen" required
                  [(ngModel)]="dniRemitente" name="dniRemi" id="dniRemi" #dniRemi="ngModel" (input)="mostrarPaso3()"
                  (blur)="cargarClienteEventual($event,'remitente')" onkeydown="javascript: return event.keyCode === 8 ||
              event.keyCode === 46 || event.keyCode===9 ? true : !isNaN(Number(event.key))" maxlength="11">
                <div [hidden]="dniRemitente!=''" class="alert alert-danger mt-1"
                  style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo
                  requerido</div>
                <div
                  [hidden]="((tipoDocRemitente!='5' && tipoDocRemitente!='6') || (dniRemitente.length==0 || dniRemitente.length==11))"
                  class="alert alert-danger mt-1"
                  style="width: 200px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Formato Incorrecto</div>
                <div [hidden]="!cargandoClientesR" class="alert alert-light mt-1"
                  style="width: 150px; height: 20px; font-size: 14px; padding: 0rem 1.30rem;">Cargando...</div>
                <!-- <div [hidden]="((tipoDocRemitente!='1') || (dniRemitente.length==0 || dniRemitente.length==8))" class="alert alert-danger mt-1"
            style="width: 200px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Formato Incorrecto</div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="form-group">
            <!-- <label>Nombre</label> -->
            <input placeholder="*Nombre" type="text" class="form-control" [disabled]="clienteOrigen" required
              [(ngModel)]="nombreRemitente" name="nomRemitente" id="nomRemitente" #nomRemitente="ngModel"
              (input)="mostrarPaso3()" (blur)="validarNomAp($event.target.value, 'remitente', 'nombre')"
              (focus)="alertNomR=true" maxlength="25">
            <div [hidden]="nombreRemitente!=''" class="alert alert-danger mt-1"
              style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido
            </div>
            <div [hidden]="alertNomR" class="alert alert-danger mt-1"
              style="width: 250px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">
              *No se pueden ingresar solo números</div>
          </div>
        </div>
        <div class="col-3">
          <div class="form-group">
            <!-- <label>Apellido</label> -->
            <input placeholder="*Apellido" type="text" class="form-control" [disabled]="clienteOrigen" required
              [(ngModel)]="apellidoRemitente" name="apeRemitente" id="apeRemitente" #apeRemitente="ngModel"
              (input)="mostrarPaso3()" (blur)="validarNomAp($event.target.value, 'remitente','apellido')"
              (focus)="alertApeR=true" maxlength="25">
            <div [hidden]="apellidoRemitente!=''" class="alert alert-danger mt-1"
              style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido
            </div>
            <div [hidden]="alertApeR" class="alert alert-danger mt-1"
              style="width: 250px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">
              *No se pueden ingresar solo números</div>
          </div>
        </div>
      </div>
      <div class="row centrar">
        <div class="col-8">
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <!-- <label>Cód. Area</label> -->
                <input placeholder="*Cód. Area" class="form-control" type="tel" required [(ngModel)]="codAreaRemitente"
                  name="codARemi" #codARemi="ngModel" (input)="mostrarPaso3()" onkeydown="javascript: return event.keyCode === 8 ||
              event.keyCode === 46 || event.keyCode===9 ? true : !isNaN(Number(event.key))" maxlength="6">
                <!-- <div [hidden]="codARemi.valid" class="alert alert-danger mt-1" style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido</div> -->
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <!-- <label>Teléfono</label> -->
                <input placeholder="*Teléfono" class="form-control" type="tel" required [(ngModel)]="telefonoRemitente"
                  name="telRemitente" #telRemitente="ngModel" (input)="mostrarPaso3()" onkeydown="javascript: return event.keyCode === 8 ||
              event.keyCode === 46 || event.keyCode===9 ? true : !isNaN(Number(event.key))" maxlength="25">
                <!-- <div [hidden]="telRemitente.valid" class="alert alert-danger mt-1" style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo Requerido</div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <!-- <label>Email</label> -->
            <input placeholder="Email" class="form-control" type="email" [(ngModel)]="mailRemitente" name="mailRemi"
              id="mailRemi" #mailRemi="ngModel" (input)="mostrarPaso3()" (focus)="alertEmailR=true"
              (blur)="validarEmail($event.target.value, 'remitente')" maxlength="30">
            <div [hidden]="alertEmailR" class="alert alert-danger mt-1"
              style="width: 220px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Formato de email incorrecto
            </div>
          </div>
        </div>
      </div>

      <div class="row centrar">
        <div class="col-4">
          <div class="form-group">
            <!-- <label *ngIf="pagoDestino===false">Calle Retiro</label> -->
            <!-- <label *ngIf="pagoDestino===true">Calle Entrega</label> -->
            <!-- <input placeholder="Calle Retiro" class="form-control" type="text" required [(ngModel)]="calleOrigen"
          [disabled]="opcionEnvioSeleccionada.ModalidadEntregaID==1 || opcionEnvioSeleccionada.ModalidadEntregaID==3"
          name="calleOrig" #calleOrig="ngModel" (input)="mostrarPaso3()" autocomplete="off" maxlength="30">
        <div [hidden]="calleOrigen!=''" class="alert alert-danger mt-1"
          style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido
        </div> -->
            <input placeholder="*Calle Retiro" class="form-control" list="callesO" required [(ngModel)]="calleOrigen"
              name="calleOrig" id="calleOrig" #calleOrig="ngModel" type="text"
              (input)="calleIngresada('origen', $event); mostrarPaso3()" autocomplete="off" maxlength="100"
              [disabled]="opcionEnvioSeleccionada.ModalidadEntregaID==1 || opcionEnvioSeleccionada.ModalidadEntregaID==3">
            <datalist id="callesO">
              <option *ngFor="let calle of callesOrigen" [id]=calle.localidadid style="width: 200px;">
                {{calle.CalleDescrip}},
                {{calle.AlturaDesde}}-{{calle.AlturaHasta}} ({{calle.CodigoPostal}})</option>
            </datalist>
            <div [hidden]="calleOrigen!='' && calleOrigen!=null" class="alert alert-danger mt-1"
              style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <!-- <label>Nro</label> -->
            <input placeholder="N°" class="form-control" type="tel" required [(ngModel)]="nroCalleRemitente"
              [disabled]="opcionEnvioSeleccionada.ModalidadEntregaID==1 || opcionEnvioSeleccionada.ModalidadEntregaID==3"
              name="nroRemi" #nroRemi="ngModel" (input)="mostrarPaso3()" (focus)="errorO=false"
              (blur)="validarNroCalle('origen')" onkeydown="javascript: return event.keyCode === 8 ||
          event.keyCode === 46 || event.keyCode===9 ? true : !isNaN(Number(event.key))" maxlength="6">
            <div [hidden]="nroCalleRemitente!='' && nroCalleRemitente!=null" class="alert alert-danger mt-1"
              style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido
            </div>
            <div [hidden]="!errorO" class="alert alert-danger mt-1"
              style="width: 250px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Numeración fuera de rango
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <!-- <label>Piso</label> -->
            <input placeholder="Piso" class="form-control" type="text" [(ngModel)]="pisoRemitente"
              [disabled]="opcionEnvioSeleccionada.ModalidadEntregaID==1 || opcionEnvioSeleccionada.ModalidadEntregaID==3"
              name="pisoRemi" maxlength="10">
          </div>
        </div>
      </div>
      <!-- <div class="row centrar">
    <div class="col">
      <div class="form-group">

        <textarea placeholder="Descripción" class="form-control" [(ngModel)]="descRemitente"
          name="descripRemi" maxlength="50"></textarea>
      </div>
    </div>
  </div> -->
      <div class="col-12 mt-4">
        <button class="btn btn-primary botonesRemitente botonLimpiar" (click)="limpiarRemitente()">Limpiar</button>
      </div>
    </div>


    <!-- DESPACHANTE -->
    <div class="despachante" *ngIf="despachanteOrigen ">
      <!-- sección despachante -->
      <!-- <h6 class="mt-1" *ngIf="pagoDestino===false">&nbsp;&nbsp; Despachante</h6>
        <h6 class="mt-1" *ngIf="pagoDestino===true">&nbsp;&nbsp; Quien recibe</h6> -->
      <h6 class="mt-1 tituloDespachante">&nbsp;&nbsp; Despachante</h6>

      <div class="row centrar">
        <div class="col-6">
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <select class="custom-select selectDespachante" style="margin-top: 32px;" required
                  [(ngModel)]="tipoDocDespachanteOrigen" name="tipodocDespaO" #tipodocDespaO="ngModel">
                  <option value="" selected disabled>Tipo documento</option>
                  <option *ngFor="let tipoDoc of tiposDoc" [value]="tipoDoc.TipoDocumentoID">
                    {{tipoDoc.TipoDocumentoDescrip}}</option>
                </select>
                <!-- <div [hidden]="tipodocDespaO.valid" class="alert alert-danger mt-1"
              style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo
              requerido</div> -->
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label>Nro Documento</label>
                <input type="tel" class="form-control" required [(ngModel)]="dniDespachanteOrigen" name="dniDespaO"
                  id="dniDespaO" #dniDespaO="ngModel" onkeydown="javascript: return event.keyCode === 8 ||
              event.keyCode === 46 || event.keyCode===9 ? true : !isNaN(Number(event.key))" maxlength="11">
                <!-- <div [hidden]="dniDespaO.valid" class="alert alert-danger mt-1"
              style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo
              requerido</div> -->
                <div
                  [hidden]="(tipoDocDespachanteOrigen!='5' && tipoDocDespachanteOrigen!='6') || (dniDespachanteOrigen.length==0 || dniDespachanteOrigen.length==11)"
                  class="alert alert-danger mt-1"
                  style="width: 200px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Formato Incorrecto</div>
                <!-- <div [hidden]="((tipoDocDespachanteOrigen!='1') || (dniDespachanteOrigen.length==0 || dniDespachanteOrigen.length==8))" class="alert alert-danger mt-1"
            style="width: 200px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Formato Incorrecto</div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="form-group">
            <label>Nombre</label>
            <input type="text" class="form-control" required [(ngModel)]="nombreDespachanteOrigen" name="nomDespaO"
              id="nomDespaO" #nomDespaO="ngModel" (blur)="validarNomAp($event.target.value, 'remitente', 'nombreA')"
              (focus)="alertNomRA=true" maxlength="25">
            <!-- <div [hidden]="nomDespaO.valid" class="alert alert-danger mt-1"
          style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido
        </div> -->
            <div [hidden]="alertNomRA" class="alert alert-danger mt-1"
              style="width: 250px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">
              *No se pueden ingresar solo números</div>
          </div>
        </div>
        <div class="col-3">
          <div class="form-group">
            <label>Apellido</label>
            <input type="text" class="form-control" required [(ngModel)]="apellidoDespachanteOrigen" name="apeDespaO"
              id="apeDespaO" #apeDespaO="ngModel" (blur)="validarNomAp($event.target.value, 'remitente', 'apellidoA')"
              (focus)="alertApeRA=true" maxlength="25">
            <!-- <div [hidden]="apeDespaO.valid" class="alert alert-danger mt-1"
          style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido
        </div> -->
            <div [hidden]="alertApeRA" class="alert alert-danger mt-1"
              style="width: 250px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">
              *No se pueden ingresar solo números</div>
          </div>
        </div>
      </div>
      <div class="row centrar">
        <div class="col-12 mt-5">
          <button class="btn btn-info botonDespachante" (click)="buscarAutorizados()">Autorizados</button>
        </div>
      </div><br><br>
    </div>




    <!-- PASOS DESTINATARIO -->
    <div *ngIf="paso3 ">
      <!-- aparece al completar el remitente -->
      <div class="row align-items-center rounded mt-5">
        <!-- <h4 class="mt-1" *ngIf="pagoDestino===false">&nbsp;&nbsp; 3°PASO - Datos Destinatario</h4>
            <h4 class="mt-1" *ngIf="pagoDestino===true">&nbsp;&nbsp; 3°PASO - Datos Remitente</h4> -->
        <h4 class="mt-1 tituloDestinatario">&nbsp;&nbsp; 3° Características del destinatario</h4>
      </div>
      <br>
      <div class="row mb-5 align-items-center">
        <!-- *ngIf="pagoDestino===false" -->
        <div class="col-3 mt-3">
          <div class="form-check">
            <input class="form-check-input" type="radio" [(ngModel)]="tipoClienteDestinatario"
              name="opcionesClienteDestinatario" id="ckEventualDest" value="eventual"
              style="width: 30px; height: 30px; vertical-align: middle;" (change)="mostrarCodCliente($event)">
            <label class="form-check-label rounded" for="exampleRadios1"
              style="margin-left: 15px;width: 130px; height: 40px; text-align: center; vertical-align: middle; color: #004993;">
              <p style="margin-top: 8px; font-size: 14px;">EVENTUAL</p>
            </label>
          </div>
        </div>
        <div class="col-3 mt-3">
          <div class="form-check">
            <input class="form-check-input" type="radio" [(ngModel)]="tipoClienteDestinatario"
              name="opcionesClienteDestinatario" id="ckCorpoDest" value="corporativo"
              style="width: 30px; height: 30px; vertical-align: middle;" (change)="mostrarCodCliente($event)"
              [disabled]="porcentajeBonificacion!=0 && porcentajeBonificacion!=null && porcentajeBonificacion!=undefined">
            <label class="form-check-label rounded" for="exampleRadios2"
              style="margin-left: 15px;width: 130px; height: 40px; text-align: center; vertical-align: middle; color: #004993;">
              <p style="margin-top: 8px; font-size: 14px;">CORPORATIVO</p>
            </label>
          </div>
        </div>
      </div>
      <div class="row centrar">
        <div class="col-3">
          <div class="form-group" *ngIf="codClienteD">
            <label>Código Cliente</label> &nbsp;
            <input type="text" class="form-control" [(ngModel)]="codClienteDestinatario" name="codCliDesti"
              id="codCorpoDest" style="width: 80px; display: initial;"
              (keydown.tab)="cargarCorporativoDestinatario($event)"
              (keydown.enter)="cargarCorporativoDestinatario($event);$event.preventDefault()" maxlength="5">
            <button class="btn btn-success"
              style="background-color: #004993; border: 1px solid #004993; margin-top: -5px;" type="button"
              (click)="abrirModalClientes('destinatario')">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search"
                viewBox="0 0 16 16">
                <path
                  d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <div class="row centrar">
        <div class="col-3">
          <div class="form-group">
            <select class="custom-select" [disabled]="clienteDestino" required
              [(ngModel)]="tipoCondicionIVADestinatario" name="tipoIvaDesti" #tipoIvaDesti="ngModel"
              (blur)="tipoCondicionIVAIngresado('destinatario')">
              <option value="" selected disabled>Tipo Condición IVA</option>
              <option *ngFor="let condIva of condicionesIva" [value]="condIva.CondicionIvaID">
                {{condIva.CondicionIvaDescrip}}</option>
              <!-- <option value="1">Monotributo</option>
                        <option value="2">Consumidor Final</option>
                        <option value="3">Responsable Inscripto</option>
                        <option value="4">Exento</option> -->
            </select>
            <div [hidden]="tipoCondicionIVADestinatario!=''" class="alert alert-danger mt-1"
              style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido
            </div>
          </div>
        </div>
      </div>

      <div class="row centrar">

        <div class="col-6">
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <select class="custom-select" [disabled]="clienteDestino" required [(ngModel)]="tipoDocDestinatario"
                  name="tipodocDesti" #tipodocDesti="ngModel">
                  <option value="" selected disabled>Tipo documento</option>
                  <option *ngFor="let tipoDoc of tiposDoc" [value]="tipoDoc.TipoDocumentoID">
                    {{tipoDoc.TipoDocumentoDescrip}}</option>
                </select>
                <div [hidden]="tipoDocDestinatario!=''" class="alert alert-danger mt-1"
                  style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo
                  requerido</div>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <!-- <label>Nro Documento</label> -->
                <input placeholder="N° de documento" type="tel" class="form-control" [disabled]="clienteDestino"
                  required [(ngModel)]="dniDestinatario" name="dniDesti" id="dniDesti" #dniDesti="ngModel"
                  (blur)="cargarClienteEventual($event,'destinatario')" (input)="mostrarBotonesEmitir()" onkeydown="javascript: return event.keyCode === 8 ||
              event.keyCode === 46 || event.keyCode===9 ? true : !isNaN(Number(event.key))" maxlength="11">
                <div [hidden]="dniDestinatario!='' && dniDestinatario!=null" class="alert alert-danger mt-1"
                  style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo
                  requerido</div>
                <div
                  [hidden]="(tipoDocDestinatario!='5' && tipoDocDestinatario!='6') || (dniDestinatario.length==0 || dniDestinatario.length==11)"
                  class="alert alert-danger mt-1"
                  style="width: 200px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Formato Incorrecto</div>
                <div [hidden]="!cargandoClientesD" class="alert alert-light mt-1"
                  style="width: 150px; height: 20px; font-size: 14px; padding: 0rem 1.30rem;">Cargando...</div>
                <!-- <div [hidden]="((tipoDocDestinatario!='1') || (dniDestinatario.length==0 || dniDestinatario.length==8))" class="alert alert-danger mt-1"
            style="width: 200px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Formato Incorrecto</div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="form-group">
            <!-- <label>Nombre</label> -->
            <input placeholder="Nombre" type="text" class="form-control" [disabled]="clienteDestino" required
              id="nomDestinatario" [(ngModel)]="nombreDestinatario" name="nomDesti" #nomDesti="ngModel"
              (input)="mostrarBotonesEmitir()" (blur)="validarNomAp($event.target.value, 'destinatario', 'nombre')"
              (focus)="alertNomD=true" maxlength="25">
            <div [hidden]="nombreDestinatario!=''" class="alert alert-danger mt-1"
              style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido
            </div>
            <div [hidden]="alertNomD" class="alert alert-danger mt-1"
              style="width: 250px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">
              *No se pueden ingresar solo números</div>
          </div>
        </div>
        <div class="col-3">
          <div class="form-group">
            <!-- <label>Apellido</label> -->
            <input placeholder="Apellido" type="text" class="form-control" [disabled]="clienteDestino" required
              id="apeDestinatario" [(ngModel)]="apellidoDestinatario" name="apeDesti" #apeDesti="ngModel"
              (input)="mostrarBotonesEmitir()" (blur)="validarNomAp($event.target.value, 'destinatario', 'apellido')"
              (focus)="alertApeD=true" maxlength="25">
            <div [hidden]="apellidoDestinatario!=''" class="alert alert-danger mt-1"
              style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido
            </div>
            <div [hidden]="alertApeD" class="alert alert-danger mt-1"
              style="width: 250px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">
              *No se pueden ingresar solo números</div>
          </div>
        </div>
      </div>
      <div class="row centrar">
        <div class="col-8">
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <!-- <label>Cód. Area</label> -->
                <input placeholder="*Cód. Area" class="form-control" type="tel" required
                  [(ngModel)]="codAreaDestinatario" name="codADesti" #codADesti="ngModel" onkeydown="javascript: return event.keyCode === 8 ||
              event.keyCode === 46 || event.keyCode===9 ? true : !isNaN(Number(event.key))" maxlength="6">
                <!-- <div [hidden]="codADesti.valid" class="alert alert-danger mt-1" style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido</div> -->
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <!-- <label>Teléfono</label> -->
                <input placeholder="*Teléfono" class="form-control" type="tel" required
                  [(ngModel)]="telefonoDestinatario" name="telDesti" #telDesti="ngModel" onkeydown="javascript: return event.keyCode === 8 ||
              event.keyCode === 46 || event.keyCode===9 ? true : !isNaN(Number(event.key))" maxlength="25">
                <!-- <div [hidden]="telDesti.valid" class="alert alert-danger mt-1" style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido</div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <!-- <label>Email</label> -->
            <input placeholder="Email" class="form-control" type="text" required [(ngModel)]="mailDestinatario"
              id="mailDest" name="mailDesti" #mailDesti="ngModel" (focus)="alertEmailD=true"
              (blur)="validarEmail($event.target.value, 'destinatario')">
            <div [hidden]="alertEmailD" class="alert alert-danger mt-1"
              style="width: 220px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Formato de email incorrecto
            </div>
          </div>
        </div>

      </div>
      <div class="row centrar">
        <!-- *ngIf="opcionEnvioSeleccionada.ModalidadEntregaID!=1 && opcionEnvioSeleccionada.ModalidadEntregaID!=4" -->
        <div class="col-4">
          <div class="form-group">
            <!-- <label *ngIf="pagoDestino===true">Calle Retiro</label> -->
            <!-- <label *ngIf="pagoDestino===false">Calle Entrega</label> -->
            <!-- <input placeholder="Calle Entrega" class="form-control" type="text" required [(ngModel)]="calleDestino"
          [disabled]="opcionEnvioSeleccionada.ModalidadEntregaID==1 || opcionEnvioSeleccionada.ModalidadEntregaID==4"
          name="calleDest" #calleDest="ngModel" (input)="mostrarBotonesEmitir()" autocomplete="off" maxlength="30">
        <div [hidden]="calleDestino!=''" class="alert alert-danger mt-1"
          style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido
        </div> -->
            <input placeholder="*Calle Entrega" class="form-control" list="callesD" required [(ngModel)]="calleDestino"
              name="calleDest" id="calleDest" #calleDest="ngModel" type="text"
              (input)="calleIngresada('destino', $event); mostrarBotonesEmitir()" autocomplete="off" maxlength="100"
              [disabled]="opcionEnvioSeleccionada.ModalidadEntregaID==1 || opcionEnvioSeleccionada.ModalidadEntregaID==4">
            <datalist id="callesD">
              <option *ngFor="let calle of callesDestino" [id]="calle.localidadid" style="width: 200px;">
                <span style="width: 200px;">{{calle.CalleDescrip}},
                  {{calle.AlturaDesde}}-{{calle.AlturaHasta}} ({{calle.CodigoPostal}})</span>
              </option>
            </datalist>
            <div [hidden]="calleDestino!='' && calleDestino!=null" class="alert alert-danger"
              style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <!-- <label>Nro</label> -->
            <input placeholder="N°" class="form-control" type="tel" required [(ngModel)]="nroCalleDestinatario"
              [disabled]="opcionEnvioSeleccionada.ModalidadEntregaID==1 || opcionEnvioSeleccionada.ModalidadEntregaID==4"
              name="nroDesti" #nroDesti="ngModel" (input)="mostrarBotonesEmitir()" (focus)="errorD=false"
              (blur)="validarNroCalle('destino')" onkeydown="javascript: return event.keyCode === 8 ||
          event.keyCode === 46 || event.keyCode===9 ? true : !isNaN(Number(event.key))" maxlength="6">
            <div [hidden]="nroCalleDestinatario!='' && nroCalleDestinatario!=null" class="alert alert-danger mt-1"
              style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido
            </div>
            <div [hidden]="!errorD" class="alert alert-danger mt-1"
              style="width: 250px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Numeración fuera de rango
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <!-- <label>Piso</label> -->
            <input placeholder="Piso" class="form-control" type="text" [(ngModel)]="pisoDestinatario"
              [disabled]="opcionEnvioSeleccionada.ModalidadEntregaID==1 || opcionEnvioSeleccionada.ModalidadEntregaID==4"
              name="pisoDesti" max="10">
          </div>
        </div>
      </div>
      <!-- <div class="row centrar">
    <div class="col">
      <div class="form-group">

        <textarea placeholder="Descripción" class="form-control" [(ngModel)]="descDestinatario"
          name="descripDesti" maxlength="50"></textarea>
      </div>
    </div>
  </div> -->
      <div class="row centrar"> <!-- style="background: #eaf4f7; width: 100%; padding: 10px 3%;" -->
        <div class="col-12">
          <div class="form-group">
            <!-- <label>Observaciones</label> -->
            <textarea placeholder="Observaciones" class="form-control" [(ngModel)]="observaciones" name="obs"
              style="width: 100%; height: 100px;"
              maxlength="100"></textarea><!-- style="background: #eaf4f7; width: 100%; height: 100px;" -->
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="despachanteDestino">
      <!-- sección despachante -->
      <!-- <h6 class="mt-1" *ngIf="pagoDestino===false">&nbsp;&nbsp; Quien Recibe</h6>
        <h6 class="mt-1" *ngIf="pagoDestino===true">&nbsp;&nbsp; Despachante</h6> -->
      <h6 class="mt-1 tituloQuienRecibe">&nbsp;&nbsp;Quien Recibe</h6>
      <!-- <hr style="color: #3f3d3d;"> -->

      <div class="row centrar">
        <div class="col-3">
          <div class="form-group">
            <!-- <label>Nombre</label> -->
            <input placeholder="Nombre" type="text" class="form-control" [(ngModel)]="nombreDespachanteDestino"
              id="nomDespaD" name="nomDespaD" #nomDespaD="ngModel" (focus)="alertNomDA=true"
              (blur)="validarNomAp($event.target.value, 'destinatario', 'nombreA')" maxlength="25">
            <div [hidden]="alertNomDA" class="alert alert-danger mt-1"
              style="width: 250px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">
              *No se pueden ingresar solo números</div>
          </div>
        </div>
        <div class="col-3">
          <div class="form-group">
            <!-- <label>Apellido</label> -->
            <input placeholder="Apellido" type="text" class="form-control" [(ngModel)]="apellidoDespachanteDestino"
              id="apeDespaD" name="apeDespaD" #apeDespaD="ngModel" (focus)="alertApeDA=true"
              (blur)="validarNomAp($event.target.value, 'destinatario', 'apellidoA')" maxlength="25">
            <div [hidden]="alertApeDA" class="alert alert-danger mt-1"
              style="width: 250px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">
              *No se pueden ingresar solo números</div>
          </div>
        </div>
        <div class="col-6">
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <select class="custom-select" [(ngModel)]="tipoDocDespachanteDestino" name="tipodocDespaD"
                  #tipodocDespaD="ngModel">
                  <option value="" selected disabled>Tipo documento</option>
                  <option *ngFor="let tipoDoc of tiposDoc" [value]="tipoDoc.TipoDocumentoID">
                    {{tipoDoc.TipoDocumentoDescrip}}</option>
                </select>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <!-- <label>Nro Documento</label> -->
                <input placeholder="N° de documento" type="tel" class="form-control" id="dniDespaD"
                  [(ngModel)]="dniDespachanteDestino" name="dniDespaD" #dniDespaD="ngModel" onkeydown="javascript: return event.keyCode === 8 ||
              event.keyCode === 46 || event.keyCode===9 ? true : !isNaN(Number(event.key))" maxlength="11">
                <div
                  [hidden]="(tipoDocDespachanteDestino!='5' && tipoDocDespachanteDestino!='6') || (dniDespachanteDestino.length==0 || dniDespachanteDestino.length==11)"
                  class="alert alert-danger mt-1"
                  style="width: 200px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Formato Incorrecto</div>
                <!-- <div [hidden]="((tipoDocDespachanteDestino!='1') || (dniDespachanteDestino.length==0 || dniDespachanteDestino.length==8))" class="alert alert-danger mt-1"
            style="width: 200px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Formato Incorrecto</div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="row justify-content-end" *ngIf="pagoDestino===true">
            <div class="col-4" style="text-align: right;">
                <button class="btn btn-info" (click)="mostrarOpcionesEnvio()">Autorizados</button>
            </div>
        </div><br><br> -->
    </div>
  </div>

  </form>
  <br>
  <br>

  <!-- BOTONES LIMPIAR Y AGREGAR AL CARRITO -->
  <div class="row " *ngIf="botonesEmitir">
    <div class="col-12 mt-4 botonesDestinatario">
      <button class="btn btn-primary botonesRemitente botonLimpiar" (click)="limpiarDestinatario()">Limpiar</button>
      <button class="btn btn-primary botonesRemitente botonContinuarRemitente" (click)="agregarCarrito()">Agregar al
        carrito</button>
    </div>
    <br>
    <br>
    <br>
  </div>



  <!-- CARRITO -->
  <div *ngIf="carrito" class="carritoDiv centrar">
    <div class="row align-items-center rounded mt-5">
      <h4 class="mt-1 tituloCarrito">&nbsp;&nbsp; Carrito de compra</h4>
    </div><br>
    <table class="table" style="width: 100%;">
      <thead class="theadCarrito">
        <tr>
          <th class="titulosTablaCarrito" scope="col" style="text-align: center;">Nro</th>
          <th class="titulosTablaCarrito" scope="col" style="text-align: center;">Destino</th>
          <th class="titulosTablaCarrito" scope="col" style="text-align: center;">Bultos-Kg</th>
          <th class="titulosTablaCarrito" scope="col" style="text-align: center;">Valor Declarado</th>
          <th class="titulosTablaCarrito" scope="col" style="text-align: center;">Modalidad de Pago</th>
          <th class="titulosTablaCarrito" scope="col" style="text-align: center;">Precio</th>
          <th class="titulosTablaCarrito" scope="col" style="text-align: center;" [hidden]="!tieneRecargo">Precio con
            Recargo</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let guia of guias, let i=index">
          <th scope="row" style="text-align: center; font-size: 18px;">{{i+1}}</th>
          <td style="text-align: center; font-size: 17px;">
            {{guia.LocalidadDestinoDescrip}},&nbsp;{{guia.ProvinciaDestinoDescrip}}({{guia.CodigoPostalDestinoNro}})
          </td>
          <td style="text-align: center;">{{guia.CantidadBultos}}&nbsp;bulto-{{guia.Peso}}kg
          </td>
          <td style="text-align: center;">${{guia.ValorDeclarado}}</td>
          <td>
            <select class="form-control selectCarrito" [id]="'cpCarrito'+(i+1)" [disabled]="guia.TipoCondicionPagoID==2"
              (change)="seleccionarCondicionPago($event, guia)">
              <option value="1" [selected]="guia.TipoCondicionPagoID==1" [disabled]="guia.PagoEnDestino">Contado
              </option>
              <option value="2" [selected]="guia.TipoCondicionPagoID==2" [disabled]="guia.TipoCondicionPagoID!==2">
                Cuenta Corriente</option>
              <option value="4" [selected]="guia.TipoCondicionPagoID==4" [disabled]="guia.PagoEnDestino">Tarjeta
                Cred/Deb</option>
              <option value="6" [selected]="guia.PagoEnDestino" [disabled]="!guia.PagoEnDestino">Pago Destino</option>
            </select>
          </td>
          <td style="text-align: center; font-size: 20px; font-weight: bold;">${{guia.ImporteTotal}}</td>
          <td style="text-align: center; font-size: 20px; font-weight: bold;" [hidden]="!tieneRecargo">
            ${{guia.ImporteTotalConRecargo}}</td>
          <td style="text-align: center;">
            <button class="btn btn-info" (click)="eliminarGuiaCarrito(guia, i)">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash"
                viewBox="0 0 16 16">
                <path
                  d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                <path fill-rule="evenodd"
                  d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
              </svg>
            </button>
          </td>
        </tr>
      </tbody>
    </table><br><br>
    <div class="row" *ngIf="pagoTarjeta">
      <h5 style="margin-top: 10px;">Datos tarjeta:</h5>
    </div>
    <div class="row" *ngIf="pagoTarjeta">
      <div class="col-4">
        <div class="form-group">
          <label>Nro. Lote</label>
          <input class="form-control" required [(ngModel)]="nroLote" id="nroL" name="nroL" #nroL="ngModel" type="text">
          <div [hidden]="nroL.valid" class="alert alert-danger mt-1"
            style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <label>Nro. Cupón</label>
          <input class="form-control" required [(ngModel)]="nroCupon" id="nroCup" name="nroCup" #nroCup="ngModel"
            type="text">
          <div [hidden]="nroCup.valid" class="alert alert-danger mt-1"
            style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-4 mt-5 text-center" style="width: 3rem; height: 3rem;" *ngIf="cargandoEmision">
        <div class="spinner-grow" role="status">
          <span class="sr-only"></span>
        </div>
      </div>
    </div>
    <div *ngIf="cargandoEmision" style="font-size: 20px; font-weight: bold;"></div>
    <div class="row" *ngIf="botonEmitir">
      <div class="col" style="text-align: center;">
        <button class="btn btn-primary" style="width: 300px; background: #004993" type="button" (click)="emitirGuia()"
          [disabled]="butEmitir">EMITIR</button>&nbsp;&nbsp;
        <button [hidden]="datosImpresion.length==0" class="btn btn-primary" style="width: 300px;" type="button"
          (click)="imprimir()">Impresión</button>
      </div><br><br><br>
    </div>
  </div>





  <!-- modal seleccionar un local destino -->
  <div>
    <div class="modal" tabindex="1" id="modalLocal" data-backdrop="static" data-keyboard="false">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Local de destino</h5>
          </div>
          <div class="modal-body">
            <div class="container">
              <table class="table">
                <thead style="background-color: #0db0ac; color: #fff">
                  <tr>
                    <th scope="col" hidden>ID</th>
                    <th scope="col">Código</th>
                    <th scope="col">Nombre</th>
                    <th scope="col">Dirección</th>
                    <th scope="col">Localidad</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let local of localesDestino | paginate: {itemsPerPage:5, currentPage:p};">
                    <td hidden>{{local.LocalID}}</td>
                    <td>&nbsp;&nbsp;{{local.CodigoLocal}}</td>
                    <td>{{local.LocalDescrip}}</td>
                    <td>{{local.Domicilio.Calle}}&nbsp;{{local.Domicilio.CalleNro}}</td>
                    <td>{{local.LocalidadDescrip}}</td>
                    <td>
                      <button title="Seleccionar local"
                        style="display: flex; justify-content: center; align-items: center; height: 40px;"
                        class="btn btn-info" (click)="seleccionarSucursalDestino(local)">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2-square"
                          fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd"
                            d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                          <path fill-rule="evenodd"
                            d="M1.5 13A1.5 1.5 0 0 0 3 14.5h10a1.5 1.5 0 0 0 1.5-1.5V8a.5.5 0 0 0-1 0v5a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V3a.5.5 0 0 1 .5-.5h8a.5.5 0 0 0 0-1H3A1.5 1.5 0 0 0 1.5 3v10z" />
                        </svg>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- <div class="modal-footer">
            <button type="button" class="btn btn-danger" style="background: #004993; color: #fff; border: 2px solid #004993;" data-dismiss="modal">Cerrar</button>
          </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- modal autorizados corporativo -->
  <app-modal-autorizados [clienteCorpo]="clienteRemitente"></app-modal-autorizados>
  <!-- <div>
  <div class="modal" tabindex="1" id="modalAutorizados" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Autorizados Cliente:&nbsp;{{clienteRemitente.RazonSocial}}</h5>
        </div>
        <div class="modal-body">
          <div class="container">
            <table class="table">
              <thead class="thead-dark">
                <tr>
                  <th scope="col" hidden>ID</th>
                  <th scope="col">Nro Documento</th>
                  <th scope="col">Nombre</th>
                  <th scope="col">Apellido</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let aut of autorizadosCorpo | paginate: {itemsPerPage:5, currentPage:p};">
                  <td hidden>{{aut.NominaAutorizadoID}}</td>
                  <td>&nbsp;&nbsp;{{aut.NroDocumento}}</td>
                  <td>{{aut.Nombre}}</td>
                  <td>{{aut.Apellido}}</td>
                  <td>
                    <button class="btn btn-info" (click)="seleccionarAutorizado(aut)">
                      <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2-square" fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                          d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                        <path fill-rule="evenodd"
                          d="M1.5 13A1.5 1.5 0 0 0 3 14.5h10a1.5 1.5 0 0 0 1.5-1.5V8a.5.5 0 0 0-1 0v5a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V3a.5.5 0 0 1 .5-.5h8a.5.5 0 0 0 0-1H3A1.5 1.5 0 0 0 1.5 3v10z" />
                      </svg>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal">Cerrar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
  <!-- modal códigos barra -->
  <div>
    <div class="modal" tabindex="1" id="modalCB" data-backdrop="static" data-keyboard="false">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 style="color: #004993;" class="modal-title">Códigos de Barra</h5>
          </div>
          <div class="modal-body">
            <div class="container">
              <div class="row justify-content-md-center">
                <div class="col-8">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">Código Barra</span>
                    </div>
                    <input type="text" class="form-control" [(ngModel)]="cb" name="codigoB" #codigoB="ngModel"
                      maxlength="11">
                    <div class="input-group-append">
                      <button class="btn btn-success" style="background-color: #004993; border: 1px solid #004993;"
                        type="button" id="button-addon2" (click)="ingresarCB()">Agregar</button>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="validandoCodigo">
                <span style="font-size: 18px;">Cargando...</span>
              </div><br>
              <table class="table">
                <thead style="background-color: #0db0ac; color: #fff;">
                  <tr>
                    <th scope="col">Código Barra</th>
                    <th scope="col">Descripción</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let cb of codigos | paginate: {itemsPerPage:5, currentPage:p};">
                    <td>{{cb.CodigoBarras}}</td>
                    <td>{{cb.Descripcion}}</td>
                    <td>
                      <button class="btn btn-info" (click)="eliminarCB(cb)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-trash" viewBox="0 0 16 16">
                          <path
                            d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                          <path fill-rule="evenodd"
                            d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                        </svg>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div *ngIf="confirmandoCodigo">
                <span style="font-size: 18px;">Cargando...</span>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-success"
                style="background-color: #004993; border: 1px solid #004993;" (click)="confirmarCB()">Aceptar</button>
              <button type="button" class="btn btn-danger"
                style="color: #004993; background-color: #fff; border: 2px solid #004993;"
                data-dismiss="modal">Cerrar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <button data-target="#modalOR" data-toggle="modal" >or</button> -->

  <!-- <app-impresion-guia></app-impresion-guia> -->
  <!-- modal clientes corporativos -->
  <div>
    <div class="modal" tabindex="1" id="modalClientesCorpo" data-backdrop="static" data-keyboard="false">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 style="color: #004993;" class="modal-title">Clientes</h5>
          </div>
          <div class="modal-body">
            <div class="container">
              <div class="row justify-content-md-center">
                <div class="col-3">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Código</span>
                    </div>
                    <input type="text" class="form-control" [(ngModel)]="codigoCliente" maxlength="5">
                  </div>
                </div>
                <div class="col-5">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Razón Social</span>
                    </div>
                    <input type="text" class="form-control" [(ngModel)]="razonSocial" maxlength="50">
                  </div>
                </div>
                <div class="col-3">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text">CUIT</span>
                    </div>
                    <input type="text" class="form-control" [(ngModel)]="cuit" maxlength="11">
                  </div>
                </div>
                <div class="col-1">
                  <div class="form-check">
                    <button title="Buscar clientes" class="btn btn-success"
                      style="background-color: #004993; border: 1px solid #004993; margin-left: -18px;" type="button"
                      (click)="buscarClientes()">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-search" viewBox="0 0 16 16">
                        <path
                          d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <table class="table">
                <thead style="background-color: #0db0ac; color: #fff;">
                  <tr>
                    <th scope="col">Código</th>
                    <th scope="col">Razón Social</th>
                    <th scope="col">CUIT</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let cliente of clientesCorporativos | paginate: {itemsPerPage:15, currentPage:p};">
                    <td>{{cliente.Codigo}}</td>
                    <td>{{cliente.RazonSocial}}</td>
                    <td>{{cliente.NroCUIT}}</td>
                    <td>
                      <button title="Seleccionar cliente"
                        style="display: flex; justify-content: center; align-items: center; height: 40px;"
                        class="btn btn-info" (click)="seleccionarCliente(cliente)">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2-square"
                          fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd"
                            d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                          <path fill-rule="evenodd"
                            d="M1.5 13A1.5 1.5 0 0 0 3 14.5h10a1.5 1.5 0 0 0 1.5-1.5V8a.5.5 0 0 0-1 0v5a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V3a.5.5 0 0 1 .5-.5h8a.5.5 0 0 0 0-1H3A1.5 1.5 0 0 0 1.5 3v10z" />
                        </svg>
                      </button>
                    </td>
                  </tr>
                </tbody>
                <pagination-controls (pageChange)="p=$event"></pagination-controls>
              </table>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger"
                style="color: #004993; background-color: #fff; border: 2px solid #004993;"
                data-dismiss="modal">Cerrar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- modal clientes eventuales -->
  <div>
    <div class="modal" tabindex="1" id="modalClientesEv" data-backdrop="static" data-keyboard="false">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 style="color: #004993;" class="modal-title">Clientes Eventuales</h5>
          </div>
          <div class="modal-body">
            <div class="container">
              <div class="row justify-content-md-center">
                <div class="col-3">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text">DNI</span>
                    </div>
                    <input type="text" class="form-control" [(ngModel)]="dniEv" maxlength="5"
                      (input)="filtrarClientes('dni')">
                  </div>
                </div>
                <div class="col-5">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Nombre Completo</span>
                    </div>
                    <input type="text" class="form-control" [(ngModel)]="nombreCompleto" maxlength="50"
                      (input)="filtrarClientes('nombre')">
                  </div>
                </div>
                <div class="col-3">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text">CUIT</span>
                    </div>
                    <input type="text" class="form-control" [(ngModel)]="cuitEv" maxlength="11"
                      (input)="filtrarClientes('cuit')">
                  </div>
                </div>
                <div class="col-1">
                  <div class="form-check">
                    <button title="Buscar clientes" class="btn btn-success"
                      style="background-color: #004993; border: 1px solid #004993; margin-left: -18px;" type="button"
                      (click)="buscarClientesEventuales()">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-search" viewBox="0 0 16 16">
                        <path
                          d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <table class="table">
                <thead style="background-color: #0db0ac; color: #fff;">
                  <tr>
                    <th scope="col">DNI</th>
                    <th scope="col">Nombre Completo</th>
                    <th scope="col">CUIT</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let cliente of clientesEventualesBusq | paginate: {itemsPerPage:15, currentPage:p};">
                    <td>{{cliente.NroDocumento}}</td>
                    <td>{{cliente.Nombre}}&nbsp;{{cliente.Apellido}}</td>
                    <td>{{cliente.NroCUIT}}</td>
                    <td>
                      <button title="Seleccionar cliente"
                        style="display: flex; justify-content: center; align-items: center; height: 40px;"
                        class="btn btn-info" (click)="seleccionarClienteEventual(cliente)">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2-square"
                          fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd"
                            d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                          <path fill-rule="evenodd"
                            d="M1.5 13A1.5 1.5 0 0 0 3 14.5h10a1.5 1.5 0 0 0 1.5-1.5V8a.5.5 0 0 0-1 0v5a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V3a.5.5 0 0 1 .5-.5h8a.5.5 0 0 0 0-1H3A1.5 1.5 0 0 0 1.5 3v10z" />
                        </svg>
                      </button>
                    </td>
                  </tr>
                </tbody>
                <pagination-controls (pageChange)="p=$event"></pagination-controls>
              </table>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger"
                style="color: #004993; background-color: #fff; border: 2px solid #004993;"
                data-dismiss="modal">Cerrar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- modal autorizaciones ocasionales -->
  <div>
    <div class="modal" tabindex="1" id="modalAutorizaciones" data-backdrop="static" data-keyboard="false">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 style="color: #004993;" class="modal-title">Búsqueda de Autorizaciones</h5>
          </div>
          <div class="modal-body">
            <div class="container">
              <div class="row">
                <div class="col-3">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Código Único</span>
                    </div>
                    <input type="text" class="form-control" [(ngModel)]="codAutorizacionBusq" maxlength="20"
                      (input)="filtrarAutorizaciones('codigo unico')">
                  </div>
                </div>
                <div class="col-3">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Nombre</span>
                    </div>
                    <input type="text" class="form-control" [(ngModel)]="nomAutorizacionBusq" maxlength="50"
                      (input)="filtrarAutorizaciones('nombre')">
                  </div>
                </div>
                <div class="col-3">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Apellido</span>
                    </div>
                    <input type="text" class="form-control" [(ngModel)]="apeAutorizacionBusq" maxlength="50"
                      (input)="filtrarAutorizaciones('apellido')">
                  </div>
                </div>
                <div class="col-3">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text">CUIT/DNI</span>
                    </div>
                    <input type="text" class="form-control" [(ngModel)]="dniAutorizacionBusq" maxlength="11"
                      (input)="filtrarAutorizaciones('dni')">
                  </div>
                </div>

              </div>
              <!-- <div class="row justify-content-md-end">
              <div class="col-1">
                <div class="form-check">
                  <button title="Buscar autorizaciones" class="btn btn-success" style="background-color: #004993; border: 1px solid #004993; margin-left: -18px;" type="button"
                    >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                      <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                    </svg>
                  </button>
                </div>
              </div>
            </div> -->
              <table class="table mt-3">
                <thead style="background-color: #0db0ac; color: #fff;">
                  <tr>
                    <th scope="col">Código Único</th>
                    <th scope="col">Nombre</th>
                    <th scope="col">Apellido</th>
                    <th scope="col">N° de Documento</th>
                    <th scope="col">Unidad Venta</th>
                    <th scope="col">Modalidad</th>
                    <th scope="col">Total</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let aut of autorizacionesFiltro | paginate: {itemsPerPage:15, currentPage:p};">
                    <td>{{aut.CodigoUnico}}</td>
                    <td>{{aut.Nombre}}</td>
                    <td>{{aut.Apellido}}</td>
                    <td>{{aut.NroDocumento}}</td>
                    <td>{{aut.UnidadVenta}}</td>
                    <td>{{aut.Modalidad}}</td>
                    <td>{{aut.Total}}</td>
                    <td>
                      <button title="Seleccionar Autorizacion"
                        style="display: flex; justify-content: center; align-items: center; height: 40px;"
                        class="btn btn-info" (click)="seleccionarAutorizacion(aut)">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2-square"
                          fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd"
                            d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                          <path fill-rule="evenodd"
                            d="M1.5 13A1.5 1.5 0 0 0 3 14.5h10a1.5 1.5 0 0 0 1.5-1.5V8a.5.5 0 0 0-1 0v5a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V3a.5.5 0 0 1 .5-.5h8a.5.5 0 0 0 0-1H3A1.5 1.5 0 0 0 1.5 3v10z" />
                        </svg>
                      </button>
                    </td>
                  </tr>
                </tbody>
                <pagination-controls (pageChange)="p=$event"></pagination-controls>
              </table>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger"
                style="color: #004993; background-color: #fff; border: 2px solid #004993;"
                data-dismiss="modal">Cerrar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-orden-retiro [ordenRetiro]="ordenRetiro" [localUV]="localUv" [localCO]="localCo" [localservicio]="localServicio"
    [localCf]="localCF" [domicilioLocal]="localDomicilio"></app-orden-retiro>
