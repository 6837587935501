<div class="modal" tabindex="1" id="modalResponsables" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Responsables</h5>
        </div>
        <div class="modal-body">
          <div class="container">
            <div class="row">
              <div class="col">
                <label>DNI</label>
                <input type="number" class="form-control" id="dniBusq" [(ngModel)]="dniBusqueda" name="dniBusq" (input)="buscarResponsable($event.target.value, 'dni')" placeholder="Buscar ...">
              </div>
              <div class="col">
                <label>Nombre y Apellido</label>
                <input type="text" class="form-control" id="apeNomBusq" [(ngModel)]="apeNomBusqueda" name="apeNomBusq" (input)="buscarResponsable($event.target.value, 'apeNom')" placeholder="Buscar ...">
              </div>
            </div>
          </div><br>
          <table class="table">
            <thead class="thead-dark">
              <tr>
                <th scope="col" hidden>ID</th>
                <th scope="col" style="width: 50px; text-align: center;">DNI/CUIT</th>
                <th scope="col" style="text-align: center;">Apellido</th>
                <th scope="col" style="text-align: center;">Nombre</th>
                <th scope="col" style="text-align: center;"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let responsable of responsablesBusqueda | paginate: {itemsPerPage:10, currentPage:p};">
                <td hidden>{{responsable.ResponsableID}}</td>
                <td style="text-align: center;">{{responsable.NroDocumento}}</td>
                <td style="text-align: center;">{{responsable.Apellido}}</td>
                <td style="text-align: center;">{{responsable.Nombre}}</td>
                <td style="text-align: center;">
                  <button class="btn btn-info" (click)="seleccionarResponsable(responsable)">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-person-check-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm9.854-2.854a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                    </svg>
                  </button>
              </td>
              </tr>
            </tbody>
            <pagination-controls (pageChange)="p=$event"></pagination-controls>
          </table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" (click)="cerrarResponsables()">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
