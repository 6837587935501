
<div (focus)="mostrarLocales()" class="modal" tabindex="1" id="modalAgencia" data-backdrop="static" data-keyboard="false">

    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Agencias</h5>
        </div>
        <div class="modal-body">
          <div class="container">
            <div class="row">
              <div class="col">
                <label>N° de Sucursal</label>
                <input type="number" class="form-control" [(ngModel)]="nrosucursal" placeholder="Buscar ..."  (input)="buscarLocal($event.target.value, 'nroSuc')">
              </div>
              <div class="col">
                <label>Razón social</label>
                <input type="text" class="form-control" [(ngModel)]="razonSocial" placeholder="Buscar ..."  (input)="buscarLocal($event.target.value, 'nomSuc')">
              </div>
            </div>
          </div><br>
          <table class="table">
            <thead class="thead-dark">
              <tr>
                <th scope="col" hidden>ID</th>
                <th scope="col" style="width: 50px; text-align: center;">Sucursal</th>
                <th scope="col" style="text-align: center;">Razón Social</th>
                <th scope="col" style="text-align: center;"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let local of localesBusqueda | paginate: {itemsPerPage:10, currentPage:p};">
                <td hidden>{{local.LocalID}}</td>
                <td style="text-align: center;">{{local.CodigoLocal}}</td>
                <td style="text-align: center;">{{local.LocalDescrip}}</td>
                <td style="text-align: center;">
                  <button class="btn btn-info" (click)="seleccionarLocal(local)">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                        <path fill-rule="evenodd" d="M1.5 13A1.5 1.5 0 0 0 3 14.5h10a1.5 1.5 0 0 0 1.5-1.5V8a.5.5 0 0 0-1 0v5a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V3a.5.5 0 0 1 .5-.5h8a.5.5 0 0 0 0-1H3A1.5 1.5 0 0 0 1.5 3v10z"/>
                    </svg>
                  </button>
              </td>
              </tr>
            </tbody>
            <pagination-controls (pageChange)="p=$event"></pagination-controls>
          </table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal">Cerrar</button>
        </div>
      </div>
    </div>
  </div>


