import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Menu, OpcionMenu, Usuario } from 'src/app/models/Usuario';
import { UsuarioService } from 'src/app/services/usuario.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

  usuario:Usuario;
  mostrarOpciones:boolean=false;
  menu:Menu[]=[]

  constructor(private usuarioService:UsuarioService, private router:Router) { }

  ngOnInit(): void {
    this.usuarioService.agregarUsuarioObservable.subscribe((user)=>{
      if(user!==null){
        this.usuario=user;
        this.generarMenu(this.usuario.OpcionesMenu);
        this.mostrarOpciones=true;

      }else{
        this.menu=[];
        this.mostrarOpciones=false;
      }
    })
    if((this.usuario==null || this.usuario==undefined) && (this.menu==null || this.menu.length==0)){
      this.usuario=JSON.parse(sessionStorage.getItem('user'));
      if(this.usuario!=null){
        this.generarMenu(this.usuario.OpcionesMenu);
        this.mostrarOpciones=true;
      }
    }
  }

  generarMenu(opciones:OpcionMenu[]){

    this.menu=[];
    for(let op of opciones){
      if(op.OpcionPadreID=="" || op.OpcionPadreID==null){
        var opcion:Menu={
          OpcionID:op.OpcionID,
          OpcionPadreID:op.OpcionPadreID,
          UsuarioID:op.UsuarioID,
          Titulo:op.Titulo,
          Url:op.Url,
          Descripcion:op.Descripcion,
          OrdenVisual:op.ordenVisual
        }
        var opcionesSubMenu:OpcionMenu[]=opciones.filter(res=>{
          return res.OpcionPadreID==op.OpcionID;
        })
        if(opcionesSubMenu.length>0){
          var submenu:Menu[]=this.generarSubmenu(opcionesSubMenu, opciones);
          opcion.Submenu=submenu;
        }
        this.menu.push(opcion);
      }
    }

  }

  generarSubmenu(opciones:OpcionMenu[], opcionesMenuUsu:OpcionMenu[]):Menu[]{
    var submenu:Menu[]=[];
    for(let op of opciones){
      var opcion:Menu={
        OpcionID:op.OpcionID,
        OpcionPadreID:op.OpcionPadreID,
        UsuarioID:op.UsuarioID,
        Titulo:op.Titulo,
        Url:op.Url,
        Descripcion:op.Descripcion,
        OrdenVisual:op.ordenVisual
      }
      var opcionesSubMenu:OpcionMenu[]=opcionesMenuUsu.filter(res=>{
        return res.OpcionPadreID==op.OpcionID;
      })
      if(opcionesSubMenu.length>0){
        var submenu2=this.generarSubmenu(opcionesSubMenu, opcionesMenuUsu);
        opcion.Submenu=submenu2;
      }
      submenu.push(opcion);
    }
    return submenu;
  }

  salir(){
    this.mostrarOpciones=false;
    this.usuarioService.salir();

    //indow.location.href='home';
    sessionStorage.setItem('user',null);
    this.router.navigate(['/home'])
    this.usuario=null;
  }

}
