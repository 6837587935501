import { Component, Input, OnInit } from '@angular/core';
import { AutorizadosPost, Cliente, NominaAutorizados } from 'src/app/models/Cliente';
import { ClienteService } from 'src/app/services/cliente.service';
declare var $:any
@Component({
  selector: 'app-modal-autorizados',
  templateUrl: './modal-autorizados.component.html',
  styleUrls: ['./modal-autorizados.component.scss', './estilosModalAutorizados.css']
})
export class ModalAutorizadosComponent implements OnInit {

  @Input() clienteCorpo: Cliente;
  autorizadosFiltro:NominaAutorizados[] = [];
  autorizadosCorpo: NominaAutorizados[] = [];
  autorizado:NominaAutorizados={};
  p: number = 1;
  //filtros
  dniBusqueda:string='';
  apeNomBusqueda:string='';
  
  constructor(private clienteService:ClienteService) { }

  ngOnInit(): void {
  }

  buscarAutorizados() {
    var clienteId: AutorizadosPost = {}
    clienteId.ClienteID = this.clienteCorpo.ClienteID;
    this.clienteService.getNominaAutorizadosCorporativo(clienteId).subscribe(autorizadosApi => {
      this.autorizadosCorpo = autorizadosApi.Autorizados;
      this.autorizadosFiltro=autorizadosApi.Autorizados;
    })
  }

  seleccionarAutorizado(autorizado: NominaAutorizados) {
    this.autorizado=autorizado;
    this.clienteService.agregarAutorizado(autorizado);
    $("#modalAutorizados").modal('hide');
  }

  filtrarAutorizados(event, campo:string){
    if(campo==='apeNom'){
      if(this.dniBusqueda==='' || this.dniBusqueda==='null' || this.dniBusqueda===null){
        this.autorizadosFiltro=this.autorizadosCorpo.filter(auto=>{
          if(auto.Apellido.toLowerCase().includes(event.toLowerCase())){
            return auto;
          }
          if(auto.Nombre.toLowerCase().includes(event.toLowerCase())){
            return auto;
          }
        })
      }else{
        this.autorizadosFiltro=this.autorizadosCorpo.filter(auto=>{
          return auto.NroDocumento.toString().includes(this.dniBusqueda);
        })
        this.autorizadosFiltro=this.autorizadosFiltro.filter(auto=>{
          if(auto.Apellido.toLowerCase().includes(event.toLowerCase())){
            return auto;
          }
          if(auto.Nombre.toLowerCase().includes(event.toLowerCase())){
            return auto;
          }
        })
      }
    }else if(campo==='dni'){
      if(event==="" || event==='null' || event===null){
        if(this.apeNomBusqueda===''){
          this.autorizadosFiltro=this.autorizadosCorpo;
        }else{
          this.autorizadosFiltro=this.autorizadosCorpo.filter(auto=>{
            if(auto.Apellido.toLowerCase().includes(this.apeNomBusqueda.toLowerCase())){
              return auto;
            }
            if(auto.Nombre.toLowerCase().includes(this.apeNomBusqueda.toLowerCase())){
              return auto;
            }
          })
        }
      }else{
        if(this.apeNomBusqueda===''){
          this.autorizadosFiltro=this.autorizadosCorpo.filter(auto=>{
            return auto.NroDocumento.toString().includes(event);
          })
        }else{
          this.autorizadosFiltro=this.autorizadosCorpo.filter(auto=>{
            if(auto.Apellido.toLowerCase().includes(this.apeNomBusqueda.toLowerCase())){
              return auto;
            }
            if(auto.Nombre.toLowerCase().includes(this.apeNomBusqueda.toLowerCase())){
              return auto;
            }
          })
          this.autorizadosFiltro=this.autorizadosFiltro.filter(auto=>{
            return auto.NroDocumento.toString().includes(event);
          })
        }
      }
    }
  }

}
