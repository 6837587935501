<div class="container-fluid mt-5" style="width: 94%;">
    <div class="row">
        <div class="col-4"><b>Agencia Conectada: {{usuario.AgenciaRazonSocial}}</b></div>
    </div>
    <div class="row">
      <div class="col-3">
        <label>Nro Hoja Ruta Interna</label>
        <input type="number" class="form-control" id="busqnroHRI" [(ngModel)]="nroHojaRutaBusq" name="busqnroHRI" placeholder="Buscar ..."
        (input)="buscarHRI()">
      </div>
    </div>
    <div class="row justify-content-end">
        <div class="col-3">
            <button type="button" class="btn btn-primary" style="margin-left: 10px;" (click)="buscarGuiasPendientes()">Nueva hoja ruta interna</button>
        </div>
    </div>
      <br>
      <!-- <jqxGrid #hojasRutaGrid class="grid mt-2"
          [width]="getWidth()" [source]="dataAdapter" [columns]="columns"
          [pageable]="true" [autoheight]="true" *ngIf="!cargando">
      </jqxGrid><br> -->
      <table class="table table-bordered table-sm" *ngIf="!cargando">
        <thead>
            <tr>
              <th scope="col">Nro Hoja ruta interno</th>
              <th scope="col">Agencia Destino</th>
              <th scope="col">Fecha</th>
              <th scope="col">Estado</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let hri of hojasRutaFiltro | paginate: {itemsPerPage:10, currentPage:p};">
              <th>{{hri.NroHojaRutaInterno}}</th>
              <td>{{hri.AgenciaDestinoDescrip}}</td>
              <td>{{hri.Fecha}}</td>
              <td>{{hri.EstadoHojaRutaInternoDescrip}}</td>
              <td>
                  <button class="btn btn-info btn-sm" title="Editar" (click)="editarHRI(hri)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                        <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                      </svg>
                  </button>
              </td>
              <td>
                  <button class="btn btn-danger btn-sm" title="Eliminar" *ngIf="hri.Pendiente" (click)="eliminarHRI(hri)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                        <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                      </svg>
                  </button>
              </td>
            </tr>
          </tbody>
          <pagination-controls (pageChange)="p=$event"></pagination-controls>
      </table>
     <div *ngIf="cargando">
        <h4>Cargando...</h4>
    </div>
    <br><br>
</div>
<!-- modal nueva hoja de ruta interno -->
<div>
  <div class="modal" tabindex="-1" id="modalNuevaHojaRutaInterna" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Emisión de hoja de ruta interna</h5>
        </div>
        <div class="modal-body">
          <div class="form-group" hidden>
            <label>HRIID</label>
            <input class="form-control" type="text" [(ngModel)]="hojaRutaInternoNew.HojaRutaInternoID" name="idHRI" id="idHRI">
          </div>
          <div class="container">
            <div *ngIf="cargandoEmision" style="font-size: 18px; font-weight: bold;">Cargando...</div><br>
            <div class="row justify-content-between">
              <div class="col">
                <div class="form-group">
                  <label>Nro hoja ruta interna</label>
                  <input class="form-control" type="number" required [(ngModel)]="nroHojaRutaInterno" disabled>                  
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <button class="btn btn-info" style="margin-top: 30px; margin-left: 350px;" (click)="buscarPorCodigo()">Buscar por código</button>
                </div>
              </div>
            </div>
            <table class="table table-bordered table-sm" *ngIf="!cargando">
              <thead>
                  <tr>
                    <th scope="col" *ngIf="hojaRutaInternoNew.HojaRutaInternoIDspk==0 || (hojaRutaInternoNew.HojaRutaInternoIDspk>0 && hojaRutaInternoNew.EstadoHojaRutaInternoID==3)"></th>
                    <th scope="col">Codigo</th>
                    <th scope="col">Cliente</th>
                    <th scope="col">Destinatario</th>
                    <th scope="col">Cantidad de bultos</th>
                    <th scope="col">Unidad de venta</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let guia of guiasPendiente"> <!--  | paginate: {itemsPerPage:10, currentPage:p}; -->
                    <th *ngIf="hojaRutaInternoNew.HojaRutaInternoIDspk==0 || (hojaRutaInternoNew.HojaRutaInternoIDspk>0 && hojaRutaInternoNew.EstadoHojaRutaInternoID==3)">
                      <input type="checkbox" name="" [id]="guia.GuiaID" (change)="seleccionarGuias(guia, '', $event.target.checked)">
                    </th>
                    <td>{{guia.Codigo}}</td>
                    <td>{{guia.ClienteDescrip}}</td>
                    <td>{{guia.Destinatario}}</td>
                    <td>{{guia.CantidadBultosTotal}}</td>
                    <td>{{guia.UnidadVentaDescrip}}</td>
                  </tr>
                </tbody>
                <!-- <pagination-controls (pageChange)="p=$event"></pagination-controls> -->
            </table>
            <div class="row" *ngIf="hojaRutaInternoNew.HojaRutaInternoIDspk==0 || (hojaRutaInternoNew.HojaRutaInternoIDspk>0 && hojaRutaInternoNew.EstadoHojaRutaInternoID==3)">
              <div class="col-12">
                &nbsp;&nbsp;<input type="checkbox" name="todos" id="todos" (change)="seleccionarGuias(null, 'todos', $event.target.checked)">&nbsp;
                <label>Todos</label>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label>Observaciones</label><br>
                <textarea class="form-control" [(ngModel)]="observaciones" name="obs"
                style="width: 100%; height: 100px;"  maxlength="100"></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-warning" (click)="guardarHRI()" [disabled]="hojaRutaInternoNew.EstadoHojaRutaInternoID==1">Guardar</button>
          <button type="button" class="btn btn-success" *ngIf="hojaRutaInternoNew.HojaRutaInternoIDspk>0" (click)="confirmarHRI()" [disabled]="hojaRutaInternoNew.EstadoHojaRutaInternoID==1">Confirmar</button>
          <button type="button" class="btn btn-info" *ngIf="hojaRutaInternoNew.EstadoHojaRutaInternoID==1" (click)="imprimir()">Imprimir</button>
          <button type="button" class="btn btn-danger" (click)="cerrarModal()">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- modal para agregar guias -->
<div>
  <div class="modal" tabindex="-1" id="buscarGuiaPorCodigo" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Agregar guías a hoja de ruta interna</h5>
        </div>
        <div class="modal-body">
          <div class="container">
            <div class="row justify-content-between">
              <div class="col">
                <div class="form-group">
                  <label>Guía</label>
                  <input class="form-control" type="text" required [(ngModel)]="codigoBusq">                  
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <button class="btn btn-info" style="margin-top: 30px; margin-left: 235px;" (click)="agregarGuias()">Agregar</button>
                </div>
              </div>
            </div>
            
            <table class="table table-bordered table-sm" *ngIf="guiasBuscadas.length>0">
              <thead>
                  <tr>
                    <th scope="col">Codigo</th>
                    <th scope="col">Cliente</th>
                    <th scope="col">Destinatario</th>
                    <th scope="col">Cantidad de bultos</th>
                    <th scope="col">Unidad de venta</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let guia of guiasBuscadas"> <!--  | paginate: {itemsPerPage:10, currentPage:p}; -->
                    <td>{{guia.Codigo}}</td>
                    <td>{{guia.ClienteDescrip}}</td>
                    <td>{{guia.Destinatario}}</td>
                    <td>{{guia.CantidadBultosTotal}}</td>
                    <td>{{guia.UnidadVentaDescrip}}</td>
                    <td>
                      <button class="btn btn-danger btn-sm" title="Eliminar" (click)="eliminarGuia(guia)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                            <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                          </svg>
                      </button>
                    </td>
                  </tr>
                </tbody>
                <!-- <pagination-controls (pageChange)="p=$event"></pagination-controls> -->
            </table><br><br>
            <div class="row justify-content-end">
              <div class="col-4">
                <div class="form-group">
                  <button class="btn btn-success" (click)="confirmar()">Confirmar</button>
                  <button class="btn btn-danger" style="margin-left: 10px;" (click)="cancelar()">Cancelar</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>