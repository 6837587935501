import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { DomicilioId, LocalidadesGet, Provincia, ProvinciaId, ProvinciasGetAll, DomicilioLocal, LocalidadPostBusqueda} from '../models/Domicilio';
import { UrlService } from './url.service';

@Injectable({
  providedIn: 'root'
})
export class DomicilioService {


  domicilio:DomicilioLocal={};
  agregado:boolean;
  private agregarDomicilioSubject= new Subject<DomicilioLocal>();
  agregarDomicilioObservable=this.agregarDomicilioSubject.asObservable();
  private mandarAgregadoSubject=new Subject<boolean>();
  mandarAgregadoObservable=this.agregarDomicilioSubject.asObservable();

  //url de api
  urlProvincias:string=this.urlService.urlApi+"/api/Domicilio/ObtenerProvincias";
  urlLocalidades:string=this.urlService.urlApi+"/api/Domicilio/ObtenerLocalidades";
  urlDomicilio:string=this.urlService.urlApi+"/api/Domicilio/ObtenerDomicilio";
  urlLocalidadesByNombre:string=this.urlService.urlApi+"/api/Domicilio/ObtenerLocalidadesByNombre" ;
 
  constructor(private http:HttpClient, private urlService:UrlService) { }

  agregarDomicilio(domicilio:DomicilioLocal){
    this.domicilio=domicilio;
    this.agregarDomicilioSubject.next(domicilio);
  }

  mandarAgregado(agregado:boolean){
    this.agregado=agregado;
    this.mandarAgregadoSubject.next(agregado);
  }

  //métodos api
  getAllProvincias():Observable<ProvinciasGetAll>{
    return this.http.post<ProvinciasGetAll>(this.urlProvincias,"");
  }

  getAllLocalidadesByIdProv(provinciaId:ProvinciaId):Observable<LocalidadesGet>{
    return this.http.post<LocalidadesGet>(this.urlLocalidades, provinciaId);
  }

  getOneDomicilio(domicilioId:DomicilioId):Observable<DomicilioLocal>{
    return this.http.post<DomicilioLocal>(this.urlDomicilio, domicilioId);
  }

  getLocalidadesByNombre(localidadPost:LocalidadPostBusqueda):Observable<LocalidadesGet>{
    return this.http.post<LocalidadesGet>(this.urlLocalidadesByNombre, localidadPost);
  }
}
