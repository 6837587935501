<div class="container-fluid" style="width: 1350px; margin-top: 50px;">
    <div class="row align-items-center rounded mt-5" style="border: solid 1px grey; height: 50px;">
        <h4 class="mt-1">&nbsp;&nbsp; Conformación Masiva</h4>
    </div><br>
    <div class="row justify-content-md-center">
        <div class="col-4">
            <div class="form-group">
                <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Número de Guía</span>
                    </div>
                    <input type="text" aria-label="First name" class="form-control" [(ngModel)]="nroGuia" (keyup.enter)="agregar()">
                </div>
            </div> 
        </div>
        <div class="col-1">
            <button class="btn btn-primary" (click)="agregar()">Aceptar</button>
        </div>
        <div class="col-1">
            <span style="font-size: 24px; font-weight: bold;">Restan:{{restantes}}</span>
        </div>
    </div>
    <div class="row justify-content-md-center" *ngIf="guias.length>0">
       <div class="col-6" style="display: flex; align-items: center; justify-content: center;">
            <table class="table table-borderless">
                <thead>
                <tr>
                    <th scope="col">Nro. de Guía</th>
                    <th scope="col">Observación</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let g of guias">
                    <td>{{g.nroGuia}}</td>
                    <td>
                        <div class="form-group">
                            <div class="input-group">
                                <input type="text" aria-label="First name" class="form-control" [id]="'cod'+g.guiaID">
                                <input type="text" aria-label="Last name" class="form-control" [id]="'descrip'+g.guiaID">
                                <button type="button" class="btn btn-primary" (click)="seleccionarObs(g.guiaID, '')"> <!-- data-target="#modalObservaciones" data-toggle="modal" -->
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                                        <path fill-rule="evenodd" d="M1.5 13A1.5 1.5 0 0 0 3 14.5h10a1.5 1.5 0 0 0 1.5-1.5V8a.5.5 0 0 0-1 0v5a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V3a.5.5 0 0 1 .5-.5h8a.5.5 0 0 0 0-1H3A1.5 1.5 0 0 0 1.5 3v10z"/>
                                    </svg>
                                </button>
                                <button type="button" class="btn btn-secondary" (click)="abrirModalDatosFactura(g.nroGuia, g.guiaID)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                        <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                      </svg>
                                </button>
                            </div>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
       </div>
    </div>
    <div class="row" *ngIf="guiasNoConformadas"><!-- *ngIf="guiasNoRecibidas" -->
        <div class="col">
            <div class="alert alert-dark" style="text-align: center;">Las siguientes guías no están en condiciones de ser conformadas: {{guiasNoConf}}</div>
        </div>
    </div>
    <div *ngIf="guiasIncompletas"><!-- *ngIf="guiasIncompletas" -->
        <div style="font-style: italic; font-size: 20px;">Debe completar los datos de las siguientes guías para conformarlas:</div>
        <div class="row justify-content-md-center">
            <div class="col-12" style="display: flex; align-items: center; justify-content: center;">
                <table class="table table-borderless">
                    <thead>
                    <tr>
                        <th scope="col">Nro. de Guía</th>
                        <th scope="col">Agencia Origen</th>
                        <th scope="col">Agencia Destino</th>
                        <th scope="col">Tipo Doc.</th>
                        <th scope="col">Nro. Doc.</th>
                        <th scope="col">Nombre</th>
                        <th scope="col">Apellido</th>
                        <th scope="col">Observación</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let g of guiasIncomp"><!-- guiasIncomp -->
                        <td>{{g.nroGuia}}</td>
                        <td>{{g.agenciaOrigenDescrip}}</td>
                        <td>{{g.agenciaDestinoDescrip}}</td>
                        <td>
                            <div class="form-group">
                                <select class="custom-select" style="margin-top: 32px;"
                                     [(ngModel)]="g.tipoDoc" >
                                    <option value="" selected disabled>Tipo documento</option>
                                    <option *ngFor="let tipoDoc of tiposDoc" [value]="tipoDoc.TipoDocumentoID">
                                        {{tipoDoc.TipoDocumentoDescrip}}</option>
                                </select>
                            </div>
                        </td>
                        <td>
                            <div class="form-group">
                                <input type="text" class="form-control" [(ngModel)]="g.nroDoc">
                            </div>
                        </td>
                        <td>
                            <div class="form-group">
                                <input type="text" class="form-control" [(ngModel)]="g.nombre">
                            </div>
                        </td>
                        <td>
                            <div class="form-group">
                                <input type="text" class="form-control" [(ngModel)]="g.apellido">
                            </div>
                        </td>
                        <td>
                            <div class="form-group">
                                <div class="input-group">
                                    <input type="text" aria-label="First name" class="form-control" [id]="'codI'+g.guiaID">
                                    <input type="text" aria-label="Last name" class="form-control" [id]="'descripI'+g.guiaID">
                                    <button type="button" class="btn btn-primary" (click)="seleccionarObs(g.guiaID, 'incompleta')">
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                                            <path fill-rule="evenodd" d="M1.5 13A1.5 1.5 0 0 0 3 14.5h10a1.5 1.5 0 0 0 1.5-1.5V8a.5.5 0 0 0-1 0v5a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V3a.5.5 0 0 1 .5-.5h8a.5.5 0 0 0 0-1H3A1.5 1.5 0 0 0 1.5 3v10z"/>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="row justify-content-end" style="margin-top: 25px;">
        <div class="col-3">
            <button type="button" class="btn btn-primary" style="margin-left: 50px;" *ngIf="guias.length>0 || guiasIncomp.length>0" (click)="confirmar()">Confirmar</button>
            <button type="button" class="btn btn-primary" style="margin-left: 20px;" *ngIf="guiasIncompletas" (click)="cancelar()">Cancelar</button>
        </div>
    </div><br><br>
</div>

<app-modal-observaciones></app-modal-observaciones>

<!-- modal datos factura remito -->
<div class="modal" tabindex="1" id="modalDatosFactura" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Datos de la factura que se realiza por el remito</h5>
        </div>
        <div class="modal-body">
          <div class="container">
            <div class="row align-items-center rounded" style="border: solid 1px grey; height: 50px;">
                <h4 class="mt-1">&nbsp;&nbsp; Datos del cliente al que se le factura</h4>
            </div><br>
            <div class="row mb-5 align-items-center">
                <div class="col-3 mt-3">
                    <div class="form-check">
                        <input class="form-check-input" type="radio" [(ngModel)]="tipoClienteDestinatario"
                            name="opcionesClienteDestinatario" id="ckEventualDest" value="eventual"
                            style="width: 30px; height: 30px; vertical-align: middle;">
                        <label class="form-check-label rounded" for="exampleRadios1"
                            style="margin-left: 15px;width: 130px; height: 40px; border: solid 1px black; text-align: center; vertical-align: middle; color: white; background-color: black;">
                            <p style="margin-top: 8px; font-size: 14px;">EVENTUAL</p>
                        </label>
                    </div>
                </div>
                <div class="col-3 mt-3">
                    <div class="form-check">
                        <input class="form-check-input" type="radio" [(ngModel)]="tipoClienteDestinatario"
                            name="opcionesClienteDestinatario" id="ckCorpoDest" value="corporativo"
                            style="width: 30px; height: 30px; vertical-align: middle;">
                        <label class="form-check-label rounded" for="exampleRadios2"
                            style="margin-left: 15px;width: 130px; height: 40px; border: solid 1px black; text-align: center; vertical-align: middle; color: white; background-color: black;">
                            <p style="margin-top: 8px; font-size: 14px;">CORPORATIVO</p>
                        </label>
                    </div>
                </div>
                <div class="col-3 justify-content-end" style="margin-top: 20px;">
                    <button type="button" class="btn btn-primary" style="margin-left: 50px;" (click)="habilitar()">Habilitar</button>
                    <button type="button" class="btn btn-primary" style="margin-left: 20px;" (click)="limpiar()">Limpiar</button>
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <div class="form-group" *ngIf="codClienteD">
                        <label>Código Cliente</label>
                        <input type="text" class="form-control" [(ngModel)]="codClienteDestinatario" name="codCliDesti"
                            id="codCorpoDest">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <div class="form-group">
                        <label>Nombre</label>
                        <input type="text" class="form-control" required
                            [(ngModel)]="nombreDestinatario" name="nomDesti" #nomDesti="ngModel">
                        <div [hidden]="nombreDestinatario!=''" class="alert alert-danger mt-1"
                            style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-group">
                        <label>Apellido</label>
                        <input type="text" class="form-control" required
                            [(ngModel)]="apellidoDestinatario" name="apeDesti" #apeDesti="ngModel">
                        <div [hidden]="apellidoDestinatario!=''" class="alert alert-danger mt-1"
                            style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="row">
                        <div class="col-5">
                            <div class="form-group">
                                <select class="custom-select" style="margin-top: 32px;"
                                    required [(ngModel)]="tipoDocDestinatario" name="tipodocDesti"
                                    #tipodocDesti="ngModel">
                                    <option value="" selected disabled>Tipo documento</option>
                                    <option *ngFor="let tipoDoc of tiposDoc" [value]="tipoDoc.TipoDocumentoID">
                                        {{tipoDoc.TipoDocumentoDescrip}}</option>
                                </select>
                                <div [hidden]="tipoDocDestinatario!=''" class="alert alert-danger mt-1"
                                    style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo
                                    requerido</div>
                            </div>
                        </div>
                        <div class="col-7">
                            <div class="form-group">
                                <label>Nro Documento</label>
                                <input type="number" class="form-control" required
                                    [(ngModel)]="dniDestinatario" name="dniDesti" id="dniDesti" #dniDesti="ngModel" (blur)="buscarCliente()">
                                <div [hidden]="dniDestinatario!='' && dniDestinatario!=null" class="alert alert-danger mt-1"
                                    style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo
                                    requerido</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <div class="row">
                        <div class="col-4">
                            <div class="form-group">
                                <label>Cód. Area</label>
                                <input class="form-control" type="number" required [(ngModel)]="codAreaDestinatario"
                                    name="codADesti" #codADesti="ngModel">
                            </div>
                        </div>
                        <div class="col-8">
                            <div class="form-group">
                                <label>Teléfono</label>
                                <input class="form-control" type="number" required [(ngModel)]="telefonoDestinatario"
                                    name="telDesti" #telDesti="ngModel">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-group">
                        <label>Email</label>
                        <input class="form-control" type="text" required [(ngModel)]="mailDestinatario" name="mailDesti"
                            #mailDesti="ngModel">
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-group">
                        <select class="custom-select" style="margin-top: 32px;" required
                            [(ngModel)]="tipoCondicionIVADestinatario" name="tipoIvaDesti" #tipoIvaDesti="ngModel">
                            <option value="" selected disabled>Tipo Condición IVA</option>
                            <option *ngFor="let condIva of condicionesIva" [value]="condIva.CondicionIvaID">
                                {{condIva.CondicionIvaDescrip}}</option>
                        </select>
                        <div [hidden]="tipoCondicionIVADestinatario!=''" class="alert alert-danger mt-1"
                            style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <div class="form-group">
                        <label>Localidad</label>
                        <input class="form-control" required [(ngModel)]="localidadDestino" name="locOrig" id="locOrig"
                            list="localidadesDestino" type="text" #locOrig="ngModel" (input)="localidadIngresada($event)"
                            autocomplete="off">
                        <datalist id="localidadesDestino">
                            <option *ngFor="let localidad of localidadesDestino" [id]="localidad.localidadid">
                                {{localidad.LocalidadDescrip}}, {{localidad.ProvinciaDescrip}} ({{localidad.CodigoPostal}})
                            </option>
                        </datalist>
                        <div [hidden]="locOrig.valid" class="alert alert-danger mt-1"
                            style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-group">
                        <label>CP</label>
                        <input class="form-control" [(ngModel)]="cpDestino" required name="cpOrig" #cpOrig="ngModel"
                            type="number">
                        <div [hidden]="cpOrig.valid" class="alert alert-danger mt-1"
                            style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido
                        </div>
                    </div>
                </div>
                
            </div>
            <div class="row">
                <!-- <div class="col-4">
                    <div class="form-group">
                        <label>Calle Entrega</label>
                        <input class="form-control" type="text" required [(ngModel)]="calleDestino"
                            name="calleDest" #calleDest="ngModel">
                        <div class="alert alert-danger mt-1"
                            style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido
                        </div>
                    </div>
                </div> -->
                <div class="col-4">
                    <div class="form-group">
                        <label>Calle Origen</label>
                        <input class="form-control" list="calles" required [(ngModel)]="calleDestino" name="calleOrig"
                            id="calleOrig" #calleOrig="ngModel" type="text" (input)="calleIngresada($event)"
                            autocomplete="off">
                        <datalist id="calles">
                            <option *ngFor="let calle of callesDestino" [id]=calle.localidadid>{{calle.CalleDescrip}},
                                {{calle.AlturaDesde}}-{{calle.AlturaHasta}} ({{calle.CodigoPostal}})</option>
                        </datalist>
                        <div [hidden]="calleDestino!=''" class="alert alert-danger mt-1"
                            style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-group">
                        <label>Nro</label>
                        <input class="form-control" type="number" required [(ngModel)]="nroCalleDestinatario"
                            name="nroDesti" #nroDesti="ngModel">
                        <div [hidden]="nroCalleDestinatario!=''" class="alert alert-danger mt-1"
                            style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-group">
                        <label>Piso</label>
                        <input class="form-control" type="number" [(ngModel)]="pisoDestinatario"
                            name="pisoDesti">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label>Descripción</label>
                        <textarea class="form-control" [(ngModel)]="descDestinatario" name="descripDesti"></textarea>
                    </div>
                </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-success" (click)="registrar()">Registrar</button>
            <button type="button" class="btn btn-danger" data-dismiss="modal">Cancelar</button>
        </div>
      </div>
    </div>
  </div>


