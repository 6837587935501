<div (focus)="mostrarOR()" class="modal" tabindex="1" id="modalOR" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content" style="width: 95%; margin: auto;">
            <div class="modal-header" style="background: #0db0ac; color: #fff; border-bottom: transparent;">
                <h5 class="modal-title">Orden de Retiro</h5>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="container-fluid" style="width: 100%;">
                        <div class="row align-items-center rounded">
                            <h4 style="font-size: 2rem; color: #004993;" class="mt-1">&nbsp;&nbsp; 1° - Características de envío</h4>
                        </div><br>
                        <form #formEmision="ngForm">

                            <div class="row mt-3 align-items-center formSeccionUno">
                                <div class="col-4 mt-3">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" [(ngModel)]="tipoCliente" name="opcionesCliente"
                                            id="ckEv" value="eventual" style="width: 30px; height: 30px; vertical-align: middle;"
                                            >
                                        <label class="form-check-label rounded" for="exampleRadios1"
                                            style="margin-left: 15px;width: 130px; height: 40px; text-align: center; vertical-align: middle;">
                                            <p class="formSeccionUnoP" style="margin-top: 8px; font-size: 14px;"disabled>Eventual</p>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-4 mt-3">
                                    <div class="form-check">
                                        <input [(ngModel)]="codCliente" id="codCorp" name="cliCorp" type="text" style="width: 80px; height: 40px;"
                                            >
                                        <label class="form-check-label rounded"
                                            style="margin-left: 15px;width: 130px; height: 40px; text-align: center; vertical-align: middle;">
                                            <p class="formSeccionUnoP" style="margin-top: 8px; font-size: 14px;" disabled>Corporativo</p>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-4 mt-3">
                                    <div class="form-check" style="display: flex;">
                                        <input [(ngModel)]="nroOrdenRetiro" name="ordenR" type="text" style="width: 80px; height: 40px;"
                                            >
                                        <label class="form-check-label rounded"
                                            style="margin-left: 10px;width: 210px; text-align: center; vertical-align: middle;">
                                            <p class="formSeccionUnoP" style="margin-top: 8px; font-size: 14px;" disabled>Cliente web/Orden retiro</p>
                                        </label>
                                    </div>
                                </div>
                            </div>


                            <div class="row mt-3 align-items-center formSeccionDos">
                                <div class="col-3 mt-3" [hidden]="tipoPago=='1' || tipoPago=='6'">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" [(ngModel)]="tipoPago" name="tipoPag" id="cta"
                                            value="2" style="width: 30px; height: 30px; vertical-align: middle;" >
                                        <label class="form-check-label rounded" for="exampleRadios2"
                                            style="margin-left: 15px;width: 130px; height: 40px; text-align: center; vertical-align: middle;">
                                            <p class="formSeccionUnoP" style="margin-top: 8px; font-size: 14px;" >CTA/CTE</p>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-4 mt-3" *ngIf="mostrarContado">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" [(ngModel)]="tipoPago" name="tipoPag" id="Contado"
                                            value="1" style="width: 30px; height: 30px; vertical-align: middle;" >
                                        <label class="form-check-label rounded" for="exampleRadios2"
                                            style="margin-left: 15px;width: 130px; height: 40px; text-align: center; vertical-align: middle;">
                                            <p class="formSeccionUnoP" style="margin-top: 8px; font-size: 14px;" [hidden]="tipoPago=='2'">Contado</p>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-4 mt-3">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" [(ngModel)]="tipoPago" name="tipoPag" id="pagoDest"
                                            value="6" style="width: 30px; height: 30px; vertical-align: middle;">
                                        <!-- (change)="tipoPagoSeleccionado($event)" -->
                                        <label class="form-check-label rounded" for="exampleRadios2"
                                            style="margin-left: 15px;width: 130px; height: 40px; text-align: center; vertical-align: middle;">
                                            <p class="formSeccionUnoP" style="margin-top: 8px; font-size: 14px;" disabled>Pago en destino</p>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-4 mt-3">
                                    <div class="form-check">
                                        <label style="color: red; font-style: italic;">{{leyendaPago}}</label>
                                    </div>
                                </div>
                            </div>


                            <div class="row mt-5" style="margin-left: 30px;" [hidden]="tipoCliente=='eventual'">
                                <label style="font-size: 25;">CLIENTE: &nbsp;</label>
                                <span style="font-size: 30; font-weight: bold;">{{clienteRemitente.RazonSocial}}</span>
                            </div>
                            <div class="row mt-5">
                                <div class="col-8">
                                    <div class="form-group">
                                        <label>Localidad Origen</label>
                                        <input class="form-control" required [(ngModel)]="localidadOrigen" name="locOrigen" id="locOrigen"
                                             type="text" #locOrigen="ngModel"
                                            disabled>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <label>CP Origen</label>
                                        <input class="form-control" [(ngModel)]="cpOrigen" required name="cpOrig" #cpOrig="ngModel"
                                            type="number" disabled>
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-8">
                                    <div class="form-group">
                                        <label>Localidad Destino</label>
                                        <input class="form-control"  required [(ngModel)]="localidadDestino"
                                            name="locDest" #locDest="ngModel" type="text"
                                            disabled>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <label>CP Destino</label>
                                        <input class="form-control" required [(ngModel)]="cpDestino" name="cpDest" #cpDest="ngModel"
                                            type="number" disabled>
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-3">
                                    <div class="form-group">
                                        <label>Cant. Bultos</label>
                                        <input class="form-control" required [(ngModel)]="cantBultos" id="bultos" name="bultos"
                                            #cantBul="ngModel" type="tel" maxlength="6"
                                            onkeydown="javascript: return event.keyCode === 8 || event.keyCode===9 ? true : !isNaN(Number(event.key))">
                                        <div [hidden]="cantBul.valid" class="alert alert-danger mt-1"
                                            style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="form-group">
                                        <label>Peso</label>
                                        <input class="form-control" required [(ngModel)]="peso" name="pesoBul" #pesoBul="ngModel"
                                            type="tel" onkeydown="javascript: return event.keyCode === 8 ||
                                            event.keyCode === 190 || event.keyCode === 110 || event.keyCode===9 ? true : !isNaN(Number(event.key))" maxlength="6">
                                        <div [hidden]="peso>0 || pesoM3>0" class="alert alert-danger mt-1"
                                            style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Ingrese Peso o M3
                                          </div>
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="form-group">
                                        <label>M3</label>
                                        <input class="form-control" required [(ngModel)]="pesoM3" name="pesom3" #pesom3="ngModel"
                                            type="tel" onkeydown="javascript: return event.keyCode === 8 ||
                                            event.keyCode === 190 || event.keyCode === 110 || event.keyCode===9 ? true : !isNaN(Number(event.key))" maxlength="8">

                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="form-group">
                                        <label>Valor Declarado</label>
                                        <input class="form-control" required [(ngModel)]="valorDeclarado" name="valorDec"
                                            #valorDec="ngModel" type="tel" onkeydown="javascript: return event.keyCode === 8 ||
                                            event.keyCode === 190 || event.keyCode === 110 || event.keyCode===9 ? true : !isNaN(Number(event.key))" maxlength="8">
                                        <div [hidden]="valorDec.valid" class="alert alert-danger mt-1"
                                            style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="peso>25">
                                <div class="col-4">
                                    <div class="form-group">
                                        <label>Alto</label>
                                        <input class="form-control" required [(ngModel)]="alto" name="altoBul" #altoBul="ngModel"
                                            type="tel" onkeydown="javascript: return event.keyCode === 8 ||
                                            event.keyCode === 190 || event.keyCode === 110 || event.keyCode===9 ? true : !isNaN(Number(event.key))" maxlength="6">
                                        <div [hidden]="altoBul.valid" class="alert alert-danger mt-1"
                                            style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <label>Ancho</label>
                                        <input class="form-control" required [(ngModel)]="ancho" name="anchoBul" #anchoBul="ngModel"
                                            type="tel" onkeydown="javascript: return event.keyCode === 8 ||
                                            event.keyCode === 190 || event.keyCode === 110 || event.keyCode===9 ? true : !isNaN(Number(event.key))" maxlength="6">
                                        <div [hidden]="anchoBul.valid" class="alert alert-danger mt-1"
                                            style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <label>Largo</label>
                                        <input class="form-control" required [(ngModel)]="largo" name="largoBul" #largoBul="ngModel"
                                            type="tel" onkeydown="javascript: return event.keyCode === 8 ||
                                            event.keyCode === 190 || event.keyCode === 110 || event.keyCode===9 ? true : !isNaN(Number(event.key))" maxlength="6">
                                        <div [hidden]="largoBul.valid" class="alert alert-danger mt-1"
                                            style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4">
                                    <div class="form-group">
                                        <label>Orden/Planilla Retiro</label>
                                        <input class="form-control" [(ngModel)]="ordenPlanillaRetiro" name="ordenPlaRet" type="text" disabled>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <label>Remito Cliente</label>
                                        <input class="form-control" [(ngModel)]="remitoCliente" name="remCli" type="text">

                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <label>Precinto de Seguridad</label>
                                        <input class="form-control" [(ngModel)]="precintoSeguridad" name="preSeg" type="text" disabled>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label>Observaciones</label>
                                        <textarea style="height: 100px; resize: none;" class="form-control" [(ngModel)]="observaciones" name="obs" disabled></textarea>
                                    </div>
                                </div>
                            </div>
                            <br>
                            <br>

                            <div class="row modalidad">
                                <div class="col-2 mb-4 mt-3">
                                    <h4>Modalidad</h4>
                                  </div>
                                <div class="modalidades centrar">
                                    <div style="text-align: center;">
                                        <input type="checkbox" [(ngModel)]="modalidadTT" name="tt" id="tt" value="1" class="visible"
                                            disabled>
                                        <label for="TT">Sucursal-Sucursal</label>
                                    </div>
                                    <div style="text-align: center;">
                                        <input type="checkbox" [(ngModel)]="modalidadPP" name="pp" id="pp" value="2" class="visible"
                                        disabled>
                                        <label for="PP">Puerta-Puerta</label>
                                    </div>
                                    <div style="text-align: center;">
                                        <input type="checkbox" [(ngModel)]="modalidadTP" name="tp" id="tp" value="3" class="visible"
                                        disabled>
                                        <label for="TP">Sucursal-Puerta</label>
                                    </div>
                                    <div style="text-align: center;">
                                        <input type="checkbox" [(ngModel)]="modalidadPT" name="pt" id="pt" value="4" class="visible"
                                        disabled>
                                        <label for="PT">Puerta-Sucursal</label>
                                    </div>
                                </div>
                            </div>
                            <br>
                            <br>

                            <div *ngIf="mostrarOpcionEnvio">
                                <!-- opciones disponibles de envío -->
                                <h5 class="col-3 mb-4 mt-3" style="margin-left: 10px; margin-top: 15px; color: #004993; font-size: 24px;">Opciones Envío</h5>

                                <div class="row" style="margin-top: 10px; " *ngFor="let opcion of opcionesEnvioFiltro">
                                    <div class="col-3" style="text-align: center;">
                                        <label style="color:#004993; font-weight: bold;">{{opcion.ProductoDescrip}}</label>
                                    </div>
                                    <div class="col-3" style="text-align: center;">
                                        <span style="color:#004993; font-weight: bold;"><i>Modalidad de envío</i></span><br>
                                        <span>{{opcion.ModalidadEntregaDescrip | uppercase}}</span>
                                    </div>
                                    <div class="col-2" style="text-align: center;">
                                        <span style="color:#004993; font-weight: bold;"><i>Precio normal</i></span><br>
                                        <span>${{opcion.ImporteNormal}}</span><br>
                                    </div>
                                    <div class="col-2" style="text-align: center;">
                                        <span style="color:#004993; font-weight: bold;"><i>Valor declarado</i></span><br>
                                        <span>${{opcion.ValorDeclarado}}</span>
                                    </div>
                                    <div class="col-2" style="text-align: center;">
                                        <span style="color:#004993; font-weight: bold;"><i>Precio total</i></span><br>
                                        <span>${{opcion.Importe}}</span>
                                    </div>
                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group" style="margin-top: 10px;">
                                        <h5 style="margin-left: 20px ; margin-top: 15px; color: #004993; font-size: 24px;">Servicios Adicionales</h5>

                                        <table class="table table-bordered" id="serviciosAdicionales" [hidden]="conceptosTabla==null || conceptosTabla.length==0">
                                            <thead>
                                                <tr>
                                                    <th style="width: 600px;">Concepto</th>
                                                    <th style="width: 200px; text-align: center;">Precinto</th>
                                                    <th style="width: 150px; text-align: center;">Hs/Días</th>
                                                    <th style="width: 200px; text-align: center;">Código Barra</th>
                                                    <th style="width: 150px; text-align: center;">Importe</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let fila of conceptosTabla">
                                                    <td>
                                                        {{fila.Descripcion}}
                                                    </td>
                                                    <td>
                                                        {{fila.Precinto}}
                                                    </td>
                                                    <td>
                                                        {{fila.HsDias}}
                                                    </td>
                                                    <td>
                                                        {{fila.CodigoBarra}}
                                                    </td>
                                                    <td>${{fila.ImporteConcepto}}</td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <br><br>
                            <div class="row" *ngIf="importeTotal!=null">
                                <div class="col"><span style="font-size: 20px; font-weight: bold;">Importe total:</span>&nbsp;<span
                                        style="font-size: 20px;">${{importeTotal}}</span></div>
                            </div>

                            <div *ngIf="paso2">
                                <div class="row align-items-center rounded mt-5">
                                    <h4 style="font-size: 2rem; color: #004993;" class="mt-1">&nbsp;&nbsp; 2° - Datos Remitente</h4>
                                </div><br>

                                <div class="row">
                                    <div class="col-2">
                                        <div class="form-group" [hidden]="tipoCliente=='eventual'">
                                            <label>Código Cliente</label>
                                            <input [(ngModel)]="codClienteRemitente" disabled name="codRemitente"
                                                id="codRemitente" type="text" class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-3">
                                        <div class="form-group">
                                            <label>Nombre</label>
                                            <input type="text" class="form-control" disabled required
                                                [(ngModel)]="nombreRemitente" name="nomRemitente" id="nomRemitente" #nomRemitente="ngModel"
                                                >
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <label>Apellido</label>
                                            <input type="text" class="form-control" disabled required
                                                [(ngModel)]="apellidoRemitente" name="apeRemitente" id="apeRemitente"
                                                #apeRemitente="ngModel" >
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <select class="custom-select" disabled style="margin-top: 32px;"
                                                        required [(ngModel)]="tipoDocRemitente" name="tipodocRemi" id="tipodocRemi"
                                                        #tipodocRemi="ngModel" >
                                                        <option value="" selected disabled>Tipo documento</option>
                                                        <option *ngFor="let tipoDoc of tiposDoc" [value]="tipoDoc.TipoDocumentoID">
                                                            {{tipoDoc.TipoDocumentoDescrip}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label>Nro Documento</label>
                                                    <input type="number" class="form-control" disabled required
                                                        [(ngModel)]="dniRemitente" name="dniRemi" id="dniRemi" #dniRemi="ngModel"
                                                        >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label>Cód. Area</label>
                                                    <input class="form-control" type="number" required [(ngModel)]="codAreaRemitente"
                                                        name="codARemi" #codARemi="ngModel" disabled>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label>Teléfono</label>
                                                    <input class="form-control" type="text" required [(ngModel)]="telefonoRemitente"
                                                        name="telRemitente" #telRemitente="ngModel" disabled>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <label>Email</label>
                                            <input class="form-control" type="text" required [(ngModel)]="mailRemitente" name="mailRemi"
                                                #mailRemi="ngModel" disabled>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <select class="custom-select" style="margin-top: 32px;" [disabled]="clienteOrigen" required
                                                [(ngModel)]="tipoCondicionIVARemitente" name="tipoIvaRemitente" id="tipoIvaRemitente"
                                                #tipoIvaRemitente="ngModel"
                                                disabled>
                                                <option value="" selected disabled>Tipo Condición IVA</option>
                                                <option *ngFor="let condIva of condicionesIva" [value]="condIva.CondicionIvaID">
                                                    {{condIva.CondicionIvaDescrip}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label >Calle Retiro</label>
                                            <input class="form-control" type="text" required [(ngModel)]="calleOrigen"
                                            disabled name="calleOrig" #calleOrig="ngModel" >
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label>Nro</label>
                                            <input class="form-control" type="number" required [(ngModel)]="nroCalleRemitente"
                                                disabled name="nroRemi" #nroRemi="ngModel" >

                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label>Piso</label>
                                            <input class="form-control" type="number" [(ngModel)]="pisoRemitente"
                                                disabled name="pisoRemi">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Descripción</label>
                                            <textarea class="form-control" [(ngModel)]="descRemitente" name="descripRemi" disabled></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="despachanteOrigen">

                                <!-- <h6 class="mt-1">&nbsp;&nbsp; Despachante</h6>
                                <hr style="color: #3f3d3d;"> -->

                                <!-- <div class="row">
                                    <div class="col-4">
                                        <div class="row">
                                            <div class="col-5">
                                                <div class="form-group">
                                                    <select class="custom-select" style="margin-top: 32px;" required
                                                        [(ngModel)]="tipoDocDespachanteOrigen" name="tipodocDespaO"
                                                        #tipodocDespaO="ngModel">
                                                        <option value="" selected disabled>Tipo documento</option>
                                                        <option *ngFor="let tipoDoc of tiposDoc" [value]="tipoDoc.TipoDocumentoID">
                                                            {{tipoDoc.TipoDocumentoDescrip}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-7">
                                                <div class="form-group">
                                                    <label>Nro Documento</label>
                                                    <input type="number" class="form-control" required [(ngModel)]="dniDespachanteOrigen"
                                                        name="dniDespaO" #dniDespaO="ngModel">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label>Nombre</label>
                                            <input type="text" class="form-control" required [(ngModel)]="nombreDespachanteOrigen"
                                                name="nomDespaO" #nomDespaO="ngModel">
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label>Apellido</label>
                                            <input type="text" class="form-control" required [(ngModel)]="apellidoDespachanteOrigen"
                                                name="apeDespaO" #apeDespaO="ngModel">
                                        </div>
                                    </div>
                                </div><br><br> -->
                            </div>


                            <div *ngIf="paso3">
                                <!-- aparece al completar el remitente -->
                                <div class="row align-items-center rounded mt-5">
                                    <h4 style="font-size: 2rem; color: #004993;" class="mt-1">&nbsp;&nbsp; 3° - Datos Destinatario</h4>
                                </div><br>
                                <div class="row mb-4 align-items-center">
                                    <div class="col-3 mt-3">
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" [(ngModel)]="tipoClienteDestinatario"
                                                name="opcionesClienteDestinatario" id="ckEventualDest" value="eventual"
                                                style="width: 30px; height: 30px; vertical-align: middle;"
                                                disabled>
                                            <label class="form-check-label rounded" for="exampleRadios1"
                                                style="margin-left: 15px;width: 130px; height: 40px; text-align: center; vertical-align: middle;">
                                                <p class="formSeccionUnoP" style="margin-top: 8px; font-size: 14px;">Eventual</p>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-3 mt-3">
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" [(ngModel)]="tipoClienteDestinatario"
                                                name="opcionesClienteDestinatario" id="ckCorpoDest" value="corporativo"
                                                style="width: 30px; height: 30px; vertical-align: middle;"
                                                disabled>
                                            <label class="form-check-label rounded" for="exampleRadios2"
                                                style="margin-left: 15px;width: 130px; height: 40px; text-align: center; vertical-align: middle;">
                                                <p class="formSeccionUnoP" style="margin-top: 8px; font-size: 14px;">Corporativo</p>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-2">
                                        <div class="form-group" [hidden]="tipoClienteDestinatario=='eventual'">
                                            <label>Código Cliente</label>
                                            <input type="text" class="form-control" [(ngModel)]="codClienteDestinatario" name="codCliDesti"
                                                id="codCorpoDest" disabled>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-3">
                                        <div class="form-group">
                                            <label>Nombre</label>
                                            <input type="text" class="form-control" [disabled]="clienteDestino" required
                                                [(ngModel)]="nombreDestinatario" name="nomDesti" #nomDesti="ngModel" disabled>

                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <label>Apellido</label>
                                            <input type="text" class="form-control" disabled required
                                                [(ngModel)]="apellidoDestinatario" name="apeDesti" #apeDesti="ngModel">

                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <select class="custom-select" style="margin-top: 32px;" disabled
                                                        required [(ngModel)]="tipoDocDestinatario" name="tipodocDesti"
                                                        #tipodocDesti="ngModel">
                                                        <option value="" selected disabled>Tipo documento</option>
                                                        <option *ngFor="let tipoDoc of tiposDoc" [value]="tipoDoc.TipoDocumentoID">
                                                            {{tipoDoc.TipoDocumentoDescrip}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label>Nro Documento</label>
                                                    <input type="number" class="form-control" disabled required
                                                        [(ngModel)]="dniDestinatario" name="dniDesti" id="dniDesti" #dniDesti="ngModel"
                                                        >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label>Cód. Area</label>
                                                    <input class="form-control" type="number" required [(ngModel)]="codAreaDestinatario"
                                                        name="codADesti" #codADesti="ngModel" disabled>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label>Teléfono</label>
                                                    <input class="form-control" type="number" required [(ngModel)]="telefonoDestinatario"
                                                        name="telDesti" #telDesti="ngModel" disabled>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <label>Email</label>
                                            <input class="form-control" type="text" required [(ngModel)]="mailDestinatario" name="mailDesti"
                                                #mailDesti="ngModel" disabled>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <select class="custom-select" style="margin-top: 32px;" disabled required
                                                [(ngModel)]="tipoCondicionIVADestinatario" name="tipoIvaDesti" #tipoIvaDesti="ngModel"
                                                >
                                                <option value="" selected disabled>Tipo Condición IVA</option>
                                                <option *ngFor="let condIva of condicionesIva" [value]="condIva.CondicionIvaID">
                                                    {{condIva.CondicionIvaDescrip}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row"
                                    *ngIf="opcionEnvioSeleccionada.ModalidadEntregaID!=1 && opcionEnvioSeleccionada.ModalidadEntregaID!=4">
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label >Calle Entrega</label>
                                            <input class="form-control" type="text" required [(ngModel)]="calleDestino"
                                                disabled name="calleDest" #calleDest="ngModel">
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label>Nro</label>
                                            <input class="form-control" type="number" required [(ngModel)]="nroCalleDestinatario"
                                                disabled name="nroDesti" #nroDesti="ngModel">
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label>Piso</label>
                                            <input class="form-control" type="number" [(ngModel)]="pisoDestinatario"
                                                disabled name="pisoDesti">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Descripción</label>
                                            <textarea class="form-control" [(ngModel)]="descDestinatario" name="descripDesti" disabled></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="despachanteDestino">
                                <!-- sección despachante -->
                                <!-- <h6 class="mt-1">&nbsp;&nbsp; Quien Recibe</h6>
                                <hr style="color: #3f3d3d;"> -->

                                <!-- <div class="row">
                                    <div class="col-4">
                                        <div class="row">
                                            <div class="col-5">
                                                <div class="form-group">
                                                    <select class="custom-select" style="margin-top: 32px;" required
                                                        [(ngModel)]="tipoDocDespachanteDestino" name="tipodocDespaD"
                                                        #tipodocDespaD="ngModel">
                                                        <option value="" selected disabled>Tipo documento</option>
                                                        <option *ngFor="let tipoDoc of tiposDoc" [value]="tipoDoc.TipoDocumentoID">
                                                            {{tipoDoc.TipoDocumentoDescrip}}</option>
                                                    </select>
                                                    <div [hidden]="tipodocDespaD.valid" class="alert alert-danger mt-1"
                                                        style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo
                                                        requerido</div>
                                                </div>
                                            </div>
                                            <div class="col-7">
                                                <div class="form-group">
                                                    <label>Nro Documento</label>
                                                    <input type="number" class="form-control" required [(ngModel)]="dniDespachanteDestino"
                                                        name="dniDespaD" #dniDespaD="ngModel">
                                                    <div [hidden]="dniDespaD.valid" class="alert alert-danger mt-1"
                                                        style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo
                                                        requerido</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label>Nombre</label>
                                            <input type="text" class="form-control" required [(ngModel)]="nombreDespachanteDestino"
                                                name="nomDespaD" #nomDespaD="ngModel">
                                            <div [hidden]="nomDespaD.valid" class="alert alert-danger mt-1"
                                                style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label>Apellido</label>
                                            <input type="text" class="form-control" required [(ngModel)]="apellidoDespachanteDestino"
                                                name="apeDespaD" #apeDespaD="ngModel">
                                            <div [hidden]="apeDespaD.valid" class="alert alert-danger mt-1"
                                                style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                        </form><br><br>
                        <div style="display: flex; flex-direction: row; justify-content: center; align-items: center;">
                            <div *ngIf="botonesEmitir">
                                    <button class="btn btn-primary" style="height: 40px; width: 200px; background-color: #004993;" type="button" (click)="agregarCarrito()">Agregar al carrito</button>
                            </div>
                            <div class="modal-footer" style="border-top: transparent;">
                                    <button type="button" class="btn btn-danger" style="width: 200px; background-color: #fff; color: #004993; border: 2px solid #004993;" data-dismiss="modal">Cancelar</button>
                            </div>
                        </div>

                    </div>
            </div>
        </div>
    </div>
</div>
