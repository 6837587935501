import { Component, OnInit } from '@angular/core';
import { Local } from 'src/app/models/Local';
import { LocalService } from 'src/app/services/local.service';
declare var $:any
@Component({
  selector: 'app-modal-agencia',
  templateUrl: './modal-agencia.component.html',
  styleUrls: ['./modal-agencia.component.scss']
})
export class ModalAgenciaComponent implements OnInit {

  locales:Local[]=[];
  localesBusqueda:Local[]=[];
  nrosucursal:string=null;
  razonSocial:string=null;
  p:number=1;
  constructor(private LocalService:LocalService) { }

  ngOnInit(): void {

    this.LocalService.getAllLocalesSimplificado().subscribe(res=>{
      this.locales=res.Locales;
      this.localesBusqueda=this.locales;
    })
  }

  mostrarLocales(){

    /* this.LocalService.getAllLocales().subscribe(res=>{
      this.locales=res.Locales;
    }) */
    
    this.localesBusqueda=[];
    this.nrosucursal=null;
    this.razonSocial=null;
    this.localesBusqueda=this.locales;
  }

  buscarLocal(event, campo:string){
    if(campo==='nroSuc'){
      if(event==='' || event===null || event==='null'){
        if((this.razonSocial==='' || this.razonSocial===null)){
          this.localesBusqueda=this.locales;
        }else if((this.razonSocial!=='' || this.razonSocial!==null)){
          this.localesBusqueda=this.locales.filter((l)=>{
            return l.LocalDescrip.toLowerCase().includes(this.razonSocial.toLowerCase());
          });
        }
      }else{
        if((this.razonSocial==='' || this.razonSocial===null)){
          this.localesBusqueda=this.locales.filter((l)=>{
            return l.CodigoLocal.includes(event);
          })
        }else if((this.razonSocial!=='' || this.razonSocial!==null)){
          this.localesBusqueda=this.locales.filter((l)=>{
            return l.LocalDescrip.toLowerCase().includes(this.razonSocial.toLowerCase());
          });
          this.localesBusqueda=this.localesBusqueda.filter((l)=>{
            return l.CodigoLocal.includes(event);
          });
        }
      }
    }else if(campo==='nomSuc'){
      if((this.nrosucursal==='' || this.nrosucursal===null || this.nrosucursal==='null')){
        this.localesBusqueda=this.locales.filter((l)=>{
          return l.LocalDescrip.toLowerCase().includes(event.toLowerCase());
        });
      }else if((this.nrosucursal!=='' || this.nrosucursal!==null)){
        this.localesBusqueda=this.locales.filter((l)=>{
          return l.CodigoLocal.includes(this.nrosucursal);
        });
        this.localesBusqueda=this.localesBusqueda.filter((l)=>{
          return l.LocalDescrip.toLocaleLowerCase().includes(event.toLowerCase());
        });
      }
    }
  }

  seleccionarLocal(local:Local){
    this.LocalService.agregarLocal(local);
    $("#modalAgencia").modal("hide");
  }

}
