<div class="container-fluid" style="width: 1350px; margin-top: 50px;">
    <div class="row align-items-center rounded mt-5" style="border: solid 1px grey; height: 50px;">
        <h4 class="mt-1">&nbsp;&nbsp; {{titulo}}</h4>
    </div><br>
    <div class="row">
        <div class="col-4">Agencia Conectada: {{usuario.AgenciaRazonSocial}}</div>
    </div>
    <div class="row" style="margin-top: 25px;">
        <div class="col-2">
            <div class="form-check">
                <input class="form-check-input" [(ngModel)]="tipoBusqueda" type="radio" name="tipo" id="tipo" value="1">
                <label class="form-check-label">
                  Código de Barras
                </label>
            </div>
        </div>
        <div class="col-3">
            <div class="form-group">
                <input type="text" class="form-control" [(ngModel)]="nroCB" id="exampleInputEmail1" aria-describedby="emailHelp">
            </div>
        </div>
    </div>
    <div class="row" style="margin-top: 25px;">
        <div class="col-2">
            <div class="form-check">
                <input class="form-check-input" [(ngModel)]="tipoBusqueda" type="radio" name="tipo" id="tipo" value="2">
                <label class="form-check-label">
                  Tipo guía:
                </label>
            </div>
        </div>
        <div class="col-3">
            <div class="form-group">
                <select class="form-control" [(ngModel)]="tipoGuia" id="tipoG" [disabled]="tipoBusqueda=='1'">
                  <option value="0">Elija tipo guía</option>
                  <option value="A">A</option>
                  <option value="B">B</option>
                  <option value="R">R</option>
                  <option value="E">E</option>
                  <option value="X">X</option>
                </select>
              </div>
        </div>
        <div class="col-3">
            <div class="form-group">
                <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Sucursal:</span>
                    </div>
                    <input type="text" aria-label="First name" [(ngModel)]="sucursal" class="form-control" [disabled]="tipoBusqueda=='1'">
                </div>
            </div>
        </div>
        <div class="col-3">
            <div class="form-group">
                <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Nro guía:</span>
                    </div>
                    <input type="text" aria-label="First name" [(ngModel)]="nroGuia" class="form-control" [disabled]="tipoBusqueda=='1'">
                </div>
            </div>
        </div>
    </div>
    <div class="row justify-content-end" style="margin-top: 25px;">
        <div class="col-2">
            <button type="button" class="btn btn-primary" (click)="buscar()">Buscar</button>&nbsp;
            <button type="button" class="btn btn-primary" (click)="limpiar()">Limpiar</button>
        </div>
    </div>
    <div class="row" style="margin-top: 25px;">
        <div class="col-5">
            <div class="form-group">
                <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Agencia origen:</span>
                    </div>
                    <input type="text" aria-label="First name" [(ngModel)]="nroSucursalO" class="form-control" [disabled]="guiaRecepcion.GuiaID!=undefined && guiaRecepcion.GuiaID>0">
                    <input type="text" aria-label="Last name" [(ngModel)]="razonSocialO" class="form-control" [disabled]="guiaRecepcion.GuiaID!=undefined && guiaRecepcion.GuiaID>0">
                </div>
            </div>
        </div>
        <div class="col-4">
            <div class="form-group">
                <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Cantidad de bultos:</span>
                    </div>
                    <input type="text" aria-label="First name" [(ngModel)]="cantBultos" class="form-control" [disabled]="guiaRecepcion.GuiaID!=undefined && guiaRecepcion.GuiaID>0">
                </div>
            </div>
        </div>
    </div>
    <div class="row" style="margin-top: 25px;">
        <div class="col-5">
            <div class="form-group">
                <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Agencia destino:</span>
                    </div>
                    <input type="text" aria-label="First name" [(ngModel)]="nroSucursalD" class="form-control" [disabled]="guiaRecepcion.GuiaID!=undefined && guiaRecepcion.GuiaID>0">
                    <input type="text" aria-label="Last name" [(ngModel)]="razonSocialD" class="form-control" [disabled]="guiaRecepcion.GuiaID!=undefined && guiaRecepcion.GuiaID>0">
                    <button type="button" class="btn btn-primary" data-target="#modalAgencia" data-toggle="modal" [disabled]="guiaRecepcion.GuiaID!=undefined && guiaRecepcion.GuiaID>0">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                            <path fill-rule="evenodd" d="M1.5 13A1.5 1.5 0 0 0 3 14.5h10a1.5 1.5 0 0 0 1.5-1.5V8a.5.5 0 0 0-1 0v5a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V3a.5.5 0 0 1 .5-.5h8a.5.5 0 0 0 0-1H3A1.5 1.5 0 0 0 1.5 3v10z"/>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
        <div class="col-4">
            <div class="form-group">
                <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Valor declarado:</span>
                    </div>
                    <input type="text" aria-label="First name" [(ngModel)]="valorDeclarado" class="form-control" [disabled]="guiaRecepcion.GuiaID!=undefined && guiaRecepcion.GuiaID>0">
                </div>
            </div>
        </div>
    </div>
    <div class="row" style="margin-top: 25px;">
        <div class="col-4">
            <div class="form-group">
                <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Fecha:</span>
                    </div>
                    <input type="date" aria-label="First name" [ngModel]="fecha  | date:'yyyy-MM-dd'" (ngModelChange)="fecha=$event" class="form-control">
                  </div>
            </div>
        </div>
        <div class="col-4">
            <div class="form-group">
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <label class="input-group-text" >Tipo de guía:</label>
                    </div>
                    <select class="custom-select" [(ngModel)]="tipoGuia2" id="tipoG2" [disabled]="guiaRecepcion.GuiaID!=undefined && guiaRecepcion.GuiaID>0">
                      <option value="1">Factura</option>
                      <option value="2">Rendicion</option>
                      <option value="3">Agencia a agencia</option>
                      <option value="4">Remito</option>
                      <option value="5">Unidades de logística</option>
                      <option value="6">Distribución local</option>
                    </select>
                  </div>
            </div>
        </div>
    </div>
    <div class="row" style="margin-top: 25px;">
        <div class="col-5">
            <div class="form-group">
                <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Observaciones:</span>
                    </div>
                    <input type="text" aria-label="First name" class="form-control" [value]="observacion.Codigo">
                    <input type="text" aria-label="Last name" class="form-control" [value]="observacion.ObsDescrip">
                    <button type="button" class="btn btn-primary" data-target="#modalObservaciones" data-toggle="modal">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                            <path fill-rule="evenodd" d="M1.5 13A1.5 1.5 0 0 0 3 14.5h10a1.5 1.5 0 0 0 1.5-1.5V8a.5.5 0 0 0-1 0v5a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V3a.5.5 0 0 1 .5-.5h8a.5.5 0 0 0 0-1H3A1.5 1.5 0 0 0 1.5 3v10z"/>
                          </svg>
                    </button>
                  </div>
            </div>
        </div>
    </div>
    <div [hidden]="!esConformacion">
        <div class="row align-items-center rounded mt-5" style="border: solid 1px grey; height: 50px;">
            <h4 class="mt-1">&nbsp;&nbsp; Destinatario</h4>
        </div><br>
        <div class="row mb-5 justify-content-end">
            
            <div class="col-3 justify-content-end" style="margin-top: 20px;">
                <button type="button" class="btn btn-primary" style="margin-left: 50px;" (click)="habilitar()">Habilitar</button>
                <button type="button" class="btn btn-primary" style="margin-left: 20px;" (click)="limpiarDestinatario()">Limpiar</button>
            </div>
        </div>
        
        <div class="row">
            <div class="col-4">
                <div class="form-group">
                    <label>Nombre</label>
                    <input type="text" class="form-control" required
                        [(ngModel)]="nombreDestinatario" name="nomDesti" #nomDesti="ngModel">
                    <div [hidden]="nombreDestinatario!=''" class="alert alert-danger mt-1"
                        style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="form-group">
                    <label>Apellido</label>
                    <input type="text" class="form-control" required
                        [(ngModel)]="apellidoDestinatario" name="apeDesti" #apeDesti="ngModel">
                    <div [hidden]="apellidoDestinatario!=''" class="alert alert-danger mt-1"
                        style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="row">
                    <div class="col-5">
                        <div class="form-group">
                            <select class="custom-select" style="margin-top: 32px;"
                                required [(ngModel)]="tipoDocDestinatario" name="tipodocDesti"
                                #tipodocDesti="ngModel">
                                <option value="" selected disabled>Tipo documento</option>
                                <option *ngFor="let tipoDoc of tiposDoc" [value]="tipoDoc.TipoDocumentoID">
                                    {{tipoDoc.TipoDocumentoDescrip}}</option>
                            </select>
                            <div [hidden]="tipoDocDestinatario!=''" class="alert alert-danger mt-1"
                                style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo
                                requerido</div>
                        </div>
                    </div>
                    <div class="col-7">
                        <div class="form-group">
                            <label>Nro Documento</label>
                            <input type="number" class="form-control" required
                                [(ngModel)]="dniDestinatario" name="dniDesti" id="dniDesti" #dniDesti="ngModel" (blur)="buscarCliente()">
                            <div [hidden]="dniDestinatario!='' && dniDestinatario!=null" class="alert alert-danger mt-1"
                                style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo
                                requerido</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <div class="row">
                    <div class="col-4">
                        <div class="form-group">
                            <label>Cód. Area</label>
                            <input class="form-control" type="number" required [(ngModel)]="codAreaDestinatario"
                                name="codADesti" #codADesti="ngModel">
                        </div>
                    </div>
                    <div class="col-8">
                        <div class="form-group">
                            <label>Teléfono</label>
                            <input class="form-control" type="number" required [(ngModel)]="telefonoDestinatario"
                                name="telDesti" #telDesti="ngModel">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="form-group">
                    <label>Email</label>
                    <input class="form-control" type="text" required [(ngModel)]="mailDestinatario" name="mailDesti"
                        #mailDesti="ngModel">
                </div>
            </div>
            <div class="col-4">
                <div class="form-group">
                    <select class="custom-select" style="margin-top: 32px;" required
                        [(ngModel)]="tipoCondicionIVADestinatario" name="tipoIvaDesti" #tipoIvaDesti="ngModel">
                        <option value="" selected disabled>Tipo Condición IVA</option>
                        <option *ngFor="let condIva of condicionesIva" [value]="condIva.CondicionIvaID">
                            {{condIva.CondicionIvaDescrip}}</option>
                    </select>
                    <div [hidden]="tipoCondicionIVADestinatario!=''" class="alert alert-danger mt-1"
                        style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <div class="form-group">
                    <label>Localidad</label>
                    <input class="form-control" required [(ngModel)]="localidadDestino" name="locOrig" id="locOrig"
                        list="localidadesDestino" type="text" #locOrig="ngModel" (input)="localidadIngresada($event)"
                        autocomplete="off">
                    <datalist id="localidadesDestino">
                        <option *ngFor="let localidad of localidadesDestino" [id]="localidad.localidadid">
                            {{localidad.LocalidadDescrip}}, {{localidad.ProvinciaDescrip}} ({{localidad.CodigoPostal}})
                        </option>
                    </datalist>
                    <div [hidden]="locOrig.valid" class="alert alert-danger mt-1"
                        style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="form-group">
                    <label>CP</label>
                    <input class="form-control" [(ngModel)]="cpDestino" required name="cpOrig" #cpOrig="ngModel"
                        type="number">
                    <div [hidden]="cpOrig.valid" class="alert alert-danger mt-1"
                        style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido
                    </div>
                </div>
            </div>
            
        </div>
        <div class="row"
            >
            <div class="col-4">
                <div class="form-group">
                    <label>Calle Entrega</label>
                    <input class="form-control" type="text" required [(ngModel)]="calleDestino"
                        name="calleDest" #calleDest="ngModel" autocomplete="off">
                    <div [hidden]="calleDest.valid" class="alert alert-danger mt-1"
                        style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="form-group">
                    <label>Nro</label>
                    <input class="form-control" type="number" required [(ngModel)]="nroCalleDestinatario"
                        name="nroDesti" #nroDesti="ngModel">
                    <div [hidden]="nroCalleDestinatario!=''" class="alert alert-danger mt-1"
                        style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="form-group">
                    <label>Piso</label>
                    <input class="form-control" type="number" [(ngModel)]="pisoDestinatario"
                        name="pisoDesti">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group">
                    <label>Descripción</label>
                    <textarea class="form-control" [(ngModel)]="descDestinatario" name="descripDesti"></textarea>
                </div>
            </div>
        </div><br>
        <div class="row">
            <div class="col-4">
                <div class="form-group">
                    <input type="checkbox" id="ckDom" [checked]="guiaRecepcion.esFPD" [(ngModel)]="esFPD"> &nbsp; Entrega a domicilio
                </div>
            </div>
            <div class="col-4">
                <div class="form-group">
                    <label>Orden/Planilla Entrega</label>
                    <input class="form-control" type="text" >
                </div>
            </div>
        </div>
        <div class="row justify-content-around" style="margin-top: 15px;" *ngIf="guiaRecepcion.GuiaID>0 && (guiaRecepcion.Servicios!=undefined || guiaRecepcion.Servicios!=null)"><!--  -->
            <div class="col-3">
                <button type="button" class="btn btn-primary" (click)="agregarConcepto()">Agregar conceptos cobro</button>
            </div>
            <div class="col-4">
                <div class="form-group">
                    <label>Importe Total</label>
                    <input class="form-control" type="number" [(ngModel)]="importeTotal">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group">
                    <table class="table table-bordered" id="serviciosAdicionales" *ngIf="tablaConceptos">
                        <thead>
                            <tr>
                                <th style="width: 300px;">Concepto de Cobro</th>
                                <th style="width: 200px; text-align: center;">Número</th>
                                <th style="width: 300px; text-align: center;">Banco</th>
                                <th style="width: 200px; text-align: center;">Importe</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let fila of conceptosTabla">
                                <td>
                                    {{fila.ConceptoDescrip}}
                                </td>
                                <td>
                                    {{fila.Numero}}
                                </td>
                                <td>
                                    {{fila.Banco}}
                                </td>
                                <td>${{fila.Importe}}</td>
                                <td>
                                    <button class="btn btn-info" (click)="editarConcepto(fila)">Editar</button>
                                </td>
                                <td>
                                    <button class="btn btn-danger" (click)="eliminarConcepto(fila)">Eliminar</button>
                                </td>
                            </tr>
                            <tr *ngIf="nuevoConcepto">
                                <td>
                                    <select class="form-control"
                                         name="conceptoFac" [(ngModel)]="concepto.ConceptoID"
                                        (change)="conceptoSeleccionado($event)">
                                        <option value=0 selected disabled>Elija un concepto de cobro</option>
                                        <option value=1>Boleta Depósito</option>
                                        <option value=2>Cheque</option>
                                    </select>
                                </td>
                                <td>
                                    <input class="form-control" type="text" [(ngModel)]="concepto.Numero">
                                    <!-- <div [hidden]="precintoValid" class="alert alert-danger mt-1"
                                        style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">
                                        *Campo requerido</div> -->
                                </td>
                                <td>
                                    <input class="form-control" type="text" [(ngModel)]="concepto.Banco">
                                    <!-- <div [hidden]="hsDiasValid" class="alert alert-danger mt-1"
                                        style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">
                                        *Campo requerido</div> -->
                                </td>
                                <td>
                                    <input class="form-control" type="text" [(ngModel)]="concepto.Importe">
                                </td>
                                <td style="text-align: center;">
                                    <button class="btn btn-info" (click)="agregarFila()">Agregar</button>
                                </td>
                                <td style="text-align: center;"><button class="btn btn-info"
                                        (click)="cerrarFila()">Cerrar</button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div *ngIf="clienteDestinatario.ClienteID!=undefined && clienteDestinatario.ClienteID>0"> <!-- autorización de retiro -->
            <div class="row align-items-center rounded mt-5" style="border: solid 1px grey; height: 50px;">
                <h4 class="mt-1">&nbsp;&nbsp; Autorizaciones de Retiro</h4>
            </div><br>
            <div class="row">
                <div class="col-4">
                    <div class="row">
                        <div class="col-5">
                            <div class="form-group">
                                <select class="custom-select" style="margin-top: 32px;" required
                                    [(ngModel)]="tipoDocAutorizado" name="tipodocDespaD"
                                    #tipodocDespaD="ngModel">
                                    <option value="" selected disabled>Tipo documento</option>
                                    <option *ngFor="let tipoDoc of tiposDoc" [value]="tipoDoc.TipoDocumentoID">
                                        {{tipoDoc.TipoDocumentoDescrip}}</option>
                                </select>
                                <div [hidden]="tipodocDespaD.valid" class="alert alert-danger mt-1"
                                    style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo
                                    requerido</div>
                            </div>
                        </div>
                        <div class="col-7">
                            <div class="form-group">
                                <label>Nro Documento</label>
                                <input type="number" class="form-control" required [(ngModel)]="dniAutorizado"
                                    name="dniDespaD" #dniDespaD="ngModel">
                                <div [hidden]="dniDespaD.valid" class="alert alert-danger mt-1"
                                    style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo
                                    requerido</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-group">
                        <label>Nombre</label>
                        <input type="text" class="form-control" required [(ngModel)]="nombreAutorizado"
                            name="nomDespaD" #nomDespaD="ngModel">
                        <div [hidden]="nomDespaD.valid" class="alert alert-danger mt-1"
                            style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-group">
                        <label>Apellido</label>
                        <input type="text" class="form-control" required [(ngModel)]="apellidoAutorizado"
                            name="apeDespaD" #apeDespaD="ngModel">
                        <div [hidden]="apeDespaD.valid" class="alert alert-danger mt-1"
                            style="width: 150px; height: 20px; font-size: 12px; padding: 0rem 1.30rem;">*Campo requerido
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-end">
                <div class="col-4" style="text-align: right;">
                    <button class="btn btn-primary" (click)="buscarAutorizados()">Autorizados</button>
                </div>
            </div><br><br>
        </div>
    </div>
    <div class="row justify-content-end" style="margin-top: 25px;">
        <div class="col-3">
            <button type="button" class="btn btn-primary" style="margin-left: 40px;" *ngIf="observacion.Codigo!=null">Observar</button>
            <button type="button" class="btn btn-primary" style="margin-left: 10px;" (click)="confirmar()">Confirmar</button>
        </div>
    </div><br><br>


</div>
<app-modal-observaciones></app-modal-observaciones>
<app-modal-agencia></app-modal-agencia>

<!-- modal autorizados -->
<app-modal-autorizados [clienteCorpo]="clienteDestinatario"></app-modal-autorizados>
<!-- <div>
    <div class="modal" tabindex="1" id="modalAutorizados" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Autorizados Cliente:&nbsp;{{clienteDestinatario.RazonSocial}}</h5>
                </div>
                <div class="modal-body">
                    <div class="container">
                        <table class="table">
                            <thead class="thead-dark">
                                <tr>
                                    <th scope="col" hidden>ID</th>
                                    <th scope="col">Nro Documento</th>
                                    <th scope="col">Nombre</th>
                                    <th scope="col">Apellido</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let aut of autorizadosCorpo | paginate: {itemsPerPage:5, currentPage:p};">
                                    <td hidden>{{aut.NominaAutorizadoID}}</td>
                                    <td>&nbsp;&nbsp;{{aut.NroDocumento}}</td>
                                    <td>{{aut.Nombre}}</td>
                                    <td>{{aut.Apellido}}</td>
                                    <td>
                                        <button class="btn btn-info" (click)="seleccionarAutorizado(aut)">
                                            <svg width="1em" height="1em" viewBox="0 0 16 16"
                                                class="bi bi-check2-square" fill="currentColor"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd"
                                                    d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                                <path fill-rule="evenodd"
                                                    d="M1.5 13A1.5 1.5 0 0 0 3 14.5h10a1.5 1.5 0 0 0 1.5-1.5V8a.5.5 0 0 0-1 0v5a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V3a.5.5 0 0 1 .5-.5h8a.5.5 0 0 0 0-1H3A1.5 1.5 0 0 0 1.5 3v10z" />
                                            </svg>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-dismiss="modal">Cerrar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
