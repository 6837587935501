import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { UrlService } from './url.service';
import { HttpClient } from '@angular/common/http';
import { CndActivos,CndActivosLista, GuiaContenedoraGuardado, GuiaContenedoraResponse, datosEmisionGC, validarOperacionGCGet  } from '../models/guiaContenedora';
import {  EmitirGuiaGCGet, EmitirGuiaGet } from '../models/Orden-Guia';
@Injectable({
  providedIn: 'root'
})
export class GuiaControladoraService {
  
  constructor(private http:HttpClient, private urlService:UrlService) { }

  urltraerdnactivos:string=this.urlService.urlApi+"/api/GuiaContenedora/TraerCNDActivos";
  urlguardarGC:string=this.urlService.urlApi+"/api/GuiaContenedora/GuardadoGC";
  urlValidaOperacion:string=this.urlService.urlApi+"/api/GuiaContenedora/ValidarOperacionGC";
  urlEmiteGC:string=this.urlService.urlApi+"/api/GuiaContenedora/EmitirguiaGC"
  getCndActivos():Observable<CndActivosLista>{
    return this.http.post<CndActivosLista>(this.urltraerdnactivos, "");
  }



  guardarGC(producto:GuiaContenedoraGuardado):Observable<GuiaContenedoraResponse>{
    return this.http.post<GuiaContenedoraResponse>(this.urlguardarGC, producto);
  }

  ValidaOperacionGC(usuarioID:number,agenciaid:number):Observable<validarOperacionGCGet>{
    return this.http.get<validarOperacionGCGet>(this.urlValidaOperacion+"?usuarioID="+usuarioID+"&AgenciaID="+agenciaid);
    
  }
  EmitirguiaGC(datos:datosEmisionGC):Observable<EmitirGuiaGCGet>{
    return this.http.post<EmitirGuiaGCGet>(this.urlEmiteGC,datos);
    
  }



}
