import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccionesCallListRequest, AccionCall, DatosReclamo, DatosReclamoRequest, InsertReclamoRequest, InsertListReclamoRequest, HistoricoCall } from 'src/app/models/callCenter';
import { Local } from 'src/app/models/Local';

import { Usuario } from 'src/app/models/Usuario';
import { CallcenterService } from 'src/app/services/callcenter.service';
import Swal from 'sweetalert2';
declare var $: any;
@Component({
  selector: 'app-call-center-reclamos',
  templateUrl: './call-center-reclamos.component.html',
  styleUrls: ['./call-center-reclamos.component.scss']
})

export class CallCenterReclamosComponent implements OnInit {



  agenciaConectadaID: number;
  usuario: Usuario;
  nroGuia: string;
  nroOrden: string;
  local: Local = {
  }
  callDatos: DatosReclamoRequest = {
    nroGuia: '',
    nroOrden: '',
  };
  datosagrilla: DatosReclamo[] = [];
  datosagrillaHistorico: HistoricoCall[] = [];
  accionesCall: AccionCall[] = [];

  insReclamo: InsertListReclamoRequest[] = [];

  Fecha: string;
  Estado: string;
  Agencia: string;
  Observacion: string;

  loading: boolean = false;
  disableConsultarButton: boolean = false;

  telefono: string;
  detalle: string;
  direccion: string;
  telefononuevaFecha: string;
  direccionNuevaFecha: string;
  FechaNueva: Date;
  accionseleccionada: number;
  ordenRetiroID: number;

  EstadoReclamo: string;
  destinatarioReclamo: string;

  constructor(private router: Router, private callcenterService: CallcenterService) { }

  ngOnInit(): void {


    this.usuario = JSON.parse(sessionStorage.getItem('user'));
    if (this.usuario != null && this.usuario != undefined) {
      for (var i = 0; i < this.usuario.OpcionesMenu.length; i++) {
        if (this.usuario.OpcionesMenu[i].Url == 'call-center-reclamos') {
          this.local.LocalID = this.usuario.LocalID;
          this.agenciaConectadaID = this.usuario.agenciaID;
          $("#slect").hide();
          $("#adicionDatos").hide();
          $("#nuevaFecha").hide();
          $("#botonreclamocarga").hide();

          break;
        } else if (i == this.usuario.OpcionesMenu.length - 1) {
          window.location.href = 'home';
          this.router.navigate(['/home'])
        }
      }
      if (this.usuario.OpcionesMenu.length == 0) {
        this.router.navigate(['/home'])
      }
    } else {
      window.location.href = 'home';
      this.router.navigate(['/home'])
    }


  }


  consultar() {
    this.limpiar()

    if ((this.nroGuia == "" && this.nroOrden == "") || (this.nroGuia == undefined && this.nroOrden == undefined)) {

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: '"Debe ingresar Nro de guia o Nro de orden"',
        didOpen: () => {
          this.loading = true;
        },
        willClose: () => {
          this.loading = false;
        },
      });

      return;
    }
    else {
      this.loading = true;
      this.callDatos.nroGuia = this.nroGuia;
      this.callDatos.nroOrden = this.nroOrden;

      this.callcenterService.getDatosConsultaReclamo(this.callDatos).subscribe(res => {

        this.datosagrilla = res.DatosReclamo;

        if (res.DatosReclamo.length == 0) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'No hay informacion para los datos ingresados.',
            didOpen: () => { },
            willClose: () => {
              this.loading = false;
            },
          });
        }
        else {
          var bandera = 0;

          for (let g of this.datosagrilla) {

            // g.observacionID=this.observacion.ObservacionID;
            this.Agencia = g.Agencia;
            this.Fecha = g.Fecha;
            this.Estado = g.Estado;

            this.EstadoReclamo = g.Estado;
            this.destinatarioReclamo = g.Destinatario;

            if (g.Estado == "Entregado") {

              bandera = 1;
            }
          }

          this.callcenterService.getAccionesCall().subscribe(res2 => {
            if (bandera == 1) {
              for (var i = 0; i < res2.AccionCall.length; i++) {
                if (res2.AccionCall[i].AccionesCallDescrip == 'Reclamo') {

                  this.accionesCall[0] = res2.AccionCall[i];
                  this.accionseleccionada=4
                }

              }

              $("#accionesCall").prop("disabled", "disabled");
            }
            else {
              this.accionesCall = res2.AccionCall;
              this.accionseleccionada = 1;

              $("#accionesCall").prop("disabled", false);
            }




          });



          $("#adicionDatos").show();

          $("#botonreclamocarga").show();

          $("#slect").show();



        }
        this.loading = false;


      });//termina carga de grilla de estados
      this.callcenterService.getDatosHistoricoCall(this.callDatos).subscribe(res => {

        this.datosagrillaHistorico = res.DatosReclamoHistorico;

        if (this.datosagrillaHistorico.length == 0) {
          $("#datoshistorico").hide();

        }
        else {
          $("#datoshistorico").show();
          for (let g of this.datosagrillaHistorico) {

            // g.observacionID=this.observacion.ObservacionID;
            this.Observacion = g.Observacion;



          }

        }

      })



    }

  }
  limpiar() {
    var selectobject = $("#slect").length;
    for (var i = 0; i < selectobject.len; i++) {
      var pp = "option" + i
      $("#slect option[value=" + pp + "]").remove();
    }
  };
  deshabilitarBoton() {

    $("#nroOrden").prop("disabled", false)
    $("#nroguia").prop("disabled", false)
    if (this.nroGuia != undefined) {
      $("#nroguia").prop("disabled", false)
      $("#nroOrden").prop("disabled", "disabled")
    }
    else if (this.nroOrden != undefined) {

      $("#nroOrden").prop("disabled", false)
      $("#nroguia").prop("disabled", "disabled")
    }
    else {
      $("#nroOrden").prop("disabled", false)
      $("#nroguia").prop("disabled", false)
    }
  }

  SeleccionAccion(accion) {
    $("#botonreclamocarga").show();
    this.accionseleccionada = accion.srcElement.value;
    if (accion.srcElement.value == "1" || accion.srcElement.value == "2" || accion.srcElement.value == "4") {
      $("#adicionDatos").show();
      $("#nuevaFecha").hide();

    }
    else if (accion.srcElement.value = "3") {
      $("#adicionDatos").hide();
      $("#nuevaFecha").show();

    }


  }
  CargarReclamo() {
    this.loading = true;

    if (this.nroGuia == undefined) {
      this.nroGuia = "";

    }
    if (this.nroOrden == undefined) {
      this.nroOrden = "";
    }

    if (this.accionseleccionada == 1) {
      //  1	Adicionar Datos o 2	Devolver al Remitente
      if (this.telefono == "" || this.telefono == undefined) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Falta ingresar un teléfono.',
          didOpen: () => {
            this.loading = true;
          },
          willClose: () => {
            this.loading = false;
          },
        });
        return;
      }
      else if (this.direccion == undefined || this.direccion == "") {

        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Falta ingresar una dirección.',
          didOpen: () => {
            this.loading = true;
          },
          willClose: () => {
            this.loading = false;
          },
        });
        return;
      }
      else if (this.detalle == undefined || this.detalle == "") {

        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Falta ingresar un detalle.',
          didOpen: () => {
            this.loading = true;
          },
          willClose: () => {
            this.loading = false;
          },
        });
        return;
      }
      var reclamoNuevo: InsertReclamoRequest = {

        Estado: this.EstadoReclamo,
        Guia: this.nroGuia,
        accionID: this.accionseleccionada,
        telefono: this.telefono,
        domicilio: this.direccion,
        reclamo: "",
        DevuelveEn0: "1",
        FechaNueva: "",
        detalle: this.detalle,
        usuarioID: this.usuario.usuarioID,
        orden: this.nroOrden,
        Destino: this.destinatarioReclamo,

      }



      this.callcenterService.InsertReclamo(reclamoNuevo).subscribe(resInsert => {
        Swal.fire(
          'Bien!',
          resInsert.Estado,
          'success'
        )

        setTimeout(function () {
          window.location.reload(); // you can pass true to reload function to ignore the client cache and reload from the server
        }, 1000);


      });

    }

    else if (this.accionseleccionada == 2) {

      if (this.telefono == "" || this.telefono == undefined) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Falta ingresar un teléfono.',
          didOpen: () => {
            this.loading = true;
          },
          willClose: () => {
            this.loading = false;
          },
        });
        return;
      }
      else if (this.direccion == undefined || this.direccion == "") {

        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Falta ingresar una dirección.',
          didOpen: () => {
            this.loading = true;
          },
          willClose: () => {
            this.loading = false;
          },
        });
        return;
      }
      else if (this.detalle == undefined || this.detalle == "") {

        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Falta ingresar un detalle.',
          didOpen: () => {
            this.loading = true;
          },
          willClose: () => {
            this.loading = false;
          },
        });
        return;
      }

      Swal.fire({
        title: 'Desea devolver la encomienda a $0?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'SI, devolver a $0!',
        cancelButtonText: 'NO',
      }).then((result) => {
        if (result.isConfirmed) {


          var reclamoNuevo: InsertReclamoRequest = {

            Estado: this.EstadoReclamo,
            Guia: this.nroGuia,
            accionID: this.accionseleccionada,
            telefono: this.telefono,
            domicilio: this.direccion,
            reclamo: "",
            DevuelveEn0: "0",
            FechaNueva: "",
            detalle: this.detalle,
            usuarioID: this.usuario.usuarioID,
            orden: this.nroOrden,
            Destino: this.destinatarioReclamo,

          }


          this.callcenterService.InsertReclamo(reclamoNuevo).subscribe(resInsert => {

            Swal.fire(
              'Bien!',
              resInsert.Estado,
              'success'
            )
            setTimeout(function () {
              window.location.reload(); // you can pass true to reload function to ignore the client cache and reload from the server
            }, 1000);


          });

        }

        else {
          var reclamoNuevo: InsertReclamoRequest = {

            Estado: this.EstadoReclamo,
            Guia: this.nroGuia,
            accionID: this.accionseleccionada,
            telefono: this.telefono,
            domicilio: this.direccion,
            reclamo: "",
            DevuelveEn0: "1",
            FechaNueva: "",//Date.parse("01/01/1990"),
            detalle: this.detalle,
            usuarioID: this.usuario.usuarioID,
            orden: this.nroOrden,
            Destino: this.destinatarioReclamo,

          }


          this.callcenterService.InsertReclamo(reclamoNuevo).subscribe(resInsert => {

            Swal.fire(
              'Bien!',
              resInsert.Estado,
              'success'
            )
            setTimeout(function () {
              window.location.reload(); // you can pass true to reload function to ignore the client cache and reload from the server
            }, 1000);


          });

        }
      })

    }
    else if (this.accionseleccionada == 4) {

      if (this.telefono == "" || this.telefono == undefined) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Falta ingresar un teléfono.'
        })
        return;
      }
      else if (this.direccion == undefined || this.direccion == "") {

        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Falta ingresar una dirección.'
        })
        return;
      }
      else if (this.detalle == undefined || this.detalle == "") {

        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Falta ingresar un detalle.'
        })
        return;
      }

      var reclamoNuevo4: InsertReclamoRequest = {

        Estado: this.EstadoReclamo,
        Guia: this.nroGuia,
        accionID: this.accionseleccionada,
        telefono: this.telefono,
        domicilio: this.direccion,
        reclamo: this.detalle,
        DevuelveEn0: "1",
        FechaNueva: "",
        detalle: this.detalle,
        usuarioID: this.usuario.usuarioID,
        orden: this.nroOrden,
        Destino: this.destinatarioReclamo,

      }
      //4	Reclamo
      this.callcenterService.InsertReclamo(reclamoNuevo4).subscribe(resInsert => {
        Swal.fire(
          'Bien!',
          resInsert.Estado,
          'success'
        )

        setTimeout(function () {
          window.location.reload(); // you can pass true to reload function to ignore the client cache and reload from the server
        }, 1000);
      });
    }
    else if (this.accionseleccionada == 3) {
      if (this.telefononuevaFecha == "" || this.telefononuevaFecha == undefined) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Falta ingresar un teléfono.',
          didOpen: () => {
            this.loading = true;
          },
          willClose: () => {
            this.loading = false;
          },
        });
        return;
      }
      else if (this.direccionNuevaFecha == undefined || this.direccionNuevaFecha == "") {

        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Falta ingresar una dirección.',
          didOpen: () => {
            this.loading = true;
          },
          willClose: () => {
            this.loading = false;
          },
        });
        return;
      }
      var fechasinguiaon1 = this.FechaNueva.toString().replace('-', '')

      var fechasinguiaon2 = fechasinguiaon1.toString().replace('-', '')
      var reclamoNuevo3: InsertReclamoRequest = {

        Estado: this.EstadoReclamo,
        Guia: this.nroGuia,
        accionID: this.accionseleccionada,
        telefono: this.telefononuevaFecha,
        domicilio: this.direccionNuevaFecha,
        reclamo: "",
        DevuelveEn0: "1",
        FechaNueva: fechasinguiaon2,
        detalle: this.detalle,
        usuarioID: this.usuario.usuarioID,
        orden: this.nroOrden,
        Destino: this.destinatarioReclamo,

      }

      //3	Indicar Nueva Fecha de Entrega
      this.callcenterService.InsertReclamo(reclamoNuevo3).subscribe(resInsert => {

        Swal.fire(
          'Bien!',
          resInsert.Estado,
          'success'
        )

        setTimeout(function () {
          window.location.reload(); // you can pass true to reload function to ignore the client cache and reload from the server
        }, 1000);
      });

    }

  }

}
