import { Component, OnInit } from '@angular/core';
import { Responsable } from 'src/app/models/Responsable';
import { ResponsableService } from 'src/app/services/responsable.service';
declare var $:any;

@Component({
  selector: 'app-modal-responsables',
  templateUrl: './modal-responsables.component.html',
  styleUrls: ['./modal-responsables.component.scss']
})
export class ModalResponsablesComponent implements OnInit {

  //datos para seleccionar
  responsables:Responsable[]=[];
  responsablesBusqueda:Responsable[]=[];

  //datos formulario
  dniResponsable:string='';
  apeNomResponsable:string='';
  responsable:Responsable={};

  //filtros
  dniBusqueda:string='';
  apeNomBusqueda:string='';

  //paginacion pv
  p:number=1;

  constructor(private responsableService:ResponsableService) { }

  ngOnInit(): void {
    this.responsableService.getAllResponsables().subscribe((res)=>{
      this.responsables=res.Responsables;
      this.responsablesBusqueda=this.responsables;
    })
  }


  //búsqueda por filtros de responsable
  buscarResponsable(event, campo:string){
    if(campo==='apeNom'){
      if(this.dniBusqueda==='' || this.dniBusqueda==='null' || this.dniBusqueda===null){
        this.responsablesBusqueda=this.responsables.filter(resp=>{
          if(resp.Apellido.toLowerCase().includes(event.toLowerCase())){
            return resp;
          }
          if(resp.Nombre.toLowerCase().includes(event.toLowerCase())){
            return resp;
          }
        })
      }else{
        this.responsablesBusqueda=this.responsables.filter(resp=>{
          return resp.NroDocumento.includes(this.dniBusqueda);
        })
        this.responsablesBusqueda=this.responsablesBusqueda.filter(resp=>{
          if(resp.Apellido.toLowerCase().includes(event.toLowerCase())){
            return resp;
          }
          if(resp.Nombre.toLowerCase().includes(event.toLowerCase())){
            return resp;
          }
        })
      }
    }else if(campo==='dni'){
      if(event==="" || event==='null' || event===null){
        if(this.apeNomBusqueda===''){
          this.responsablesBusqueda=this.responsables;
        }else{
          this.responsablesBusqueda=this.responsables.filter(resp=>{
            if(resp.Apellido.toLowerCase().includes(this.apeNomBusqueda.toLowerCase())){
              return resp;
            }
            if(resp.Nombre.toLowerCase().includes(this.apeNomBusqueda.toLowerCase())){
              return resp;
            }
          })
        }
      }else{
        if(this.apeNomBusqueda===''){
          this.responsablesBusqueda=this.responsables.filter(resp=>{
            return resp.NroDocumento.includes(event);
          })
        }else{
          this.responsablesBusqueda=this.responsables.filter(resp=>{
            if(resp.Apellido.toLowerCase().includes(this.apeNomBusqueda.toLowerCase())){
              return resp;
            }
            if(resp.Nombre.toLowerCase().includes(this.apeNomBusqueda.toLowerCase())){
              return resp;
            }
          })
          this.responsablesBusqueda=this.responsablesBusqueda.filter(resp=>{
            return resp.NroDocumento.includes(event);
          })
        }
      }
    }
    console.log(this.responsables);
    console.log(this.responsablesBusqueda);
  }

  //seleccionar responsable
  seleccionarResponsable(responsable:Responsable){
    /* this.dniResponsable=responsable.NroDocumento;
    this.apeNomResponsable=responsable.Apellido+' '+responsable.Nombre;
    this.responsable=responsable; */
    this.responsableService.seleccionarResponsable(responsable);
    $("#modalResponsables").modal('hide');
    $("#modalLocal").modal('hide');
    $("#modalLocal").modal('show');
    $("#modalPuntoVenta").modal('hide');
    $("#modalPuntoVenta").modal('show');
  }
  
  //cerrar modal responsables
  cerrarResponsables(){
    $("#modalResponsables").modal('hide');
    $("#modalLocal").modal('hide');
    $("#modalLocal").modal('show');
    $("#modalPuntoVenta").modal('hide');
    $("#modalPuntoVenta").modal('show');
  }

}
