import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { DomicilioId, DomicilioLocal } from 'src/app/models/Domicilio';
import {Local, LocalGetById, LocalPost, LocalPostId, LocalServicio, Servicio, UnidadVenta, LocalUVenta, LocalUVPost, ConceptoFacturable, LocalCFacturable, LocalCFacturablePost, ConceptoFGet } from 'src/app/models/Local';
import { PuntoVentaGet } from 'src/app/models/PuntoVenta';
import { Responsable } from 'src/app/models/Responsable';
import { Usuario } from 'src/app/models/Usuario';
import { CategoriaPVService } from 'src/app/services/categoria-pv.service';
import { DomicilioService } from 'src/app/services/domicilio.service';
import { LocalService } from 'src/app/services/local.service';
import { ResponsableService } from 'src/app/services/responsable.service';
declare var $:any;

@Component({
  selector: 'app-abm-local',
  templateUrl: './abm-local.component.html',
  styleUrls: ['./abm-local.component.scss']
})
export class AbmLocalComponent implements OnInit {

  @ViewChild('localesGrid', { static: false }) localesGrid: jqxGridComponent;
  usuario:Usuario;
  cargando:boolean=true;

  locales:Local[]=[];
  localesBusqueda:Local[]=[];
  local:LocalGetById={};
  localNuevo:LocalPost={};
  
  serviciosApi:Servicio[]=[]
  unidadesVentaApi:UnidadVenta[]=[];

  //paginacion pv
  p:number=1;
  
  //filtros locales
  codigo:string='';
  nombreL:string='';
  baja:string='null';
  nombrePV:string='';
  localidad:string='';

  //filtros pv
  sucDgi:string='';
  nomPv:string='';
  categoria:string='';

  //filtros responsables
  /* dniBusqueda:string='';
  apeNomBusqueda:string=''; */
 
  //filtros redespacho
  codigoLR:string='';
  nomLR:string='';
  localidadLR:string='';

  //filtros uv
  nombreUV:string='';
  unidadesVentaBusqueda:UnidadVenta[]=[];
  unidadesVentaSelected:UnidadVenta[]=[];
  unidadesVentaLocal:LocalUVenta[]=[];

  //filtros conceptos facturables
  concepto:string='';
  conceptosFacturables:ConceptoFacturable[]=[];
  conceptosFacturablesBusqueda:ConceptoFacturable[]=[];
  conceptosFacturablesSelected:ConceptoFacturable[]=[];
  conceptosFLocal:LocalCFacturable[]=[];


  //ng if
  domicilioAgregado:boolean=false;
  mostrarBotonDom:boolean=true;

  //datos para seleccionar
  //pv
  puntosVenta:PuntoVentaGet[]=[];
  puntosVentaBusqueda:PuntoVentaGet[]=[];
  
  //responsable
  responsables:Responsable[]=[];
  /* responsablesBusqueda:Responsable[]=[]; */

  //local redespacho
  localesRedespacho:Local[]=[];
  localesRedespachoBusqueda:Local[]=[];

  //datos formulario
  //pv
  nroSucPv:string='';
  nombrePv:string='';
  pvLocal:PuntoVentaGet;
  //responsable
  dniResponsable:string='';
  apeNomResponsable:string='';
  responsable:Responsable={};
  //uv
  localesUv:LocalUVenta[]=[];
  //local
  idLocal:number=0;
  codigoLocal:string='';
  disponible:boolean=true;
  nombreLocal:string='';
  estadoLocal:boolean=false;
  cabecera:boolean=false;
  etiquetas:boolean=false;
  entregaDom:boolean=false;
  retiroDom:boolean=false;
  activa:boolean=false;
  pRecepcion:number=0;
  pRedespacho:boolean=false;
  nroLocal:string=null;
  nomLocal:string='';
  localReferencia:Local={};
  retiroWeb:boolean=false;
  visualizaWeb:boolean=false;
  esCD:boolean=false;
  recibeCargas:boolean=false;
  tarjetaCredito:boolean=false;
  colectora:boolean=false;
  recibePlaArrastre:boolean=false;
  //domicilio
  domicilio:DomicilioLocal={};
  provinciaDom:string='';
  localidadDom:string='';
  cpDom:string='';
  calleDom:string='';
  nroDom:string='';
  telefono1:string='';
  telefono2:string='';
  observacionesDom:string='';
  //servicios
  serviciosSeleccionados:LocalServicio[]=[];
  

  //modal uv
  nombreLocalUv:string;

  //jqxgrid-locales
  source: any =
    {
      datatype: 'json',
        datafields: [
          { name: 'LocalID'},
          { name: 'CodigoLocal'},
          { name: 'LocalDescrip'},
          { name: 'Baja'},
          { name: 'PuntoVentaNombre'},
          { name: 'LocalidadDescrip'},
          { name: 'Estado', map:'Baja'}
        ]
    };
	getWidth() : any {
		if (document.body.offsetWidth < 1250) {
			return '90%';
		}
		
		return 1250;
	}
  dataAdapter: any;
    cellsrenderer = (row: number, columnfield: string, value: string | number, defaulthtml: string, columnproperties: any, rowdata: any): string => {
        if (value < 20) {
            return '<span style="margin: 4px; float: ' + columnproperties.cellsalign + '; color: #ff0000;">' + value + '</span>';
        }
        else {
            return '<span style="margin: 4px; float: ' + columnproperties.cellsalign + '; color: #008000;">' + value + '</span>';
        }
    };
    columns: any[] =
    [
      { text: 'Local ID', datafield: 'LocalID', width: 150, hidden:true},
      { text: 'Código Local', datafield: 'CodigoLocal', width: 150},
      { text: 'Nombre', datafield: 'LocalDescrip', width: 150},
      { text: 'Estado', datafield: 'Baja', width: 150,
        cellsrenderer:(index:number, datafield:boolean, value:any, defaultvalue:any, columnproperties: any, column:any, rowdata:any):string=>{
          if(value===false){
            value="Habilitada"
            return '<span style="margin: 4px; float: ' + columnproperties.cellsalign + ';">'+ value +'</span>';
          }else if(value===true){
            value="Deshabilitada"
            return '<span style="margin: 4px; float: ' + columnproperties.cellsalign + ';"> '+ value +' </span>';
          }
        }
      },
      { text: 'Punto Venta Nombre', datafield: 'PuntoVentaNombre', width: 200},
      { text: 'Localidad', datafield: 'LocalidadDescrip', width: 200},
      { text: 'Editar', datafield: 'Editar', columntype: 'button', width: 100,
        cellsrenderer: (): string => {
          return 'Editar';
        },
        buttonclick: (row:number)=>{
          let dataRecord=this.localesGrid.getrowdata(row);
          this.abrirModalLocal(dataRecord.LocalID);
        }
      },
      { text: 'Deshabilitar', datafield: 'Deshabilitar', columntype: 'button', width: 100,
        cellsrenderer: (): string => {
          return 'Deshabilitar';
        },
        buttonclick: (row:number)=>{
          var opcion=confirm("¿Está seguro que quiere deshabilitar el local?");
          if(opcion){
            let dataRecord=this.localesGrid.getrowdata(row);
            this.delete(dataRecord.LocalID);
          }
        }
      },
      { text: 'U.Venta', datafield: 'U.Venta', columntype: 'button', width: 100,
        cellsrenderer: (): string => {
          return 'U.Venta';
        },
        buttonclick: (row:number)=>{
          let dataRecord=this.localesGrid.getrowdata(row);
          for(let local of this.locales){
            if(local.LocalID===dataRecord.LocalID){
              this.abrirModalUV(local);
              break;
            }
          }
          
        }
      },
      { text: 'C.Facturación', datafield: 'C.Facturación', columntype: 'button', width: 100,
        cellsrenderer: (): string => {
          return 'C.Facturación';
        },
        buttonclick: (row:number)=>{
          let dataRecord=this.localesGrid.getrowdata(row);
          this.nombreLocal=dataRecord.LocalDescrip;
          for(let local of this.locales){
            if(local.LocalID===dataRecord.LocalID){
              this.abrirModalCF(local);
              break;
            }
          }
        }
      }
    ];


constructor(private pvService:CategoriaPVService, private router:Router, private responsableService:ResponsableService, private domicilioService:DomicilioService, private localService:LocalService) { }


  ngOnInit(): void {
    this.usuario=JSON.parse(sessionStorage.getItem('user'));
    if(this.usuario!=null && this.usuario!=undefined){
      for(var i=0; i<this.usuario.OpcionesMenu.length; i++){
        if(this.usuario.OpcionesMenu[i].Url=='locales'){
          break;
        }else if(i==this.usuario.OpcionesMenu.length-1){

          //window.location.href='home';
          this.router.navigate(['/home'])
        }
      }
      if(this.usuario.OpcionesMenu.length==0){
        this.router.navigate(['/home'])
      }
    }else{
      //window.location.href='home';
      this.router.navigate(['/home'])
    }
    this.localService.getAllLocales().subscribe((locales)=>{
      this.localesRedespacho=locales.Locales;
      this.localesRedespachoBusqueda=this.localesRedespacho;
      this.locales=locales.Locales;
      this.localesBusqueda=locales.Locales;
      this.source.localdata=this.localesBusqueda;
      console.log(this.localesBusqueda);
      this.mostrarGrid();
    })
    this.pvService.getAllPV().subscribe((pvApi)=>{
      this.puntosVenta=pvApi.Puntos;
      this.puntosVentaBusqueda=this.puntosVenta;
    })
    this.responsableService.seleccionarResponsableObservable.subscribe((respSelec)=>{
      this.responsable=respSelec;
      this.dniResponsable=this.responsable.NroDocumento;
      this.apeNomResponsable=this.responsable.Apellido+' '+this.responsable.Nombre;
    })
    /* this.responsableService.getAllResponsables().subscribe((res)=>{
      this.responsables=res.Responsables;
    }) */
    //cuando se recibe el domicilio creado se setea en los inputs y se muestra botón editar
    this.domicilioService.agregarDomicilioObservable.subscribe((domicilio)=>{
      if(domicilio===null){
        this.domicilioAgregado=false;
      }else{
        this.domicilioAgregado=true;
        this.domicilio=domicilio;
        this.calleDom=this.domicilio.Calle;
        this.nroDom=this.domicilio.CalleNro;
        this.provinciaDom=this.domicilio.ProvinciaDescrip;
        this.localidadDom=this.domicilio.LocalidadDescrip;
        this.cpDom=this.domicilio.CodigoPostalNro;
        this.telefono1=this.domicilio.Telefono;
        this.telefono2=this.domicilio.Telefono2;
        this.observacionesDom=this.domicilio.Observacion;
      }
    });
    this.localService.getAllServicios().subscribe((servicios)=>{
      this.serviciosApi=servicios.Servicios;
    })
 
    this.localService.getAllUnidadesVenta().subscribe((uv)=>{
      this.unidadesVentaApi=uv.Unidades;
      this.unidadesVentaBusqueda=this.unidadesVentaApi;
    })
    
    this.localService.getAllConceptosF().subscribe((cf)=>{
      this.conceptosFacturables=cf.Conceptos;
      this.conceptosFacturablesBusqueda=this.conceptosFacturables;
    })
  }

  //muestra tabla
  mostrarGrid(){
    this.dataAdapter = new jqx.dataAdapter(this.source);
    this.cargando=false;
  }

  //búsqueda por filtros
  buscarLocal(event, campo:string){

    if(campo==='codigo'){
      if((this.nombreL==='' || this.nombreL===null || this.nombreL==='null') &&
        (this.baja===null || this.baja==='null') &&
        (this.nombrePV==='' || this.nombrePV===null || this.nombrePV==='null') &&
        (this.localidad==='' || this.localidad===null || this.localidad==='null')){
          this.localesBusqueda=this.locales.filter((local)=>{
            return local.CodigoLocal.includes(event);
          })
          
      }/* else if((this.nombreL!=='' || this.nombreL!==null) &&
              (this.baja!==null || this.baja!=='null') &&
              (this.nombrePV!=='' || this.nombrePV!==null) &&
              (this.localidad!=='' || this.localidad!==null)){

      } */else{
        var i=0;
        if(this.nombreL!==''){
          i++;
          this.localesBusqueda=this.locales.filter((local)=>{
            return local.LocalDescrip.toLowerCase().includes(this.nombreL.toLowerCase());
          });
        }
        if(this.baja!=='null'){
          if(i===0){
            i++;
            this.localesBusqueda=this.locales.filter((local)=>{
              return local.Baja.toString().includes(this.baja);
            });
          }else{
            i++;
            this.localesBusqueda=this.localesBusqueda.filter((local)=>{
              return local.Baja.toString().includes(this.baja);
            });
          }
        }
        if(this.nombrePV!==''){
          if(i===0){
            i++;
            this.localesBusqueda=this.locales.filter((local)=>{
              return local.PuntoVentaNombre.toLowerCase().includes(this.nombrePV.toLowerCase());
            });
          }else{
            i++;
            this.localesBusqueda=this.localesBusqueda.filter((local)=>{
              return local.PuntoVentaNombre.toLowerCase().includes(this.nombrePV.toLowerCase());
            });
          }
        }
        if(this.localidad!==''){
          if(i===0){
            this.localesBusqueda=this.locales.filter((local)=>{
              return local.LocalidadDescrip.toLowerCase().includes(this.localidad.toLowerCase());
            });
          }else{

          }
        }
        this.localesBusqueda=this.localesBusqueda.filter((local)=>{
          return local.CodigoLocal.includes(event);
        })
      }
    }else if(campo==='nombreL'){
      if((this.codigo==='' || this.codigo===null || this.codigo==='null') &&
        (this.baja===null || this.baja==='null') &&
        (this.nombrePV==='' || this.nombrePV===null || this.nombrePV==='null') &&
        (this.localidad==='' || this.localidad===null || this.localidad==='null')){
          this.localesBusqueda=this.locales.filter((local)=>{
            return local.LocalDescrip.toLowerCase().includes(event.toLowerCase());
          })
      }else{
        var i=0;
        if(this.codigo!==''){
          i++;
          this.localesBusqueda=this.locales.filter((local)=>{
            return local.CodigoLocal.includes(this.codigo);
          });
        }
        if(this.baja!=='null'){
          if(i===0){
            i++;
            this.localesBusqueda=this.locales.filter((local)=>{
              return local.Baja.toString().includes(this.baja);
            });
          }else{
            i++;
            this.localesBusqueda=this.localesBusqueda.filter((local)=>{
              return local.Baja.toString().includes(this.baja);
            });
          }
        }
        if(this.nombrePV!==''){
          if(i===0){
            i++;
            this.localesBusqueda=this.locales.filter((local)=>{
              return local.PuntoVentaNombre.toLowerCase().includes(this.nombrePV.toLowerCase());
            });
          }else{
            i++;
            this.localesBusqueda=this.localesBusqueda.filter((local)=>{
              return local.PuntoVentaNombre.toLowerCase().includes(this.nombrePV.toLowerCase());
            });
          }
        }
        if(this.localidad!==''){
          if(i===0){
            this.localesBusqueda=this.locales.filter((local)=>{
              return local.LocalidadDescrip.toLowerCase().includes(this.localidad.toLowerCase());
            });
          }else{

          }
        }
        this.localesBusqueda=this.localesBusqueda.filter((local)=>{
          return local.LocalDescrip.toLowerCase().includes(event.toLowerCase());
        })
      }
    }else if(campo==='baja'){
      if(event==='null' || event===null){
        if((this.codigo==='' || this.codigo===null || this.codigo==='null') &&
        (this.nombreL==='' || this.nombreL===null || this.nombreL==='null') &&
        (this.nombrePV==='' || this.nombrePV===null || this.nombrePV==='null') &&
        (this.localidad==='' || this.localidad===null || this.localidad==='null')){
          this.localesBusqueda=this.locales;
        }else{
          var i=0;
          if(this.codigo!==''){
            i++;
            this.localesBusqueda=this.locales.filter((local)=>{
              return local.CodigoLocal.includes(this.codigo);
            });
          }
          if(this.nombreL!==''){
            if(i===0){
              i++;
              this.localesBusqueda=this.locales.filter((local)=>{
                return local.LocalDescrip.toLowerCase().includes(this.nombreL.toLowerCase());
              });
            }else{
              i++;
              this.localesBusqueda=this.localesBusqueda.filter((local)=>{
                return local.LocalDescrip.toLowerCase().includes(this.nombreL.toLowerCase());
              });
            }
          }
          if(this.nombrePV!==''){
            if(i===0){
              i++;
              this.localesBusqueda=this.locales.filter((local)=>{
                return local.PuntoVentaNombre.toLowerCase().includes(this.nombrePV.toLowerCase());
              });
            }else{
              i++;
              this.localesBusqueda=this.localesBusqueda.filter((local)=>{
                return local.PuntoVentaNombre.toLowerCase().includes(this.nombrePV.toLowerCase());
              });
            }
          }
          if(this.localidad!==''){
            if(i===0){
              this.localesBusqueda=this.locales.filter((local)=>{
                return local.LocalidadDescrip.toLowerCase().includes(this.localidad.toLowerCase());
              });
            }else{

            }
          }
         /*  this.localesBusqueda=this.localesBusqueda.filter((local)=>{
            return local.Baja.toString().includes(event);
          }) */
        }
      }else{
        if((this.codigo==='' || this.codigo===null || this.codigo==='null') &&
          (this.nombreL==='' || this.nombreL===null || this.nombreL==='null') &&
          (this.nombrePV==='' || this.nombrePV===null || this.nombrePV==='null') &&
          (this.localidad==='' || this.localidad===null || this.localidad==='null')){
            this.localesBusqueda=this.locales.filter((local)=>{
              return local.Baja.toString().includes(event);
            })
        }else{
          var i=0;
          if(this.codigo!==''){
            i++;
            this.localesBusqueda=this.locales.filter((local)=>{
              return local.CodigoLocal.includes(this.codigo);
            });
          }
          if(this.nombreL!==''){
            if(i===0){
              i++;
              this.localesBusqueda=this.locales.filter((local)=>{
                return local.LocalDescrip.toLowerCase().includes(this.nombreL.toLowerCase());
              });
            }else{
              i++;
              this.localesBusqueda=this.localesBusqueda.filter((local)=>{
                return local.LocalDescrip.toLowerCase().includes(this.nombreL.toLowerCase());
              });
            }
          }
          if(this.nombrePV!==''){
            if(i===0){
              i++;
              this.localesBusqueda=this.locales.filter((local)=>{
                return local.PuntoVentaNombre.toLowerCase().includes(this.nombrePV.toLowerCase());
              });
            }else{
              i++;
              this.localesBusqueda=this.localesBusqueda.filter((local)=>{
                return local.PuntoVentaNombre.toLowerCase().includes(this.nombrePV.toLowerCase());
              });
            }
          }
          if(this.localidad!==''){
            if(i===0){
              this.localesBusqueda=this.locales.filter((local)=>{
                return local.LocalidadDescrip.toLowerCase().includes(this.localidad.toLowerCase());
              });
            }else{

            }
          }
          this.localesBusqueda=this.localesBusqueda.filter((local)=>{
            return local.Baja.toString().includes(event);
          })
        }
      }
    }else if(campo==='nombrePV'){
      if((this.codigo==='' || this.codigo===null || this.codigo==='null') &&
        (this.baja===null || this.baja==='null') &&
        (this.nombreL==='' || this.nombreL===null || this.nombreL==='null') &&
        (this.localidad==='' || this.localidad===null || this.localidad==='null')){
          this.localesBusqueda=this.locales.filter((local)=>{
            return local.PuntoVentaNombre.toLowerCase().includes(event.toLowerCase());
          })
      }else{
        var i=0;
        if(this.codigo!==''){
          i++;
          this.localesBusqueda=this.locales.filter((local)=>{
            return local.CodigoLocal.includes(this.codigo);
          });
        }
        if(this.baja!=='null'){
          if(i===0){
            i++;
            this.localesBusqueda=this.locales.filter((local)=>{
              return local.Baja.toString().includes(this.baja);
            });
          }else{
            i++;
            this.localesBusqueda=this.localesBusqueda.filter((local)=>{
              return local.Baja.toString().includes(this.baja);
            });
          }
        }
        if(this.nombreL!==''){
          if(i===0){
            i++;
            this.localesBusqueda=this.locales.filter((local)=>{
              return local.LocalDescrip.toLowerCase().includes(this.nombreL.toLowerCase());
            });
          }else{
            i++;
            this.localesBusqueda=this.localesBusqueda.filter((local)=>{
              return local.LocalDescrip.toLowerCase().includes(this.nombreL.toLowerCase());
            });
          }
        }
        if(this.localidad!==''){
          if(i===0){
            this.localesBusqueda=this.locales.filter((local)=>{
              return local.LocalidadDescrip.toLowerCase().includes(this.localidad.toLowerCase());
            });
          }else{
            this.localesBusqueda=this.localesBusqueda.filter((local)=>{
              return local.LocalidadDescrip.toLowerCase().includes(this.localidad.toLowerCase());
            });
          }
        }
        this.localesBusqueda=this.localesBusqueda.filter((local)=>{
          return local.PuntoVentaNombre.toLowerCase().includes(event.toLowerCase());
        })
      }
    }else if(campo==='localidad'){
      if((this.codigo==='' || this.codigo===null || this.codigo==='null') &&
        (this.baja===null || this.baja==='null') &&
        (this.nombrePV==='' || this.nombrePV===null || this.nombrePV==='null') &&
        (this.nombreL==='' || this.nombreL===null || this.nombreL==='null')){
          this.localesBusqueda=this.locales.filter((local)=>{
            return local.LocalidadDescrip.toLowerCase().includes(event.toLowerCase());
          })
      }else{
        var i=0;
        if(this.codigo!==''){
          i++;
          this.localesBusqueda=this.locales.filter((local)=>{
            return local.CodigoLocal.includes(this.codigo);
          });
        }
        if(this.baja!=='null'){
          if(i===0){
            i++;
            this.localesBusqueda=this.locales.filter((local)=>{
              return local.Baja.toString().includes(this.baja);
            });
          }else{
            i++;
            this.localesBusqueda=this.localesBusqueda.filter((local)=>{
              return local.Baja.toString().includes(this.baja);
            });
          }
        }
        if(this.nombrePV!==''){
          if(i===0){
            i++;
            this.localesBusqueda=this.locales.filter((local)=>{
              return local.PuntoVentaNombre.toLowerCase().includes(this.nombrePV.toLowerCase());
            });
          }else{
            i++;
            this.localesBusqueda=this.localesBusqueda.filter((local)=>{
              return local.PuntoVentaNombre.toLowerCase().includes(this.nombrePV.toLowerCase());
            });
          }
        }
        if(this.nombreL!==''){
          if(i===0){
            this.localesBusqueda=this.locales.filter((local)=>{
              return local.LocalDescrip.toLowerCase().includes(this.nombreL.toLowerCase());
            });
          }else{
            this.localesBusqueda=this.localesBusqueda.filter((local)=>{
              return local.LocalDescrip.toLowerCase().includes(this.nombreL.toLowerCase());
            });
          }
        }
        this.localesBusqueda=this.localesBusqueda.filter((local)=>{
          return local.LocalidadDescrip.toLowerCase().includes(event.toLowerCase());
        })
      }
    }
    this.source.localdata=this.localesBusqueda;
    this.mostrarGrid();
  }

  //abrir modal local
  abrirModalLocal(idLocal:number){
    //vaciar inputs si id es 0 si no mostrar datos del id
    if(idLocal===0){
      this.idLocal=idLocal;
      this.nroSucPv='';
      this.nombrePv='';
      this.dniResponsable='';
      this.apeNomResponsable='';
      this.responsable={};
      this.codigoLocal='';
      this.nombreLocal='';
      this.estadoLocal=false;
      this.cabecera=false;
      this.etiquetas=false;
      this.entregaDom=false;
      this.retiroDom=false;
      this.activa=false;
      this.pRedespacho=false;
      this.nroLocal=null;
      this.nomLocal='';
      this.retiroWeb=false;
      this.visualizaWeb=false;
      this.esCD=false;
      this.recibeCargas=false;
      this.tarjetaCredito=false;
      this.colectora=false;
      this.recibePlaArrastre=false;
      this.domicilio={};
      this.provinciaDom='';
      this.localidadDom='';
      this.cpDom='';
      this.calleDom='';
      this.nroDom='';
      this.telefono1='';
      this.telefono2='';
      this.observacionesDom='';
      this.serviciosSeleccionados=[];
      this.domicilioAgregado=false;
      this.domicilio=null;
      $("#cod").prop('disabled', false);
      $("input:checkbox").prop('checked', false);
    }else{
      //buscar por id
      var localId:LocalPostId={};
      localId.LocalID=idLocal;
      this.localService.getOneLocal(localId).subscribe((localApi)=>{
        this.local.Local=localApi.Locales[0];
        this.idLocal=this.local.Local.LocalID;
        this.codigoLocal=this.local.Local.CodigoLocal;
        this.nombreLocal=this.local.Local.LocalDescrip;
        this.estadoLocal=this.local.Local.Baja;
        for(let pv of this.puntosVentaBusqueda){
          if(pv.PuntoVentaID===this.local.Local.PuntoVentaID){
            this.nroSucPv=pv.SucursalDGI;
            this.nombrePv=pv.PuntoVentaDescrip;
            this.pvLocal=pv;
            break;
          }
        }
        for(let responsable of this.responsables){
          if(responsable.ResponsableID===this.local.Local.ResponsableID){
            this.dniResponsable=responsable.NroDocumento;
            this.apeNomResponsable=this.local.Local.ResponsableNombre;
            this.responsable=responsable;
            break;
          }
        }
        this.localService.getLocalCO(localId).subscribe((localCoApi)=>{
          this.local.LocalesCO=localCoApi.Local;
          this.cabecera=this.local.LocalesCO.Cabecera;
          this.pRecepcion=this.local.LocalesCO.PuntoRecepcion;
          this.entregaDom=this.local.LocalesCO.EntregaDomicilio;
          this.retiroDom=this.local.LocalesCO.RetiroDomicilio;
          this.activa=this.local.LocalesCO.Activa;
          this.pRedespacho=this.local.LocalesCO.PuntoRedespacho;
          for(let local of this.localesBusqueda){
            if(local.LocalID===this.local.LocalesCO.LocalReferenciaID){
              this.nroLocal=local.CodigoLocal;
              this.nomLocal=local.LocalDescrip;
              this.localReferencia=local;
              break;
            }
          }
          this.retiroWeb=this.local.LocalesCO.RetirowEB;
          this.visualizaWeb=this.local.LocalesCO.VisualizaWeb;
          this.esCD=this.local.LocalesCO.EsCD;
          this.recibeCargas=this.local.LocalesCO.RecibeCargas;
          this.tarjetaCredito=this.local.LocalesCO.TarjetaCredito;
          this.colectora=this.local.LocalesCO.Colectora;
          this.recibePlaArrastre=this.local.LocalesCO.RecibePlanillaArrastre;
        })

        this.localService.getLocalServicio(localId).subscribe((localServicioApi)=>{
          this.local.Servicios=localServicioApi.LocalesServicio;
          this.serviciosSeleccionados=this.local.Servicios;
        
        for(var i:number=0; i<this.serviciosApi.length; i++){
          for(var j:number=0; j<this.serviciosSeleccionados.length; j++){
            if(this.serviciosApi[i].ServicioID===this.serviciosSeleccionados[j].ServicioID){
              if(this.serviciosSeleccionados[j].Emite){
                $("#emision"+i).prop("checked", true);
              }
              if(this.serviciosSeleccionados[j].Recibe){
                $("#recepcion"+i).prop("checked", true);
              }
            }
          }
        }
        })

        var domicilio:DomicilioId={};
        domicilio.Id=this.local.Local.DomicilioLocalID;
        this.localService.getLocalDomicilio(domicilio).subscribe((res)=>{
          this.domicilio=res.Domicilio;
          this.local.Domicilio=this.domicilio;
          this.domicilio=this.local.Domicilio;
          this.provinciaDom=this.local.Domicilio.ProvinciaDescrip;
          this.localidadDom=this.local.Domicilio.LocalidadDescrip;
          this.cpDom=this.local.Domicilio.CodigoPostalNro;
          this.calleDom=this.local.Domicilio.Calle;
          this.nroDom=this.local.Domicilio.CalleNro;
          this.telefono1=this.local.Domicilio.Telefono;
          this.telefono2=this.local.Domicilio.Telefono2;
          this.observacionesDom=this.local.Domicilio.Observacion;
          this.domicilioAgregado=true;
        })
        console.log(this.local)
        $("#cod").prop('disabled', true);
      });
      
      
    }
    $("#modalLocal").modal('show');
  }

  //cerrar modal local
  cerrarModalLocal(){
    this.idLocal=0;
    this.nroSucPv='';
    this.nombrePv='';
    this.dniResponsable='';
    this.apeNomResponsable='';
    this.responsable={};
    this.codigoLocal='';
    this.nombreLocal='';
    this.estadoLocal=false;
    this.cabecera=false;
    this.etiquetas=false;
    this.entregaDom=false;
    this.retiroDom=false;
    this.activa=false;
    this.pRedespacho=false;
    this.nroLocal=null;
    this.nomLocal='';
    this.retiroWeb=false;
    this.visualizaWeb=false;
    this.esCD=false;
    this.recibeCargas=false;
    this.tarjetaCredito=false;
    this.colectora=false;
    this.recibePlaArrastre=false;
    this.domicilio={};
    this.provinciaDom='';
    this.localidadDom='';
    this.cpDom='';
    this.calleDom='';
    this.nroDom='';
    this.telefono1='';
    this.telefono2='';
    this.observacionesDom='';
    this.serviciosSeleccionados=[];
    this.domicilioAgregado=false;
    this.domicilio=null;
    $("input:checkbox").prop('checked', false);
    $("#modalLocal").modal('hide');
  }

  //búsqueda por filtros de pv
  buscarPV(event, campo:string){
    if(campo==='nroSuc'){
      if(event==='' || event===null || event==='null'){
        if((this.categoria==='' || this.categoria===null) && (this.nomPv==='' || this.nomPv===null)){
          this.puntosVentaBusqueda=this.puntosVenta;
        }else if((this.categoria!=='' || this.categoria!==null) && (this.nomPv==='' || this.nomPv===null)){
          this.puntosVentaBusqueda=this.puntosVenta.filter((pv)=>{
            return pv.CategoriaNombre.toLowerCase().includes(this.categoria.toLowerCase());
          });
        }else if((this.categoria==='' || this.categoria===null) && (this.nomPv!=='' || this.nomPv!==null)){
          this.puntosVentaBusqueda=this.puntosVenta.filter((pv)=>{
            return pv.PuntoVentaDescrip.toLowerCase().includes(this.nomPv.toLowerCase());
          });
        }else if((this.categoria!=='' || this.categoria!==null) && (this.nomPv!=='' || this.nomPv!==null)){
          this.puntosVentaBusqueda=this.puntosVenta.filter((pv)=>{
            return pv.CategoriaNombre.toLowerCase().includes(this.categoria.toLowerCase());
          });
          this.puntosVentaBusqueda=this.puntosVentaBusqueda.filter((pv)=>{
            return pv.PuntoVentaDescrip.toLowerCase().includes(this.nomPv.toLowerCase());
          });
        }
      }else{
        if((this.categoria==='' || this.categoria===null) && (this.nomPv==='' || this.nomPv===null)){
          this.puntosVentaBusqueda=this.puntosVenta.filter((pv)=>{
            if(pv.SucursalDGI!=null){
              return pv.SucursalDGI.includes(event);
            }
          })
        }else if((this.categoria!=='' || this.categoria!==null) && (this.nomPv==='' || this.nomPv===null)){
          this.puntosVentaBusqueda=this.puntosVenta.filter((pv)=>{
            return pv.CategoriaNombre.toLowerCase().includes(this.categoria.toLowerCase());
          });
          this.puntosVentaBusqueda=this.puntosVentaBusqueda.filter((pv)=>{
            if(pv.SucursalDGI!=null){
              return pv.SucursalDGI.includes(event);
            }
          });
        }else if((this.categoria==='' || this.categoria===null) && (this.nomPv!=='' || this.nomPv!==null)){
          this.puntosVentaBusqueda=this.puntosVenta.filter((pv)=>{
            return pv.PuntoVentaDescrip.toLowerCase().includes(this.nomPv.toLowerCase());
          });
          this.puntosVentaBusqueda=this.puntosVentaBusqueda.filter((pv)=>{
            if(pv.SucursalDGI!=null){
              return pv.SucursalDGI.includes(event);
            }
          });
        }else if((this.categoria!=='' || this.categoria!==null) && (this.nomPv!=='' || this.nomPv!==null)){
          this.puntosVentaBusqueda=this.puntosVenta.filter((pv)=>{
            return pv.CategoriaNombre.toLowerCase().includes(this.categoria.toLowerCase());
          });
          this.puntosVentaBusqueda=this.puntosVentaBusqueda.filter((pv)=>{
            return pv.PuntoVentaDescrip.toLowerCase().includes(this.nomPv.toLowerCase());
          });
          this.puntosVentaBusqueda=this.puntosVentaBusqueda.filter((pv)=>{
            if(pv.SucursalDGI!=null){
              return pv.SucursalDGI.includes(event);
            }
          });
        }
      }
    }else if(campo==='nomSuc'){
      if((this.sucDgi==='' || this.sucDgi===null || this.sucDgi==='null') && (this.categoria==='' || this.categoria===null)){
        this.puntosVentaBusqueda=this.puntosVenta.filter((pv)=>{
          return pv.PuntoVentaDescrip.toLowerCase().includes(event.toLowerCase());
        });
      }else if((this.sucDgi!=='' || this.sucDgi!==null) && (this.categoria==='' || this.categoria===null)){
        this.puntosVentaBusqueda=this.puntosVenta.filter((pv)=>{
          if(pv.SucursalDGI!=null){
            return pv.SucursalDGI.includes(this.sucDgi);
          }
        });
        this.puntosVentaBusqueda=this.puntosVentaBusqueda.filter((pv)=>{
          return pv.PuntoVentaDescrip.toLocaleLowerCase().includes(event.toLowerCase());
        });
      }else if((this.sucDgi==='' || this.sucDgi===null || this.nroSucPv==='null') && (this.categoria!=='' || this.categoria!==null)){
        this.puntosVentaBusqueda=this.puntosVenta.filter((pv)=>{
          return pv.CategoriaNombre.toLowerCase().includes(this.categoria.toLowerCase());
        });
        this.puntosVentaBusqueda=this.puntosVentaBusqueda.filter((pv)=>{
          return pv.PuntoVentaDescrip.toLocaleLowerCase().includes(event.toLowerCase());
        });
      }else if((this.sucDgi!=='' || this.sucDgi!==null) && (this.categoria!=='' || this.categoria!==null)){
        this.puntosVentaBusqueda=this.puntosVenta.filter((pv)=>{
          if(pv.SucursalDGI!=null){
            return pv.SucursalDGI.includes(this.sucDgi);
          }
        });
        this.puntosVentaBusqueda=this.puntosVentaBusqueda.filter((pv)=>{
          return pv.CategoriaNombre.toLowerCase().includes(this.categoria.toLowerCase());
        });
        this.puntosVentaBusqueda=this.puntosVentaBusqueda.filter((pv)=>{
          return pv.PuntoVentaDescrip.toLocaleLowerCase().includes(event.toLowerCase());
        });
      }
    }else if(campo==='cat'){
      if((this.sucDgi==='' || this.sucDgi===null || this.sucDgi==='null') && (this.nomPv==='' || this.nomPv===null)){
        this.puntosVentaBusqueda=this.puntosVenta.filter((pv)=>{
          return pv.CategoriaNombre.toLowerCase().includes(event.toLowerCase());
        });
      }else if((this.sucDgi!=='' || this.sucDgi!==null) && (this.nomPv==='' || this.nomPv===null)){
        this.puntosVentaBusqueda=this.puntosVenta.filter((pv)=>{
          if(pv.SucursalDGI!=null){
            return pv.SucursalDGI.includes(this.sucDgi);
          }
        });
        this.puntosVentaBusqueda=this.puntosVentaBusqueda.filter((pv)=>{
          return pv.CategoriaNombre.toLowerCase().includes(event.toLowerCase());
        });
      }else if((this.sucDgi==='' || this.sucDgi===null || this.sucDgi==='null') && (this.nomPv!=='' || this.nomPv!==null)){
        this.puntosVentaBusqueda=this.puntosVenta.filter((pv)=>{
          return pv.PuntoVentaDescrip.toLowerCase().includes(this.nomPv.toLowerCase());
        });
        this.puntosVentaBusqueda=this.puntosVentaBusqueda.filter((pv)=>{
          return pv.CategoriaNombre.toLowerCase().includes(event.toLowerCase());
        });
      }else if((this.sucDgi!=='' || this.sucDgi!==null) && (this.nomPv!=='' || this.nomPv!==null)){
        this.puntosVentaBusqueda=this.puntosVenta.filter((pv)=>{
          if(pv.SucursalDGI!=null){
            return pv.SucursalDGI.includes(this.sucDgi);
          }
        });
        this.puntosVentaBusqueda=this.puntosVentaBusqueda.filter((pv)=>{
          return pv.PuntoVentaDescrip.toLowerCase().includes(this.nomPv.toLowerCase());
        });
        this.puntosVentaBusqueda=this.puntosVentaBusqueda.filter((pv)=>{
          return pv.CategoriaNombre.toLowerCase().includes(event.toLowerCase());
        });
      }
    }
  }

  //seleccionar pv
  seleccionarPV(pv:PuntoVentaGet){
    this.pvLocal=pv;
    this.nroSucPv=pv.SucursalDGI;
    this.nombrePv=pv.PuntoVentaDescrip;
    $("#modalPV").modal('hide');
    $("#modalLocal").modal('hide');
    $("#modalLocal").modal('show');
  }

  //cerrar modal pv
  cerrarPV(){
    $("#modalPV").modal('hide');
    $("#modalLocal").modal('hide');
    $("#modalLocal").modal('show');
  }

  //búsqueda por filtros de responsable
  /* buscarResponsable(event, campo:string){
    if(campo==='apeNom'){
      if(this.dniBusqueda==='' || this.dniBusqueda==='null' || this.dniBusqueda===null){
        this.responsablesBusqueda=this.responsables.filter(resp=>{
          if(resp.Apellido.toLowerCase().includes(event.toLowerCase())){
            return resp;
          }
          if(resp.Nombre.toLowerCase().includes(event.toLowerCase())){
            return resp;
          }
        })
      }else{
        this.responsablesBusqueda=this.responsables.filter(resp=>{
          return resp.NroDocumento.includes(this.dniBusqueda);
        })
        this.responsablesBusqueda=this.responsablesBusqueda.filter(resp=>{
          if(resp.Apellido.toLowerCase().includes(event.toLowerCase())){
            return resp;
          }
          if(resp.Nombre.toLowerCase().includes(event.toLowerCase())){
            return resp;
          }
        })
      }
    }else if(campo==='dni'){
      if(event==="" || event==='null' || event===null){
        if(this.apeNomBusqueda===''){
          this.responsablesBusqueda=this.responsables;
        }else{
          this.responsablesBusqueda=this.responsables.filter(resp=>{
            if(resp.Apellido.toLowerCase().includes(this.apeNomBusqueda.toLowerCase())){
              return resp;
            }
            if(resp.Nombre.toLowerCase().includes(this.apeNomBusqueda.toLowerCase())){
              return resp;
            }
          })
        }
      }else{
        if(this.apeNomBusqueda===''){
          this.responsablesBusqueda=this.responsables.filter(resp=>{
            return resp.NroDocumento.includes(event);
          })
        }else{
          this.responsablesBusqueda=this.responsables.filter(resp=>{
            if(resp.Apellido.toLowerCase().includes(this.apeNomBusqueda.toLowerCase())){
              return resp;
            }
            if(resp.Nombre.toLowerCase().includes(this.apeNomBusqueda.toLowerCase())){
              return resp;
            }
          })
          this.responsablesBusqueda=this.responsablesBusqueda.filter(resp=>{
            return resp.NroDocumento.includes(event);
          })
        }
      }
    }
    console.log(this.responsables);
    console.log(this.responsablesBusqueda);
  } */

  //seleccionar responsable
  /* seleccionarResponsable(responsable:Responsable){
    this.dniResponsable=responsable.NroDocumento;
    this.apeNomResponsable=responsable.Apellido+' '+responsable.Nombre;
    this.responsable=responsable;
    $("#modalResponsables").modal('hide');
    $("#modalLocal").modal('hide');
    $("#modalLocal").modal('show');
  } */
  
  //cerrar modal responsables
  /* cerrarResponsables(){
    $("#modalResponsables").modal('hide');
    $("#modalLocal").modal('hide');
    $("#modalLocal").modal('show');
  } */

  //abrir modal domicilio
  abrirModalDom(accion:string){
    if(accion==='agregar'){
      this.domicilioService.agregarDomicilio(this.domicilio);
      $("#modalDomicilio").modal('show');
    }else if(accion==='editar'){
      this.domicilioService.agregarDomicilio(this.domicilio);
      $("#modalDomicilio").modal('show');
    }
    
  }

  //abrir modal unidades de venta
  abrirModalUV(local:Local){
    this.unidadesVentaSelected=null;
    this.unidadesVentaSelected=[];
    this.unidadesVentaLocal=[];
    for(let uvB of this.unidadesVentaBusqueda){
      $("#"+uvB.UnidadVentaID).prop("checked", false);
    }
    $("#selectAll").prop("cheked", false);
    this.unidadesVentaBusqueda=this.unidadesVentaApi;
    this.nombreUV='';
    var localId:LocalPostId={};
    localId.LocalID=local.LocalID;
    this.localService.getLocalUv(localId).subscribe((res)=>{
      if(res.LocalesUV.length!==0){
        this.localesUv=res.LocalesUV;
        this.unidadesVentaLocal=this.localesUv;
        for(let localUv of this.localesUv){
          for(let uv of this.unidadesVentaApi){
            if(localUv.UnidadVentaID===uv.UnidadVentaID){
              this.unidadesVentaSelected.push(uv);
              $("#"+uv.UnidadVentaID).prop('checked', true);
              break;
            }
          }
        }
      }
      this.idLocal=local.LocalID;
      this.nombreLocalUv=local.LocalDescrip;
      console.log(this.unidadesVentaSelected);
    });
    $("#modalUV").modal('show');
  }

  //buscar unidades venta
  buscarUV(event){
    this.unidadesVentaBusqueda=this.unidadesVentaApi.filter((uv)=>{
      return uv.UnidadVentaDescrip.toLowerCase().includes(event.toLowerCase());
    })
    setTimeout(() => {
      this.mostrarUVSelected();
    }, 250);
  }

  mostrarUVSelected(){
    var todos:number=0;
    if(this.unidadesVentaBusqueda.length!==0){
      for(let uvB of this.unidadesVentaBusqueda){
        for(let uvS of this.unidadesVentaSelected){
          if(uvB.UnidadVentaID===uvS.UnidadVentaID){
            $("#"+uvB.UnidadVentaID).prop("checked", true);
            todos++;
            break;
          }
        }
      }
      if(todos===this.unidadesVentaBusqueda.length){
        $("#selectAll").prop("checked", true);
      }else{
        $("#selectAll").prop("checked", false);
      }
    }
  }

  //seleccionar unidades venta
  seleccionarTodasUV(event){
    console.log(event.target.checked)
    if(event.target.checked){
      for(let uv of this.unidadesVentaBusqueda){
        $("#"+uv.UnidadVentaID).prop("checked", true);
        this.unidadesVentaSelected.push(uv);
        if(this.unidadesVentaLocal.length!==0){
          for(var i:number=0; i<this.unidadesVentaLocal.length; i++){
            if(this.unidadesVentaLocal[i].UnidadVentaID===uv.UnidadVentaID){
              break;
            }else if(i===(this.unidadesVentaLocal.length-1)){
              
              var uvLocal:LocalUVenta={}
              uvLocal.LocalID=this.idLocal;
              uvLocal.FechaAlta=new Date;
              uvLocal.LocalUVentaID=0;
              uvLocal.UnidadVentaID=uv.UnidadVentaID;
              this.unidadesVentaLocal.push(uvLocal);
              break;
            }
          }
        }else{
          var uvLocal:LocalUVenta={}
          uvLocal.LocalID=this.idLocal;
          uvLocal.FechaAlta=new Date;
          uvLocal.LocalUVentaID=0;
          uvLocal.UnidadVentaID=uv.UnidadVentaID;
          this.unidadesVentaLocal.push(uvLocal);
        }        
      }
      
    }else{
      for(let uv of this.unidadesVentaBusqueda){
        $("#"+uv.UnidadVentaID).prop("checked", false);
        for(var i:number=0; i<this.unidadesVentaSelected.length; i++){
          if(uv.UnidadVentaID===this.unidadesVentaSelected[i].UnidadVentaID){
            this.unidadesVentaSelected.splice(i, 1);
            
          }
        }
        for(var i:number=0; i<this.unidadesVentaLocal.length; i++){
          if(this.unidadesVentaLocal[i].UnidadVentaID===uv.UnidadVentaID){
            this.unidadesVentaLocal.splice(i, 1);
          }
        }
      }
    }
    console.log(this.unidadesVentaSelected)
    console.log(this.unidadesVentaLocal)
  }

  seleccionarUV(event, uv:UnidadVenta){
    console.log(uv)
    if(event.target.checked){
      this.unidadesVentaSelected.push(uv);
      if(this.unidadesVentaLocal.length!==0){
        for(var i:number=0; i<this.unidadesVentaLocal.length; i++){
          if(this.unidadesVentaLocal[i].UnidadVentaID===uv.UnidadVentaID){
            break;
          }else if(i===(this.unidadesVentaLocal.length-1)){
            var uvLocal:LocalUVenta={}
            uvLocal.LocalID=this.idLocal;
            uvLocal.FechaAlta=new Date;
            uvLocal.LocalUVentaID=0;
            uvLocal.UnidadVentaID=uv.UnidadVentaID;
            this.unidadesVentaLocal.push(uvLocal);
            break;
          }
        }
      }else{
        var uvLocal:LocalUVenta={}
        uvLocal.LocalID=this.idLocal;
        uvLocal.FechaAlta=new Date;
        uvLocal.LocalUVentaID=0;
        uvLocal.UnidadVentaID=uv.UnidadVentaID;
        this.unidadesVentaLocal.push(uvLocal);
      }
    }else{
      for(var i:number=0; i<this.unidadesVentaSelected.length; i++){
        if(uv.UnidadVentaID===this.unidadesVentaSelected[i].UnidadVentaID){
          this.unidadesVentaSelected.splice(i, 1);
        }
      }
      for(var i:number=0; i<this.unidadesVentaLocal.length; i++){
        if(this.unidadesVentaLocal[i].UnidadVentaID===uv.UnidadVentaID){
          this.unidadesVentaLocal.splice(i, 1);
        }
      }
    }
    console.log(this.unidadesVentaSelected)
    console.log(this.unidadesVentaLocal);
  }

  //guardar unidades venta
  guardarUV(){
    console.log(this.unidadesVentaSelected);
    this.localesUv=this.unidadesVentaLocal;
    /* if(this.localesUv.length==0){
      this.localesUv=this.unidadesVentaLocal;
  
      var localUvNuevo:LocalUVPost={};
      localUvNuevo.Unidades=this.localesUv;
      
      this.localService.saveLocalUv(localUvNuevo).subscribe((res)=>{
        if(res.Codigo===200){
          alert(res.Mensaje);
        }else{
          alert(res.Mensaje);
        }
      });
    }else{
      debugger
      console.log(this.localesUv)
      console.log(this.unidadesVentaSelected)
      
      for(var i:number=0; i< this.unidadesVentaLocal.length; i++){
        for(var j:number=0; j<this.localesUv.length; j++){
          if(this.unidadesVentaLocal[i].UnidadVentaID===this.localesUv[j].UnidadVentaID){
            break;
          }else if(j===(this.localesUv.length-1)){
            var localuv:LocalUVenta={};
            localuv.LocalID=this.idLocal;
            localuv.LocalUVentaID=0;
            localuv.FechaAlta=new Date;
            localuv.UnidadVentaID=this.unidadesVentaSelected[i].UnidadVentaID;
            this.localesUv.push(localuv);
            break;
          }
        }
      }
      
      var localUvNuevo:LocalUVPost={};
      localUvNuevo.Unidades=this.localesUv;
      console.log(this.localesUv)
      this.localService.saveLocalUv(localUvNuevo).subscribe((res)=>{
        if(res.Codigo===200){
          alert(res.Mensaje);
        }else{
          alert(res.Mensaje);
        }
      });
    } */
    var localUvNuevo:LocalUVPost={};
      localUvNuevo.Unidades=this.localesUv;
      this.localService.saveLocalUv(localUvNuevo).subscribe((res)=>{
        if(res.Codigo===200){
          alert(res.Mensaje);
        }else{
          alert(res.Mensaje);
        }
      });
    $("#modalUV").modal('hide');
    
  }

  //cerrar modal unidades venta
  cerrarUV(){
    this.unidadesVentaSelected=[];
    this.unidadesVentaLocal=[];
    this.nombreUV='';
    for(let uvB of this.unidadesVentaBusqueda){
      $("#"+uvB.UnidadVentaID).prop("checked", false);
    }
    $("#selectAll").prop("cheked", false);
    this.unidadesVentaBusqueda=this.unidadesVentaApi;
    $("#modalUV").modal('hide');
  }

  validarCodigoLocal(codigoLocal:string){
    if(codigoLocal!=null){
      for(let local of this.locales){
        if(codigoLocal.toString()===local.CodigoLocal){
          this.disponible=false;
          break;
        }else{
          this.disponible=true;
        }
      }
    }
  }

  //seleccionar local de redespacho
  seleccionarLR(local:Local){
    this.localReferencia=local;
    this.nomLocal=local.LocalDescrip;
    this.nroLocal=local.CodigoLocal;
    $("#modalLR").modal('hide');
    $("#modalLocal").modal('hide');
    $("#modalLocal").modal('show');
  }

  //cerrar modal redespacho
  cerrarLR(){
    $("#modalLR").modal('hide');
    $("#modalLocal").modal('hide');
    $("#modalLocal").modal('show');
  }

  //seleccionar servicios
  seleccionarServicio(event, tipo:string, servicioSelec:Servicio){
    if(this.idLocal===0){
      if(event.target.checked){
        if(this.serviciosSeleccionados.length===0){
          var servicioNuevo:LocalServicio={};
          servicioNuevo.LocalID=0;
          servicioNuevo.LocalServicioID=0;
          servicioNuevo.ServicioID=servicioSelec.ServicioID;
          if(event.target.id.includes('emision')){
            servicioNuevo.Emite=true;
            servicioNuevo.Recibe=false;
          }else{
            servicioNuevo.Recibe=true;
            servicioNuevo.Emite=false;
          }
          servicioNuevo.Baja=false;
          this.serviciosSeleccionados.push(servicioNuevo);
        }else{
          var cont=0;
          for(var i:number=0; i<this.serviciosSeleccionados.length; i++){
            if(this.serviciosSeleccionados[i].ServicioID===servicioSelec.ServicioID){
              cont++;
              if(event.target.id.includes('emision')){
                this.serviciosSeleccionados[i].Emite=true;
              }else{
                this.serviciosSeleccionados[i].Recibe=true;
              }
            }else if(i===(this.serviciosSeleccionados.length-1) && cont===0){
              var servicioNuevo:LocalServicio={};
              servicioNuevo.LocalID=0;
              servicioNuevo.LocalServicioID=0;
              servicioNuevo.ServicioID=servicioSelec.ServicioID;
              if(event.target.id.includes('emision')){
                servicioNuevo.Emite=true;
                servicioNuevo.Recibe=false;
              }else{
                servicioNuevo.Recibe=true;
                servicioNuevo.Emite=false;
              }
              servicioNuevo.Baja=false;
              this.serviciosSeleccionados.push(servicioNuevo);
            }
          }
        }
      }else{
        for(var i:number=0; i<this.serviciosSeleccionados.length; i++){
          if(this.serviciosSeleccionados[i].ServicioID===servicioSelec.ServicioID){
            if(event.target.id.includes('emision')){
              this.serviciosSeleccionados[i].Emite=false;
            }else{
              this.serviciosSeleccionados[i].Recibe=false;
            }
            if(this.serviciosSeleccionados[i].Emite===false && this.serviciosSeleccionados[i].Recibe===false){
              this.serviciosSeleccionados.splice(i,1);
              break;
            }
          }
        }
      }
    }else{
      if(event.target.checked){
        if(this.serviciosSeleccionados.length===0){
          var servicioNuevo:LocalServicio={};
          servicioNuevo.LocalID=this.idLocal;
          servicioNuevo.LocalServicioID=0;
          servicioNuevo.ServicioID=servicioSelec.ServicioID;
          if(event.target.id.includes('emision')){
            servicioNuevo.Emite=true;
            servicioNuevo.Recibe=false;
          }else{
            servicioNuevo.Recibe=true;
            servicioNuevo.Emite=false;
          }
          servicioNuevo.Baja=false;
          this.serviciosSeleccionados.push(servicioNuevo);
        }else{
          var cont=0;
          for(var i:number=0; i<this.serviciosSeleccionados.length; i++){
            if(this.serviciosSeleccionados[i].ServicioID===servicioSelec.ServicioID){
              cont++;
              if(event.target.id.includes('emision')){
                this.serviciosSeleccionados[i].Emite=true;
              }else{
                this.serviciosSeleccionados[i].Recibe=true;
              }
            }else if(i===(this.serviciosSeleccionados.length-1) && cont===0){
              var servicioNuevo:LocalServicio={};
              servicioNuevo.LocalID=this.idLocal;
              servicioNuevo.LocalServicioID=0;
              servicioNuevo.ServicioID=servicioSelec.ServicioID;
              if(event.target.id.includes('emision')){
                servicioNuevo.Emite=true;
                servicioNuevo.Recibe=false;
              }else{
                servicioNuevo.Recibe=true;
                servicioNuevo.Emite=false;
              }
              servicioNuevo.Baja=false;
              this.serviciosSeleccionados.push(servicioNuevo);
            }
          }
        }
      }else{
        for(var i:number=0; i<this.serviciosSeleccionados.length; i++){
          if(this.serviciosSeleccionados[i].ServicioID===servicioSelec.ServicioID){
            if(event.target.id.includes('emision')){
              this.serviciosSeleccionados[i].Emite=false;
            }else{
              this.serviciosSeleccionados[i].Recibe=false;
            }
            if(this.serviciosSeleccionados[i].Emite===false && this.serviciosSeleccionados[i].Recibe===false){
              this.serviciosSeleccionados.splice(i,1);
              break;
            }
          }
        }
      }
    }
    
    console.log(this.serviciosSeleccionados)
    
  }

  //guarda el local
  save(){
    if(this.idLocal===0){
      this.localNuevo.Local={};
      this.localNuevo.Local.LocalID=0;
      this.localNuevo.Local.LocalDescrip=this.nombreLocal;
      this.localNuevo.Local.CodigoLocal=this.codigoLocal;
      this.localNuevo.Local.Baja=this.estadoLocal;
      this.localNuevo.Local.PuntoVentaID=this.pvLocal.PuntoVentaID;
      this.localNuevo.Local.PuntoVentaNombre=this.pvLocal.PuntoVentaDescrip;
      this.localNuevo.Local.ResponsableID=this.responsable.ResponsableID;
      this.localNuevo.Local.ResponsableNombre=this.responsable.Nombre+" "+this.responsable.Apellido;
      this.localNuevo.Local.Etiquetas=this.etiquetas;
      this.localNuevo.Local.DomicilioLocalID=0;

      this.localNuevo.LocalCO={};
      this.localNuevo.LocalCO.LocalID=0;
      this.localNuevo.LocalCO.LocalCOID=0;
      this.localNuevo.LocalCO.PuntoRecepcion=this.pRecepcion;
      this.localNuevo.LocalCO.RecibeCargas=this.recibeCargas;
      this.localNuevo.LocalCO.RecibePlanillaArrastre=this.recibePlaArrastre;
      this.localNuevo.LocalCO.RetiroDomicilio=this.retiroDom;
      this.localNuevo.LocalCO.RetirowEB=this.retiroWeb;
      this.localNuevo.LocalCO.TarjetaCredito=this.tarjetaCredito;
      this.localNuevo.LocalCO.VisualizaWeb=this.visualizaWeb;
      this.localNuevo.LocalCO.Activa=this.activa;
      this.localNuevo.LocalCO.Cabecera=this.cabecera;
      this.localNuevo.LocalCO.Colectora=this.colectora;
      this.localNuevo.LocalCO.EntregaDomicilio=this.entregaDom;
      this.localNuevo.LocalCO.EsCD=this.esCD;
      this.localNuevo.LocalCO.PuntoRedespacho=this.pRedespacho;
      if(this.pRedespacho){
        this.localNuevo.LocalCO.LocalReferenciaID=this.localReferencia.LocalID;
      }else{
        this.localNuevo.LocalCO.LocalReferenciaID=0;
      }
      
      this.localNuevo.Domicilio=this.domicilio;

      this.localNuevo.LocalServicio=[];
      this.localNuevo.LocalServicio=this.serviciosSeleccionados;

      this.localService.saveLocal(this.localNuevo).subscribe((local)=>{
        console.log(local);
        if(local.Codigo===200){
          alert(local.Mensaje);
          this.locales.push(local.Local);
          this.localesBusqueda=this.locales;
          this.source.localdata=this.localesBusqueda;
          this.mostrarGrid();
        }else{
          alert(local.Mensaje);
        }
      });
    }else{
      this.localNuevo.Local={};
      this.localNuevo.Local.LocalID=this.idLocal;
      this.localNuevo.Local.LocalDescrip=this.nombreLocal;
      this.localNuevo.Local.CodigoLocal=this.codigoLocal;
      this.localNuevo.Local.Baja=this.estadoLocal;
      this.localNuevo.Local.PuntoVentaID=this.pvLocal.PuntoVentaID;
      this.localNuevo.Local.PuntoVentaNombre=this.pvLocal.PuntoVentaDescrip;
      this.localNuevo.Local.ResponsableID=this.responsable.ResponsableID;
      this.localNuevo.Local.ResponsableNombre=this.responsable.Nombre+" "+this.responsable.Apellido;
      this.localNuevo.Local.Etiquetas=this.etiquetas;
      this.localNuevo.Local.DomicilioLocalID=this.local.Local.DomicilioLocalID;

      this.localNuevo.LocalCO={};
      this.localNuevo.LocalCO.LocalID=this.idLocal;
      this.localNuevo.LocalCO.LocalCOID=this.local.LocalesCO.LocalCOID;
      this.localNuevo.LocalCO.PuntoRecepcion=this.pRecepcion;
      this.localNuevo.LocalCO.RecibeCargas=this.recibeCargas;
      this.localNuevo.LocalCO.RecibePlanillaArrastre=this.recibePlaArrastre;
      this.localNuevo.LocalCO.RetiroDomicilio=this.retiroDom;
      this.localNuevo.LocalCO.RetirowEB=this.retiroWeb;
      this.localNuevo.LocalCO.TarjetaCredito=this.tarjetaCredito;
      this.localNuevo.LocalCO.VisualizaWeb=this.visualizaWeb;
      this.localNuevo.LocalCO.Activa=this.activa;
      this.localNuevo.LocalCO.Cabecera=this.cabecera;
      this.localNuevo.LocalCO.Colectora=this.colectora;
      this.localNuevo.LocalCO.EntregaDomicilio=this.entregaDom;
      this.localNuevo.LocalCO.EsCD=this.esCD;
      this.localNuevo.LocalCO.PuntoRedespacho=this.pRedespacho;
      if(this.pRedespacho){
        this.localNuevo.LocalCO.LocalReferenciaID=this.localReferencia.LocalID;
      }else{
        this.localNuevo.LocalCO.LocalReferenciaID=0;
      }
      
      this.localNuevo.Domicilio=this.domicilio;

      this.localNuevo.LocalServicio=[];
      this.localNuevo.LocalServicio=this.serviciosSeleccionados;

      this.localService.saveLocal(this.localNuevo).subscribe((localApi)=>{
        console.log(localApi);
        if(localApi.Codigo===200){
          alert(localApi.Mensaje);
          for(let local of this.locales){
            if(localApi.Local.LocalID===local.LocalID){
              local.LocalID=localApi.Local.LocalID;
              local.LocalDescrip=localApi.Local.LocalDescrip;
              local.LocalidadDescrip=localApi.Local.LocalidadDescrip;
              local.CodigoLocal=localApi.Local.CodigoLocal;
              local.Baja=localApi.Local.Baja;
              local.DomicilioLocalID=localApi.Local.DomicilioLocalID;
              local.Etiquetas=localApi.Local.Etiquetas;
              local.PuntoVentaID=localApi.Local.PuntoVentaID;
              local.PuntoVentaNombre=localApi.Local.PuntoVentaNombre;
              local.ResponsableID=localApi.Local.ResponsableID;
              local.ResponsableNombre=localApi.Local.ResponsableNombre;
              this.localesBusqueda=this.locales;
              this.source.localdata=this.localesBusqueda;
              this.mostrarGrid();
              break;
            }
          }
        }else{
          alert(localApi.Mensaje);
        }
      });
    }
    $("#modalLocal").modal('hide');
  }

  //deshabilita un local
  delete(idLocal:number){
    
    for(let local of this.locales){
      if(local.LocalID===idLocal){
        this.localNuevo.Local=local;
        this.localNuevo.Local.Baja=true;
        var localId:LocalPostId={};
        localId.LocalID=local.LocalID;
        this.localService.getLocalCO(localId).subscribe((localCo)=>{
          this.localNuevo.LocalCO=localCo.Local;
        })
        this.localService.getLocalServicio(localId).subscribe((localServicio)=>{
          this.localNuevo.LocalServicio=localServicio.LocalesServicio;
        })
        var domicilio:DomicilioId={};
        domicilio.Id=local.DomicilioLocalID;
        this.localService.getLocalDomicilio(domicilio).subscribe((localDomicilio)=>{
          this.localNuevo.Domicilio=localDomicilio.Domicilio;
        })
        console.log(this.localNuevo);
        setTimeout(() => {
          this.localService.saveLocal(this.localNuevo).subscribe((localApi)=>{
            if(localApi.Codigo===200){
              alert(localApi.Mensaje);
              for(let loc of this.locales){
                if(loc.LocalID===localApi.Local.LocalID){
                  local=localApi.Local;
                  this.localesBusqueda=this.locales;            
                  this.source.localdata=this.localesBusqueda;
                  this.mostrarGrid();
                  break;
                }
              }
              
            }else{
              alert(localApi.Mensaje);
            }
          });
        }, 1500);
        break;
      }
    }
  }

  //abrir modal conceptos facturación
  abrirModalCF(local:Local){
    this.conceptosFLocal=[];
    for(let cfB of this.conceptosFacturablesBusqueda){
      $("#cf"+cfB.ConceptoFacturacionId).prop("checked", false);
      $("#guia"+cfB.ConceptoFacturacionId).prop("checked", false);
      $("#entrega"+cfB.ConceptoFacturacionId).prop("checked", false);
    }
    $("#cfselectAll").prop("checked", false);
    this.concepto='';
    this.conceptosFacturablesBusqueda=this.conceptosFacturables;
    var localId:LocalPostId={};
    localId.LocalID=local.LocalID;
    this.localService.getLocalCF(localId).subscribe((res)=>{
      if(res.Conceptos.length!==0){
        this.conceptosFLocal=res.Conceptos;
        for(let localCf of this.conceptosFLocal){
          for(let cf of this.conceptosFacturables){
            if(localCf.ConceptoFacturacionID===cf.ConceptoFacturacionId){
              $("#cf"+cf.ConceptoFacturacionId).prop("checked", true);
              if(localCf.CargarConModalidadEntrega){
                $("#entrega"+cf.ConceptoFacturacionId).prop("checked", true);
              }
              if(localCf.CargarEnEmisionGuia){
                $("#guia"+cf.ConceptoFacturacionId).prop("checked", true)
              }
              break;
            }
          }
        }
      }
      this.idLocal=local.LocalID;
      this.nombreLocal=local.LocalDescrip;
      console.log(this.conceptosFLocal);
    })
    $("#modalCF").modal('show');
  }

  //buscar conceptos facturación
  buscarCF(event){
    this.conceptosFacturablesBusqueda=this.conceptosFacturables.filter((cf)=>{
      return cf.ConceptoFacturacionDescrip.toLowerCase().includes(event.toLowerCase());
    })
    setTimeout(() => {
      this.mostrarCFSelected();
    }, 250);
  }

  mostrarCFSelected(){
    var todos:number=0;
    if(this.conceptosFacturablesBusqueda.length!==0){
      for(let cfB of this.conceptosFacturablesBusqueda){
        for(let cfS of this.conceptosFLocal){
          if(cfB.ConceptoFacturacionId===cfS.ConceptoFacturacionID){
            $("#cf"+cfB.ConceptoFacturacionId).prop("checked", true);
            if(cfS.CargarEnEmisionGuia){
              $("#guia"+cfB.ConceptoFacturacionId).prop("checked", true);
            }
            if(cfS.CargarConModalidadEntrega){
              $("#entrega"+cfB.ConceptoFacturacionId).prop("checked", true);
            }
            todos++;
            break;
          }
        }
      }
      if(todos===this.conceptosFacturablesBusqueda.length){
        $("#cfselectAll").prop("checked", true);
      }else{
        $("#cfselectAll").prop("checked", false);
      }
    }
    
  }

  //seleccionar conceptos facturación
  seleccionarTodosCF(event){
    if(event.target.checked){
      for(let cf of this.conceptosFacturablesBusqueda){
        $("#cf"+cf.ConceptoFacturacionId).prop("checked", true);
        if(this.conceptosFLocal.length!==0){
          for(var i:number=0; i<this.conceptosFLocal.length; i++){
            if(this.conceptosFLocal[i].ConceptoFacturacionID===cf.ConceptoFacturacionId){
              break;
            }else if(i===(this.conceptosFLocal.length-1)){
              var cfLocal:LocalCFacturable={}
              cfLocal.LocalID=this.idLocal;
              cfLocal.ConceptoFacturacionLocalID=0;
              cfLocal.ConceptoFacturacionID=cf.ConceptoFacturacionId;
              cfLocal.CargarConModalidadEntrega=false;
              cfLocal.CargarEnEmisionGuia=false;
              this.conceptosFLocal.push(cfLocal);
              break;
            }
          }
        }else{
          var cfLocal:LocalCFacturable={}
          cfLocal.LocalID=this.idLocal;
          cfLocal.ConceptoFacturacionLocalID=0;
          cfLocal.ConceptoFacturacionID=cf.ConceptoFacturacionId;
          cfLocal.CargarConModalidadEntrega=false;
          cfLocal.CargarEnEmisionGuia=false;
          this.conceptosFLocal.push(cfLocal);
        }
      }
    }else{
      for(let cf of this.conceptosFacturablesBusqueda){
        $("#cf"+cf.ConceptoFacturacionId).prop("checked", false);
        for(var i:number=0; i<this.conceptosFLocal.length; i++){
          if(this.conceptosFLocal[i].ConceptoFacturacionID===cf.ConceptoFacturacionId){
            this.conceptosFLocal.splice(i, 1);
          }
        }
      }
    }
    console.log(this.conceptosFLocal);
    console.log(this.conceptosFacturablesBusqueda)
  }

  seleccionarCF(event, cf:ConceptoFacturable){
    if(event.target.checked){
      if(this.conceptosFLocal.length!==0){
        for(var i:number=0; i<this.conceptosFLocal.length; i++){
          if(this.conceptosFLocal[i].ConceptoFacturacionID===cf.ConceptoFacturacionId){
            break;
          }else if(i===(this.conceptosFLocal.length-1)){
            var cfLocal:LocalCFacturable={}
            cfLocal.LocalID=this.idLocal;
            cfLocal.ConceptoFacturacionLocalID=0;
            cfLocal.ConceptoFacturacionID=cf.ConceptoFacturacionId;
            cfLocal.CargarConModalidadEntrega=false;
            cfLocal.CargarEnEmisionGuia=false;
            this.conceptosFLocal.push(cfLocal);
            break;
          }
        }
      }else{
        var cfLocal:LocalCFacturable={}
        cfLocal.LocalID=this.idLocal;
        cfLocal.ConceptoFacturacionLocalID=0;
        cfLocal.ConceptoFacturacionID=cf.ConceptoFacturacionId;
        cfLocal.CargarConModalidadEntrega=false;
        cfLocal.CargarEnEmisionGuia=false;
        this.conceptosFLocal.push(cfLocal);
      }
      this.mostrarCFSelected();
    }else{
      for(var i:number=0; i<this.conceptosFLocal.length; i++){
        if(this.conceptosFLocal[i].ConceptoFacturacionID===cf.ConceptoFacturacionId){
          this.conceptosFLocal.splice(i, 1);
        }
      }
      this.mostrarCFSelected();
    }
    console.log(this.conceptosFLocal);
  }

  seleccionarCFOpciones(event, cf:ConceptoFacturable, tipo:string){
    if(event.target.checked){
      if(this.conceptosFLocal.length!==0){
        for(var i:number=0; i<this.conceptosFLocal.length; i++){
          if(this.conceptosFLocal[i].ConceptoFacturacionID===cf.ConceptoFacturacionId){
            if(tipo==='guia'){
              this.conceptosFLocal[i].CargarEnEmisionGuia=true;
            }else if(tipo==='entrega'){
              this.conceptosFLocal[i].CargarConModalidadEntrega=true;
            }
          }else if(i===(this.conceptosFLocal.length-1)){
            var cfLocal:LocalCFacturable={}
            cfLocal.LocalID=this.idLocal;
            cfLocal.ConceptoFacturacionLocalID=0;
            cfLocal.ConceptoFacturacionID=cf.ConceptoFacturacionId;
            if(tipo==='guia'){
              cfLocal.CargarEnEmisionGuia=true;
              cfLocal.CargarConModalidadEntrega=false;
            }else if(tipo==='entrega'){
              cfLocal.CargarEnEmisionGuia=false;
              cfLocal.CargarConModalidadEntrega=true;
            }
            $("#cf"+cf.ConceptoFacturacionId).prop("checked", true);
            this.conceptosFLocal.push(cfLocal);
            break;
          }
        }
      }else{
        var cfLocal:LocalCFacturable={}
        cfLocal.LocalID=this.idLocal;
        cfLocal.ConceptoFacturacionLocalID=0;
        cfLocal.ConceptoFacturacionID=cf.ConceptoFacturacionId;
        if(tipo==='guia'){
          cfLocal.CargarEnEmisionGuia=true;
          cfLocal.CargarConModalidadEntrega=false;
        }else if(tipo==='entrega'){
          cfLocal.CargarEnEmisionGuia=false;
          cfLocal.CargarConModalidadEntrega=true;
        }
        $("#cf"+cf.ConceptoFacturacionId).prop("checked", true);
        this.conceptosFLocal.push(cfLocal);
      }
    }else{
      for(var i:number=0; i<this.conceptosFLocal.length; i++){
        if(this.conceptosFLocal[i].ConceptoFacturacionID===cf.ConceptoFacturacionId){
          if(tipo==='guia'){
            this.conceptosFLocal[i].CargarEnEmisionGuia=false;
          }else if(tipo==='entrega'){
            this.conceptosFLocal[i].CargarConModalidadEntrega=false;
          }
          if(this.conceptosFLocal[i].CargarConModalidadEntrega===false && this.conceptosFLocal[i].CargarEnEmisionGuia===false){
            this.conceptosFLocal.splice(i, 1);
            $("#cf"+cf.ConceptoFacturacionId).prop("checked", false);
            break;
          }
          
        }
      }
    }
    console.log(this.conceptosFLocal);
  }

  //guardar conceptos facturación del local
  guardarLocalCF(){
    var localCFNuevo:ConceptoFGet={};
    localCFNuevo.Conceptos=this.conceptosFLocal;
    this.localService.saveLocalCF(localCFNuevo).subscribe((res)=>{
      if(res.Codigo===200){
        alert(res.Mensaje);
      }else{
        alert(res.Mensaje);
      }
    });
    $("#modalCF").modal('hide');
  }

  //cerrar modal conceptos facturación
  cerrarCF(){
    this.conceptosFLocal=[];
    for(let cfB of this.conceptosFacturablesBusqueda){
      $("#cf"+cfB.ConceptoFacturacionId).prop("checked", false);
      $("#guia"+cfB.ConceptoFacturacionId).prop("checked", false);
      $("#entrega"+cfB.ConceptoFacturacionId).prop("checked", false);
    }
    $("#cfselectAll").prop("checked", false);
    this.concepto='';
    this.conceptosFacturablesBusqueda=this.conceptosFacturables;
    $("#modalCF").modal('hide');
  }
}
