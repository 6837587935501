<div class="container-fluid mt-5" style="width: 94%;">
  <div class="row align-items-center rounded mt-5" style="border: solid 1px grey; height: 50px;">
    <h4 class="mt-1">&nbsp;&nbsp; Búsqueda de Usuarios</h4>
  </div><br>
  <div class="row">
    <div class="col">
      <label>Usuario</label>
      <input type="text" class="form-control" id="usu" [(ngModel)]="usuariob" name="usu" (input)="buscarUsuario('usuario', $event.target.value)">
    </div>
    <div class="col">
      <label>Nombre</label>
      <input type="text" class="form-control" id="nomb" [(ngModel)]="nombre" name="nomb" (input)="buscarUsuario('nombre', $event.target.value)">
    </div>
    <div class="col">
      <label>Apellido</label>
      <input type="text" class="form-control" id="ape" [(ngModel)]="apellido" name="ape" (input)="buscarUsuario('apellido', $event.target.value)">
    </div>
  </div>
  <div class="row mt-3">
    <div class="col">
      <label>Agencias</label>
      <select class="custom-select" id="ag" [(ngModel)]="agencia" name="ag" (change)="seleccionarAgencia($event.target.value, 'consulta'); buscarUsuario('agencia', $event.target.value)">
        <option selected value="0" disabled>Seleccione</option>
        <option [value]="ag.AgenciaID" *ngFor="let ag of agencias">{{ag.nroSucursal}}-{{ag.razonSocial}}</option>
        <!-- <option value="1">Agencia</option>
        <option value="2">Punto Venta</option> -->
        <!-- <option value="3">Local</option> -->
      </select>
    </div>
    <div class="col">
      <label>Puntos de Venta</label>
      <select class="custom-select" id="pv" [(ngModel)]="puntoVenta" name="pv">
        <option [value]="pv.PuntoVentaID" [selected]="pv.PuntoVentaID==agenciasPV[0].PuntoVentaID" *ngFor="let pv of agenciasPV">{{pv.nroSucursalPV}}-{{pv.PuntoVentaDescrip}}</option>
      </select>
    </div>
    <div class="col">
      <label>Locales</label>
      <select class="custom-select" id="loc" [(ngModel)]="local" name="loc" (change)="buscarUsuario('local', $event.target.value)">
        <option selected value="0" disabled>Seleccione</option>
        <option [value]="loc.LocalID" *ngFor="let loc of locales">{{loc.CodigoLocal}}-{{loc.LocalDescrip}}</option>
      </select>
    </div>

  </div>
  <div class="row justify-content-end">
    <div class="col-3">
      <button type="button" class="btn btn-primary mt-5" style="margin-left: 10px;" (click)=abrirModal(null)>Nuevo Usuario</button>
    </div>
  </div>
  <br>
  <div *ngIf="cargando" style="font-size: 18px; font-weight: bold;">Cargando...</div><br>
  <table class="table table-bordered table-sm" *ngIf="!cargando">
    <thead>
      <tr>
        <th scope="col">Apellido y Nombre</th>
        <th scope="col">Login</th>
        <th scope="col">Email</th>
        <th scope="col">Estado</th>
        <th></th>
        <th></th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let usu of usuariosFiltro | paginate: {itemsPerPage:10, currentPage:p};">
        <th>{{usu.nombre}}&nbsp;{{usu.apellido}}</th>
        <td>{{usu.login}}</td>
        <td>{{usu.email}}</td>
        <td *ngIf="usu.estadoUsuarioPerfilID==1">Activo</td>
        <td *ngIf="usu.estadoUsuarioPerfilID==2">Suspendido</td>
        <td *ngIf="usu.estadoUsuarioPerfilID==4">Bloqueado por reintentos fallidos de ingreso al sistema</td>
        <td>
          <button class="btn btn-info btn-sm" title="Editar" (click)="abrirModal(usu)">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
              class="bi bi-pencil-square" viewBox="0 0 16 16">
              <path
                d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
              <path fill-rule="evenodd"
                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
            </svg>
          </button>
        </td>
        <td>
          <button class="btn btn-danger btn-sm" title="Eliminar" (click)="eliminarUsuario(usu.usuarioID)">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash"
              viewBox="0 0 16 16">
              <path
                d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
              <path fill-rule="evenodd"
                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
            </svg>
          </button>
        </td>
        <td>
          <button class="btn btn-info btn-sm" title="Perfiles" (click)="abrirModalOpciones(usu)">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-people-fill"
              viewBox="0 0 16 16">
              <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
              <path fill-rule="evenodd"
                d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z" />
              <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
            </svg>
          </button>
        </td>
      </tr>
    </tbody>
    <pagination-controls (pageChange)="p=$event"></pagination-controls>
  </table>
  <!-- <div *ngIf="cargando">
        <h4>Cargando...</h4>
    </div> -->
  <br><br>
</div>
<!-- modal nuevo usuario -->
<div>
  <div class="modal" tabindex="-1" id="modalNuevoUsuario" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Nuevo Usuario-Datos del usuario</h5>
        </div>
        <div class="modal-body">
          <div class="container">
            <div *ngIf="cargandoNuevo" style="font-size: 18px; font-weight: bold;">Cargando...</div><br>
            <div class="row ">
              <div class="col">
                <div class="form-group">
                  <label>Nombre</label>
                  <input class="form-control" type="text" required [(ngModel)]="nombreNuevo">                  
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label>Apellido</label>
                  <input class="form-control" type="text" required [(ngModel)]="apellidoNuevo">                  
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label>Login</label>
                  <input class="form-control" type="text" required [(ngModel)]="loginNuevo">                  
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label>Email</label>
                  <input class="form-control" type="text" required [(ngModel)]="emailNuevo">                  
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col">
                <label>Agencias</label>
                <select class="custom-select" id="agN" [(ngModel)]="agenciaNuevo" name="agN" (change)="seleccionarAgencia($event.target.value, 'nuevo')">
                  <option selected value="0" disabled>Seleccione</option>
                  <option [value]="ag.AgenciaID" *ngFor="let ag of agenciasN">{{ag.nroSucursal}}-{{ag.razonSocial}}</option>
                  <!-- <option value="1">Agencia</option>
                  <option value="2">Punto Venta</option> -->
                  <!-- <option value="3">Local</option> -->
                </select>
              </div>
              <div class="col">
                <label>Puntos de Venta</label>
                <select class="custom-select" id="pvN" [(ngModel)]="pvNuevo" name="pvN">
                  <option [value]="pv.PuntoVentaID" [selected]="pv.PuntoVentaID==agenciasPVN[0].PuntoVentaID" *ngFor="let pv of agenciasPVN">{{pv.nroSucursalPV}}-{{pv.PuntoVentaDescrip}}</option>
                </select>
              </div>
              <div class="col">
                <label>Locales</label>
                <select class="custom-select" id="locN" [(ngModel)]="localNuevo" name="locN">
                  <option selected value="0" disabled>Seleccione</option>
                  <option [value]="loc.LocalID" *ngFor="let loc of localesN">{{loc.CodigoLocal}}-{{loc.LocalDescrip}}</option>
                </select>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col">
                <label>Estado</label>
                <select class="custom-select" id="estN" [(ngModel)]="estadoNuevo" name="estN">
                  <option selected value="0" disabled>Seleccione</option>
                  <option selected value="1">Activo</option>
                  <option selected value="2">Suspendido</option>
                  <option selected value="4">Bloqueados por reintentos fallidos de ingreso al sistema</option>
                  <!-- <option value="1">Agencia</option>
                  <option value="2">Punto Venta</option> -->
                  <!-- <option value="3">Local</option> -->
                </select>
              </div>
              <div class="col">
                <label>Unidad de Negocio</label>
                <select class="custom-select" id="un" [(ngModel)]="unidadNegocio" name="un">
                  <option selected value="0" disabled>Seleccione</option>
                  <option selected value="1">Andesmar S.A.</option>
                  <option selected value="2">Petrobus</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-success" (click)="guardarUsuario()">Guardar</button>
          <button type="button" class="btn btn-danger" (click)="cerrarModal()">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- modal asignar perfiles -->
<div>
  <div class="modal" tabindex="-1" id="modalAsignacionPantallaUsuario" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Asignación de pantallas a usuarios</h5>
        </div>
        <div class="modal-body">
          <div class="container">
            <div class="row">
              <div class="col-4"><b>Usuario: {{usuarioN.nombre}} {{usuarioN.apellido}}</b></div>
            </div>
            <div *ngIf="cargandoPerfil" style="font-size: 18px; font-weight: bold;">Cargando...</div><br>
            <table class="table table-bordered table-sm" *ngIf="!cargando">
              <thead>
                  <tr>
                    <th scope="col" style="text-align:center;">Pantalla</th>
                    <th scope="col" style="text-align:center;">Descripción</th>
                    <th scope="col" style="text-align:center;">Asignado</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let opcion of opciones"> <!--  | paginate: {itemsPerPage:10, currentPage:p}; -->
                    
                    <td>{{opcion.Titulo}}</td>
                    <td>{{opcion.Descripcion}}</td>
                    <th style="text-align:center;">
                      <input type="checkbox" name="" [id]="opcion.OpcionID" (change)="agregarOpciones($event, opcion.OpcionID)">
                    </th>
                  </tr>
                </tbody>
                <!-- <pagination-controls (pageChange)="p=$event"></pagination-controls> -->
            </table>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-success" (click)="guardarOpcionUsuario()">Guardar</button>
          <button type="button" class="btn btn-danger" (click)="cerrarModalOpciones()">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
</div>