import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { DomicilioGet, DomicilioId } from '../models/Domicilio';
import { ConceptoFGet, ConceptoFGetAll, GetAgenciaPVLocal, Local, LocalCOGet, LocalesUV, LocalGetAll, LocalPost, LocalPostId, LocalServicioGet, ServiciosGetAll, UnidadesVentaGet } from '../models/Local';
import { UrlService } from './url.service';

@Injectable({
  providedIn: 'root'
})
export class LocalService {
  local:Local;
  private agregarLocalSubject= new Subject<Local>();
  agregarLocalObservable=this.agregarLocalSubject.asObservable();
  constructor(private http:HttpClient, private urlService:UrlService) { }

  urlServicios:string=this.urlService.urlApi+"/api/Utiles/ObtenerServicios";
  urlUnidadesVenta:string=this.urlService.urlApi+"/api/Utiles/ObtenerUVentas";
  urlLocalUV:string=this.urlService.urlApi+"/api/Local/ObtenerLocalUV";
  urlLocalUVGuardar:string=this.urlService.urlApi+"/api/Local/GuardarUV";
  urlLocalById:string=this.urlService.urlApi+"/api/Local/ObtenerLocalByID";
  
  urlLocalGuardar:string=this.urlService.urlApi+"/api/Local/Guardar";
  /* urlLocalGuardar:string="http://localhost:7724/api/Local/Guardar"; */
  /* urlLocales:string="http://localhost:7724/api/Local/ObtenerLocales"; */
  urlLocalesSimplificado:string=this.urlService.urlApi+"/api/Local/ObtenerLocalesSimplificado";
  urlLocales:string=this.urlService.urlApi+"/api/Local/ObtenerLocales";
  urlLocalCO:string=this.urlService.urlApi+"/api/Local/ObtenerLocalCO";
  urlLocalServicio:string=this.urlService.urlApi+"/api/Local/ObtenerLocalServicio";
  
  urlLocalDomicilio:string=this.urlService.urlApi+"/api/Domicilio/ObtenerDomicilio";
  urlConceptosFacturacion:string=this.urlService.urlApi+"/api/Utiles/ObtenerConceptosFacturacion";
  urlLocalCF:string=this.urlService.urlApi+"/api/Local/ObtenerConceptosFacturableLocal";
  urlLocalCFGuardar:string=this.urlService.urlApi+"/api/Local/GuardarConceptosFacturacionLocal";

  urlObtenerAgencias:string=this.urlService.urlApi+"/api/Utiles/ObtenerAgencias";

  

  agregarLocal(local:Local){
    this.local=local;
    this.agregarLocalSubject.next(local);
  }

  getAllServicios():Observable<ServiciosGetAll>{
    return this.http.post<ServiciosGetAll>(this.urlServicios,"");
  }

  getAllUnidadesVenta():Observable<UnidadesVentaGet>{
    return this.http.post<UnidadesVentaGet>(this.urlUnidadesVenta, "");
  }

  getAllLocales():Observable<LocalGetAll>{
    return this.http.post<LocalGetAll>(this.urlLocales, "");
  }

  getAllLocalesSimplificado():Observable<LocalGetAll>{
    return this.http.post<LocalGetAll>(this.urlLocalesSimplificado, "");
  }

  getOneLocal(local:LocalPostId):Observable<LocalGetAll>{
    return this.http.post<LocalGetAll>(this.urlLocalById, local);
  }

  saveLocal(local:LocalPost):Observable<LocalPost>{
    return this.http.post<LocalPost>(this.urlLocalGuardar, local);
  }

  getLocalUv(local:LocalPostId):Observable<LocalesUV>{
    return this.http.post<LocalesUV>(this.urlLocalUV, local);
  }

  saveLocalUv(localUv:LocalesUV):Observable<LocalesUV>{
    return this.http.post<LocalesUV>(this.urlLocalUVGuardar, localUv);
  }

  getLocalCO(local:LocalPostId):Observable<LocalCOGet>{
    return this.http.post<LocalCOGet>(this.urlLocalCO, local);
  }

  getLocalServicio(local:LocalPostId):Observable<LocalServicioGet>{
    return this.http.post<LocalServicioGet>(this.urlLocalServicio, local);
  }

  getLocalDomicilio(idDomicilio:DomicilioId):Observable<DomicilioGet>{
    return this.http.post<DomicilioGet>(this.urlLocalDomicilio, idDomicilio);
  }

  getAllConceptosF():Observable<ConceptoFGetAll>{
    return this.http.post<ConceptoFGetAll>(this.urlConceptosFacturacion, '');
  }

  getLocalCF(local:LocalPostId):Observable<ConceptoFGet>{
    return this.http.post<ConceptoFGet>(this.urlLocalCF, local);
  }

  saveLocalCF(localCF:ConceptoFGet):Observable<ConceptoFGet>{
    return this.http.post<ConceptoFGet>(this.urlLocalCFGuardar, localCF);
  }

  getAgenciasPVLocal():Observable<GetAgenciaPVLocal>{
    return this.http.post<GetAgenciaPVLocal>(this.urlObtenerAgencias, "");
  }

}
