<!-- búsqueda de cliente -->
<div class="container-md mt-5" *ngIf="clienteSeleccionado.ClienteID==undefined && !validado">
  <div class="row justify-content-center justify-content-md-start">
    <div class="col">
      <div class="form-group centrar">
        <input placeholder="*Seleccione un cliente" class="form-control" required name="codCli" id="codCli" type="text"
          [(ngModel)]="codigoCliente" list="clientes" autocomplete="off" maxlength="100" style="width: 300px;"
          (input)="buscarClientes($event)">
        <datalist id="clientes">
          <option *ngFor="let cliente of clientes" [id]="cliente.ClienteID" style="width: 200px;">
            <span>{{cliente.RazonSocial}} - {{cliente.Codigo}}</span>
          </option>
        </datalist>
        <div class="spinner-border text-info" role="status" *ngIf="cargandoClientes" style="margin-left: 15px;">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- carga de guías -->
<div class="container-md mt-5" *ngIf="clienteSeleccionado.ClienteID!=undefined && !validado">
  <div class="row justify-content-center justify-content-md-start">
    <div class="col">
      <div class="form-group centrar">
        <span class="rounded-pill centrar border" style="width: 300px;">
          Ingresar Paquete <br> {{clienteSeleccionado.RazonSocial}}
        </span>
        <button type="button" class="rounded-circle" (click)="limpiarCliente()"
          style="margin-left: 5px; width: 40px;height: 40px; background-color: #dc3545; border: #dc3545;">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-x-lg"
            viewBox="0 0 16 16">
            <path
              d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
          </svg>
        </button>
      </div>
    </div>
  </div>
  <div class="row justify-content-center justify-content-md-start">
    <div class="col">
      <div class="form-group centrar">
        <input placeholder="*Ingresar código" class="form-control" required name="codRemito" id="codRemito" type="text"
          [(ngModel)]="codigoPedido" autocomplete="off" maxlength="100" style="width: 400px;"
          (keydown.enter)="enviarCodigo()">
        <div class="spinner-border text-info" role="status" *ngIf="cargandoCodigo" style="margin-left: 15px;">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </div>

  <div class="row justify-content-center justify-content-md-start" *ngIf="mensaje!=null && mensaje!=undefined">
    <div class="col">
      <div class="form-group centrar">
        <div class="alert alert-dark" role="alert">
          {{mensaje}}
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-center justify-content-md-start" style="margin-top: 30px;">
    <div class="col">
      <div class="form-group centrar">
        <button type="button" class="btn btn-info rounded-pill" style="width: 300px;"
          (click)="validarOperacion()">Validar Operación</button>
      </div>
    </div>
  </div>
</div>

<!-- info validar operación -->
<div class="container-md mt-5" *ngIf="(pedidosFacturados > 0 || pedidosNoFacturados.length > 0) && !masDetalle">
  <div class="row justify-content-center justify-content-md-start">
    <div class="col">
      <div class="form-group centrar">
        <span class="rounded-pill centrar border" style="width: 300px;">
          Resumen de ingreso <br> cliente {{clienteSeleccionado.RazonSocial}}
        </span>
      </div>
    </div>
  </div>
  <div class="row justify-content-center justify-content-md-start" style="margin-top: 30px;">
    <div class="col">
      <div class="form-group centrar">
        <span style="font-size: 20pt; color:green">PEDIDOS FACTURADOS &nbsp; &nbsp; {{pedidosFacturados}}</span>
      </div>
    </div>
  </div>
  <div class="row justify-content-center justify-content-md-start">
    <div class="col">
      <div class="form-group centrar">
        <span style="font-size: 20pt; color:orange">ERROR EN FACTURAR &nbsp; &nbsp;
          {{pedidosNoFacturados.length}}</span> &nbsp; <button type="button" class="btn btn-link"
          (click)="masDetalle=true"><span style="font-size: 30pt; color: black;">+</span></button>
      </div>
    </div>
  </div>
  <div class="row justify-content-center justify-content-md-start" style="margin-top: 30px;">
    <div class="col">
      <div class="form-group centrar">
        <button type="button" class="btn btn-info rounded-pill" style="width: 200px;" (click)="enviarEmail()">Enviar
          resumen por email</button>
      </div>
    </div>
    <div class="col">
      <div class="form-group centrar">
        <button type="button" class="btn btn-info rounded-pill" style="width: 200px;" (click)="imprimirGuias()">Imprimir
          guías</button>
      </div>
    </div>
    <div class="col">
      <div class="form-group centrar">
        <button type="button" class="btn btn-info rounded-pill" style="width: 200px;"
          (click)="finalizar()">Finalizar</button>
      </div>
    </div>
  </div>
</div>

<!-- detalle errores -->
<div class="container-md mt-5" *ngIf="masDetalle">
  <div class="row justify-content-center justify-content-md-start">
    <div class="col">
      <div class="form-group centrar">
        <span class="rounded-pill centrar border" style="width: 300px;">
          Resumen Errores
        </span>
      </div>
    </div>
  </div>
  <div class="row justify-content-center justify-content-md-start" *ngFor="let p of pedidosNoFacturados">
    <div class="col-8">
      <div class="form-group">
        {{p}}
      </div>
    </div>
  </div>
  <table></table>
  <div class="row justify-content-center justify-content-md-start" style="margin-top: 30px;">
    <div class="col">
      <div class="form-group centrar">
        <button type="button" class="btn btn-info rounded-pill" style="width: 300px;"
          (click)="masDetalle=false">Volver</button>
      </div>
    </div>
  </div>
</div>
