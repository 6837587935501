import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Responsable, ResponsableGet } from '../models/Responsable';
import { UrlService } from './url.service';

@Injectable({
  providedIn: 'root'
})
export class ResponsableService {

  responsable:Responsable={};
  private seleccionarResponsableSubject= new Subject<Responsable>();
  seleccionarResponsableObservable=this.seleccionarResponsableSubject.asObservable();

  urlResponsablesGet:string=this.urlService.urlApi+'/api/Utiles/ObtenerResponsables';


  constructor(private http:HttpClient, private urlService:UrlService) { }

  seleccionarResponsable(responsable:Responsable){
    this.responsable=responsable;
    this.seleccionarResponsableSubject.next(responsable);
  }

  getAllResponsables():Observable<ResponsableGet>{
    return this.http.post<ResponsableGet>(this.urlResponsablesGet, '');
  }
}
