<div (focus)="getObservaciones()" class="modal" tabindex="1" id="modalObservaciones" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Observaciones</h5>
        </div>
        <div class="modal-body">
          <div class="container">
            <div class="row">
              <div class="col">
                <label>Codigo</label>
                <input type="number" class="form-control" placeholder="Buscar ..." [(ngModel)]="codigo" (input)="buscarObservacion($event.target.value, 'codigo')">
              </div>
              <div class="col">
                <label>Observacion</label>
                <input type="text" class="form-control" placeholder="Buscar ..." [(ngModel)]="descripcion" (input)="buscarObservacion($event.target.value, 'descripcion')">
              </div>
            </div>
          </div><br>
          <table class="table">
            <thead class="thead-dark">
              <tr>
                <th scope="col" hidden>ID</th>
                <th scope="col" style="width: 50px; text-align: center;">Código</th>
                <th scope="col" style="text-align: center;">Observación</th>
                <th scope="col" style="text-align: center;"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let obs of observacionesBusqueda  | paginate: {itemsPerPage:10, currentPage:p};">
                <td hidden>{{obs.ObservacionID}}</td>
                <td style="text-align: center;">{{obs.Codigo}}</td>
                <td style="text-align: center;">{{obs.ObsDescrip}}</td>
                <td style="text-align: center;">
                  <button class="btn btn-info" (click)="seleccionarObs(obs)">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                      <path fill-rule="evenodd" d="M1.5 13A1.5 1.5 0 0 0 3 14.5h10a1.5 1.5 0 0 0 1.5-1.5V8a.5.5 0 0 0-1 0v5a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V3a.5.5 0 0 1 .5-.5h8a.5.5 0 0 0 0-1H3A1.5 1.5 0 0 0 1.5 3v10z"/>
                    </svg>
                  </button>
                </td>
              </tr>
            </tbody>
            <pagination-controls (pageChange)="p=$event"></pagination-controls>
          </table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal">Cerrar</button>
        </div>
      </div>
    </div>
  </div>

