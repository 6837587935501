import { Component, OnInit } from '@angular/core';
import { Error } from 'src/app/models/Error';
import { ErroresService } from 'src/app/services/errores.service';
declare var $: any;
@Component({
  selector: 'app-errores',
  templateUrl: './errores.component.html',
  styleUrls: ['./errores.component.scss']
})
export class ErroresComponent implements OnInit {
  errores:Error[];
  error:Error={
    FechaError:''
  };
  constructor(private errorService:ErroresService) { }

  ngOnInit(): void {
    this.errorService.getErrores().subscribe(res=>{
      if(res.errores!=null){
        this.errores=res.errores;
      }
      console.log(this.errores)
    })
  }

  mostrarError(error:Error){
    this.error=error;
    $("#modalDetalle").modal('show');
  }

}
