import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalPostId } from 'src/app/models/Local';
import { GuiaSeguimiento, OrdenRetiroSeguimiento, SeguimientoOrdenPost } from 'src/app/models/Orden-Guia';
import { Usuario } from 'src/app/models/Usuario';
import { EmisionService } from 'src/app/services/emision.service';

@Component({
  selector: 'app-seguimiento-or',
  templateUrl: './seguimiento-or.component.html',
  styleUrls: ['./seguimiento-or.component.scss']
})
export class SeguimientoOrComponent implements OnInit {

  
  cbGuia:string="";
  planillaRetiro:string="";
  nroSeguimiento:string="";
  estados:OrdenRetiroSeguimiento[]=[]
  usuario:Usuario;
  localId: LocalPostId = {
    LocalID: 0
  }
  agenciaID: number = 0;

  constructor(private router:Router, private seguimientoService:EmisionService) { }

  ngOnInit(): void {
    this.usuario=JSON.parse(sessionStorage.getItem('user'));
    if(this.usuario!=null && this.usuario!=undefined){
      for(var i=0; i<this.usuario.OpcionesMenu.length; i++){
        if(this.usuario.OpcionesMenu[i].Url=='seguimiento-or'){ 
          this.localId.LocalID=this.usuario.LocalID;
          this.agenciaID=this.usuario.agenciaID;
          break;
        }else if(i==this.usuario.OpcionesMenu.length-1){
          //window.location.href='home';
          this.router.navigate(['/home'])
        }
      }
      if(this.usuario.OpcionesMenu.length==0){
        this.router.navigate(['/home'])
      }
    }else{
      //window.location.href='home';
      this.router.navigate(['/home'])
    }
  }

  validarDatos():boolean{
    var correcto:boolean=true;
    if(this.planillaRetiro=='' && this.nroSeguimiento=='' && this.cbGuia=='') correcto=false;
    return correcto;
  }

  consultar(){
    var correcto=this.validarDatos();
    if(!correcto) alert("Debe completar alguno de los campos");
    else{
      var tGuia:string='';
      var nroSuc:string='';
      var nroGuia:string="0";
      if(this.cbGuia!='' && this.cbGuia!=undefined && this.cbGuia!=null){
        if(this.cbGuia.substring(0,1)=="3"){
          tGuia="A";
        }else if(this.cbGuia.substring(0,1)=="4"){
          tGuia="B";
        }else if(this.cbGuia.substring(0,1)=="9"){
          tGuia="R";
        }
        nroSuc=this.cbGuia.substring(1,5);
        nroGuia=this.cbGuia.substring(5);
      }
      var datos:SeguimientoOrdenPost={
        nroGuia:nroGuia,
        nroSeguimiento:this.nroSeguimiento,
        nroOrden:this.planillaRetiro,
        tipoGuia:tGuia,
        nroSuc:nroSuc
      }
      this.seguimientoService.getSeguimientoOrdenes(datos).subscribe(res=>{
        if(res.Codigo==400)alert(res.Mensaje);
        else{
          this.estados=res.listaEstados;
        }
      })
    }
  }


  limpiar(){
    this.cbGuia=''
    this.planillaRetiro=''
    this.nroSeguimiento=''
    this.estados=[]
  }

}
